import React, { useContext, useRef } from "react";
import { useState, useEffect } from "react";
import "../../Tabels/Tabels.css";
import { Link } from "react-router-dom";
import { Context } from "../../../utils/context";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import Offcanvas from "react-bootstrap/Offcanvas";
// *******************toster****************************
import Card from "react-bootstrap/Card";
import ModalSave from "../../common/ModelSave";
import Validation from "../../common/FormValidation";
import { CancelButton, SaveButton } from "../../common/Button";
import JoditEditor from "jodit-react";

import {
  Container,
  Row,
  Col,
  Button,
  Form,
  InputGroup,
  Modal,
} from "react-bootstrap";

library.add(fas);

const EditOffCanvance = (props) => {
  const editor = useRef(null);
  const { postData, getData, IMG_URL } = useContext(Context);

  const id = props.show;
  const [data, setData] = useState();

  const [formData, setFormData] = useState({
    image: "",
    name: "",
    description: "",
    imageUrl: "",
  });

  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });

  const handleChange = (e) => {
    if (e?.target?.name === "image") {
      const newFormData = {
        ...formData,
        image: e.target.files[0],
      };

      if (e.target.files[0]?.type.startsWith("image/")) {
        newFormData.imageUrl = URL.createObjectURL(e.target.files[0]);
      } else {
        newFormData.imageUrl = null;
      }

      setFormData(newFormData);
    } else if (e?.target) {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  const handleDescriptionChange = (value) => {
    setFormData({ ...formData, description: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = Validation.FormValidation({
      classname: ".clientreviewclass",
    });

    if (validationErrors) {
      try {
        const data = new FormData();

        data.append("image", formData.image);
        data.append("name", formData.name);
        data.append("description", formData.description);

        const response = await postData(`/masters/client_review/${id}`, data);

        if (response.success) {
          setShowModal({ code: response.code, message: response.message });
          setTimeout(() => {
            setShowModal(0);
            props.handleClose();
          }, 1000);
        } else {
          setShowModal({ code: response.code, message: response.message });
          setTimeout(() => {
            setShowModal(0);
            props.handleClose();
          }, 1000);
        }
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    }
  };

  const GetEditData = async () => {
    const response = await getData(`/masters/client_review/${id}`);

    setData(response);
    setFormData(response?.data);
  };

  useEffect(() => {
    GetEditData();
  }, []);

  const errorStyle = {
    color: "red",
    marginLeft: "5px",
  };

  return (
    <>
      {/* <Offcanvas
        show={props.show}
        style={{ width: "80%" }}
        placement={"end"}
        onHide={props.handleClose}
      >
        <Offcanvas.Header closeButton></Offcanvas.Header>
        <Offcanvas.Body>
          <Card className="shadow-lg p-3 mb-5 bg-white rounded">
            <Card.Body>
              <Card.Title>Edit Client Review </Card.Title>
              <hr />
              <Container>
                <Row>
                  <Col md={12}>
                    <Container>
                      <Row className="">
                        <Col xxl={12} xl={12} md={10}>
                          <Form
                            onSubmit={handleSubmit}
                            role="form"
                            className="clientreviewclass"
                          >
                            <Row>
                              <Col
                                lg={8}
                                md={10}
                                className=" mx-auto Add-content"
                              >
                                <Col sm={12}>
                                  <div className="main-form-section mt-5">
                                    <Row className="row justify-content-center mb-2">
                                      <Form.Label column sm={3}>
                                        Name
                                      </Form.Label>
                                      <Col sm={6}>
                                        <Form.Group>
                                          <InputGroup>
                                            <Form.Control
                                              type="text"
                                              name="name"
                                              value={formData.name}
                                              onChange={handleChange}
                                              placeholder="Enter Name"
                                              id="inputEmail3"
                                              className="input-mandatory"
                                            />
                                          </InputGroup>
                                          <span className="error-message"></span>
                                        </Form.Group>
                                      </Col>
                                    </Row>
                                  </div>
                                </Col>

                                <Col sm={12}>
                                  <div className="main-form-section mt-5">
                                    <Row className="row justify-content-center mb-2">
                                      <Form.Label column sm={3}>
                                        Image
                                      </Form.Label>
                                      <Col sm={6}>
                                        <Form.Group>
                                          <InputGroup>
                                            <Form.Control
                                              type="file"
                                              name="image"
                                              onChange={handleChange}
                                              placeholder="Add Image"
                                              id="inputEmail3"
                                            />
                                          </InputGroup>

                                          <span className="error-message"></span>
                                        </Form.Group>
                                      </Col>
                                    </Row>
                                  </div>
                                </Col>

                                <Col sm={12}>
                                  <div className="main-form-section mt-5">
                                    <Row className="row justify-content-center mb-2">
                                      <Form.Label column sm={3}>
                                        Description
                                      </Form.Label>
                                      <Col sm={6}>
                                        <Form.Group>
                                          <InputGroup>
                                            <Form.Control
                                              type="text"
                                              name="description"
                                              value={formData.description}
                                              onChange={handleChange}
                                              placeholder="Description"
                                              id="inputEmail3"
                                              className="input-mandatory"
                                            />
                                          </InputGroup>
                                          <span className="error-message"></span>
                                        </Form.Group>
                                      </Col>
                                    </Row>
                                  </div>
                                </Col>

                                <Row>
                                  <Col md={12}>
                                    <div className="main-form-section mt-5">
                                      <Row className="justify-content-center">
                                        <Form.Label column sm={3}>
                                          Image Preview
                                        </Form.Label>
                                        <Col sm={6}>
                                          {formData.image && (
                                            <Col
                                              sm={3}
                                              className="image-preview-col"
                                            >
                                              <div className="image-preview-container">
                                                <img
                                                  src={
                                                    formData?.imageUrl
                                                      ? formData?.imageUrl
                                                      : IMG_URL + formData.image
                                                  }
                                                  alt="Preview"
                                                  className="image-preview-container"
                                                  style={{
                                                    width: "120px",
                                                    height: "120px",
                                                  }}
                                                />
                                              </div>
                                            </Col>
                                          )}
                                        </Col>
                                      </Row>
                                    </div>
                                  </Col>
                                </Row>

                                <Row className="mt-5 pb-3">
                                  <div className="d-flex justify-content-center">
                                    <Link>
                                      <CancelButton
                                        name={"cancel"}
                                        handleClose={props.handleClose}
                                      />
                                    </Link>
                                    <SaveButton
                                      name={"save"}
                                      handleSubmit={handleSubmit}
                                    />
                                  </div>
                                </Row>
                              </Col>
                            </Row>
                          </Form>
                        </Col>
                      </Row>
                    </Container>
                  </Col>
                </Row>
              </Container>
            </Card.Body>
          </Card>
        </Offcanvas.Body>
      </Offcanvas> */}

      <Modal
        {...props}
        onHide={props.handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Edit Client Review
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            onSubmit={handleSubmit}
            role="form"
            className="clientreviewclass"
          >
            <Row>
              <Col sm={6}>
                <div className="main-form-section mt-3">
                  <Row className="row justify-content-center mb-2">
                    <Form.Label>Name</Form.Label>

                    <Form.Group>
                      <InputGroup>
                        <Form.Control
                          type="text"
                          name="name"
                          value={formData.name}
                          onChange={handleChange}
                          placeholder="Enter Name"
                          id="inputEmail3"
                          className="input-mandatory"
                        />
                      </InputGroup>
                      <span className="error-message"></span>
                    </Form.Group>
                  </Row>
                </div>
              </Col>

              <Col sm={6}>
                <div className="main-form-section mt-3">
                  <Row className="row justify-content-center mb-2">
                    <Form.Label>Image</Form.Label>

                    <Form.Group>
                      <InputGroup>
                        <Form.Control
                          type="file"
                          name="image"
                          onChange={handleChange}
                          placeholder="Add Image"
                          id="inputEmail3"
                        />
                      </InputGroup>

                      <span className="error-message"></span>
                    </Form.Group>
                  </Row>
                </div>
              </Col>

              <Col md={12}>
                <div className="main-form-section mt-5">
                  <Row className="">
                    <Form.Label>Image Preview</Form.Label>

                    {formData.image && (
                      <Col sm={3} className="image-preview-col">
                        <div className="image-preview-container">
                          <img
                            src={
                              formData?.imageUrl
                                ? formData?.imageUrl
                                : IMG_URL + formData.image
                            }
                            alt="Preview"
                            className="image-preview-container"
                            style={{
                              width: "120px",
                              height: "120px",
                            }}
                          />
                        </div>
                      </Col>
                    )}
                  </Row>
                </div>
              </Col>

              <Col md={12}>
                <div className="main-form-section mt-5">
                  <Row className="justify-content-center">
                    <Form.Label>Description</Form.Label>

                    <Form.Group>
                      <InputGroup>
                        <JoditEditor
                          ref={editor}
                          value={formData?.description}
                          tabIndex={2}
                          className="custom-jodit-editor"
                          onChange={handleDescriptionChange}
                        />
                      </InputGroup>
                      <span className="error-message"></span>
                    </Form.Group>
                  </Row>
                </div>
              </Col>

              <Row className="mt-5 pb-3">
                <div className="d-flex justify-content-center">
                  <Link>
                    <CancelButton
                      name={"cancel"}
                      handleClose={props.handleClose}
                    />
                  </Link>
                  <SaveButton name={"save"} handleSubmit={handleSubmit} />
                </div>
              </Row>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>

      <ModalSave
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      />
    </>
  );
};

export default EditOffCanvance;
