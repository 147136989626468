import React, { useContext ,useEffect} from "react";
import { useState } from "react";
import "../../../../../Tabels/Tabels.css";
import { Context } from "../../../../../../utils/context";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";

import ModalSave from "../../../../../common/ModelSave";
import { CancelButton, SaveButton } from "../../../../../common/Button";
import { Row, Col, Form, Modal } from "react-bootstrap";
// import { useForm } from "react-hook-form";
import classNames from "classnames";
import JoditEditor from 'jodit-react';
import { useForm, Controller } from 'react-hook-form';

library.add(fas);

const AddOffCanvance = (props) => {
  const { postData, getDimension } = useContext(Context);

  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    watch,
    trigger,
    setValue
  } = useForm();

  const imageFile1 = watch("image1");
  const imageFile2 = watch("image2");
  const descriptionValue = watch("description");
  console.log(errors);
  const onSubmit = async (data) => {
    try {
      const fromData = new FormData();
      fromData.append("title", data?.title);
      fromData.append("description", data?.description);
      fromData.append("image1", data?.image1[0]);
      fromData.append("image2", data?.image2[0]);
      const response = await postData(`/masters/ed-tech/course/pg-course/mba/what`, fromData);
      console.log(response);
      if (response?.success) {
        await setShowModal({ code: response.code, message: response.message });
      } else {
        await setShowModal({ code: response.code, message: response.message });
      }
      setTimeout(() => {
        setShowModal(0);
        props.handleClose();
      }, 1000);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    register('description', {
      required: 'Description is required.',
      maxLength: {
        value: 600,
        message: 'Description must be at most 600 characters long.'
      }
    });
  }, [register]);
  return (
    <>
      <Modal
        onHide={props.handleClose}
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Add What Is
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            onSubmit={() => handleSubmit(onSubmit)}
            role="form"
            className="countryclass"
          >
            <Row>
              {/* Title */}
              <Row>
              <Col lg={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label column sm={3} className="text-center">
                      Title
                    </Form.Label>
                    <Col sm={9}>
                      <Form.Group>
                        <Form.Control
                          className={classNames("", {
                            "is-invalid": errors?.title,
                          })}
                          type="text"
                          {...register("title", {
                            required: "Title is required",
                            maxLength: {
                              value: 30,
                              message: "subtitle must be at most 30 characters long",
                            },
                          })}
                          placeholder="Title"
                        />
                      </Form.Group>
                      {errors.title && (
                        <span className="text-danger">
                          {errors.title.message}
                        </span>
                      )}
                    </Col>
                  </Row>
                </div>
              </Col>
              </Row>
              
             
              
              <Col lg={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label column sm={3} className="text-center">
                      Image1
                    </Form.Label>
                    <Col sm={9}>
                      <Form.Group>
                        <Form.Control
                          className={classNames("", {
                            "is-invalid": errors?.image1,
                          })}
                          type="file"
                          {...register("image1", {
                            required: "image1 is required",
                            validate: async (value) => {
                              const fileTypes = ["jpg", "png", "jpeg"];
                              const fileType = value[0].name.split(".")[1];

                              if (!fileTypes.includes(fileType)) {
                                return `please upload a valid file format. (${fileTypes})`;
                              }

                              const sizes = await getDimension(value[0]);
                              if (sizes.width !== 285 && sizes.height !== 298) {
                                return "Image width and height must be 285px and 298px";
                              }

                              const fileSize = Math.round(value[0].size / 1024);
                              if (fileSize > 500) {
                                return "file size must be lower than 500kb";
                              }
                            },
                          })}
                          accept=".jpg, .jpeg, .png"
                        />
                      </Form.Group>
                      {errors.image1 && (
                        <span className="text-danger">
                          {errors.image1.message}
                        </span>
                      )}
                    </Col>
                  </Row>
                </div>
              </Col>

              {/* Image Preview */}
              <Col lg={3}>

                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label column sm={3} className="text-center">
                      Image Preview
                    </Form.Label>
                    <Col sm={9}>
                      {imageFile1 && imageFile1?.length > 0 && (
                        <div className="image-preview-container">
                          <img
                            src={URL.createObjectURL(imageFile1[0])}
                            alt="Preview"
                            className="image-preview"
                            style={{width:100 ,height:100 , marginLeft:50}}
                          />
                        </div>
                      )}
                    </Col>
                  </Row>
                </div>
              </Col>

              <Col lg={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label column sm={3} className="text-center">
                      Image2
                    </Form.Label>
                    <Col sm={9}>
                      <Form.Group>
                        <Form.Control
                          className={classNames("", {
                            "is-invalid": errors?.image2,
                          })}
                          type="file"
                          {...register("image2", {
                            required: "image2 is required",
                            validate: async (value) => {
                              const fileTypes = ["jpg", "png", "jpeg"];
                              const fileType = value[0].name.split(".")[1];

                              if (!fileTypes.includes(fileType)) {
                                return `please upload a valid file format. (${fileTypes})`;
                              }

                              const sizes = await getDimension(value[0]);
                              if (sizes.width !== 255 && sizes.height !== 298) {
                                return "Image width and height must be 255px and 298px";
                              }

                              const fileSize = Math.round(value[0].size / 1024);
                              if (fileSize > 500) {
                                return "file size must be lower than 500kb";
                              }
                            },
                          })}
                          accept=".jpg, .jpeg, .png"
                        />
                      </Form.Group>
                      {errors.image2 && (
                        <span className="text-danger">
                          {errors.image2.message}
                        </span>
                      )}
                    </Col>
                  </Row>
                </div>
              </Col>


              {/* Image Preview */}
              <Col lg={3}>

                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label column sm={3} className="text-center">
                      Image Preview
                    </Form.Label>
                    <Col sm={9}>
                      {imageFile2 && imageFile2?.length > 0 && (
                        <div className="image-preview-container">
                          <img
                            src={URL.createObjectURL(imageFile2[0])}
                            alt="Preview"
                            className="image-preview"
                            style={{width:100 ,height:100 , marginLeft:50}}
                          />
                        </div>
                      )}
                    </Col>
                  </Row>
                </div>
              </Col>

              <Col lg={12}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label column sm={2} className="text-center">
                      Description
                    </Form.Label>
                    <Col sm={10}>
                      <Form.Group>
                        {/* <Controller
                          name="description" // Provide the field name
                          control={control} // Pass the control object from useForm()
                          rules={{ required: "Description is required" ,maxLength: {
                            value: 600,
                            message: "subtitle must be at most 600 characters long",
                          },}} // Validation rules
                          render={({ field }) => (
                            <JoditEditor
                              value={field.value}
                              onChange={(newContent) => field.onChange(newContent)}
                              onBlur={field.onBlur}
                              className={classNames("", {
                                "is-invalid": !!errors.description,
                              })}
                              placeholder="Description"
                            />
                          )}
                        /> */}
                         <JoditEditor
                        value={descriptionValue || ''}
                        onBlur={(newContent) => {
                          setValue('description', newContent); 
                          trigger('description'); 
                        }}
                      
                      />
                      </Form.Group>
                      {errors.description && (
                        <span className="text-danger">
                          {errors.description.message}
                        </span>
                      )}
                    </Col>
                  </Row>
                </div>
              </Col>
            
            </Row>



            {/* Buttons */}
            <Row className="justify-content-center mt-5 pb-3">
              <Col sm={6} className="d-flex justify-content-center">
                <CancelButton name={"Cancel"} handleClose={props.handleClose} />
                <SaveButton
                  name={"Save"}
                  handleSubmit={handleSubmit(onSubmit)}
                />
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>

      <ModalSave
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      />
    </>
  );
};

export default AddOffCanvance;
