import React, { useContext } from "react";
import { useState, useEffect, useRef } from "react";
import "../../../Tabels/Tabels.css";
import { Link } from "react-router-dom";
import { Context } from "../../../../utils/context";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import Offcanvas from "react-bootstrap/Offcanvas";
// *******************toster****************************
import Card from "react-bootstrap/Card";
import ModalSave from "../../../common/ModelSave";
import Validation from "../../../common/FormValidation";
import { CancelButton, SaveButton } from "../../../common/Button";
import Modal from "react-bootstrap/Modal";
import { Container, Row, Col, Button, Form, InputGroup } from "react-bootstrap";
import JoditEditor from "jodit-react";
import { useForm, Controller } from "react-hook-form";
import classNames from "classnames";
import Select from "react-select";

library.add(fas);

const EditOffCanvance = (props) => {
  const { postData, getData, IMG_URL, getDimension, Select2Data } = useContext(Context);

  const id = props.show;
  const [partnerTypes, setPartnerTypes] = useState([]);
  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });

  const GetEditData = async () => {
    const response = await getData(`/masters/finance/home/f-our-partner/${id}`);
    console.log(response?.data);
    reset(response?.data);
  };

  const GetAllPartnerType = async () => {
    const response = await getData("/allFPartnerTypes");

    if (response.success) {
      setPartnerTypes(await Select2Data(response?.data, "partner_type_id"));
    }
  };

  useEffect(() => {
    GetEditData();
    GetAllPartnerType();
  }, []);


  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    watch,
    getValues,
    setValue,
    reset,
  } = useForm();
  console.log("getValues", getValues());
  const imageFile = watch("image");

  const onSubmit = async (data) => {
    try {
      const fromData = new FormData();
      fromData.append("name", data?.name);
      fromData.append("partner_type_id", data?.partner_type_id?.value);
      fromData.append("image", data?.image[0]);
      const response = await postData(
        `/masters/finance/home/f-our-partner/${id}`,
        fromData
      );
      console.log(response);
      if (response?.success) {
        await setShowModal({ code: response.code, message: response.message });
      } else {
        await setShowModal({ code: response.code, message: response.message });
      }
      setTimeout(() => {
        setShowModal(0);
        props.handleClose();
      }, 1000);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Modal
        onHide={props.handleClose}
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Edit Our Partner
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            onSubmit={() => handleSubmit(onSubmit)}
            role="form"
            className="countryclass"
          >
            <Row>
              {/* Title */}
              <Col lg={6}>
                <div className="main-form-section mt-3">
                  <Row className="row justify-content-center mb-2">
                    <Form.Label>Partner Type</Form.Label>

                    <Controller
                      name="partner_type_id" // name of the field
                      {...register("partner_type_id", {
                        required: "Course Type is required",
                      })}
                      control={control}
                      render={({ field }) => (
                        <Select
                          styles={{
                            control: (baseStyles) => ({
                              ...baseStyles,
                              borderColor: errors.partner_type_id
                                ? "red"
                                : baseStyles,
                            }),
                          }}
                          {...field}
                          options={partnerTypes}


                        />
                      )}
                    />

                    {errors.partner_type_id && (
                      <span className="text-danger">
                        {errors.partner_type_id.message}
                      </span>
                    )}
                  </Row>
                </div>
                <div className="main-form-section mt-3">

                  <Form.Label>Title</Form.Label>

                  <Form.Group>
                    <Form.Control
                      className={classNames("", {
                        "is-invalid": errors?.name,
                      })}
                      type="text"
                      {...register("name", {
                        required: "Title is required",
                        // maxLength: {
                        //   value: 20,
                        //   message: "Title must be at most 20 characters long",
                        // },
                      })}
                      placeholder="Title"
                    />
                  </Form.Group>
                  {errors.name && (
                    <span className="text-danger">
                      {errors.name.message}
                    </span>
                  )}


                </div>
                <div className="main-form-section mt-3">

                  <Form.Label column sm={3} className="text-center">
                    Image
                  </Form.Label>

                  <Form.Control
                    className={classNames("", {

                    })}
                    type="file"
                    {...register("image", {

                      // validate: async (value) => {
                      //   if (typeof value !== "string") {
                      //     const fileTypes = ["jpg", "png", "jpeg"];
                      //     const fileType = value[0]?.name?.split(".")[1];

                      //     if (!fileTypes.includes(fileType)) {
                      //       return `please upload a valid file format. (${fileTypes})`;
                      //     }

                      //     const sizes = await getDimension(value[0]);
                      //     if (sizes.width !== 295 && sizes.height !== 270) {
                      //       return "Image width and height must be 295 px and 270 px";
                      //     }

                      //     const fileSize = Math.round(value[0].size / 1024);
                      //     if (fileSize > 500) {
                      //       return "file size must be lower than 500kb";
                      //     }
                      //   }
                      // },
                    })}
                    accept=".jpg, .jpeg, .png"
                  />
                  {errors.image && (
                    <span className="text-danger">
                      {errors.image.message}
                    </span>
                  )}
                </div>
              </Col>
              <Col lg={6} className="text-center">
                {/* Image Preview */}
                <Col lg={6}>
                  <div className="main-form-section mt-3">
                    <Form.Label >Image Preview</Form.Label>
                    {typeof getValues("image") == "string" ? (
                      <div className="image-preview-container">
                        <img
                          src={IMG_URL + getValues("image")}
                          alt="Preview"
                          className="image-preview"
                          style={{ width: "150px", height: "130px" }}
                        />
                      </div>
                    ) : (
                      imageFile &&
                      imageFile?.length > 0 && (
                        <div className="image-preview-container">
                          <img
                            // src={URL.createObjectURL(getValues("image")[0])}
                            src={URL?.createObjectURL(imageFile[0])}
                            alt="Preview"
                            className="image-preview"
                            style={{ width: "100px", height: "100px" }}
                          />
                        </div>
                      )
                    )}


                  </div>
                </Col>
                {/* </Row>
                </div> */}
              </Col>
            </Row>



            {/* Buttons */}
            <Row className="justify-content-center mt-5 pb-3">
              <Col sm={6} className="d-flex justify-content-center">
                <CancelButton name={"Cancel"} handleClose={props.handleClose} />
                <SaveButton
                  name={"Save"}
                  handleSubmit={handleSubmit(onSubmit)}
                />
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>

      <ModalSave
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      />
    </>
  );
};

export default EditOffCanvance;
