import React, { useContext, useEffect } from "react";
import { useState } from "react";
import "../../Tabels/Tabels.css";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";

import ModalSave from "../../common/ModelSave";
import { CancelButton, SaveButton } from "../../common/Button";
import { Row, Col, Form, Modal, InputGroup } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import classNames from "classnames";
import { Context } from "../../../utils/context";
import Select from "react-select";
import ColorPickerInput from "../../common/ColorPicker";
library.add(fas);

const AddOffCanvance = (props) => {
  const { postData, getData, Select2Data, getDimension } = useContext(Context);
  const [course, setCourse] = useState([]);

  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm();

  const imageFile = watch("image");
  console.log(errors);

  const onSubmit = async (data) => {
    try {
      const fromData = new FormData();
      fromData.append("name", data?.name);
      fromData.append("border_color", data?.border_color);
      fromData.append("image", data?.image[0]);
      const response = await postData(`/masters/university`, fromData);

      if (response?.success) {
        await setShowModal({
          code: response?.code,
          message: response?.message,
        });
      } else {
        await setShowModal({
          code: response?.code,
          message: response?.message,
        });
      }
      setTimeout(() => {
        setShowModal(0);
        props.handleClose();
      }, 1000);
    } catch (error) {
      console.log(error);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSubmit(onSubmit)();
    }
  };

  return (
    <>
      <Modal
        onHide={props.handleClose}
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Add University
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            onSubmit={() => handleSubmit(onSubmit)}
            role="form"
            className="countryclass"
            onKeyDown={handleKeyDown}
          >
            <Row>
              <Col lg={6}>
                <div className="main-form-section mt-3">

                  <Form.Label >
                    University
                  </Form.Label>

                  <Form.Group>
                    <Form.Control
                      className={classNames("", {
                        "is-invalid": errors?.name,
                      })}
                      type="text"
                      {...register("name", {
                        required: "Title is required",
                      })}
                      placeholder="Title"
                    />
                  </Form.Group>
                  {errors.name && (
                    <span className="text-danger">
                      {errors.name.message}
                    </span>
                  )}


                </div>
              </Col>
              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label>Border Color</Form.Label>
                    <Form.Group>
                      <InputGroup>
                        <Form.Control
                          type="color"
                          name="border_color"
                          placeholder="Header Color"
                          className={classNames("", {
                            "is-invalid": errors?.border_color,
                          })}
                          {...register("border_color", {
                            required: "Border Color is required",
                          })}
                        />
                      </InputGroup>
                      {errors.border_color && (
                        <span className="text-danger">
                          {errors.border_color.message}
                        </span>
                      )}
                    </Form.Group>
                  </Row>
                </div>
              </Col>

              {/* Image Upload */}
              <Col lg={6}>
                <div className="main-form-section mt-3">

                  <Form.Label >
                    Image
                  </Form.Label>

                  <Form.Group>
                    <Form.Control
                      className={classNames("", {
                        "is-invalid": errors?.image,
                      })}
                      type="file"
                      {...register("image", {
                        required: "Image is required",
                        // validate: async (value) => {
                        //   const fileTypes = ["jpg", "png", "jpeg"];
                        //   const fileType = value[0].name.split(".")[1];

                        //   if (!fileTypes.includes(fileType)) {
                        //     return `please upload a valid file format. (${fileTypes})`;
                        //   }

                        //   // const sizes = await getDimension(value[0]);
                        //   // if (sizes.width !== 300 && sizes.height !== 324) {
                        //   //   return "Image width and height must be 300px and 324px";
                        //   // }

                        //   // const fileSize = Math.round(value[0].size / 1024);
                        //   // if (fileSize > 500) {
                        //   //   return "file size must be lower than 500kb";
                        //   // }
                        // },
                      })}
                      accept=".jpg, .jpeg, .png"
                    />
                  </Form.Group>
                  {errors.image && (
                    <span className="text-danger">
                      {errors.image.message}
                    </span>
                  )}

                </div>
              </Col>

              {/* Image Preview */}
              <Col lg={12}>
                <div className="main-form-section mt-3">

                  <Form.Label >
                    Image Preview
                  </Form.Label>

                  {imageFile && imageFile?.length > 0 && (
                    <div className="image-preview-container">
                      <img
                        src={URL.createObjectURL(imageFile[0])}
                        alt="Preview"
                        className="image-preview"
                        style={{ width: "100px", height: "100px" }}
                      />
                    </div>
                  )}

                </div>
              </Col>
            </Row>

            {/* Buttons */}
            <Row className="justify-content-center mt-5 pb-3">
              <Col sm={6} className="d-flex justify-content-center">
                <CancelButton name={"Cancel"} handleClose={props.handleClose} />
                <SaveButton
                  name={"Save"}
                  handleSubmit={handleSubmit(onSubmit)}
                />
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>

      <ModalSave
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      />
    </>
  );
};

export default AddOffCanvance;
