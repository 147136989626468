import React, { useContext } from "react";
import { useState, useEffect, useRef } from "react";
import "../../../Tabels/Tabels.css";
import { Link } from "react-router-dom";
import { Context } from "../../../../utils/context";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import Offcanvas from "react-bootstrap/Offcanvas";
// *******************toster****************************
import Card from "react-bootstrap/Card";
import ModalSave from "../../../common/ModelSave";
import Validation from "../../../common/FormValidation";
import { CancelButton, SaveButton } from "../../../common/Button";
import Modal from "react-bootstrap/Modal";
import { Container, Row, Col, Button, Form, InputGroup } from "react-bootstrap";
import JoditEditor from "jodit-react";

import classNames from "classnames";

import Select from "react-select";
import { useForm, Controller } from "react-hook-form";
library.add(fas);

const EditOffCanvance = (props) => {
  const { postData, getData, IMG_URL, getDimension, Select2Data } = useContext(Context);


  const id = props.show;

  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });


  const GetEditData = async () => {
    const response = await getData(
      `/masters/ed-tech/specialization/highlights/${id}`
    );
    console.log(response.data);
    reset(response?.data);
  };

  useEffect(() => {
    GetEditData();
  }, []);

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    reset,
    watch,
    trigger,
  } = useForm();
  console.log(getValues());
  const imageFile = watch("image");

  const [university, setUniversity] = useState([]);
  const GetAllUniversity = async () => {
    const response = await getData("/allSpecialization");

    if (response.success) {
      setUniversity(await SelectData(response?.data, "specialization_id"));
    }
  };
  useEffect(() => {
    GetAllUniversity();
  }, []);

  const SelectData = async (data, name, other = false) => {
    const result = data.map((data) => ({
      value: data?.id,
      label: `${data?.name}-${data?.cource_university?.university?.name}-${data?.cource_university?.ed_tech_course?.name}`,
      name: name,
    }));

    if (other) {
      result.push({ value: "0", label: "Other", name: name });
    }
    return result;
  };

  const onSubmit = async (data) => {
    try {
      const fromData = new FormData();
      fromData.append("name", data?.name);
      fromData.append("specialization_id", data?.specialization_id?.value);
      if (typeof data?.image !== "string") {
        fromData.append("image", data?.image[0]);
      }
      const response = await postData(
        `/masters/ed-tech/specialization/highlights/${id}`,
        fromData
      );
      console.log(response);
      if (response?.success) {
        await setShowModal({ code: response.code, message: response.message });
      } else {
        await setShowModal({ code: response.code, message: response.message });
      }
      setTimeout(() => {
        setShowModal(0);
        props.handleClose();
      }, 1000);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Modal
        onHide={props.handleClose}
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Edit Highlights
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            onSubmit={() => handleSubmit(onSubmit)}
            role="form"
            className="countryclass"
          >
            <Row>
              {/* Title */}

              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="row justify-content-center mb-2">
                    <Form.Label>Specialization</Form.Label>

                    <Controller
                      name="specialization_id" // name of the field
                      {...register("specialization_id", {
                        required: "Specialization  is required",
                      })}
                      control={control}
                      render={({ field }) => (
                        <Select
                          styles={{
                            control: (baseStyles) => ({
                              ...baseStyles,
                              borderColor: errors.specialization_id
                                ? "red"
                                : baseStyles,
                            }),
                          }}
                          {...field}
                          options={university}
                        />
                      )}
                    />


                    {errors.specialization_id && (
                      <span className="text-danger">
                        {errors.specialization_id.message}
                      </span>
                    )}
                  </Row>
                </div>

                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label>Title</Form.Label>

                    <Form.Group>
                      <Form.Control
                        className={classNames("", {
                          "is-invalid": errors?.name,
                        })}
                        type="text"
                        {...register("name", {
                          required: "Title is required",
                        })}
                        placeholder="Title"
                      />
                    </Form.Group>
                    {errors.name && (
                      <span className="text-danger">
                        {errors.name.message}
                      </span>
                    )}

                  </Row>
                </div>

                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label > Image </Form.Label>

                    <Form.Group>
                      <Form.Control
                        className={classNames("", {

                        })}
                        type="file"
                        {...register("image", {
                          // required: "Image is required",
                          // validate: async (value) => {
                          //   if (typeof value !== "string") {
                          //     const fileTypes = ["jpg", "png", "jpeg"];
                          //     const fileType = value[0].name?.split(".")[1];

                          //     if (!fileTypes.includes(fileType)) {
                          //       return `please upload a valid file format. (${fileTypes})`;
                          //     }

                          //     const sizes = await getDimension(value[0]);
                          //     if (
                          //       sizes.width !== 1296 &&
                          //       sizes.height !== 396
                          //     ) {
                          //       return "Image width and height must be 1296px and 396px";
                          //     }


                          //   }
                          // },
                        })}
                        accept=".jpg, .jpeg, .png"
                      />
                    </Form.Group>
                    {errors.image && (
                      <span className="text-danger">
                        {errors.image.message}
                      </span>
                    )}

                  </Row>
                </div>

              </Col>
              <Col lg={6}>

                <div className="main-form-section mt-3" style={{ marginLeft: '50px' }}>
                  <Row className="justify-content-center">
                    <Form.Label> Image Preview</Form.Label>

                    {typeof getValues("image") == "string" ? (
                      <div className="image-preview-container">
                        <img
                          src={IMG_URL + getValues("image")}
                          alt="Preview"
                          className="image-preview"
                          style={{ width: "220px", height: "200px" }}
                        />
                      </div>
                    ) : (
                      imageFile &&
                      imageFile?.length > 0 && (
                        <div className="image-preview-container">
                          <img
                            // src={URL.createObjectURL(getValues("image")[0])}
                            src={URL?.createObjectURL(imageFile[0])}
                            alt="Preview"
                            className="image-preview"
                            style={{ width: "220px", height: "200px" }}
                          />
                        </div>
                      )
                    )}
                  </Row>
                </div>
              </Col>
              {/* <Col lg={12}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-start"> 
                 
                    <Col sm={12}>
                      <Form.Label className="text-center">
                        Description
                      </Form.Label>
                      <Form.Group>
                        <Controller
                          name="description"
                          control={control}
                          rules={{ required: 'Description is required.', maxLength: { value: 85, message: 'Description must be at most 85 characters long.' } }} // Correct the maxLength rule
                          render={({ field: { onChange, onBlur, value } }) => (
                            <JoditEditor
                              value={value}
                              onBlur={() => {
                                onBlur();
                                trigger('description');
                              }}
                              onChange={onChange}
                            />
                          )}
                        />
                      </Form.Group>
                      {errors.description && (
                        <span className="text-danger">
                          {errors.description.message}
                        </span>
                      )}
                    </Col>
                  </Row>
                </div>
              </Col> */}

            </Row>

            {/* Buttons */}
            <Row className="justify-content-center mt-5 pb-3">
              <Col sm={6} className="d-flex justify-content-center">
                <CancelButton name={"Cancel"} handleClose={props.handleClose} />
                <SaveButton
                  name={"Save"}
                  handleSubmit={handleSubmit(onSubmit)}
                />
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>

      <ModalSave
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      />
    </>
  );
};

export default EditOffCanvance;
