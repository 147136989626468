import React, { useContext, useEffect } from "react";
import { useState } from "react";
import "../../../../Tabels/Tabels.css";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";

import ModalSave from "../../../../common/ModelSave";
import { CancelButton, SaveButton } from "../../../../common/Button";
import { Row, Col, Form, Modal } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import classNames from "classnames";
import { Context } from "../../../../../utils/context";
import JoditEditor from "jodit-react";
import Select from "react-select";
library.add(fas);

const AddOffCanvance = (props) => {
  const { postData, getDimension, getData, Select2Data } = useContext(Context);
  const [course, setCourse] = useState([]);
  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    watch,
    trigger,
    setValue
  } = useForm();

  //   const imageFile = watch("image");
  const GetAllUniversity = async () => {
    const response = await getData("/alluniversity");

    if (response.success) {
      setCourse(await Select2Data(response?.data, "university_id"));
    }
  };
  useEffect(() => {
    GetAllUniversity();
  }, []);

  console.log(errors);
  const descriptionValue = watch("description");
  console.log(errors);
  const onSubmit = async (data) => {
    try {
      const fromData = new FormData();
      fromData.append("name", data?.name);
      fromData.append("description", data?.description);
      fromData.append("university_id", data?.university_id?.value);
      const response = await postData(
        `/masters/ed-tech/university/common/about-banner`,
        fromData
      );

      if (response?.success) {
        await setShowModal({
          code: response?.code,
          message: response?.message,
        });
      } else {
        await setShowModal({
          code: response?.code,
          message: response?.message,
        });
      }
      setTimeout(() => {
        setShowModal(0);
        props.handleClose();
      }, 1000);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    register('description', {
      required: 'Description is required.',
      // maxLength: {
      //   value: 300,
      //   message: 'Description must be at most 300 characters long.'
      // }
    });
  }, [register]);

  return (
    <>
      <Modal
        onHide={props.handleClose}
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Add About University
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            onSubmit={() => handleSubmit(onSubmit)}
            role="form"
            className="countryclass"
          >
            <Row>
              {/* Title */}
              <Col lg={6}>
                <div className="main-form-section mt-3">
                  <Row className="row justify-content-center mb-2">
                    <Form.Label>University</Form.Label>
                    <Controller
                      name="university_id" // name of the field
                      {...register("university_id", {
                        required: "University  is required",
                      })}
                      control={control}
                      render={({ field }) => (
                        <Select
                          styles={{
                            control: (baseStyles) => ({
                              ...baseStyles,
                              borderColor: errors.university_id
                                ? "red"
                                : baseStyles,
                            }),
                          }}
                          {...field}
                          options={course}
                        />
                      )}
                    />

                    {errors.university_id && (
                      <span className="text-danger">
                        {errors.university_id.message}
                      </span>
                    )}
                  </Row>
                </div>
              </Col>
              {/* <Col lg={6}>
                <div className="main-form-section mt-3">

                  <Form.Label >
                    Title
                  </Form.Label>

                  <Form.Group>
                    <Form.Control
                      className={classNames("", {
                        "is-invalid": errors?.name,
                      })}
                      type="text"
                      {...register("name", {
                        required: "Title is required",
                        maxLength: {
                          value: 100,
                          message: "Title must be at most 100 characters long",
                        },
                      })}
                      placeholder="Title"
                    />
                  </Form.Group>
                  {errors.name && (
                    <span className="text-danger">
                      {errors.name.message}
                    </span>
                  )}

                </div>
              </Col> */}

              <Col lg={12}>
                <div className="main-form-section mt-3">

                  <Form.Label >
                    Description
                  </Form.Label>

                  <Form.Group>

                    <JoditEditor
                      value={descriptionValue || ''}
                      onBlur={(newContent) => {
                        setValue('description', newContent);
                        trigger('description');
                      }}

                    />
                  </Form.Group>
                  {errors.description && (
                    <span className="text-danger">
                      {errors.description.message}
                    </span>
                  )}

                </div>
              </Col>

              {/* Image Upload */}
            </Row>

            {/* Buttons */}
            <Row className="justify-content-center mt-5 pb-3">
              <Col sm={6} className="d-flex justify-content-center">
                <CancelButton name={"Cancel"} handleClose={props.handleClose} />
                <SaveButton
                  name={"Save"}
                  handleSubmit={handleSubmit(onSubmit)}
                />
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>

      <ModalSave
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      />
    </>
  );
};

export default AddOffCanvance;
