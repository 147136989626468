import React, { useContext } from "react";
import { useState, useEffect, useRef } from "react";
import "../../../Tabels/Tabels.css";
import { Context } from "../../../../utils/context";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import ModalSave from "../../../common/ModelSave";
import { CancelButton } from "../../../common/Button";
import Modal from "react-bootstrap/Modal";
import { Row, Col } from "react-bootstrap";
library.add(fas);

const EditOffCanvance = (props) => {
  const { getData } = useContext(Context);

  const id = props.show;
  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });

  const [formData, setFormData] = useState({});

  const GetEditData = async () => {
    const response = await getData(`/masters/finance/customer-management/credit-card-eligibility/${id}`);
    setFormData(response?.data);
    console.log(formData);
  };

  useEffect(() => {
    GetEditData();
  }, []);

  const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Add 1 because months are zero-indexed
    const day = String(date.getDate()).padStart(2, '0');
    return `${day}-${month}-${year}`;
  };

  return (
    <>
      <Modal
        onHide={props.handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={true} // Assuming you're controlling the modal visibility from outside
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            View Details
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col lg={12}>
              <div className="main-form-section mt-3">
                <Row className="justify-content-center">
                  <Col sm={3} className="text-center">Name:</Col>
                  <Col sm={9} className="text-center">{formData.name}</Col>
                </Row>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <div className="main-form-section mt-3">
                <Row className="justify-content-center">
                  <Col sm={3} className="text-center">Email:</Col>
                  <Col sm={9} className="text-center">{formData.email}</Col>
                </Row>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <div className="main-form-section mt-3">
                <Row className="justify-content-center">
                  <Col sm={3} className="text-center">Phone:</Col>
                  <Col sm={9} className="text-center">{formData.contact_no}</Col>
                </Row>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <div className="main-form-section mt-3">
                <Row className="justify-content-center">
                  <Col sm={3} className="text-center">Gender:</Col>
                  <Col sm={9} className="text-center">{formData.gender || 'N/A'}</Col>
                </Row>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <div className="main-form-section mt-3">
                <Row className="justify-content-center">
                  <Col sm={3} className="text-center">Pan :</Col>
                  <Col sm={9} className="text-center">{formData.pan || 'N/A'}</Col>
                </Row>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <div className="main-form-section mt-3">
                <Row className="justify-content-center">
                  <Col sm={3} className="text-center">Pincode :</Col>
                  <Col sm={9} className="text-center">{formData.pincode || 'N/A'}</Col>
                </Row>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <div className="main-form-section mt-3">
                <Row className="justify-content-center">
                  <Col sm={3} className="text-center">Date Of Birth :</Col>
                  <Col sm={9} className="text-center">{formatDate(formData.dob) || 'N/A'}</Col>
                </Row>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <div className="main-form-section mt-3">
                <Row className="justify-content-center">
                  <Col sm={3} className="text-center">City :</Col>
                  <Col sm={9} className="text-center">{formData.city || 'N/A'}</Col>
                </Row>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <div className="main-form-section mt-3">
                <Row className="justify-content-center">
                  <Col sm={3} className="text-center">Occupation :</Col>
                  <Col sm={9} className="text-center">{formData.occupation || 'N/A'}</Col>
                </Row>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <div className="main-form-section mt-3">
                <Row className="justify-content-center">
                  <Col sm={3} className="text-center">Company :</Col>
                  <Col sm={9} className="text-center">{formData.company_name || 'N/A'}</Col>
                </Row>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <div className="main-form-section mt-3">
                <Row className="justify-content-center">
                  <Col sm={3} className="text-center">Monthly Income :</Col>
                  <Col sm={9} className="text-center">₹. {formData.monthly_income || 'N/A'} /-</Col>
                </Row>
              </div>
            </Col>
          </Row>
          {/* <Row>
            <Col lg={12}>
              <div className="main-form-section mt-3">
                <Row className="justify-content-center">
                  <Col sm={3} className="text-center">Occupation:</Col>
                  <Col sm={9} className="text-center">{formData.occupation || 'N/A'}</Col>
                </Row>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <div className="main-form-section mt-3">
                <Row className="justify-content-center">
                  {formData && formData?.occupation === 'Salaried' &&
                    <Col sm={3} className="text-center">Employer:</Col>
                  }
                  {formData && formData?.occupation === 'Self Employed' &&
                    <Col sm={3} className="text-center">Profession:</Col>
                  }
                  {formData && formData?.occupation === 'Business Owner' &&
                    <Col sm={3} className="text-center">Business:</Col>
                  }
                  <Col sm={9} className="text-center">{formData.company_name || 'N/A'}</Col>
                </Row>
              </div>
            </Col>
          </Row> */}
          {/* <Row>
            <Col lg={12}>
              <div className="main-form-section mt-3">
                <Row className="justify-content-center">
                  {formData && formData?.occupation === 'Salaried' ?
                    <Col sm={3} className="text-center">Monthly Income :</Col> :
                    <Col sm={3} className="text-center">Yearly Income :</Col>
                  }

                  <Col sm={9} className="text-center">₹{formData.monthly_income || 'N/A'}</Col>
                </Row>
              </div>
            </Col>
          </Row> */}
          {/* Buttons */}
          <Row className="justify-content-center mt-5 pb-3">
            <Col sm={6} className="d-flex justify-content-center">
              <CancelButton name={"Close"} handleClose={props.handleClose} />
            </Col>
          </Row>
        </Modal.Body>
      </Modal>

      <ModalSave
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      />
    </>
  );
};

export default EditOffCanvance;
