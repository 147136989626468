import React, { useContext, useEffect } from "react";
import { useState } from "react";
import "../../../../Tabels/Tabels.css";
import { Context } from "../../../../../utils/context";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";
import ModalSave from "../../../../common/ModelSave";
import { CancelButton, SaveButton } from "../../../../common/Button";
import { Row, Col, Form, Modal } from "react-bootstrap";
// import { useForm } from "react-hook-form";
import classNames from "classnames";
import JoditEditor from 'jodit-react';
import { useForm, Controller } from 'react-hook-form';

library.add(fas);

const AddOffCanvance = (props) => {
  const { postData, getData, Select2Data, getDimension } = useContext(Context);
  const [course, setCourse] = useState([]);
  const [coursetype, setCourseType] = useState([]);

  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    watch,
    trigger,
    setValue
  } = useForm();

  const videoFile = watch("video");
  const imageFile = watch("image");
  const descriptionValue = watch("description");
  console.log(errors);
  const onSubmit = async (data) => {
    try {
      const fromData = new FormData();
      fromData.append("name", data?.name);
      fromData.append("description", data?.description);
      fromData.append("image", data?.image[0]);
      fromData.append("course_id", data?.course_id?.value);
      fromData.append("course_type_id", data?.course_type_id?.value);

      const response = await postData(`/masters/ed-tech/course/common/discover`, fromData);
      console.log(response);
      if (response?.success) {
        await setShowModal({ code: response.code, message: response.message });
      } else {
        await setShowModal({ code: response.code, message: response.message });
      }
      setTimeout(() => {
        setShowModal(0);
        props.handleClose();
      }, 1000);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    register('description', {
      required: 'Description is required.',
      // maxLength: {
      //   value: 250,
      //   message: 'Description must be at most 250 characters long.'
      // }
    });
  }, [register]);


  const GetAllCourse = async (Id) => {
    const response = await getData(`/allcourse/${Id}`);
    // const response = await getData(`/allstate/${id}`);
    if (response.success) {
      setCourse(await Select2Data(response?.data, "course_id"));
    }
  };

  const GetAllCourseType = async () => {
    const response = await getData("/allcoursetype");

    if (response?.success) {
      setCourseType(await Select2Data(response?.data, "course_type_id"));
    }
  };

  useEffect(() => {
    GetAllCourse();
    GetAllCourseType();
  }, []);

  return (
    <>
      <Modal
        onHide={props.handleClose}
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Add Discover
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            onSubmit={() => handleSubmit(onSubmit)}
            role="form"
            className="countryclass"
          >
            <Row>
              {/* Title */}
              <Col lg={6}>

                <Row className="justify-content-center">

                  {/* Course Type */}
                  <div className="main-form-section mt-3 col-12">
                    <Form.Label>Course Type</Form.Label>
                    <Controller
                      name="course_type_id"
                      {...register("course_type_id", {
                        required: "Course Type is required",
                      })}
                      control={control}
                      render={({ field }) => (
                        <Select
                          {...field}
                          options={coursetype}
                          onChange={(selectedOption) => {
                            GetAllCourse(selectedOption.value);
                            setValue("course_type_id", selectedOption);
                          }}
                        />
                      )}
                    />
                    {errors.course_type_id && (
                      <span className="text-danger">
                        {errors.course_type_id.message}
                      </span>
                    )}
                  </div>

                  <div className="main-form-section mt-3">
                    <Form.Label> Title</Form.Label>

                    <Form.Group>
                      <Form.Control
                        className={classNames("", {
                          "is-invalid": errors?.name,
                        })}
                        type="text"
                        {...register("name", {
                          required: "name is required",
                          // maxLength: {
                          //   value: 40,
                          //   message: "Title must be at most 40 characters long",
                          // },
                        })}
                        placeholder="Title"
                      />
                    </Form.Group>
                    {errors.name && (
                      <span className="text-danger">
                        {errors.name.message}
                      </span>
                    )}

                  </div>



                </Row>

              </Col>

              <Col lg={6}>
                {/* Course */}
                <div className="main-form-section mt-3 col-12">
                  <Form.Label>Course</Form.Label>
                  <Controller
                    name="course_id"
                    {...register("course_id", {
                      required: "Course is required",
                    })}
                    control={control}
                    render={({ field }) => (
                      <Select {...field} options={course} />
                    )}
                  />
                  {errors.course_id && (
                    <span className="text-danger">
                      {errors.course_id.message}
                    </span>
                  )}
                </div>

              </Col>
              <Col lg={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label column sm={3} className="text-center">
                      Image
                    </Form.Label>
                    <Col sm={9}>
                      <Form.Group>
                        <Form.Control
                          className={classNames("", {
                            "is-invalid": errors?.image,
                          })}
                          type="file"
                          {...register("image", {
                            required: "Image is required",
                            validate: async (value) => {
                              const fileTypes = ["jpg", "png", "jpeg"];
                              const fileType = value[0].name.split(".")[1];

                              if (!fileTypes.includes(fileType)) {
                                return `please upload a valid file format. (${fileTypes})`;
                              }

                              const sizes = await getDimension(value[0]);
                              if (sizes.width !== 173 && sizes.height !== 173) {
                                return "Image width and height must be 173 px and 173 px";
                              }

                              const fileSize = Math.round(value[0].size / 1024);
                              if (fileSize > 500) {
                                return "file size must be lower than 500kb";
                              }
                            },
                          })}
                          accept=".jpg, .jpeg, .png"
                        />
                      </Form.Group>
                      {errors.image && (
                        <span className="text-danger">
                          {errors.image.message}
                        </span>
                      )}
                    </Col>
                  </Row>
                </div>
              </Col>

              {/* Image Preview */}
              <Col lg={3}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label column sm={3} className="text-center">
                      Image Preview
                    </Form.Label>
                    <Col sm={9}>
                      {imageFile && imageFile?.length > 0 && (
                        <div className="image-preview-container">
                          <img
                            src={URL.createObjectURL(imageFile[0])}
                            alt="Preview"
                            className="image-preview"
                            style={{ width: 100, height: 100, marginLeft: 50 }}
                          />
                        </div>
                      )}
                    </Col>
                  </Row>
                </div>
              </Col>

              <Col lg={12}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-start"> {/* Align to left */}
                    {/* Description */}
                    <Col sm={12}>
                      <Form.Label className="text-center">
                        Description
                      </Form.Label>
                      <Form.Group>
                        {/* <Controller
                          name="description" // Provide the field name
                          control={control} // Pass the control object from useForm()
                          rules={{ required: "Description is required" }} // Validation rules
                          render={({ field }) => (
                            <JoditEditor
                              value={field.value}
                              onChange={(newContent) =>
                                field.onChange(newContent)
                              }
                              onBlur={field.onBlur}
                              className={classNames("", {
                                "is-invalid": !!errors.description,
                              })}
                              placeholder="Description"
                            />
                          )}
                        /> */}
                        <JoditEditor
                          value={descriptionValue || ''}
                          onBlur={(newContent) => {
                            setValue('description', newContent);
                            trigger('description');
                          }}

                        />
                      </Form.Group>
                      {errors.description && (
                        <span className="text-danger">
                          {errors.description.message}
                        </span>
                      )}
                    </Col>
                  </Row>
                </div>
              </Col>


            </Row>



            {/* Buttons */}
            <Row className="justify-content-center mt-5 pb-3">
              <Col sm={6} className="d-flex justify-content-center">
                <CancelButton name={"Cancel"} handleClose={props.handleClose} />
                <SaveButton
                  name={"Save"}
                  handleSubmit={handleSubmit(onSubmit)}
                />
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal >

      <ModalSave
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      />
    </>
  );
};

export default AddOffCanvance;
