import React, { useContext, useEffect } from "react";
import { useState } from "react";
import "../../../../Tabels/Tabels.css";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";

import ModalSave from "../../../../common/ModelSave";
import { CancelButton, SaveButton } from "../../../../common/Button";
import { Row, Col, Form, Modal } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import classNames from "classnames";
import { Context } from "../../../../../utils/context";
import Select from "react-select";
library.add(fas);

const AddOffCanvance = (props) => {
  const { postData, getDimension, getData, Select2Data } = useContext(Context);
  const [course, setCourse] = useState([]);
  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm();

  const imageFile = watch("image");
  console.log(errors);

  const onSubmit = async (data) => {
    try {
      const fromData = new FormData();
      fromData.append("name", data?.name);
      fromData.append("image", data?.image[0]);
      fromData.append("university_id", data?.university_id?.value);
      const response = await postData(
        `/masters/ed-tech/university/common/alumani-image`,
        fromData
      );

      if (response?.success) {
        await setShowModal({
          code: response?.code,
          message: response?.message,
        });
      } else {
        await setShowModal({
          code: response?.code,
          message: response?.message,
        });
      }
      setTimeout(() => {
        setShowModal(0);
        props.handleClose();
      }, 1000);
    } catch (error) {
      console.log(error);
    }
  };
  const GetAllUniversity = async () => {
    const response = await getData("/alluniversity");

    if (response.success) {
      setCourse(await Select2Data(response?.data, "university_id"));
    }
  };
  useEffect(() => {
    GetAllUniversity();
  }, []);

  return (
    <>
      <Modal
        onHide={props.handleClose}
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Add Alumni Image
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            onSubmit={() => handleSubmit(onSubmit)}
            role="form"
            className="countryclass"
          >
            <Row>

              <Col lg={6}>
                <div className="main-form-section mt-3">
                  <Row className="row justify-content-center mb-2">
                    <Form.Label>University</Form.Label>
                    <Controller
                      name="university_id" // name of the field
                      {...register("university_id", {
                        required: "University  is required",
                      })}
                      control={control}
                      render={({ field }) => (
                        <Select
                          styles={{
                            control: (baseStyles) => ({
                              ...baseStyles,
                              borderColor: errors.university_id
                                ? "red"
                                : baseStyles,
                            }),
                          }}
                          {...field}
                          options={course}
                        />
                      )}
                    />

                    {errors.university_id && (
                      <span className="text-danger">
                        {errors.university_id.message}
                      </span>
                    )}
                  </Row>
                </div>

                <div className="main-form-section mt-3">

                  <Form.Label >
                    Title
                  </Form.Label>

                  <Form.Group>
                    <Form.Control
                      className={classNames("", {
                        "is-invalid": errors?.name,
                      })}
                      type="text"
                      {...register("name", {
                        required: "Title is required",
                      })}
                      placeholder="Title"
                    />
                  </Form.Group>
                  {errors.name && (
                    <span className="text-danger">
                      {errors.name.message}
                    </span>
                  )}

                </div>

                <div className="main-form-section mt-3">

                  <Form.Label >
                    Image
                  </Form.Label>

                  <Form.Group>
                    <Form.Control
                      className={classNames("", {
                        "is-invalid": errors?.image,
                      })}
                      type="file"
                      {...register("image", {
                        required: "Image is required",
                        validate: async (value) => {
                          const fileTypes = ["jpg", "png", "jpeg"];
                          const fileType = value[0].name.split(".")[1];

                          if (!fileTypes.includes(fileType)) {
                            return `please upload a valid file format. (${fileTypes})`;
                          }

                          const sizes = await getDimension(value[0]);
                          if (sizes.width !== 306 && sizes.height !== 306) {
                            return "Image width and height must be 306 px and 306 px";
                          }

                          const fileSize = Math.round(value[0].size / 1024);
                          if (fileSize > 2000) {
                            return "file size must be lower than 2000kb";
                          }
                        },
                      })}
                    //   accept=".jpg, .jpeg, .png"
                    />
                  </Form.Group>
                  {errors.image && (
                    <span className="text-danger">
                      {errors.image.message}
                    </span>
                  )}

                </div>
              </Col>

              {/* Image Preview */}
              <Col lg={5}>
                <div className="main-form-section mt-3">

                  <Form.Label >
                    Image Preview
                  </Form.Label>

                  {imageFile && imageFile?.length > 0 && (
                    <div className="image-preview-container">
                      <img
                        src={URL.createObjectURL(imageFile[0])}
                        alt="Preview"
                        className="image-preview"
                        style={{ width: "100px", height: "100px" }}
                      />
                    </div>
                  )}

                </div>
              </Col>
            </Row>

            {/* Buttons */}
            <Row className="justify-content-center mt-5 pb-3">
              <Col sm={6} className="d-flex justify-content-center">
                <CancelButton name={"Cancel"} handleClose={props.handleClose} />
                <SaveButton
                  name={"Save"}
                  handleSubmit={handleSubmit(onSubmit)}
                />
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>

      <ModalSave
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      />
    </>
  );
};

export default AddOffCanvance;
