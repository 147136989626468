import React, { useContext, useEffect } from "react";
import { useState } from "react";
import "../../Tabels/Tabels.css";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";

import ModalSave from "../../common/ModelSave";
import { CancelButton, SaveButton } from "../../common/Button";
import { Row, Col, Form, Modal } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import classNames from "classnames";
import { Context } from "../../../utils/context";
import Select from "react-select";
library.add(fas);

const AddOffCanvance = (props) => {
  const { postData, getData, Select2Data, getDimension } = useContext(Context);
  const [university, setUniversity] = useState([]);

  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm();

  const imageFile = watch("image");
  console.log(errors);

  const onSubmit = async (data) => {
    try {
      const fromData = new FormData();
      fromData.append("name", data?.name);
      fromData.append("course_university_id", data?.course_university_id?.value);
      fromData.append("image", data?.image[0]);
      //   fromData.append("image", data?.image[0]);
      const response = await postData(`/masters/specialization`, fromData);

      if (response?.success) {
        await setShowModal({
          code: response?.code,
          message: response?.message,
        });
      } else {
        await setShowModal({
          code: response?.code,
          message: response?.message,
        });
      }
      setTimeout(() => {
        setShowModal(0);
        props.handleClose();
      }, 1000);
    } catch (error) {
      console.log(error);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSubmit(onSubmit)();
    }
  };

  const GetAllCourseUniversity = async () => {
    const response = await getData("/allCourseUniversity");

    if (response.success) {
      setUniversity(await Select2Data(response?.data, "course_university_id"));
    }
  };

  const SelectData = async (data, name, other = false) => {
    const result = data.map((data) => ({
      value: data?.id,
      label: `${data?.university?.name} - ${data?.ed_tech_course?.name}`,
      name: name,
    }));

    if (other) {
      result.push({ value: "0", label: "Other", name: name });
    }
    return result;
  };


  useEffect(() => {
    GetAllCourseUniversity();
  }, []);
  return (
    <>
      <Modal
        onHide={props.handleClose}
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Add specialization
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            onSubmit={() => handleSubmit(onSubmit)}
            role="form"
            className="countryclass"
            onKeyDown={handleKeyDown}
          >
            <Row>

              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="row justify-content-center mb-2">
                    <Form.Label>University-Course</Form.Label>

                    <Controller
                      name="course_university_id" // name of the field
                      {...register("course_university_id", {
                        required: "course_university_id is required",
                      })}
                      control={control}
                      render={({ field }) => (
                        <Select
                          styles={{
                            control: (baseStyles) => ({
                              ...baseStyles,
                              borderColor: errors.course_university_id
                                ? "red"
                                : baseStyles,
                            }),
                          }}
                          {...field}
                          options={university}
                        />
                      )}
                    />

                    {errors.course_university_id && (
                      <span className="text-danger">
                        {errors.course_university_id.message}
                      </span>
                    )}
                  </Row>
                </div>

                <div className="main-form-section mt-3">

                  <Form.Label >
                    Specialization
                  </Form.Label>

                  <Form.Group>
                    <Form.Control
                      className={classNames("", {
                        "is-invalid": errors?.name,
                      })}
                      type="text"
                      {...register("name", {
                        required: "Title is required",
                      })}
                      placeholder="Title"
                    />
                  </Form.Group>
                  {errors.name && (
                    <span className="text-danger">
                      {errors.name.message}
                    </span>
                  )}

                </div>

                <div className="main-form-section mt-3">

                  <Form.Label >
                    Image
                  </Form.Label>

                  <Form.Group>
                    <Form.Control
                      className={classNames("", {
                        "is-invalid": errors?.image,
                      })}
                      type="file"
                      {...register("image", {
                        required: "Image is required",
                        validate: async (value) => {
                          const fileTypes = ["jpg", "png", "jpeg"];
                          const fileType = value[0].name.split(".")[1];

                          if (!fileTypes.includes(fileType)) {
                            return `please upload a valid file format. (${fileTypes})`;
                          }


                        },
                      })}
                      accept=".jpg, .jpeg, .png"
                    />
                  </Form.Group>
                  {errors.image && (
                    <span className="text-danger">
                      {errors.image.message}
                    </span>
                  )}

                </div>
              </Col>

              {/* Image Preview */}
              <Col lg={3}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label column sm={3} className="text-center">
                      Image Preview
                    </Form.Label>
                    <Col sm={9}>
                      {imageFile && imageFile?.length > 0 && (
                        <div className="image-preview-container">
                          <img
                            src={URL.createObjectURL(imageFile[0])}
                            alt="Preview"
                            className="image-preview"
                            style={{ width: 100, height: 100, marginLeft: 50 }}
                          />
                        </div>
                      )}
                    </Col>
                  </Row>
                </div>
              </Col>


            </Row>

            {/* Buttons */}
            <Row className="justify-content-center mt-5 pb-3">
              <Col sm={6} className="d-flex justify-content-center">
                <CancelButton name={"Cancel"} handleClose={props.handleClose} />
                <SaveButton
                  name={"Save"}
                  handleSubmit={handleSubmit(onSubmit)}
                />
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>

      <ModalSave
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      />
    </>
  );
};

export default AddOffCanvance;
