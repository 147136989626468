import React, { useContext, useEffect } from "react";
import { useState } from "react";
import "../../../../../Tabels/Tabels.css";
import { Context } from "../../../../../../utils/context";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";

import ModalSave from "../../../../../common/ModelSave";
import { CancelButton, SaveButton } from "../../../../../common/Button";
import { Row, Col, Form, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import classNames from "classnames";
import JoditEditor from "jodit-react";

library.add(fas);

const AddOffCanvance = (props) => {
  const { postData } = useContext(Context);

  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    trigger,
  } = useForm();

  const imageFile = watch("image");
  const descriptionValue = watch("description");
  console.log(errors);
  const onSubmit = async (data) => {
    try {
      const fromData = new FormData();
      fromData.append("name", data?.name);
      fromData.append("description", data?.description);
      fromData.append("image", data?.image[0]);
      const response = await postData(`/masters/ed-tech/course/ug-courses/bcom/who_qualified`, fromData);
      console.log(response);
      if (response?.success) {
        await setShowModal({ code: response?.code, message: response?.message });
      } else {
        await setShowModal({ code: response?.code, message: response?.message });
      }
      setTimeout(() => {
        setShowModal(0);
        props.handleClose();
      }, 1000);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    register('description', {
      required: 'Description is required.',
      maxLength: {
        value: 254,
        message: 'Description must be at most 250 characters long.'
      }
    });
  }, [register]);

  return (
    <>
      <Modal
        onHide={props.handleClose}
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Add Qualified
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            onSubmit={handleSubmit(onSubmit)}
            role="form"
            className="countryclass"
          >
            <Row>
              {/* Left side: Title and Image Upload */}
              <Col lg={6}>
                <div className="main-form-section mt-3">
                  <Form.Label className="text-center">Title</Form.Label>
                  <Row className="justify-content-center">
                    <Col sm={9}>
                      <Form.Group>
                        <Form.Control
                          className={classNames("", {
                            "is-invalid": errors?.name,
                          })}
                          type="text"
                          {...register("name", {
                            required: "Title is required",
                            maxLength: { value: 35, message: "Title cannot exceed 30 characters" },
                          })}
                          placeholder="Title"
                        />
                      </Form.Group>
                      {errors.name && (
                        <span className="text-danger">
                          {errors.name.message}
                        </span>
                      )}
                    </Col>
                  </Row>
                </div>
                <div className="main-form-section mt-3">
                  <Form.Label className="text-center">Image</Form.Label>
                  <Row className="justify-content-center">
                    <Col sm={9}>
                      <Form.Group>
                        <Form.Control
                          className={classNames("", {
                            "is-invalid": errors?.image,
                          })}
                          type="file"
                          {...register("image", {
                            required: "Image is required",
                          })}
                          accept=".jpg, .jpeg, .png"
                        />
                      </Form.Group>
                      {errors.image && (
                        <span className="text-danger">
                          {errors.image.message}
                        </span>
                      )}
                    </Col>
                  </Row>
                </div>
              </Col>

              {/* Right side: Image Preview */}
              <Col lg={6}>
                <div className="main-form-section mt-3">
                  <Form.Label className="text-center">Image Preview</Form.Label>
                  <Row className="justify-content-center">
                    <Col sm={9}>
                      {imageFile && imageFile?.length > 0 && (
                        <div className="image-preview-container">
                          <img
                            src={URL.createObjectURL(imageFile[0])}
                            alt="Preview"
                            className="image-preview"
                            style={{ width: "100px", height: "100px" }}
                          />
                        </div>
                      )}
                    </Col>
                  </Row>
                </div>
              </Col>

              {/* Bottom: Description */}
              <Col lg={12}>
                <div className="main-form-section mt-3">
                  <Form.Label className="text-center">Description</Form.Label>
                  <Row className="justify-content-center">
                    <Col sm={12}>
                      <Form.Group>
                        <JoditEditor
                          value={descriptionValue || ''}
                          onBlur={(newContent) => {
                            setValue('description', newContent);
                            trigger('description');
                          }}

                        />
                        {errors.description && (
                          <span className="text-danger">
                            {errors.description.message}
                          </span>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>

            {/* Buttons */}
            <Row className="justify-content-center mt-5 pb-3">
              <Col sm={6} className="d-flex justify-content-center">
                <CancelButton name={"Cancel"} handleClose={props.handleClose} />
                <SaveButton
                  name={"Save"}
                  handleSubmit={handleSubmit(onSubmit)}
                />
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>

      <ModalSave
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      />
    </>
  );
};

export default AddOffCanvance;
