import React, { useContext } from "react";
import { useState } from "react";
import "../../../Tabels/Tabels.css";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";

import ModalSave from "../../../common/ModelSave";
import { CancelButton, SaveButton } from "../../../common/Button";
import { Row, Col, Form, Modal } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import classNames from "classnames";
import { Context } from "../../../../utils/context";
import JoditEditor from "jodit-react";

library.add(fas);

const AddOffCanvance = (props) => {
  const { postData, getDimension } = useContext(Context);

  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    watch,
    getValues,
    setValue,
  } = useForm();

  const imageFile = watch("image");
  console.log(errors);

  const onSubmit = async (data) => {
    try {
      const fromData = new FormData();
      fromData.append("name", data?.name);
      fromData.append("description", data?.description);
      fromData.append("sub_name", data?.sub_name);
      fromData.append("image", data?.image[0]);
      const response = await postData(`/masters/finance/about-us/f-card-second`, fromData);

      if (response?.success) {
        await setShowModal({
          code: response?.code,
          message: response?.message,
        });
      } else {
        await setShowModal({
          code: response?.code,
          message: response?.message,
        });
      }
      setTimeout(() => {
        setShowModal(0);
        props.handleClose();
      }, 700);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Modal
        onHide={props.handleClose}
        {...props}
        size="lg"
        aria-labelledby="contained-modal-name-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-name-vcenter">
            Add Card
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            onSubmit={() => handleSubmit(onSubmit)}
            role="form"
            className="countryclass"
          >
            <Row>
              {/* name */}
              <Col lg={6}>
                <div className="main-form-section mt-3">
                  <Form.Label > Title</Form.Label>
                  <Form.Group>
                    <Form.Control
                      className={classNames("", {
                        "is-invalid": errors?.name,
                      })}
                      type="text"
                      {...register("name", {
                        required: "Title is required",
                        // maxLength: {
                        //   value: 20,
                        //   message: "Title must be at most 20 characters long",
                        // },
                      })}
                      placeholder="Title"
                    />
                  </Form.Group>
                  {errors.name && (
                    <span className="text-danger">
                      {errors.name.message}
                    </span>
                  )}


                </div>

                <div className="main-form-section mt-3">
                  <Form.Label >Sub Title</Form.Label>
                  <Form.Group>
                    <Form.Control
                      className={classNames("", {
                        "is-invalid": errors?.sub_name,
                      })}
                      type="text"
                      {...register("sub_name", {
                        required: "Sub Title is required",

                      })}
                      placeholder="Sub Title"
                    />
                  </Form.Group>
                  {errors.sub_name && (
                    <span className="text-danger">
                      {errors.sub_name.message}
                    </span>
                  )}


                </div>
                <div className="main-form-section mt-3">
                  <Form.Label>Image</Form.Label>
                  <Form.Control
                    type="file"
                    {...register("image", {
                      required: "Image is required",
                      validate: async (value) => {
                        if (typeof value !== "string") {
                          const fileTypes = ["jpg", "png", "jpeg"];
                          const fileType = value[0].name?.split(".")[1];

                          if (!fileTypes.includes(fileType)) {
                            return `please upload a valid file format. (${fileTypes})`;
                          }

                          const sizes = await getDimension(value[0]);
                          if (sizes.width !== 630 && sizes.height !== 250) {
                            return "Image width and height must be 630 px and 250 px";
                          }
                        }
                      },
                    })}
                    accept=".jpg, .jpeg, .png"
                  />
                  {errors.image && (
                    <span className="text-danger">{errors.image.message}</span>
                  )}
                </div>
              </Col>
              <Col lg={6} className="text-center">
                <div className="main-form-section mt-3">
                  <Form.Label>Image Preview</Form.Label>
                  {imageFile && imageFile?.length > 0 && (
                    <div className="image-preview-container">
                      <img
                        src={URL.createObjectURL(imageFile[0])}
                        alt="Preview"
                        className="image-preview"
                        style={{ width: "150px", height: "130px" }}
                      />
                    </div>
                  )}
                </div>
              </Col>

            </Row>
            <Row>
              <Col lg={12}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-start"> {/* Align to left */}
                    {/* Description */}
                    <Col sm={12}>
                      <Form.Label className="text-center">
                        Description
                      </Form.Label>
                      <Form.Group>
                        <Controller
                          name="description" // Provide the field name
                          control={control} // Pass the control object from useForm()
                          rules={{ required: "Description is required" }} // Validation rules
                          render={({ field }) => (
                            <JoditEditor
                              value={field.value}
                              onChange={(newContent) =>
                                field.onChange(newContent)
                              }
                              onBlur={field.onBlur}
                              className={classNames("", {
                                "is-invalid": !!errors.description,
                              })}
                              placeholder="Description"
                            />
                          )}
                        />
                      </Form.Group>
                      {errors.description && (
                        <span className="text-danger">
                          {errors.description.message}
                        </span>
                      )}
                    </Col>
                  </Row>
                </div>
              </Col>


            </Row>

            {/* Buttons */}
            <Row className="justify-content-center mt-5 pb-3">
              <Col sm={6} className="d-flex justify-content-center">
                <CancelButton name={"Cancel"} handleClose={props.handleClose} />
                <SaveButton
                  name={"Save"}
                  handleSubmit={handleSubmit(onSubmit)}
                />
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>

      <ModalSave
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      />
    </>
  );
};

export default AddOffCanvance;
