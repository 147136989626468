import React, { useContext } from "react";
import { useState, useEffect, useRef } from "react";
import "../../../Tabels/Tabels.css";
import { Link } from "react-router-dom";
import { Context } from "../../../../utils/context";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import Offcanvas from "react-bootstrap/Offcanvas";
// *******************toster****************************
import Card from "react-bootstrap/Card";
import ModalSave from "../../../common/ModelSave";
import Validation from "../../../common/FormValidation";
import { CancelButton, SaveButton } from "../../../common/Button";
import Modal from "react-bootstrap/Modal";
import { Container, Row, Col, Button, Form, InputGroup } from "react-bootstrap";
import JoditEditor from "jodit-react";
import { useForm, Controller } from "react-hook-form";
import classNames from "classnames";
import Select from "react-select";

library.add(fas);

const EditOffCanvance = (props) => {
  const { postData, getData, getDimension, Select2Data, IMG_URL } = useContext(Context);
  const [services, setServices] = useState([]);

  const id = props.show;

  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });

  const GetEditData = async () => {
    const response = await getData(`/masters/finance/masters/cards/${id}`);
    console.log(response?.data);
    reset(response?.data);
  };

  useEffect(() => {
    GetEditData();
  }, []);

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    getValues,
    reset,
    watch,
  } = useForm();
  console.log(getValues());
  const imageFile = watch("image");
  const onSubmit = async (data) => {
    try {
      const fromData = new FormData();
      fromData.append("name", data?.name);
      fromData.append("joining_fees", data?.joining_fees);
      fromData.append("annual_fees", data?.annual_fees);
      fromData.append("rating", data?.rating);
      fromData.append("sub_name", data?.sub_name);
      if (typeof data?.image !== "string") {
        fromData.append("image", data?.image[0]);
      }
      const response = await postData(
        `/masters/finance/masters/cards/${id}`,
        fromData
      );
      console.log(response);
      if (response?.success) {
        await setShowModal({ code: response.code, message: response.message });
      } else {
        await setShowModal({ code: response.code, message: response.message });
      }
      setTimeout(() => {
        setShowModal(0);
        props.handleClose();
      }, 1000);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Modal
        onHide={props.handleClose}
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Edit card
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            onSubmit={() => handleSubmit(onSubmit)}
            role="form"
            className="countryclass"
          >
            <Row>
              {/* Title */}
              <Col lg={6}>
                <div className="main-form-section mt-3">
                  <Form.Label > Title</Form.Label>
                  <Form.Group>
                    <Form.Control
                      className={classNames("", {
                        "is-invalid": errors?.name,
                      })}
                      type="text"
                      {...register("name", {
                        required: "Title is required",
                        // maxLength: {
                        //   value: 20,
                        //   message: "Title must be at most 20 characters long",
                        // },
                      })}
                      placeholder="Title"
                    />
                  </Form.Group>
                  {errors.name && (
                    <span className="text-danger">
                      {errors.name.message}
                    </span>
                  )}


                </div>

                <div className="main-form-section mt-3">
                  <Form.Label > Rewards</Form.Label>
                  <Form.Group>
                    <Form.Control
                      className={classNames("", {
                        "is-invalid": errors?.sub_name,
                      })}
                      type="text"
                      {...register("sub_name", {
                        required: "Title is required",
                        // maxLength: {
                        //   value: 20,
                        //   message: "Reward must be at most 20 characters long",
                        // },
                      })}
                      placeholder="Reward"
                    />
                  </Form.Group>
                  {errors.sub_name && (
                    <span className="text-danger">
                      {errors.sub_name.message}
                    </span>
                  )}
                </div>
                {/* <div className="main-form-section mt-3">
                  <Form.Label > Rating</Form.Label>
                  <Form.Group>
                    <Form.Control
                      className={classNames("", {
                        "is-invalid": errors?.rating,
                      })}
                      type="text"
                      {...register("rating", {
                        required: "rating is required",
                        // maxLength: {
                        //   value: 20,
                        //   message: "Reward must be at most 20 characters long",
                        // },
                      })}
                      placeholder="Reward"
                    />
                  </Form.Group>
                  {errors.rating && (
                    <span className="text-danger">
                      {errors.rating.message}
                    </span>
                  )}
                </div> */}
                <Col lg={6}>
                  <div className="main-form-section mt-3">
                    <Form.Label > Rating</Form.Label>
                    <Form.Group>
                      <Form.Control
                        className={classNames("", {
                          "is-invalid": errors?.rating,
                        })}
                        type="number"
                        min="0"
                        max="5"
                        {...register("rating", {
                          required: "Rating is required",
                          max: {
                            value: 5,
                            message: "Rating must be at most 5",
                          },
                        })}
                        placeholder="Rating"
                      />
                    </Form.Group>
                    {errors.rating && (
                      <span className="text-danger">
                        {errors.rating.message}
                      </span>
                    )}

                  </div>
                </Col>
                <div className="main-form-section mt-3">
                  <Form.Label > Annual Fees</Form.Label>
                  <Form.Group>
                    <Form.Control
                      className={classNames("", {
                        "is-invalid": errors?.annual_fees,
                      })}
                      type="text"
                      {...register("annual_fees", {
                        required: "Annual Fees is required",
                        // maxLength: {
                        //   value: 20,
                        //   message: "Annual Fees must be at most 20 characters long",
                        // },
                      })}
                      placeholder="Annual Fees"
                    />
                  </Form.Group>
                  {errors.annual_fees && (
                    <span className="text-danger">
                      {errors.annual_fees.message}
                    </span>
                  )}
                </div>
                <div className="main-form-section mt-3">
                  <Form.Label > Joining Fees</Form.Label>
                  <Form.Group>
                    <Form.Control
                      className={classNames("", {
                        "is-invalid": errors?.name,
                      })}
                      type="text"
                      {...register("joining_fees", {
                        required: "Title is required",
                        // maxLength: {
                        //   value: 20,
                        //   message: "Joining Fees must be at most 20 characters long",
                        // },
                      })}
                      placeholder="Joining Fees"
                    />
                  </Form.Group>
                  {errors.joining_fees && (
                    <span className="text-danger">
                      {errors.joining_fees.message}
                    </span>
                  )}
                </div>


              </Col>

              {/* Image Preview */}
              <Col lg={6}>

                <div className="main-form-section mt-3">
                  <Form.Label > Image</Form.Label>

                  <Form.Group>
                    <Form.Control
                      className={classNames("", {
                        "is-invalid": errors?.image,
                      })}
                      type="file"
                      {...register("image", {
                        // required: "Image is required",
                        // validate: async (value) => {
                        //   const fileTypes = ["jpg", "png", "jpeg"];
                        //   const fileType = value[0].name.split(".")[1];

                        //   if (!fileTypes.includes(fileType)) {
                        //     return `please upload a valid file format. (${fileTypes})`;
                        //   }

                        //   const sizes = await getDimension(value[0]);
                        //   if (
                        //     sizes.width !== 1296 &&
                        //     sizes.height !== 465
                        //   ) {
                        //     return "Image width and height must be 1296px and 465px";
                        //   }

                        //   const fileSize = Math.round(value[0].size / 1024);
                        //   if (fileSize > 500) {
                        //     return "file size must be lower than 500kb";
                        //   }
                        // },
                      })}
                      accept=".jpg, .jpeg, .png"
                    />
                  </Form.Group>
                  {errors.image && (
                    <span className="text-danger">
                      {errors.image.message}
                    </span>
                  )}

                </div>
                <div className="main-form-section mt-3" style={{ marginLeft: '50px' }}>
                  <Form.Label > Image Preview</Form.Label>

                  {typeof getValues("image") == "string" ? (
                    <div className="image-preview-container">
                      <img
                        src={IMG_URL + getValues("image")}
                        alt="Preview"
                        className="image-preview"
                        style={{ width: "150px", height: "150px" }}
                      />
                    </div>
                  ) : (
                    imageFile &&
                    imageFile?.length > 0 && (
                      <div className="image-preview-container">
                        <img
                          // src={URL.createObjectURL(getValues("image")[0])}
                          src={URL?.createObjectURL(imageFile[0])}
                          alt="Preview"
                          className="image-preview"
                          style={{ width: "150px", height: "150px" }}
                        />
                      </div>
                    )
                  )}

                </div>


              </Col>

            </Row>

            {/* Buttons */}
            <Row className="justify-content-center mt-5 pb-3">
              <Col sm={6} className="d-flex justify-content-center">
                <CancelButton name={"Cancel"} handleClose={props.handleClose} />
                <SaveButton
                  name={"Save"}
                  handleSubmit={handleSubmit(onSubmit)}
                />
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>

      <ModalSave
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      />
    </>
  );
};

export default EditOffCanvance;
