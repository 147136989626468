import React, { useContext } from "react";
import { useState, useEffect } from "react";
import "../../Tabels/Tabels.css";
import { Link } from "react-router-dom";
import { Context } from "../../../utils/context";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import Offcanvas from "react-bootstrap/Offcanvas";
// *******************toster****************************
import Card from "react-bootstrap/Card";
import ModalSave from "../../common/ModelSave";
import Validation from "../../common/FormValidation";
import { CancelButton, SaveButton } from "../../common/Button";
import Select from "react-select";
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  InputGroup,
  Modal,
} from "react-bootstrap";

library.add(fas);

const EditOffCanvance = (props) => {
  const { postData, getData } = useContext(Context);

  const id = props.show;
  const [data, setData] = useState();
  const [category, setCategory] = useState([]);
  const [formData, setFormData] = useState({
    category_descipline_id: "",
    name: "",
  });

  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });

  const handleChange = (e) => {
    if (e?.name == "category_descipline_id") {
      setFormData({ ...formData, [e.name]: e });
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = Validation.FormValidation({
      classname: ".desciplineclass",
    });
    const validationErrorsSelect = Validation.SelectValidation({
      classname: ".desciplineclass",
    });

    if (validationErrors && validationErrorsSelect) {
      try {
        const response = await postData(`/masters/descipline/${id}`, formData);

        if (response.success) {
          setShowModal({ code: response.code, message: response.message });
          setTimeout(() => {
            setShowModal(0);
            props.handleClose();
          }, 1000);
        } else {
          setShowModal({ code: response.code, message: response.message });
          setTimeout(() => {
            setShowModal(0);
            props.handleClose();
          }, 1000);
        }
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    }
  };

  const GetEditData = async () => {
    const response = await getData(`/masters/descipline/${id}`);
    setData(response);
    setFormData(response?.data);
  };

  useEffect(() => {
    GetEditData();
  }, []);

  const errorStyle = {
    color: "red",
    marginLeft: "5px",
  };

  return (
    <>
      {/* <Offcanvas
          show={props.show}
          style={{ width: "80%" }}
          placement={"end"}
          onHide={props.handleClose}
        >
          <Offcanvas.Header closeButton>
          
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Card className="shadow-lg p-3 mb-5 bg-white rounded">
              <Card.Body>
                <Card.Title>Edit Descipline</Card.Title>
                <hr />
                <Container>
                  <Row>
                    <Col md={12}>
                      <Container>
                        <Row className="">
                          <Col xxl={12} xl={12} md={10}>
                            <Form onSubmit={handleSubmit} role="form" className="desciplineclass">
                              <Row>
                                <Col
                                  lg={8}
                                  md={10}
                                  className=" mx-auto Add-content"
                                >
                                  <Row>
                              
                                    <Col md={12}>
                                      <div className="main-form-section mt-5">
                                        <Row className="justify-content-center">
                                          <Form.Label column sm={3}>
                                            Degree 
                                          </Form.Label>
                                          <Col sm={6}>
                                            <Form.Group>
                                              <InputGroup>
                                                <Form.Control
                                                  type="text"
                                                  name="name"
                                                  value={formData?.name}
                                                  onChange={handleChange}
                                                  placeholder="Degree "
                                                  id="inputEmail3"
                                                  className="input-mandatory"
                                                />
                                              </InputGroup>
                                              <span className="error-message"></span>
                                            </Form.Group>
                                          </Col>
                                        </Row>
                                      </div>
                                    </Col>
                                  </Row>
  
                                  <Row className="mt-5 pb-3">
                                  <div className="d-flex justify-content-center">
                                    <Link>
                                      <CancelButton
                                        name={"cancel"}
                                        handleClose={props.handleClose}
                                      />
                                    </Link>
                                    <SaveButton
                                      name={"save"}
                                      handleSubmit={handleSubmit}
                                    />
                                  </div>
                                </Row>
                                </Col>
                              </Row>
                            </Form>
                          </Col>
                        </Row>
                      </Container>
                    </Col>
                  </Row>
                </Container>
              </Card.Body>
            </Card>
          </Offcanvas.Body>
        </Offcanvas> */}

      <Modal
        {...props}
        size="lg"
        onHide={props.handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Edit Descipline
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit} role="form" className="desciplineclass">
            <Row>
              <Col lg={12} md={12} className=" mx-auto Add-content">
                <Row>
                  <Col md={6}>
                    <div className="main-form-section mt-3">
                      <Row className="row justify-content-center mb-2">
                        <Form.Label>Category Descipline</Form.Label>

                        <Select
                          name="category_descipline_id"
                          className="input-mandatory"
                          onChange={handleChange}
                          placeholder="Select Category Descipline"
                          value={formData.category_descipline_id}
                          options={category}
                        />
                        <span className="error-message"></span>
                      </Row>
                    </div>
                  </Col>

                  <Col md={6}>
                    <div className="main-form-section mt-3">
                      <Row className="justify-content-center">
                        <Form.Label>Descipline</Form.Label>

                        <Form.Group>
                          <InputGroup>
                            <Form.Control
                              type="text"
                              name="name"
                              value={formData.name}
                              onChange={handleChange}
                              placeholder="Descipline "
                              id="inputEmail3"
                              className="input-mandatory"
                            />
                          </InputGroup>
                          <span className="error-message"></span>
                        </Form.Group>
                      </Row>
                    </div>
                  </Col>
                </Row>

                <Row className="mt-5 pb-3">
                  <div className="d-flex justify-content-center">
                    <Link>
                      <CancelButton
                        name={"cancel"}
                        handleClose={props.handleClose}
                      />
                    </Link>
                    <SaveButton name={"save"} handleSubmit={handleSubmit} />
                  </div>
                </Row>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>

      <ModalSave
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      />
    </>
  );
};

export default EditOffCanvance;
