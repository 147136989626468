import React from "react";
import LoginPage from "./LoginPage/LoginPage.js";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Context } from "../../utils/context.js";

const Login = () => {
  const { signin, usertype } = useContext(Context);
  const navigate = useNavigate();

  return (
    <div>
      {signin ? (
        usertype === "Admin" ? (
          navigate("/advanceDashboard")
        ) : (
          <LoginPage />
        )
      ) : (
        <LoginPage />
      )}
    </div>
  );
};

export default Login;
