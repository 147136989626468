import React, { useState } from "react";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import Header from "../../Header/Header";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import "./SettingContent.css";
import Citytable from "../City/Tables";
import Statetable from "../State/Tables";
import Countrytable from "../Country/Tables";

import CourseType from "../CourseType/Table";
import Course from "../Course/Table";
import University from "../University/Table";
import QualificationTable from "../Qualification/Tables";

import JobTypetable from "../JobType/Tables";
import JobTitleTable from "../JobTitle/Tables";
import IndustryTable from "../Industry/Tables";
import CareerLevelTable from "../CareerLevel/Tables";
import ShiftTable from "../Shift/Table";
import SkilltypeTable from "../SkillType/Table";
import SkillnameTable from "../Skills/Tables";
import CompanyTable from "../Company/Table";
import LanguageTable from "../Language/Table";
import NoticePeriod from "../NoticePeriod/Table";
import ModeOfWork from "../ModeOfWork/Table";

import JobPortalDeleteReasons from "../DeleteReasons/Table";
import InterviewType from "../InterviewType/Tables";

import SocialMedia from "../SocialMedia/Table";
import PersonalStatus from "../PersonalStatus/Table";
import PersonalMoreInformation from "../PersonalMoreInformation/Table";
import Specialization from "../Specialization/Table";
import CourseUniversity from "../CourseUniversity/Table";
import Proficiency from "../Proficiency/Table";

import EducationTable from "../Education/Table";

import BlogTypeTable from "../BlogType/Tables";
import BlogTable from "../Blog/Tables";
import ExpereinceInYearsTable from "../ExpericeInYears/Tables";
import { useLocation } from "react-router-dom";

import DegreeTypetable from "../DegreeType/Table";
import Degreetable from "../Degree/Tables";
import UniversityTypetable from "../UniversityType/Table";
import UniverSitytable from "../University/Table";

import ClientreviewTable from "../ClientReview/Tables";

import SocialLinktable from "../SocialLink/Tables";
import ProjectTypetable from "../ProjectType/Table";
import RoleTypetable from "../Role_type/Table";
import ROlesubtypeTable from "../RoleSubtype/Table";
import RoleTable from "../Roles/Table";
import RoundTable from "../Round/Table";
import AuthorityTable from "../Authority/Table";
import LeagueTable from "../League/Table";
import DesciplineTable from "../Descipline/Table";
import DepartmentTable from "../Department/Table";
import CategoryDesciplineTable from "../CategoryDescipline/Table";
import SkillLeaguetable from "../SkillLeague/Table";
import SkillMastertable from "../SkillsMaster/Table";
import EducationTypeTable from "../EducationType/Tables";
import AchievementTable from "../Achievement/Table";
import ExperienceType from "../ExperienceType/Table";
import LevelTable from "../Level/Tables";
import NationalityTable from "../Nationality/Tables";
import TalentTable from "../Talent/Table";
import JobNature from "../JobNature/Tables";
import Location from "../Location/Tables";
import Category from "../Category/Tables";
import Type from "../Type/Tables";
import CertificateFor from "../CertificateFor/Tables";
import Ourpartners from "../Ourpartners/Tables";
import OurAbout from "../OurAbout/Table";


const SettingContent = () => {
  const [filter, setFilter] = useState("");
  const [active, setActive] = useState(0);

  const handleFilterChange = (event) => {
    setFilter(event.target.value.toLowerCase());
  };

  const tabItems = [
    { key: 1, name: "Country", link: "masters/country" },
    { key: 2, name: "State", link: "masters/state" },
    { key: 3, name: "City", link: "masters/city" },
    { key: 4, name: "Job Type", link: "masters/job_type" },
    { key: 5, name: "Job Title", link: "masters/job_title" },
    { key: 6, name: "Industry", link: "masters/industry" },
    { key: 7, name: "Language", link: "masters/language" },
    { key: 8, name: "Shift", link: "masters/shift" },
    { key: 9, name: "Skill Type", link: "masters/skill_type" },
    { key: 10, name: "Skill Name", link: "masters/skills" },

    { key: 11, name: "Company List", link: "masters/company" },
    { key: 12, name: "Notice Period", link: "masters/notice-period" },

    { key: 13, name: "Mode Of Work", link: "/masters/mode-of-work" },

    { key: 19, name: "Course Type", link: "masters/course-type" },
    { key: 20, name: "Course", link: "masters/course" },
    { key: 21, name: "University", link: "masters/university" },
    { key: 22, name: "Qualifications", link: "masters/qualification" },

    { key: 23, name: "Career Level", link: "/masters/career_level" },
    { key: 24, name: "Add user Delete Resons", link: "/masters/job-portal/customer-management/employer/delete-reasons" },
    { key: 25, name: "Interview Type", link: "/masters/interview-type" },


    { key: 26, name: "Social Media", link: "/masters/personal-status" },
    { key: 27, name: "Personal Status", link: "/masters/personal-status" },
    { key: 28, name: "Personal More Information", link: "/masters/personal-more-information" },
    { key: 29, name: "Specialization", link: "/masters/specialization" },
    { key: 30, name: "CourseUniversity", link: "/masters/course-university" },
    { key: 31, name: "Proficiency", link: "/masters/proficiency" },
    { key: 32, name: "Department", link: "/masters/department" },


  ];

  return (
    <>
      <Header title={tabItems[active]?.name} link={"/masters/country"} />

      <Tab.Container id="left-tabs-example" defaultActiveKey="1">
        <Row>
          <Col xl={2} lg={3} md={4}>
            <div className="card settingCard">
              <InputGroup className="mb-3 p-2 settingInputGrp">
                <Form.Control
                  placeholder="Search..."
                  aria-label="Search"
                  aria-describedby="basic-addon2"
                  onChange={handleFilterChange}
                  value={filter}
                />
              </InputGroup>

              <Nav variant="pills" className="flex-column">
                {tabItems.map(({ key, name }, index) => (
                  <Nav.Item
                    key={key}
                    style={{
                      display: name.toLowerCase().includes(filter)
                        ? "block"
                        : "none",
                    }}
                  >
                    <Nav.Link
                      eventKey={key}
                      onClick={() => {
                        setActive(index);
                      }}
                    >
                      {name}
                    </Nav.Link>
                  </Nav.Item>
                ))}
              </Nav>
            </div>
          </Col>
          <Col xl={10} lg={9} md={8}>
            <Tab.Content>
              <Tab.Pane eventKey="1">
                <Countrytable />
              </Tab.Pane>
              <Tab.Pane eventKey="2">
                <Statetable />
              </Tab.Pane>

              <Tab.Pane eventKey="3">
                <Citytable />
              </Tab.Pane>

              <Tab.Pane eventKey="4">
                <JobTypetable />
              </Tab.Pane>
              <Tab.Pane eventKey="5">
                <JobTitleTable />
              </Tab.Pane>

              <Tab.Pane eventKey="6">
                <IndustryTable />
              </Tab.Pane>

              <Tab.Pane eventKey="7">
                <LanguageTable />
              </Tab.Pane>
              <Tab.Pane eventKey="8">
                <ShiftTable />
              </Tab.Pane>

              <Tab.Pane eventKey="9">
                <SkilltypeTable />
              </Tab.Pane>
              <Tab.Pane eventKey="10">
                <SkillnameTable />
              </Tab.Pane>
              <Tab.Pane eventKey="11">
                <CompanyTable />
              </Tab.Pane>

              <Tab.Pane eventKey="12">
                <NoticePeriod />
              </Tab.Pane>

              <Tab.Pane eventKey="13">
                <ModeOfWork />
              </Tab.Pane>

              <Tab.Pane eventKey="19">
                <CourseType />
              </Tab.Pane>

              <Tab.Pane eventKey="20">
                <Course />
              </Tab.Pane>

              <Tab.Pane eventKey="21">
                <University />
              </Tab.Pane>

              <Tab.Pane eventKey="22">
                <QualificationTable />
              </Tab.Pane>

              <Tab.Pane eventKey="23">
                <CareerLevelTable />
              </Tab.Pane>

              <Tab.Pane eventKey="24">
                <JobPortalDeleteReasons />
              </Tab.Pane>

              <Tab.Pane eventKey="25">
                <InterviewType />
              </Tab.Pane>

              <Tab.Pane eventKey="26">
                <SocialMedia />
              </Tab.Pane>

              <Tab.Pane eventKey="27">
                <PersonalStatus />
              </Tab.Pane>

              <Tab.Pane eventKey="28">
                <PersonalMoreInformation />
              </Tab.Pane>

              <Tab.Pane eventKey="29">
                <Specialization />
              </Tab.Pane>

              <Tab.Pane eventKey="30">
                <CourseUniversity />
              </Tab.Pane>

              <Tab.Pane eventKey="31">
                <Proficiency />
              </Tab.Pane>

              <Tab.Pane eventKey="32">
                <DepartmentTable />
              </Tab.Pane>




            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    </>
  );
};

export default SettingContent;
