import React, { useContext, useEffect } from "react";
import { useState } from "react";
import "../../Tabels/Tabels.css";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";

import ModalSave from "../../common/ModelSave";
import { CancelButton, SaveButton } from "../../common/Button";
import { Row, Col, Form, Modal } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import classNames from "classnames";
import { Context } from "../../../utils/context";
import Select from "react-select";
library.add(fas);

const AddOffCanvance = (props) => {
  const { postData, getData, Select2Data, getDimension } = useContext(Context);
  const [course, setCourse] = useState([]);
  const [university, setUniversity] = useState([]);

  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm();

  const imageFile = watch("image");
  console.log(errors);

  const onSubmit = async (data) => {
    try {
      const fromData = new FormData();
      fromData.append("course_id", data?.course_id?.value);
      fromData.append("university_id", data?.university_id?.value);
      const response = await postData(`/masters/cource-university`, fromData);

      if (response?.success) {
        await setShowModal({
          code: response?.code,
          message: response?.message,
        });
      } else {
        await setShowModal({
          code: response?.code,
          message: response?.message,
        });
      }
      setTimeout(() => {
        setShowModal(0);
        props.handleClose();
      }, 1000);
    } catch (error) {
      console.log(error);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSubmit(onSubmit)();
    }
  };

  const GetAllCourse = async () => {
    const response = await getData("/allcourse");

    if (response.success) {
      setCourse(await Select2Data(response?.data, "course_id"));
    }
  };

  useEffect(() => {
    GetAllCourse();
  }, []);

  const GetAllUnivercity = async () => {
    const response = await getData("/alluniversity");

    if (response.success) {
      setUniversity(await Select2Data(response?.data, "university_id"));
    }
  };

  // useEffect(() => {
  //   GetAllCourse();
  // }, []);
  useEffect(() => {
    GetAllUnivercity();
  }, []);
  return (
    <>
      <Modal
        onHide={props.handleClose}
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Add Course University
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            onSubmit={() => handleSubmit(onSubmit)}
            role="form"
            className="countryclass"
            onKeyDown={handleKeyDown}
          >
            <Row>
              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="row justify-content-center mb-2">
                    <Form.Label>Course Type</Form.Label>

                    <Controller
                      name="course_id" // name of the field
                      {...register("course_id", {
                        required: "course_id is required",
                      })}
                      control={control}
                      render={({ field }) => (
                        <Select
                          styles={{
                            control: (baseStyles) => ({
                              ...baseStyles,
                              borderColor: errors.course_id
                                ? "red"
                                : baseStyles,
                            }),
                          }}
                          {...field}
                          options={course}
                        />
                      )}
                    />

                    {errors.course_id && (
                      <span className="text-danger">
                        {errors.course_id.message}
                      </span>
                    )}
                  </Row>
                </div>
              </Col>

              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="row justify-content-center mb-2">
                    <Form.Label>University</Form.Label>

                    <Controller
                      name="university_id" // name of the field
                      {...register("university_id", {
                        required: "university_id is required",
                      })}
                      control={control}
                      render={({ field }) => (
                        <Select
                          styles={{
                            control: (baseStyles) => ({
                              ...baseStyles,
                              borderColor: errors.university_id
                                ? "red"
                                : baseStyles,
                            }),
                          }}
                          {...field}
                          options={university}
                        />
                      )}
                    />

                    {errors.university_id && (
                      <span className="text-danger">
                        {errors.university_id.message}
                      </span>
                    )}
                  </Row>
                </div>
              </Col>
            </Row>

            {/* Buttons */}
            <Row className="justify-content-center mt-5 pb-3">
              <Col sm={6} className="d-flex justify-content-center">
                <CancelButton name={"Cancel"} handleClose={props.handleClose} />
                <SaveButton
                  name={"Save"}
                  handleSubmit={handleSubmit(onSubmit)}
                />
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>

      <ModalSave
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      />
    </>
  );
};

export default AddOffCanvance;
