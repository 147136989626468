import React, { useContext } from "react";
import { useState, useEffect } from "react";
import "../../Tabels/Tabels.css";
import { Link } from "react-router-dom";
import { Context } from "../../../utils/context";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import Offcanvas from "react-bootstrap/Offcanvas";
import Card from "react-bootstrap/Card";
import Select from "react-select";
import ModalSave from "../../common/ModelSave";
import Validation from "../../common/FormValidation";
import { CancelButton, SaveButton } from "../../common/Button";

import { Container, Row, Col, Button, Form, InputGroup } from "react-bootstrap";

library.add(fas);

const AddOffCanvance = (props) => {
  const { postData, getData } = useContext(Context);
  const [qualification, setQualification] = useState([]);
  const [authorityname, setAuthorityName] = useState([]);
  const [country, setCountry] = useState([]);
  const [league, setLeague] = useState([]);
  const [descipline, setDescipline] = useState([]);

  const [formData, setFormData] = useState({
    qualification_id: "",
    authority_name_id: "",
    country_id: "",
    league_id: "",
    descipline_id: "",
    q_score: "",
    hic_no: "",
  });

  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });

  const handleChange = (e) => {
    if (
      e?.name == "qualification_id" ||
      e?.name == "authority_name_id" ||
      e?.name == "country_id" ||
      e?.name == "league_id" ||
      e?.name == "descipline_id"
    ) {
      // await setFormData({ ...formData, [e.name]: e });
      if (e?.name == "role_type_id") {
        setFormData({
          ...formData,
          [e.name]: e,
          role_subtype_id: "",
        });
        // getSelectedrolesubtype(e.value);
      } else {
        setFormData({ ...formData, [e.name]: e });
      }
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = await Validation.FormValidation({
      classname: ".educationclass",
    });

    const validationErrorsSelect = Validation.SelectValidation({
      classname: ".educationclass",
    });

    if (validationErrors && validationErrorsSelect) {
      try {
        const response = await postData("/masters/education", formData);
        if (response.success) {
          setShowModal({ code: response.code, message: response.message });
          setTimeout(() => {
            setShowModal(0);
            props.handleClose();
          }, 1000);
        } else {
          setShowModal({ code: response.code, message: response.message });
          setTimeout(() => {
            setShowModal(0);
            props.handleClose();
          }, 1000);
        }
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    }
  };

  const handleKeyPressContact = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);

    if (!/^\d*\.?\d*$/.test(keyValue)) {
      event.preventDefault();
    }
  };

  const GetAllQualification = async () => {
    const response = await getData("/allqualification");

    const option = [];
    if (response.success) {
      response?.data?.map((data) => {
        option.push({
          value: data.id,
          name: "qualification_id",
          label: data.name,
        });
      });
    }
    setQualification(option);
  };

  const GetAllAuthorityName = async () => {
    const response = await getData("/allauthorityname");

    const option = [];
    if (response.success) {
      response?.data?.map((data) => {
        option.push({
          value: data.id,
          name: "authority_name_id",
          label: data.name,
        });
      });
    }
    setAuthorityName(option);
  };

  const GetAllCountry = async () => {
    const response = await getData("/allcountry");

    const option = [];
    if (response.success) {
      response?.data?.map((data) => {
        option.push({
          value: data.id,
          name: "country_id",
          label: data.name,
        });
      });
    }
    setCountry(option);
  };

  const GetAllLeague = async () => {
    const response = await getData("/allleague");

    const option = [];
    if (response.success) {
      response?.data?.map((data) => {
        option.push({
          value: data.id,
          name: "league_id",
          label: data.name,
        });
      });
    }
    setLeague(option);
  };

  const GetAllDescipline = async () => {
    const response = await getData("/alldescipline");

    const option = [];
    if (response.success) {
      response?.data?.map((data) => {
        option.push({
          value: data.id,
          name: "descipline_id",
          label: data.name,
        });
      });
    }
    setDescipline(option);
  };

  useEffect(() => {
    GetAllQualification();
    GetAllAuthorityName();
    GetAllCountry();
    GetAllLeague();
    GetAllDescipline();
  }, []);

  return (
    <>
      <Offcanvas
        show={props.show}
        style={{ width: "80%" }}
        placement={"end"}
        onHide={props.handleClose}
      >
        <Offcanvas.Header closeButton></Offcanvas.Header>
        <Offcanvas.Body>
          <Card className="shadow-lg p-3 mb-5 bg-white rounded">
            <Card.Body>
              <Card.Title>Add Education Master</Card.Title>
              <hr />
              <Container>
                <Row>
                  <Col md={12}>
                    <Container>
                      <Row className="">
                        <Col xxl={12} xl={12} md={10}>
                          <Form
                            onSubmit={handleSubmit}
                            role="form"
                            className="educationclass"
                          >
                            <Row>
                              <Col md={4}>
                                <div className="main-form-section mt-5">
                                  <Row className="row justify-content-center mb-2">
                                    <Form.Label>Qualification</Form.Label>

                                    <Select
                                      name="qualification_id"
                                      className="input-mandatory"
                                      onChange={(selectedOption) =>
                                        handleChange(selectedOption)
                                      }
                                      value={formData.qualification_id}
                                      options={qualification}
                                    />

                                    <span className="error-message"></span>
                                  </Row>
                                </div>
                              </Col>

                              <Col md={4}>
                                <div className="main-form-section mt-5">
                                  <Row className="row justify-content-center mb-2">
                                    <Form.Label>Authority Name</Form.Label>

                                    <Select
                                      name="authority_name_id"
                                      className="input-mandatory"
                                      onChange={(selectedOption) =>
                                        handleChange(selectedOption)
                                      }
                                      value={formData.authority_name_id}
                                      options={authorityname}
                                    />

                                    <span className="error-message"></span>
                                  </Row>
                                </div>
                              </Col>
                              <Col md={4}>
                                <div className="main-form-section mt-5">
                                  <Row className="row justify-content-center mb-2">
                                    <Form.Label>Country </Form.Label>

                                    <Select
                                      name="country_id"
                                      className="input-mandatory"
                                      onChange={(selectedOption) =>
                                        handleChange(selectedOption)
                                      }
                                      value={formData.country_id}
                                      options={country}
                                    />

                                    <span className="error-message"></span>
                                  </Row>
                                </div>
                              </Col>
                              <Col md={4}>
                                <div className="main-form-section mt-5">
                                  <Row className="row justify-content-center mb-2">
                                    <Form.Label>League </Form.Label>

                                    <Select
                                      name="league_id"
                                      className="input-mandatory"
                                      onChange={(selectedOption) =>
                                        handleChange(selectedOption)
                                      }
                                      value={formData.league_id}
                                      options={league}
                                    />

                                    <span className="error-message"></span>
                                  </Row>
                                </div>
                              </Col>

                              <Col md={4}>
                                <div className="main-form-section mt-5">
                                  <Row className="row justify-content-center mb-2">
                                    <Form.Label>Descipline </Form.Label>

                                    <Select
                                      name="descipline_id"
                                      className="input-mandatory"
                                      onChange={(selectedOption) =>
                                        handleChange(selectedOption)
                                      }
                                      value={formData.descipline_id}
                                      options={descipline}
                                    />

                                    <span className="error-message"></span>
                                  </Row>
                                </div>
                              </Col>

                              <Col md={4}>
                                <div className="main-form-section mt-5">
                                  <Row className="justify-content-center">
                                    <Form.Label>Q Score</Form.Label>

                                    <Form.Group>
                                      <InputGroup>
                                        <Form.Control
                                          type="tel"
                                          name="q_score"
                                          value={formData.q_score}
                                          onChange={handleChange}
                                          onKeyPress={handleKeyPressContact}
                                          placeholder="Q Score "
                                          id="inputEmail3"
                                          className="input-mandatory"
                                        />
                                      </InputGroup>
                                      <span className="error-message"></span>
                                    </Form.Group>
                                  </Row>
                                </div>
                              </Col>

                              <Col md={4}>
                                <div className="main-form-section mt-5">
                                  <Row className="justify-content-center">
                                    <Form.Label>Hic No</Form.Label>

                                    <Form.Group>
                                      <InputGroup>
                                        <Form.Control
                                          type="tel"
                                          name="hic_no"
                                          value={formData.hic_no}
                                          onChange={handleChange}
                                          onKeyPress={handleKeyPressContact}
                                          placeholder="Hic No "
                                          id="inputEmail3"
                                          className="input-mandatory"
                                        />
                                      </InputGroup>
                                      <span className="error-message"></span>
                                    </Form.Group>
                                  </Row>
                                </div>
                              </Col>
                              {/* </Row> */}

                              <Row className="mt-5 pb-3">
                                <div className="d-flex justify-content-center">
                                  <Link>
                                    <CancelButton
                                      name={"cancel"}
                                      handleClose={props.handleClose}
                                    />
                                  </Link>
                                  <SaveButton
                                    name={"save"}
                                    handleSubmit={handleSubmit}
                                  />
                                </div>
                              </Row>
                              {/* </Col> */}
                            </Row>
                          </Form>
                        </Col>
                      </Row>
                    </Container>
                  </Col>
                </Row>
              </Container>
            </Card.Body>
          </Card>
        </Offcanvas.Body>
      </Offcanvas>

      <ModalSave
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      />
    </>
  );
};

export default AddOffCanvance;
