import React, { useContext } from "react";
import { useState } from "react";
import "../../../../Tabels/Tabels.css";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";

import ModalSave from "../../../../common/ModelSave";
import { CancelButton, SaveButton } from "../../../../common/Button";
import { Row, Col, Form, Modal } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import classNames from "classnames";
import { Context } from "../../../../../utils/context";
import JoditEditor from "jodit-react";

library.add(fas);

const AddOffCanvance = (props) => {
  const { postData, getDimension } = useContext(Context);

  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm();

  const imageFile = watch("image_one");
  const imageFile2 = watch("image_two");
  console.log(errors);

  const onSubmit = async (data) => {
    try {
      const fromData = new FormData();
      fromData.append("name", data?.name);
      fromData.append("description", data?.description);
      fromData.append("image_one", data?.image_one[0]);
      fromData.append("image_two", data?.image_two[0]);
      const response = await postData(
        `/masters/ed-tech/university/amity/stagetwo`,
        fromData
      );

      if (response?.success) {
        await setShowModal({
          code: response?.code,
          message: response?.message,
        });
      } else {
        await setShowModal({
          code: response?.code,
          message: response?.message,
        });
      }
      setTimeout(() => {
        setShowModal(0);
        props.handleClose();
      }, 1000);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Modal
        onHide={props.handleClose}
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Add Stage Two
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            onSubmit={() => handleSubmit(onSubmit)}
            role="form"
            className="countryclass"
          >
            <Row>
              {/* Title */}
              <Col lg={6}>
                <div className="main-form-section mt-3">
                  <Form.Label className="text-center">Title</Form.Label>
                  <Form.Control
                    className={classNames("", {
                      "is-invalid": errors?.name,
                    })}
                    type="text"
                    {...register("name", {
                      required: "Title is required",
                    })}
                    placeholder="Title"
                  />
                  {errors.name && (
                    <span className="text-danger">{errors.name.message}</span>
                  )}
                </div>
              </Col>


              {/* Image Preview One */}
              <Col lg={6}>
                <div className="main-form-section mt-3">
                  <Form.Label className="text-center">Image One Preview</Form.Label>
                  {imageFile && imageFile?.length > 0 && (
                    <div className="image-preview-container">
                      <img
                        src={URL.createObjectURL(imageFile[0])}
                        alt="Preview"
                        className="image-preview"
                        style={{ width: "100px", height: "100px" }}
                      />
                    </div>
                  )}
                </div>
              </Col>

              {/* Image Upload One */}
              <Col lg={6}>
                <div className="main-form-section mt-3">
                  <Form.Label className="text-center">Image One</Form.Label>
                  <Form.Control
                    className={classNames("", {
                      "is-invalid": errors?.image_one,
                    })}
                    type="file"
                    {...register("image_one", {
                      required: "Image One is required",
                      validate: async (value) => {
                        const fileTypes = ["jpg", "png", "jpeg"];
                        const fileType = value[0].name.split(".")[1];

                        if (!fileTypes.includes(fileType)) {
                          return `please upload a valid file format. (${fileTypes})`;
                        }

                        const sizes = await getDimension(value[0]);
                        if (sizes.width !== 335 && sizes.height !== 296) {
                          return "Image width and height must be 335px and 296px";
                        }

                        const fileSize = Math.round(value[0].size / 1024);
                        if (fileSize > 500) {
                          return "file size must be lower than 500kb";
                        }
                      },
                    })}
                  />
                  {errors.image_one && (
                    <span className="text-danger">{errors.image_one.message}</span>
                  )}
                </div>
              </Col>

              {/* Image Preview Two */}
              <Col lg={6}>
                <div className="main-form-section mt-3">
                  <Form.Label className="text-center">Image Two Preview</Form.Label>
                  {imageFile2 && imageFile2?.length > 0 && (
                    <div className="image-preview-container">
                      <img
                        src={URL.createObjectURL(imageFile2[0])}
                        alt="Preview"
                        className="image-preview"
                        style={{ width: "100px", height: "100px" }}
                      />
                    </div>
                  )}
                </div>
              </Col>

              {/* Image Upload Two */}
              <Col lg={6}>
                <div className="main-form-section mt-3">
                  <Form.Label className="text-center">Image Two</Form.Label>
                  <Form.Control
                    className={classNames("", {
                      "is-invalid": errors?.image_two,
                    })}
                    type="file"
                    {...register("image_two", {
                      required: "Image Two is required",
                      validate: async (value) => {
                        const fileTypes = ["jpg", "png", "jpeg"];
                        const fileType = value[0].name.split(".")[1];

                        if (!fileTypes.includes(fileType)) {
                          return `please upload a valid file format. (${fileTypes})`;
                        }

                        const sizes = await getDimension(value[0]);
                        if (sizes.width !== 272 && sizes.height !== 217) {
                          return "Image width and height must be 272px and 217px";
                        }

                        const fileSize = Math.round(value[0].size / 1024);
                        if (fileSize > 500) {
                          return "file size must be lower than 500kb";
                        }
                      },
                    })}
                  />
                  {errors.image_two && (
                    <span className="text-danger">{errors.image_two.message}</span>
                  )}
                </div>
              </Col>



              {/* Description */}
              <Col lg={12} className="mt-3">
                <div className="main-form-section">
                  <Form.Label className="text-center">Description</Form.Label>
                  <Form.Group>
                    <Controller
                      name="description"
                      control={control}
                      rules={{ required: "Description is required" }}
                      render={({ field }) => (
                        <JoditEditor
                          value={field.value}
                          onChange={(newContent) => field.onChange(newContent)}
                          onBlur={field.onBlur}
                          className={classNames("", {
                            "is-invalid": !!errors.description,
                          })}
                          placeholder="Description"
                        />
                      )}
                    />
                  </Form.Group>
                  {errors.description && (
                    <span className="text-danger">
                      {errors.description.message}
                    </span>
                  )}
                </div>
              </Col>
            </Row>

            {/* Buttons */}
            <Row className="justify-content-center mt-5 pb-3">
              <Col sm={6} className="d-flex justify-content-center">
                <CancelButton name={"Cancel"} handleClose={props.handleClose} />
                <SaveButton
                  name={"Save"}
                  handleSubmit={handleSubmit(onSubmit)}
                />
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>

      <ModalSave
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      />
    </>
  );
};

export default AddOffCanvance;
