import React, { useContext } from "react";
import { useState, useEffect } from "react";

import "../../../../Tabels/Tabels.css";
import { Context } from "../../../../../utils/context";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";
import ModalSave from "../../../../common/ModelSave";
import { CancelButton, SaveButton } from "../../../../common/Button";
import { Row, Col, Form, Modal } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import classNames from "classnames";
import { useParams } from "react-router-dom";
import JoditEditor from "jodit-react";

library.add(fas);

const AddOffCanvance = (props) => {
  const { postData, getData, Select2Data, getDimension } = useContext(Context);
  const [course, setCourse] = useState([]);
  const [coursetype, setCourseType] = useState([]);

  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });

  const GetAllCourse = async (Id) => {
    const response = await getData(`/allcourse/${Id}`);
    // const response = await getData(`/allstate/${id}`);
    if (response.success) {
      setCourse(await Select2Data(response?.data, "course_id"));
    }
  };

  const GetAllCourseType = async () => {
    const response = await getData("/allcoursetype");

    if (response?.success) {
      setCourseType(await Select2Data(response?.data, "course_type_id"));
    }
  };

  useEffect(() => {
    GetAllCourse();
    GetAllCourseType();
  }, []);

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    watch,
    getValues,
    setValue,
    trigger,
  } = useForm();

  const imageFile = watch("image");
  const descriptionValue = watch("description");
  console.log(errors);
  const onSubmit = async (data) => {
    try {
      const fromData = new FormData();
      fromData.append("name", data?.name);
      fromData.append("description", data?.description);
      fromData.append("course_id", data?.course_id?.value);
      fromData.append("course_type_id", data?.course_type_id?.value);
      // fromData.append("video", data?.video[0]);
      fromData.append("image", data?.image[0]);
      console.log(data, "data");
      const response = await postData(
        `/masters/ed-tech/course/common/reason`,
        fromData
      );
      console.log(response, "response");
      if (response?.success) {
        await setShowModal({ code: response.code, message: response.message });
      } else {
        await setShowModal({ code: response.code, message: response.message });
      }
      setTimeout(() => {
        setShowModal(0);
        props.handleClose();
      }, 1000);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    register('description', {
      required: 'Description is required.',
      // maxLength: {
      //   value: 85,
      //   message: 'Description must be at most 85 characters long.'
      // }
    });
  }, [register]);

  return (
    <>
      <Modal
        onHide={props.handleClose}
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Add Reason
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            onSubmit={() => handleSubmit(onSubmit)}
            role="form"
            className="countryclass"
          >
            <Row>
              {/* Left Side: Input Fields */}
              <Col lg={6}>
                {/* Course Type */}
                <div className="main-form-section mt-3">
                  <Form.Label>Course Type</Form.Label>
                  <Controller
                    name="course_type_id"
                    {...register("course_type_id", {
                      required: "Course Type is required",
                    })}
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={coursetype}
                        onChange={(selectedOption) => {
                          GetAllCourse(selectedOption.value);
                          setValue("course_type_id", selectedOption);
                        }}
                      />
                    )}
                  />
                  {errors.course_type_id && (
                    <span className="text-danger">
                      {errors.course_type_id.message}
                    </span>
                  )}
                </div>

                {/* Course */}
                <div className="main-form-section mt-3">
                  <Form.Label>Course</Form.Label>
                  <Controller
                    name="course_id"
                    {...register("course_id", {
                      required: "Course is required",
                    })}
                    control={control}
                    render={({ field }) => (
                      <Select {...field} options={course} />
                    )}
                  />
                  {errors.course_id && (
                    <span className="text-danger">
                      {errors.course_id.message}
                    </span>
                  )}
                </div>

                {/* Title */}
                <div className="main-form-section mt-3">
                  <Form.Label>Title</Form.Label>
                  <Form.Control
                    type="text"
                    {...register("name", {
                      required: "Title is required",
                      // maxLength: {
                      //   value: 35,
                      //   message: "Title must be at most 35 characters long",
                      // },
                    })}
                    placeholder="Title"

                  />
                  {errors.name && (
                    <span className="text-danger">{errors.name.message}</span>
                  )}
                </div>

                {/* Image */}
                <div className="main-form-section mt-3">
                  <Form.Label>Image</Form.Label>
                  <Form.Control
                    type="file"
                    {...register("image", {
                      required: "Image is required",
                      validate: async (value) => {
                        if (typeof value !== "string") {
                          const fileTypes = ["jpg", "png", "jpeg"];
                          const fileType = value[0].name?.split(".")[1];

                          if (!fileTypes.includes(fileType)) {
                            return `please upload a valid file format. (${fileTypes})`;
                          }

                          const sizes = await getDimension(value[0]);
                          if (sizes.width !== 60 && sizes.height !== 60) {
                            return "Image width and height must be 60 px and 60 px";
                          }

                          const fileSize = Math.round(value[0].size / 1024);
                          if (fileSize > 500) {
                            return "file size must be lower than 500kb";
                          }
                        }
                      },
                    })}
                    accept=".jpg, .jpeg, .png"
                  />
                  {errors.image && (
                    <span className="text-danger">{errors.image.message}</span>
                  )}
                </div>
              </Col>

              {/* Right Side: Image Preview */}
              <Col lg={6} className="text-center">
                <div className="main-form-section mt-3">
                  <Form.Label>Image Preview</Form.Label>
                  {imageFile && imageFile?.length > 0 && (
                    <div className="image-preview-container">
                      <img
                        src={URL.createObjectURL(imageFile[0])}
                        alt="Preview"
                        className="image-preview"
                        style={{ width: "150px", height: "130px" }}
                      />
                    </div>
                  )}
                </div>
              </Col>
            </Row>

            {/* <Col lg={6}>
              <div className="main-form-section mt-3">
                <Row className="justify-content-center">
                  <Form.Label column sm={3} className="text-center">
                    Image
                  </Form.Label>
                  <Col sm={9}>
                    <Form.Group>
                      <Form.Control
                        className={classNames("", {
                          "is-invalid": errors?.image,
                        })}
                        type="file"
                        {...register("image", {
                          required: "Image is required",
                          validate: async (value) => {
                            const fileTypes = ["jpg", "png", "jpeg"];
                            const fileType = value[0].name.split(".")[1];

                            if (!fileTypes.includes(fileType)) {
                              return `please upload a valid file format. (${fileTypes})`;
                            }

                            const sizes = await getDimension(value[0]);
                            if (sizes.width !== 295 && sizes.height !== 269) {
                              return "Image width and height must be 295 px and 269 px";
                            }

                            const fileSize = Math.round(value[0].size / 1024);
                            if (fileSize > 2000) {
                              return "file size must be lower than 2000kb";
                            }
                          },
                        })}
                        accept=".jpg, .jpeg, .png"
                      />
                    </Form.Group>
                    {errors.image && (
                      <span className="text-danger">
                        {errors.image.message}
                      </span>
                    )}
                  </Col>
                </Row>
              </div>
            </Col> */}

            {/* Image Preview */}
            {/* <Col lg={6}>
              <div className="main-form-section mt-3">
                <Row className="justify-content-center">
                  <Form.Label column sm={3} className="text-center">
                    Image Preview
                  </Form.Label>
                  <Col sm={9}>
                    {imageFile && imageFile?.length > 0 && (
                      <div className="image-preview-container">
                        <img
                          src={URL.createObjectURL(imageFile[0])}
                          alt="Preview"
                          className="image-preview"
                          style={{ width: "150px", height: "130px" }}
                        />
                      </div>
                    )}
                  </Col>
                </Row>
              </div>
            </Col> */}

            <Col lg={12}>
              <div className="main-form-section mt-3">
                <Row className="justify-content-start"> {/* Align to left */}
                  {/* Description */}
                  <Col sm={12}>
                    <Form.Label className="text-center">
                      Description
                    </Form.Label>
                    <Form.Group>
                      <Controller
                        name="description" // Provide the field name
                        control={control} // Pass the control object from useForm()
                        rules={{ required: "Description is required" }} // Validation rules
                        render={({ field }) => (
                          <JoditEditor
                            value={descriptionValue || ''}
                            onBlur={(newContent) => {
                              setValue('description', newContent);
                              trigger('description');
                            }}

                          />
                        )}
                      />
                    </Form.Group>
                    {errors.description && (
                      <span className="text-danger">
                        {errors.description.message}
                      </span>
                    )}
                  </Col>
                </Row>
              </div>
            </Col>


            {/* Buttons */}
            <Row className="justify-content-center mt-5 pb-3">
              <Col sm={6} className="d-flex justify-content-center">
                <CancelButton name={"Cancel"} handleClose={props.handleClose} />
                <SaveButton
                  name={"Save"}
                  handleSubmit={handleSubmit(onSubmit)}
                />
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>

      <ModalSave
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      />
    </>
  );
};

export default AddOffCanvance;
