import React, { useContext } from "react";
import { useState } from "react";
import "../../../Tabels/Tabels.css";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";

import ModalSave from "../../../common/ModelSave";
import { CancelButton, SaveButton } from "../../../common/Button";
import { Row, Col, Form, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import classNames from "classnames";
import { Context } from "../../../../utils/context";

library.add(fas);

const AddOffCanvance = (props) => {
  const { postData, getDimension } = useContext(Context);

  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm();

  const imageFile = watch("image");
  console.log(errors);

  const onSubmit = async (data) => {
    try {
      const fromData = new FormData();
      fromData.append("name", data?.name);
      fromData.append("expert_in", data?.expert_in);
      fromData.append("experience", data?.experience);
      fromData.append("city", data?.city);
      fromData.append("image", data?.image[0]);
      const response = await postData(
        `/masters/ed-tech/home/right-guidence`,
        fromData
      );

      if (response?.success) {
        await setShowModal({
          code: response?.code,
          message: response?.message,
        });
      } else {
        await setShowModal({
          code: response?.code,
          message: response?.message,
        });
      }
      setTimeout(() => {
        setShowModal(0);
        props.handleClose();
      }, 700);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Modal
        onHide={props.handleClose}
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Add Right Guidance
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            onSubmit={() => handleSubmit(onSubmit)}
            role="form"
            className="countryclass"
          >
            <Row>
              {/* Title */}
              <Col lg={6}>
                <div className="main-form-section mt-3">
                  <Form.Label>Name</Form.Label>

                  <Form.Group>
                    <Form.Control
                      className={classNames("", {
                        "is-invalid": errors?.name,
                      })}
                      type="text"
                      {...register("name", {
                        required: "Name is required",
                      })}
                      placeholder="Name"
                    />
                  </Form.Group>
                  {errors.name && (
                    <span className="text-danger">{errors.name.message}</span>
                  )}
                </div>
              </Col>

              <Col lg={6}>
                <div className="main-form-section mt-3">
                  <Form.Label>Expert In</Form.Label>
                  <Col sm={9}>
                    <Form.Group>
                      <Form.Control
                        className={classNames("", {
                          "is-invalid": errors?.expert_in,
                        })}
                        type="text"
                        {...register("expert_in", {
                          required: "Expert In is required",
                          maxLength: {
                            value: 40,
                            message: "Expert In Is required",
                          },
                        })}
                        placeholder="Expert In"
                      />
                    </Form.Group>
                    {errors.expert_in && (
                      <span className="text-danger">
                        {errors.expert_in.message}
                      </span>
                    )}
                  </Col>
                </div>
              </Col>

              <Col lg={6}>
                <div className="main-form-section mt-3">
                  <Form.Label>City</Form.Label>
                  <Col sm={9}>
                    <Form.Group>
                      <Form.Control
                        className={classNames("", {
                          "is-invalid": errors?.city,
                        })}
                        type="text"
                        {...register("city", {
                          required: "City is required",
                          maxLength: {
                            value: 40,
                            message: "City Is required",
                          },
                        })}
                        placeholder="Expert In"
                      />
                    </Form.Group>
                    {errors.city && (
                      <span className="text-danger">{errors.city.message}</span>
                    )}
                  </Col>
                </div>
              </Col>

              <Col lg={6}>
                <div className="main-form-section mt-3">
                  <Form.Label>Experience</Form.Label>
                  <Col sm={9}>
                    <Form.Group>
                      <Form.Control
                        className={classNames("", {
                          "is-invalid": errors?.experience,
                        })}
                        type="text"
                        {...register("experience", {
                          required: "Experience is required",
                          maxLength: {
                            value: 40,
                            message: "Experience required",
                          },
                        })}
                        placeholder="Experience"
                      />
                    </Form.Group>
                    {errors.experience && (
                      <span className="text-danger">
                        {errors.experience.message}
                      </span>
                    )}
                  </Col>
                </div>
              </Col>

              {/* Image Upload */}
              <Col lg={6}>
                <div className="main-form-section mt-3">
                  <Form.Label>Image</Form.Label>

                  <Form.Group>
                    <Form.Control
                      className={classNames("", {
                        "is-invalid": errors?.image,
                      })}
                      type="file"
                      {...register("image", {
                        required: "Image is required",
                        validate: async (value) => {
                          const fileTypes = ["jpg", "png", "jpeg"];
                          const fileType = value[0].name.split(".")[1];

                          //   if (!fileTypes.includes(fileType)) {
                          //     return `please upload a valid file format. (${fileTypes})`;
                          //   }

                          //   const sizes = await getDimension(value[0]);
                          //   if (sizes.width !== 1296 && sizes.height !== 465) {
                          //     return "Image width and height must be 1296px and 465px";
                          //   }

                          // const fileSize = Math.round(value[0].size / 1024);
                          // if (fileSize > 500) {
                          //   return "file size must be lower than 500kb";
                          // }
                        },
                      })}
                      //   accept=".jpg, .jpeg, .png"
                    />
                  </Form.Group>
                  {errors.image && (
                    <span className="text-danger">{errors.image.message}</span>
                  )}
                </div>
              </Col>

              {/* Image Preview */}
              <Col lg={6}>
                <div
                  className="main-form-section mt-3"
                  style={{ marginLeft: "50px" }}
                >
                  <Form.Label>Image Preview</Form.Label>

                  {imageFile && imageFile?.length > 0 && (
                    <div className="image-preview-container">
                      <img
                        src={URL.createObjectURL(imageFile[0])}
                        alt="Preview"
                        className="image-preview"
                        style={{ width: "100px", height: "100px" }}
                      />
                    </div>
                  )}
                </div>
              </Col>
            </Row>

            {/* Buttons */}
            <Row className="justify-content-center mt-5 pb-3">
              <Col sm={6} className="d-flex justify-content-center">
                <CancelButton name={"Cancel"} handleClose={props.handleClose} />
                <SaveButton
                  name={"Save"}
                  handleSubmit={handleSubmit(onSubmit)}
                />
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>

      <ModalSave
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      />
    </>
  );
};

export default AddOffCanvance;
