import React, { useContext, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./AdvanceDashboard.css";
import Header from "../../Header/Header";
import Piechart from "../Piechart/Piechart";
import Barchart from "../Barchart/Barchart";
import LineChart from "../LineChart/LineChart";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import { Swiper, SwiperSlide } from "swiper/react";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import "swiper/swiper-bundle.css";
import CanvasJSReact from "@canvasjs/react-charts";
import { Pagination } from "swiper/modules";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
import { Context } from "../../../utils/context";
import University from "../../Masters/University/Table";
var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;
library.add(fas);

const AdvanceDashboard = () => {
  const { getData } = useContext(Context);
  const [result, setResult] = useState({});
  const getDataAll = async () => {
    const result = await getData("/dashboard");
    setResult(result.data);
  };
  console.log(result);
  useEffect(() => {
    // getDataAll();
  }, []);

  const featuredDetails = [
    { companyname: "Wipro", vacancy: "21 Vacancy" },
    { companyname: "Infosys", vacancy: "21 Vacancy" },
    { companyname: "TCS", vacancy: "21 Vacancy" },
    { companyname: "Google", vacancy: "21 Vacancy" },
    { companyname: "Google", vacancy: "21 Vacancy" },
  ];
  const percentage = 66;
  const [startDate, setStartDate] = useState(new Date());

  const options = {
    animationEnabled: true,
    theme: "light2",

    axisX: {
      valueFormatString: "DD MMM",
      crosshair: {
        enabled: true,
        snapToDataPoint: true,
      },
    },
    axisY: {
      title: "Closing Price (in EUR)",
      valueFormatString: "€##0.00",
      crosshair: {
        enabled: true,
        snapToDataPoint: true,
        labelFormatter: function (e) {
          return "€" + CanvasJS.formatNumber(e.value, "##0.00");
        },
      },
    },
    data: [
      {
        type: "area",
        xValueFormatString: "DD MMM",
        yValueFormatString: "€##0.00",
        dataPoints: [
          { x: new Date("2018-03-01"), y: 85.3 },
          { x: new Date("2018-03-02"), y: 83.97 },
          { x: new Date("2018-03-05"), y: 83.49 },
          { x: new Date("2018-03-06"), y: 84.16 },
          { x: new Date("2018-03-07"), y: 84.86 },
          { x: new Date("2018-03-08"), y: 84.97 },
          { x: new Date("2018-03-09"), y: 85.13 },
          { x: new Date("2018-03-12"), y: 85.71 },
          { x: new Date("2018-03-13"), y: 84.63 },
          { x: new Date("2018-03-14"), y: 84.17 },
          { x: new Date("2018-03-15"), y: 85.12 },
          { x: new Date("2018-03-16"), y: 85.86 },
          { x: new Date("2018-03-19"), y: 85.17 },
          { x: new Date("2018-03-20"), y: 85.99 },
          { x: new Date("2018-03-21"), y: 86.1 },
          { x: new Date("2018-03-22"), y: 85.33 },
          { x: new Date("2018-03-23"), y: 84.18 },
          { x: new Date("2018-03-26"), y: 85.21 },
          { x: new Date("2018-03-27"), y: 85.81 },
          { x: new Date("2018-03-28"), y: 85.56 },
          { x: new Date("2018-03-29"), y: 88.15 },
        ],
      },
    ],
  };

  // search functionality start

  const [filter, setFilter] = useState("");

  const handleFilterChange = (event) => {
    setFilter(event.target.value.toLowerCase());
  };

  const settingItems = [
    { name: "Country", link: "masters/country" },
    { name: "State", link: "masters/state" },
    { name: "City", link: "masters/city" },
    { name: "Job Type", link: "masters/job_type" },
    { name: "Job Title", link: "masters/job_title" },
    { name: "Industry", link: "masters/industry" },
    { name: "Sector", link: "masters/sector" },
    { name: "Career Level", link: "masters/career_level" },
    { name: "Qualification", link: "masters/qualification" },
    { name: "Education", link: "masters/education_type" },
    { name: "Experince in Years", link: "masters/experience_in_year" },
    { name: "Blog Type", link: "masters/blog_type" },
    { name: "Blog", link: "masters/blog" },
    { name: "Language", link: "masters/language" },
    { name: "Shift", link: "masters/shift" },
    { name: "Degree Type", link: "masters/degree_type" },
    { name: "Degree", link: "masters/degree" },
    { name: " University Type", link: "masters/university_type" },
    { name: "University", link: "masters/university" },
    { name: "Skill Type", link: "masters/skill_type" },
    { name: "Skill Name", link: "masters/skills" },
    { name: "Client Review", link: "masters/client_review" },
    { name: "Company List", link: "masters/company" },
    { name: "Social Link", link: "masters/social_links" },
    { name: "Project Type", link: "masters/project_type" },
    { name: " Role Type", link: "masters/role_type" },
    { name: "Role Subtype", link: "masters/role_subtype" },
    { name: "Roles", link: "masters/roles" },
    { name: "Round", link: "masters/round" },
    { name: "Authority Name", link: "masters/authority" },
    { name: "League", link: "masters/league" },
    { name: "Descipline", link: "masters/descipline" },
    { name: "Skill League", link: "masters/skill_league" },
    { name: "Skill Master", link: "masters/skills_master" },
    { name: "Education Master", link: "masters/education" },
  ];
  // search functionality end

  return (
    <div className="main-advancedashboard">
      <section className="AdvanceDashboard">
        <div className="AdvanceDashboardTabs">
          <div className="row justify-content-end me-0 ms-0">
            <div className="col-xxl-12 col-12 p-0">
              <Header title={"Dashboard"} link={"/masters/social_links"} />
              <div className="badhboard-header sticky-top bg-white">
                {/* <ul
                  className="nav nav-tabs bg-white "
                  id="myTab"
                  role="tablist"
                >
                  <div className="vr"></div>

                  <li className="nav-item" role="presentation">
                    <button className="nav-link active" id="Overview-tab" data-bs-toggle="pill" data-bs-target="#Overview-tab-pane" type="button" role="tab" aria-controls="pills-home" aria-selected="true">Overview</button>
                  </li>
                  <div className="vr"></div>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="Project-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#Project-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="Project-tab-pane"
                      aria-selected="false"
                    >
                      Project
                    </button>
                  </li>
                  <div className="vr"></div>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="Client-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#Client-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="Client-tab-pane"
                      aria-selected="false"
                    >
                      Client
                    </button>
                  </li>
                  <div className="vr"></div>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="HR-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#HR-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="HR-tab-pane"
                      aria-selected="false"
                    >
                      HR
                    </button>
                  </li>
                  <div className="vr"></div>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="Ticket-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#Ticket-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="Ticket-tab-pane"
                      aria-selected="false"
                    >
                      Ticket
                    </button>
                  </li>
                  <div className="vr"></div>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="Finance-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#Finance-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="Finance-tab-pane"
                      aria-selected="false"
                    >
                      Finance
                    </button>
                  </li>
                  <div className="vr"></div>
                </ul> */}

                {/* 
                <div className="setting">
                  <div className="vr me-2"></div>
                  <div className="calender ">
                    <div>
                      <FontAwesomeIcon icon="fa-solid fa-calendar-days" />{" "}
                      <DatePicker
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                      />
                    </div>
                  </div>
                  <div className="vr me-2"></div>
                  <div>
                    <FontAwesomeIcon icon="fa-solid fa-gear" />
                  </div>
                </div> */}
              </div>

              {/* <div className="card settingCard">
                    <InputGroup className="mb-3 settingInputGrp">
                      <Form.Control
                        placeholder="Search..."
                        aria-label="Search"
                        aria-describedby="basic-addon2"
                        onChange={handleFilterChange}
                        value={filter}
                      />
                    </InputGroup>
                    <ul className="Setting_list">
                      {settingItems.map((item, index) => (
                        <li key={index} style={{ display: item.name.toLowerCase().includes(filter) ? 'block' : 'none' }}>
                          <a href={item.link} style={{ textDecoration: 'none', color: 'inherit' }}>
                            {item.name}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div> */}

              <div className="tab-content" id="myTabContent">
                <div
                  className="tab-pane fade show active"
                  role="tabpanel"
                  id="Overview-tab-pane"
                  tabindex="0"
                  aria-labelledby="Overview-tab"
                >
                  <div className="AdvanceDashboardTabsContent">
                    <div className="row ">
                      <div className="col-xl-3 col-lg-6 col-md-6 mb-3">
                        <div className="box bg-primary">
                          <div className="text-holder">
                            <h5 className="text">Total Job Seeker</h5>
                            <div className="number-holder">
                              <p className="number">
                                <span id="">{result?.job_seeker}</span>
                              </p>
                            </div>
                          </div>
                          <div className="users">
                            <FontAwesomeIcon icon="fa-solid fa-users" />
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-3 col-lg-6 col-md-6 mb-3">
                        <div className="box bg-info">
                          <div className="text-holder">
                            <h5 className="text">Total Recruiters</h5>
                            <div className="number-holder">
                              <p className="number">
                                <span id="">{result?.recruiters}</span>
                              </p>
                            </div>
                          </div>
                          <div className="users">
                            <FontAwesomeIcon icon="fa-solid fa-user" />
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-3 col-lg-6 col-md-6 mb-3">
                        <div className="box bg-primary">
                          <div className="text-holder">
                            <h5 className="text">Total Pool Subscribers</h5>
                            <div className="number-holder">
                              <p className="number">
                                <span id="">{result?.pool_subscribers}</span>
                              </p>
                            </div>
                          </div>
                          <div className="users">
                            <FontAwesomeIcon icon="fa-solid fa-layer-group" />
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-3 col-lg-6 col-md-6 mb-3">
                        <div className="box bg-info">
                          <div className="text-holder">
                            <h5 className="text">Total Job Posted</h5>
                            <div className="number-holder">
                              <p className="number">
                                <span id="">{result?.job_posted}</span>
                              </p>
                            </div>
                          </div>
                          <div className="users">
                            <FontAwesomeIcon icon="fa-solid fa-file-invoice" />
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-3 col-lg-6 col-md-6 mb-3">
                        <div className="box bg-info">
                          <div className="text-holder">
                            <h5 className="text">Total Job Expired</h5>
                            <div className="number-holder">
                              <p className="number">
                                <span id="">{result?.job_expired}</span>
                              </p>
                            </div>
                          </div>
                          <div className="users">
                            {/* <FontAwesomeIcon icon="fa-solid fa-clock" />
                             */}
                            <FontAwesomeIcon icon="fa-solid fa-circle-xmark" />
                          </div>
                        </div>
                      </div>

                      <div className="col-xl-3 col-lg-6 col-md-6 mb-3" >

                        <div className="box bg-primary">
                          <div className="text-holder">
                            <h5 className="text">Total University </h5>
                            <div className="number-holder">
                              <p className="number">
                                <span id="">{result?.university}</span>
                              </p>
                            </div>
                          </div>
                          <div className="users">
                            <FontAwesomeIcon icon="fa-solid fa-building-columns" />
                          </div>
                        </div>

                      </div>

                      <div className="col-xl-3 col-lg-6 col-md-6 mb-3">
                        <div className="box bg-info">
                          <div className="text-holder">
                            <h5 className="text">Total Degree</h5>
                            <div className="number-holder">
                              <p className="number">
                                <span id="">{result?.degree}</span>
                              </p>
                            </div>
                          </div>
                          <div className="users">
                            <FontAwesomeIcon icon="fa-solid fa-user-graduate" />
                          </div>
                        </div>
                      </div>

                      <div className="col-xl-3 col-lg-6 col-md-6 mb-3">
                        <div className="box bg-primary">
                          <div className="text-holder">
                            <h5 className="text">Total Roles</h5>
                            <div className="number-holder">
                              <p className="number">
                                <span id="">{result?.roles}</span>
                              </p>
                            </div>
                          </div>
                          <div className="users">
                            <FontAwesomeIcon icon="fa-solid fa-briefcase" />
                          </div>
                        </div>
                      </div>

                      <div className="col-xl-3 col-lg-6 col-md-6 mb-3">
                        <div className="box bg-primary">
                          <div className="text-holder">
                            <h5 className="text">Total Skills</h5>
                            <div className="number-holder">
                              <p className="number">
                                <span id="">{result?.skills}</span>
                              </p>
                            </div>
                          </div>
                          <div className="users">
                            <FontAwesomeIcon icon="fa-solid fa-microchip" />
                          </div>
                        </div>
                      </div>

                      <div className="col-xl-3 col-lg-6 col-md-6 mb-3">
                        <div className="box bg-info">
                          <div className="text-holder">
                            <h5 className="text">Total Descipline</h5>
                            <div className="number-holder">
                              <p className="number">
                                <span id="">{result?.descipline}</span>
                              </p>
                            </div>
                          </div>
                          <div className="users">
                            <FontAwesomeIcon icon="fa-solid fa-arrow-down-short-wide" />
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* <div className="row mb-5">
                      <div className="col-xl-3 col-sm-12 col-lg-3 mt-3">
                        <div className="card profileCard">
                          <div className="card-title"></div>
                          <div className="card-body">
                            <div className="card-body-data">
                              <div className="profile-image mb-4">
                                <img
                                  src={
                                    process.env.PUBLIC_URL + "profile-img.png"
                                  }
                                  className="rounded-circle"
                                  alt=""
                                />
                              </div>
                              <h4 className="fs-22 text-black mb-1">
                                Saurav Khambayate
                              </h4>
                              <p className="mb-4">Programmer</p>
                              <div className="row">
                                <div className="col-lg-4 col-md-4">
                                  <CircularProgressbar
                                    value={percentage}
                                    text={`${percentage}%`}
                                  />
                                  <p>PHP</p>
                                </div>
                                <div className="col-lg-4 col-md-4">
                                  <CircularProgressbar
                                    value={percentage}
                                    text={`${percentage}%`}
                                  />
                                  <p>React</p>
                                </div>
                                <div className="col-lg-4 col-md-4">
                                  <CircularProgressbar
                                    value={percentage}
                                    text={`${percentage}%`}
                                  />
                                  <p>Vue</p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="p-3">
                            <div className="mb-4 text-start">
                              Recent activity
                            </div>
                            <div className="d-flex mb-5">
                              <div className="p-3 border me-3 rounded">
                                <svg
                                  className="primary-icon"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M20.3955 10.8038C19.9733 10.8038 19.5767 10.8742 19.2057 11.0213V4.79104H12.9883C13.1226 4.42004 13.193 4.01066 13.193 3.58849C13.193 1.60554 11.5874 0 9.60447 0C7.62152 0 6.01598 1.60554 6.01598 3.58849C6.01598 4.01066 6.08634 4.41365 6.22067 4.79104H0.00958252V11.7441C0.642845 11.1684 1.48719 10.8102 2.4083 10.8102C4.39125 10.8102 5.99679 12.4158 5.99679 14.3987C5.99679 16.3817 4.39125 17.9872 2.4083 17.9872C1.48719 17.9872 0.642845 17.629 0.00958252 17.0533V24H19.2121V17.7697C19.5831 17.9104 19.9797 17.9872 20.4019 17.9872C22.3912 17.9872 23.9904 16.3817 23.9904 14.3987C23.9904 12.4158 22.3912 10.8038 20.3955 10.8038Z"
                                    fill="#40189D"
                                  ></path>
                                </svg>
                              </div>
                              <div>
                                <div className="media-body">
                                  <p className="fs-14 mb-1 ">
                                    Your application has accepted in{" "}
                                    <strong>3 Vacancy</strong>
                                  </p>
                                  <span className="fs-14">12h ago</span>
                                </div>
                              </div>
                            </div>

                            <div className="d-flex mb-5">
                              <div className="p-3 border me-3 rounded">
                                <svg
                                  className="primary-icon"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M20.3955 10.8038C19.9733 10.8038 19.5767 10.8742 19.2057 11.0213V4.79104H12.9883C13.1226 4.42004 13.193 4.01066 13.193 3.58849C13.193 1.60554 11.5874 0 9.60447 0C7.62152 0 6.01598 1.60554 6.01598 3.58849C6.01598 4.01066 6.08634 4.41365 6.22067 4.79104H0.00958252V11.7441C0.642845 11.1684 1.48719 10.8102 2.4083 10.8102C4.39125 10.8102 5.99679 12.4158 5.99679 14.3987C5.99679 16.3817 4.39125 17.9872 2.4083 17.9872C1.48719 17.9872 0.642845 17.629 0.00958252 17.0533V24H19.2121V17.7697C19.5831 17.9104 19.9797 17.9872 20.4019 17.9872C22.3912 17.9872 23.9904 16.3817 23.9904 14.3987C23.9904 12.4158 22.3912 10.8038 20.3955 10.8038Z"
                                    fill="#40189D"
                                  ></path>
                                </svg>
                              </div>
                              <div>
                                <div className="media-body">
                                  <p className="fs-14 mb-1 ">
                                    Your application has accepted in{" "}
                                    <strong>3 Vacancy</strong>
                                  </p>
                                  <span className="fs-14">12h ago</span>
                                </div>
                              </div>
                            </div>

                            <div className="d-flex mb-5">
                              <div className="p-3 border me-3 rounded">
                                <svg
                                  className="primary-icon"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M20.3955 10.8038C19.9733 10.8038 19.5767 10.8742 19.2057 11.0213V4.79104H12.9883C13.1226 4.42004 13.193 4.01066 13.193 3.58849C13.193 1.60554 11.5874 0 9.60447 0C7.62152 0 6.01598 1.60554 6.01598 3.58849C6.01598 4.01066 6.08634 4.41365 6.22067 4.79104H0.00958252V11.7441C0.642845 11.1684 1.48719 10.8102 2.4083 10.8102C4.39125 10.8102 5.99679 12.4158 5.99679 14.3987C5.99679 16.3817 4.39125 17.9872 2.4083 17.9872C1.48719 17.9872 0.642845 17.629 0.00958252 17.0533V24H19.2121V17.7697C19.5831 17.9104 19.9797 17.9872 20.4019 17.9872C22.3912 17.9872 23.9904 16.3817 23.9904 14.3987C23.9904 12.4158 22.3912 10.8038 20.3955 10.8038Z"
                                    fill="#40189D"
                                  ></path>
                                </svg>
                              </div>
                              <div>
                                <div className="media-body">
                                  <p className="fs-14 mb-1 ">
                                    Your application has accepted in{" "}
                                    <strong>3 Vacancy</strong>
                                  </p>
                                  <span className="fs-14">12h ago</span>
                                </div>
                              </div>
                            </div>

                            <div className="d-flex mb-5">
                              <div className="p-3 border me-3 rounded">
                                <svg
                                  className="primary-icon"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M20.3955 10.8038C19.9733 10.8038 19.5767 10.8742 19.2057 11.0213V4.79104H12.9883C13.1226 4.42004 13.193 4.01066 13.193 3.58849C13.193 1.60554 11.5874 0 9.60447 0C7.62152 0 6.01598 1.60554 6.01598 3.58849C6.01598 4.01066 6.08634 4.41365 6.22067 4.79104H0.00958252V11.7441C0.642845 11.1684 1.48719 10.8102 2.4083 10.8102C4.39125 10.8102 5.99679 12.4158 5.99679 14.3987C5.99679 16.3817 4.39125 17.9872 2.4083 17.9872C1.48719 17.9872 0.642845 17.629 0.00958252 17.0533V24H19.2121V17.7697C19.5831 17.9104 19.9797 17.9872 20.4019 17.9872C22.3912 17.9872 23.9904 16.3817 23.9904 14.3987C23.9904 12.4158 22.3912 10.8038 20.3955 10.8038Z"
                                    fill="#40189D"
                                  ></path>
                                </svg>
                              </div>
                              <div>
                                <div className="media-body">
                                  <p className="fs-14 mb-1 ">
                                    Your application has accepted in{" "}
                                    <strong>3 Vacancy</strong>
                                  </p>
                                  <span className="fs-14">12h ago</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-9 col-sm-12 col-lg-9 mt-3">
                        <div className="card profileCard">
                          <div className="card-title">
                            <Navbar className="card_nav bg-body-tertiary justify-content-center">
                              <Container>
                                <Navbar.Text href="#home">
                                  Vacancy Stats
                                </Navbar.Text>
                                <Navbar.Text href="#home">
                                  <input type="checkbox" />
                                  <span className="graph_txt ms-2">
                                    Application Sent
                                  </span>
                                </Navbar.Text>
                                <Navbar.Text href="#home">
                                  <input type="checkbox" />
                                  <span className=" graph_txt ms-2">
                                    Interviews
                                  </span>
                                </Navbar.Text>
                                <Navbar.Text>
                                  <input type="checkbox" />
                                  <span className=" graph_txt ms-2">
                                    Rejected
                                  </span>
                                </Navbar.Text>
                                <Navbar.Text>
                                  <div className="icon dropdown">
                                    <div
                                      className=" text-center dropdown-toggle"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="false"
                                    >
                                      <button
                                        className="thisMonth_btn graph_txt btn border   show"
                                        role="button"
                                      >
                                        This Month
                                      </button>
                                    </div>

                                    <ul className="dropdown-menu">
                                      <li>
                                        <a className="dropdown-item" href="#">
                                          <span className="ms-3">Details</span>
                                        </a>
                                      </li>
                                      <li>
                                        <a className="dropdown-item" href="#">
                                          <span className="ms-3">Cancel</span>
                                        </a>
                                      </li>
                                    </ul>
                                  </div>
                                </Navbar.Text>
                              </Container>
                            </Navbar>
                          </div>
                          <div className="card-body">
                            <div className="card-body-data pb-4">
                              <div>
                                <CanvasJSChart options={options} />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row jobCards mt-4">
                          <h5 className="mb-4">Recommended Jobs</h5>

                          <Swiper
                            modules={[Pagination]}
                            pagination={{
                              clickable: true,
                            }}
                            autoplay={{
                              delay: 1000,
                              disableOnInteraction: false,
                            }}
                            breakpoints={{
                              0: {
                                slidesPerView: 2,
                              },
                              360: {
                                slidesPerView: 2,
                              },
                              640: {
                                slidesPerView: 2,
                              },
                              768: {
                                slidesPerView: 2,
                              },
                              1024: {
                                slidesPerView: 2,
                              },
                              1200: {
                                slidesPerView: 2,
                              },
                              1400: {
                                slidesPerView: 2,
                              },
                            }}
                          >
                            <SwiperSlide>
                              <div className="card profileCard">
                                <div className="text-left p-4">
                                  <div className="recommendImg  mb-2">
                                    <div className="">
                                      <p className="mb-1">Maximoz Team</p>
                                      <h4 className="">Intern UX Designer</h4>
                                    </div>
                                    <svg
                                      className="ms-3"
                                      width="60"
                                      height="60"
                                      viewBox="0 0 60 60"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M0 8.72727C0 3.90733 3.90733 0 8.72727 0H51.2727C56.0927 0 60 3.90733 60 8.72727V51.2727C60 56.0927 56.0927 60 51.2727 60H8.72727C3.90733 60 0 56.0927 0 51.2727V8.72727Z"
                                        fill="#D3D3D3"
                                      ></path>
                                      <path
                                        d="M0 8.72727C0 3.90733 3.90733 0 8.72727 0H51.2727C56.0927 0 60 3.90733 60 8.72727V51.2727C60 56.0927 56.0927 60 51.2727 60H8.72727C3.90733 60 0 56.0927 0 51.2727V8.72727Z"
                                        fill="#FE8024"
                                      ></path>
                                      <path
                                        d="M15.4662 15.4665C17.3565 13.5761 19.6007 12.0766 22.0705 11.0536C24.5403 10.0305 27.1875 9.50398 29.8608 9.50398C32.5342 9.50399 35.1813 10.0305 37.6512 11.0536C40.121 12.0766 42.3652 13.5761 44.2555 15.4665C46.1459 17.3568 47.6453 19.6009 48.6684 22.0708C49.6914 24.5406 50.218 27.1878 50.218 29.8611C50.218 32.5345 49.6914 35.1816 48.6684 37.6515C47.6453 40.1213 46.1458 42.3655 44.2555 44.2558L37.0582 37.0585C38.0033 36.1133 38.7531 34.9912 39.2646 33.7563C39.7761 32.5214 40.0394 31.1978 40.0394 29.8611C40.0394 28.5245 39.7761 27.2009 39.2646 25.966C38.7531 24.731 38.0033 23.609 37.0582 22.6638C36.113 21.7186 34.9909 20.9689 33.756 20.4574C32.5211 19.9458 31.1975 19.6826 29.8608 19.6826C28.5242 19.6826 27.2006 19.9458 25.9657 20.4574C24.7307 20.9689 23.6087 21.7186 22.6635 22.6638L15.4662 15.4665Z"
                                        fill="white"
                                      ></path>
                                      <path
                                        d="M15.4661 44.2558C11.6484 40.4381 9.50364 35.2601 9.50364 29.8611C9.50363 24.462 11.6484 19.2841 15.4661 15.4664C19.2838 11.6487 24.4617 9.50395 29.8608 9.50395C35.2598 9.50394 40.4377 11.6487 44.2554 15.4664L37.0581 22.6637C35.1493 20.7549 32.5603 19.6825 29.8608 19.6825C27.1613 19.6825 24.5723 20.7549 22.6634 22.6638C20.7546 24.5726 19.6822 27.1616 19.6822 29.8611C19.6822 32.5606 20.7546 35.1496 22.6634 37.0584L15.4661 44.2558Z"
                                        fill="white"
                                      ></path>
                                    </svg>
                                  </div>

                                  <div className="mb-3">$14,000 - $25,000</div>
                                  <p
                                    style={{ color: "#ccc", fontSize: "15px" }}
                                  >
                                    Lorem ipsum dolor sit amet, consectetur
                                    adipiscing elit, sed do eiusmod tempor
                                    incididunt ut labore et dolore magna aliqua.
                                    Ut enim ad minim veniam...
                                  </p>

                                  <div className="row">
                                    <div className="col-lg-6 col-md-6">
                                      <button className="remote_btn">
                                        Remote
                                      </button>
                                    </div>
                                    <div className="col-lg-6 col-md-6 text-end">
                                      <span
                                        className="city_txt"
                                        style={{ fontWeight: "600" }}
                                      >
                                        Bavdhan, Pune
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </SwiperSlide>

                            <SwiperSlide>
                              <div className="card profileCard">
                                <div className="text-left p-4">
                                  <div className="recommendImg mb-2">
                                    <div className="">
                                      <p className="mb-1">Maximoz Team</p>
                                      <h4 className="">Intern UX Designer</h4>
                                    </div>
                                    <svg
                                      className="ms-3"
                                      width="60"
                                      height="60"
                                      viewBox="0 0 60 60"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M0 8.72727C0 3.90733 3.90733 0 8.72727 0H51.2727C56.0927 0 60 3.90733 60 8.72727V51.2727C60 56.0927 56.0927 60 51.2727 60H8.72727C3.90733 60 0 56.0927 0 51.2727V8.72727Z"
                                        fill="#D3D3D3"
                                      ></path>
                                      <path
                                        d="M0 8.72727C0 3.90733 3.90733 0 8.72727 0H51.2727C56.0927 0 60 3.90733 60 8.72727V51.2727C60 56.0927 56.0927 60 51.2727 60H8.72727C3.90733 60 0 56.0927 0 51.2727V8.72727Z"
                                        fill="#FE8024"
                                      ></path>
                                      <path
                                        d="M15.4662 15.4665C17.3565 13.5761 19.6007 12.0766 22.0705 11.0536C24.5403 10.0305 27.1875 9.50398 29.8608 9.50398C32.5342 9.50399 35.1813 10.0305 37.6512 11.0536C40.121 12.0766 42.3652 13.5761 44.2555 15.4665C46.1459 17.3568 47.6453 19.6009 48.6684 22.0708C49.6914 24.5406 50.218 27.1878 50.218 29.8611C50.218 32.5345 49.6914 35.1816 48.6684 37.6515C47.6453 40.1213 46.1458 42.3655 44.2555 44.2558L37.0582 37.0585C38.0033 36.1133 38.7531 34.9912 39.2646 33.7563C39.7761 32.5214 40.0394 31.1978 40.0394 29.8611C40.0394 28.5245 39.7761 27.2009 39.2646 25.966C38.7531 24.731 38.0033 23.609 37.0582 22.6638C36.113 21.7186 34.9909 20.9689 33.756 20.4574C32.5211 19.9458 31.1975 19.6826 29.8608 19.6826C28.5242 19.6826 27.2006 19.9458 25.9657 20.4574C24.7307 20.9689 23.6087 21.7186 22.6635 22.6638L15.4662 15.4665Z"
                                        fill="white"
                                      ></path>
                                      <path
                                        d="M15.4661 44.2558C11.6484 40.4381 9.50364 35.2601 9.50364 29.8611C9.50363 24.462 11.6484 19.2841 15.4661 15.4664C19.2838 11.6487 24.4617 9.50395 29.8608 9.50395C35.2598 9.50394 40.4377 11.6487 44.2554 15.4664L37.0581 22.6637C35.1493 20.7549 32.5603 19.6825 29.8608 19.6825C27.1613 19.6825 24.5723 20.7549 22.6634 22.6638C20.7546 24.5726 19.6822 27.1616 19.6822 29.8611C19.6822 32.5606 20.7546 35.1496 22.6634 37.0584L15.4661 44.2558Z"
                                        fill="white"
                                      ></path>
                                    </svg>
                                  </div>

                                  <div className="mb-3">$14,000 - $25,000</div>
                                  <p
                                    style={{ color: "#ccc", fontSize: "15px" }}
                                  >
                                    Lorem ipsum dolor sit amet, consectetur
                                    adipiscing elit, sed do eiusmod tempor
                                    incididunt ut labore et dolore magna aliqua.
                                    Ut enim ad minim veniam...
                                  </p>

                                  <div className="row">
                                    <div className="col-lg-6 col-md-6">
                                      <button className="remote_btn">
                                        Remote
                                      </button>
                                    </div>
                                    <div className="col-lg-6 col-md-6 text-end">
                                      <span
                                        className="city_txt"
                                        style={{ fontWeight: "600" }}
                                      >
                                        Bavdhan, Pune
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </SwiperSlide>

                            <SwiperSlide>
                              <div className="card profileCard">
                                <div className="text-left p-4">
                                  <div className="recommendImg mb-2">
                                    <div className="">
                                      <p className="mb-1">Maximoz Team</p>
                                      <h4 className="">Intern UX Designer</h4>
                                    </div>
                                    <svg
                                      className="ms-3"
                                      width="60"
                                      height="60"
                                      viewBox="0 0 60 60"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M0 8.72727C0 3.90733 3.90733 0 8.72727 0H51.2727C56.0927 0 60 3.90733 60 8.72727V51.2727C60 56.0927 56.0927 60 51.2727 60H8.72727C3.90733 60 0 56.0927 0 51.2727V8.72727Z"
                                        fill="#D3D3D3"
                                      ></path>
                                      <path
                                        d="M0 8.72727C0 3.90733 3.90733 0 8.72727 0H51.2727C56.0927 0 60 3.90733 60 8.72727V51.2727C60 56.0927 56.0927 60 51.2727 60H8.72727C3.90733 60 0 56.0927 0 51.2727V8.72727Z"
                                        fill="#FE8024"
                                      ></path>
                                      <path
                                        d="M15.4662 15.4665C17.3565 13.5761 19.6007 12.0766 22.0705 11.0536C24.5403 10.0305 27.1875 9.50398 29.8608 9.50398C32.5342 9.50399 35.1813 10.0305 37.6512 11.0536C40.121 12.0766 42.3652 13.5761 44.2555 15.4665C46.1459 17.3568 47.6453 19.6009 48.6684 22.0708C49.6914 24.5406 50.218 27.1878 50.218 29.8611C50.218 32.5345 49.6914 35.1816 48.6684 37.6515C47.6453 40.1213 46.1458 42.3655 44.2555 44.2558L37.0582 37.0585C38.0033 36.1133 38.7531 34.9912 39.2646 33.7563C39.7761 32.5214 40.0394 31.1978 40.0394 29.8611C40.0394 28.5245 39.7761 27.2009 39.2646 25.966C38.7531 24.731 38.0033 23.609 37.0582 22.6638C36.113 21.7186 34.9909 20.9689 33.756 20.4574C32.5211 19.9458 31.1975 19.6826 29.8608 19.6826C28.5242 19.6826 27.2006 19.9458 25.9657 20.4574C24.7307 20.9689 23.6087 21.7186 22.6635 22.6638L15.4662 15.4665Z"
                                        fill="white"
                                      ></path>
                                      <path
                                        d="M15.4661 44.2558C11.6484 40.4381 9.50364 35.2601 9.50364 29.8611C9.50363 24.462 11.6484 19.2841 15.4661 15.4664C19.2838 11.6487 24.4617 9.50395 29.8608 9.50395C35.2598 9.50394 40.4377 11.6487 44.2554 15.4664L37.0581 22.6637C35.1493 20.7549 32.5603 19.6825 29.8608 19.6825C27.1613 19.6825 24.5723 20.7549 22.6634 22.6638C20.7546 24.5726 19.6822 27.1616 19.6822 29.8611C19.6822 32.5606 20.7546 35.1496 22.6634 37.0584L15.4661 44.2558Z"
                                        fill="white"
                                      ></path>
                                    </svg>
                                  </div>

                                  <div className="mb-3">$14,000 - $25,000</div>
                                  <p
                                    style={{ color: "#ccc", fontSize: "15px" }}
                                  >
                                    Lorem ipsum dolor sit amet, consectetur
                                    adipiscing elit, sed do eiusmod tempor
                                    incididunt ut labore et dolore magna aliqua.
                                    Ut enim ad minim veniam...
                                  </p>

                                  <div className="row">
                                    <div className="col-lg-6 col-md-6">
                                      <button className="remote_btn">
                                        Remote
                                      </button>
                                    </div>
                                    <div className="col-lg-6 col-md-6 text-end">
                                      <span
                                        className="city_txt"
                                        style={{ fontWeight: "600" }}
                                      >
                                        Bavdhan, Pune
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </SwiperSlide>
                          </Swiper>
                        </div>
                      </div>
                    </div> */}

                    {/* <div className="row">
                      <div className="col-lg-6 col-md-6">
                        <h4 className="mb-5">Featured Companies</h4>
                      </div>
                      <div className="col-lg-6 col-md-6 text-end">
                        <button class="viewMOre_btn btn btn-primary">
                          View More
                          <svg
                            class="ms-3"
                            width="24"
                            height="14"
                            viewBox="0 0 24 14"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M23.5607 5.93941L18.2461 0.62482C17.9532 0.331898 17.5693 0.185461 17.1854 0.185461C16.8015 0.185461 16.4176 0.331898 16.1247 0.62482C15.539 1.21062 15.539 2.16035 16.1247 2.74615L18.8787 5.50005L1.5 5.50005C0.671578 5.50005 0 6.17163 0 7.00005C0 7.82848 0.671578 8.50005 1.5 8.50005L18.8787 8.50005L16.1247 11.254C15.539 11.8398 15.539 12.7895 16.1247 13.3753C16.7106 13.9611 17.6602 13.9611 18.2461 13.3753L23.5607 8.06069C24.1464 7.47495 24.1464 6.52516 23.5607 5.93941Z"
                              fill="#40189D"
                            ></path>
                          </svg>
                        </button>
                      </div>
                    </div> */}

                    {/* <div className="row">
                      <Swiper
                        autoplay={{ delay: 250, disableOnInteraction: false }}
                        loop={true}
                        breakpoints={{
                          0: {
                            slidesPerView: 2,
                          },
                          360: {
                            slidesPerView: 2,
                          },
                          640: {
                            slidesPerView: 2,
                          },
                          768: {
                            slidesPerView: 2,
                          },
                          1024: {
                            slidesPerView: 4,
                          },
                          1200: {
                            slidesPerView: 4,
                          },
                          1400: {
                            slidesPerView: 4,
                          },
                        }}
                      >
                        {featuredDetails.map((item, index) => (
                          <SwiperSlide key={index}>
                            <div className="card  mb-3 featuredCard">
                              <div className="card-body">
                                <div className="media d-flex">
                                  <svg
                                    className="me-3"
                                    width="80"
                                    height="80"
                                    viewBox="0 0 80 80"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M0 11.6364C0 5.20978 5.20978 0 11.6364 0H68.3636C74.7902 0 80 5.20978 80 11.6364V68.3636C80 74.7902 74.7902 80 68.3636 80H11.6364C5.20978 80 0 74.7902 0 68.3636V11.6364Z"
                                      fill="#D3D3D3"
                                    ></path>
                                    <path
                                      d="M0 11.6364C0 5.20978 5.20978 0 11.6364 0H68.3636C74.7902 0 80 5.20978 80 11.6364V68.3636C80 74.7902 74.7902 80 68.3636 80H11.6364C5.20978 80 0 74.7902 0 68.3636V11.6364Z"
                                      fill="#6EC061"
                                    ></path>
                                    <path
                                      d="M39.8144 66.9577C36.25 66.9577 32.7205 66.2556 29.4273 64.8916C26.1342 63.5275 23.142 61.5282 20.6216 59.0077C18.1011 56.4873 16.1018 53.4951 14.7377 50.2019C13.3737 46.9088 12.6716 43.3793 12.6716 39.8148C12.6716 36.2504 13.3737 32.7208 14.7377 29.4277C16.1018 26.1346 18.1011 23.1424 20.6216 20.6219C23.142 18.1015 26.1342 16.1021 29.4273 14.7381C32.7205 13.374 36.25 12.672 39.8145 12.672L39.8145 26.2434C38.0322 26.2434 36.2675 26.5944 34.6209 27.2765C32.9743 27.9585 31.4782 28.9582 30.218 30.2184C28.9578 31.4786 27.9581 32.9747 27.2761 34.6213C26.5941 36.2678 26.243 38.0326 26.243 39.8148C26.243 41.597 26.5941 43.3618 27.2761 45.0084C27.9581 46.6549 28.9578 48.151 30.218 49.4113C31.4782 50.6715 32.9743 51.6712 34.6209 52.3532C36.2675 53.0352 38.0322 53.3863 39.8144 53.3863L39.8144 66.9577Z"
                                      fill="white"
                                    ></path>
                                    <path
                                      d="M20.6215 59.0077C15.5312 53.9174 12.6715 47.0135 12.6715 39.8148C12.6715 32.6161 15.5312 25.7122 20.6215 20.6219C25.7118 15.5316 32.6157 12.6719 39.8144 12.6719C47.0131 12.6719 53.917 15.5316 59.0073 20.6219L49.4108 30.2183C46.8657 27.6732 43.4138 26.2434 39.8144 26.2434C36.215 26.2434 32.7631 27.6732 30.2179 30.2183C27.6728 32.7635 26.243 36.2154 26.243 39.8148C26.243 43.4141 27.6728 46.8661 30.2179 49.4112L20.6215 59.0077Z"
                                      fill="white"
                                    ></path>
                                  </svg>
                                  <div className="media-body">
                                    <h6 className=" text-black Featured_companyName ">
                                      {item.companyname}
                                    </h6>
                                    <span className="Featured_vacancy">
                                      {item.vacancy}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </SwiperSlide>
                        ))}
                      </Swiper>
                    </div> */}
                  </div>
                </div>

                {/* overview tab end */}
                {/* project tab start */}
                {/* <div
                  className="tab-pane fade "
                  id="Project-tab-pane"
                  role="tabpanel"
                  aria-labelledby="Project-tab"
                  tabindex="0"
                >
                  <div className="AdvanceDashboardTabsContent">
                    <div className="row ">
                      <div className="col-xl-3 col-lg-6 col-md-6 mb-3">
                        <div className="box bg-white">
                          <div className="text-holder">
                            <h5 className="text">Total Projects</h5>
                            <div className="number-holder">
                              <p className="number">
                                <span id="">1</span>
                              </p>
                            </div>
                          </div>
                          <div className="users">
                            <FontAwesomeIcon icon="fa-solid fa-layer-group" />
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-3 col-lg-6 col-md-6 mb-3">
                        <div className="box bg-white">
                          <div className="text-holder">
                            <h5 className="text">Overdue Projects</h5>
                            <div className="number-holder">
                              <p className="number">
                                <span id="">0</span>
                              </p>
                            </div>
                          </div>
                          <div className="users">
                            <FontAwesomeIcon icon="fa-solid fa-layer-group" />
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-3 col-lg-6 col-md-6 mb-3">
                        <div className="box bg-white">
                          <div className="text-holder">
                            <h5 className="text">Hours Logged</h5>
                            <div className="number-holder">
                              <p className="number">
                                <span id="">2m</span>
                              </p>
                            </div>
                          </div>
                          <div className="users">
                            <FontAwesomeIcon icon="fa-solid fa-clock" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12 col-lg-6 mt-3">
                        <div className="card">
                          <div className="card-title">
                            <h4>Status Wise Projects</h4>
                          </div>
                          <div className="card-body">
                            <div className="card-body-data">
                              <FontAwesomeIcon icon="fa-solid fa-chart-pie" />
                              <div className="no-data">
                                <p>- Not enough data -</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-12 col-lg-6 mt-3">
                        {" "}
                        <div className="card">
                          <div className="card-title">
                            <h4>Pending Milestone</h4>
                          </div>
                          <div className="card-body">
                            <table
                              className="table border-0 pb-3 admin-dash-table table-hover"
                              id="example"
                            >
                              <thead className="heading">
                                <tr className="to-do">
                                  <th className="">#</th>
                                  <th>Milestone Title</th>
                                  <th>Milestone Cost</th>
                                  <th>Project</th>
                                </tr>
                              </thead>
                              <tbody>
                              
                                <div className="card-body-data">
                                  <FontAwesomeIcon icon="fa-solid fa-list" />
                                  <div className="no-data">
                                    <p>- Not enough data -</p>
                                  </div>
                                </div>
                               
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
                {/* project tab end */}

                {/* client tab start */}
                {/* <div
                  className="tab-pane fade "
                  id="Client-tab-pane"
                  role="tabpanel"
                  aria-labelledby="Client-tab"
                  tabindex="0"
                >
                  <div className="AdvanceDashboardTabsContent">
                    <div className="row ">
                      <div className="col-xl-4 col-lg-6 col-md-6 mb-3">
                        <div className="box bg-white">
                          <div className="text-holder">
                            <h5 className="text">Total Clients</h5>
                            <div className="number-holder">
                              <p className="number">
                                <span id="">0</span>
                              </p>
                            </div>
                          </div>
                          <div className="users">
                            <FontAwesomeIcon icon="fa-solid fa-users" />
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-4 col-lg-6 col-md-6 mb-3">
                        <div className="box bg-white">
                          <div className="text-holder">
                            <h5 className="text">Total Leads</h5>
                            <div className="number-holder">
                              <p className="number">
                                <span id="">2</span>
                              </p>
                            </div>
                          </div>
                          <div className="users">
                            <FontAwesomeIcon icon="fa-solid fa-users" />
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-4 col-lg-6 col-md-6 mb-3">
                        <div className="box bg-white">
                          <div className="text-holder">
                            <h5 className="text">Lead Conversions</h5>
                            <div className="number-holder">
                              <p className="number">
                                <span id="">0</span>
                              </p>
                            </div>
                          </div>
                          <div className="users">
                            <FontAwesomeIcon icon="fa-solid fa-users" />
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-4 col-lg-6 col-md-6 mb-3">
                        <div className="box bg-white">
                          <div className="text-holder">
                            <h5 className="text">Contracts Generated</h5>
                            <div className="number-holder">
                              <p className="number">
                                <span id="">0</span>
                              </p>
                            </div>
                          </div>
                          <div className="users">
                            <FontAwesomeIcon icon="fa-solid fa-file-contract" />
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-4 col-lg-6 col-md-6 mb-3">
                        <div className="box bg-white">
                          <div className="text-holder">
                            <h5 className="text">Contracts Signed</h5>
                            <div className="number-holder">
                              <p className="number">
                                <span id="">0</span>
                              </p>
                            </div>
                          </div>
                          <div className="users">
                            <FontAwesomeIcon icon="fa-solid fa-file-signature" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12 col-lg-6 mt-3">
                        <div className="card">
                          <div className="card-title">
                            <h4>
                              Client Wise Earnings{" "}
                              <FontAwesomeIcon icon="fa-solid fa-circle-question" />
                            </h4>
                          </div>
                          <div className="card-body">
                            <div className="card-body-data">
                              <FontAwesomeIcon icon="fa-solid fa-chart-simple" />
                              <div className="no-data">
                                <p>- Not enough data -</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-12 col-lg-6 mt-3">
                        {" "}
                        <div className="card">
                          <div className="card-title">
                            <h4>Client Wise Timelogs</h4>
                          </div>
                          <div className="card-body">
                            <div className="card-body-data">
                              <FontAwesomeIcon icon="fa-solid fa-chart-simple" />
                              <div className="no-data">
                                <p>- Not enough data -</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-12 col-lg-6 mt-3">
                        <div className="card">
                          <div className="card-title">
                            <h4>Leads Count by Status</h4>
                          </div>
                          <div className="card-body">
                            <div className="card-body-data">
                              <FontAwesomeIcon icon="fa-solid fa-chart-pie" />
                              <div className="no-data">
                                <p>- Not enough data -</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-12 col-lg-6 mt-3">
                        {" "}
                        <div className="card">
                          <div className="card-title">
                            <h4>Leads Count by Source</h4>
                          </div>
                          <div className="card-body">
                            <div className="card-body-data">
                              <FontAwesomeIcon icon="fa-solid fa-chart-pie" />
                              <div className="no-data">
                                <p>- Not enough data -</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-12 col-lg-6 mt-3">
                        <div className="card">
                          <div className="card-title">
                            <h4>Latest Clients</h4>
                          </div>
                          <div className="card-body">
                            <table
                              className="table border-0 pb-3 admin-dash-table table-hover"
                              id="example"
                            >
                              <thead className="heading">
                                <tr className="to-do">
                                  <th className="">#</th>
                                  <th>Milestone Title</th>
                                  <th>Milestone Cost</th>
                                  <th>Project</th>
                                </tr>
                              </thead>
                              <tbody>
                               
                                <div className="card-body-data">
                                  <FontAwesomeIcon icon="fa-solid fa-list" />
                                  <div className="no-data">
                                    <p>- Not enough data -</p>
                                  </div>
                                </div>
                               
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-12 col-lg-6 mt-3">
                        <div className="card">
                          <div className="card-title">
                            <h4>Recent Login Activities</h4>
                          </div>
                          <div className="card-body">
                            <table
                              className="table border-0 pb-3 admin-dash-table table-hover"
                              id="example"
                            >
                              <thead className="heading">
                                <tr className="to-do">
                                  <th className="">#</th>
                                  <th>Milestone Title</th>
                                  <th>Milestone Cost</th>
                                  <th>Project</th>
                                </tr>
                              </thead>
                              <tbody>
                              
                                <div className="card-body-data">
                                  <FontAwesomeIcon icon="fa-solid fa-list" />
                                  <div className="no-data">
                                    <p>- Not enough data -</p>
                                  </div>
                                </div>
                              
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
                {/* client tab end */}

                {/* HR tab start */}
                {/* <div
                  className="tab-pane fade "
                  id="HR-tab-pane"
                  role="tabpanel"
                  aria-labelledby="HR-tab"
                  tabindex="0"
                >
                  <div className="AdvanceDashboardTabsContent">
                    <div className="row ">
                      <div className="col-xl-3 col-lg-6 col-md-6 mb-3">
                        <div className="box bg-white">
                          <div className="text-holder">
                            <h5 className="text">
                              Leaves Approved
                              <FontAwesomeIcon icon="fa-solid fa-circle-question" />
                            </h5>
                            <div className="number-holder">
                              <p className="number">
                                <span id="">0</span>
                              </p>
                            </div>
                          </div>
                          <div className="users">
                            <FontAwesomeIcon icon="fa-solid fa-plane-departure" />
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-4 col-lg-6 col-md-6 mb-3">
                        <div className="box bg-white">
                          <div className="text-holder">
                            <h5 className="text">Employees</h5>
                            <div className="number-holder">
                              <p className="number">
                                <div>
                                  <span id="">14</span>
                                </div>
                                <div>
                                  <p>
                                    Total Employees{" "}
                                    <FontAwesomeIcon icon="fa-solid fa-circle-question" />
                                  </p>
                                </div>
                              </p>
                              <p className="number">
                                <div>
                                  <span id="">0</span>
                                </div>
                                <div>
                                  <p>
                                    Total Employees{" "}
                                    <FontAwesomeIcon icon="fa-solid fa-circle-question" />
                                  </p>
                                </div>
                              </p>
                            </div>
                          </div>
                          <div className="users">
                            <FontAwesomeIcon icon="fa-solid fa-user" />
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-3 col-lg-6 col-md-6 mb-3">
                        <div className="box bg-white">
                          <div className="text-holder">
                            <h5 className="text">
                              Employees Exist{" "}
                              <FontAwesomeIcon icon="fa-solid fa-circle-question" />
                            </h5>
                            <div className="number-holder">
                              <p className="number">
                                <span id="">0</span>
                              </p>
                            </div>
                          </div>
                          <div className="users">
                            <FontAwesomeIcon icon="fa-solid fa-right-from-bracket" />
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-3 col-lg-6 col-md-6 mb-3">
                        <div className="box bg-white">
                          <div className="text-holder">
                            <h5 className="text">Total attendance</h5>
                            <div className="number-holder">
                              <p className="number">
                                <span id="">1/14</span>
                              </p>
                            </div>
                          </div>
                          <div className="users">
                            <FontAwesomeIcon icon="fa-solid fa-calendar-check" />
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-3 col-lg-6 col-md-6 mb-3">
                        <div className="box bg-white">
                          <div className="text-holder">
                            <h5 className="text">Average Attendance</h5>
                            <div className="number-holder">
                              <p className="number">
                                <span id="">7.14%</span>
                              </p>
                            </div>
                          </div>
                          <div className="users">
                            <FontAwesomeIcon icon="fa-solid fa-fingerprint" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12 col-lg-6 mt-3">
                        <div className="card">
                          <div className="card-title">
                            <h4>
                              Department Wise Employee{" "}
                              <FontAwesomeIcon icon="fa-solid fa-circle-question" />
                            </h4>
                          </div>
                          <div className="card-body">
                          
                            <Piechart />
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-12 col-lg-6 mt-3">
                        {" "}
                        <div className="card">
                          <div className="card-title">
                            <h4>
                              Designation Wise Employee{" "}
                              <FontAwesomeIcon icon="fa-solid fa-circle-question" />
                            </h4>
                          </div>
                          <div className="card-body">
                          
                            <Piechart />
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-12 col-lg-6 mt-3">
                        <div className="card">
                          <div className="card-title">
                            <h4>
                              Gender Wise Employee{" "}
                              <FontAwesomeIcon icon="fa-solid fa-circle-question" />
                            </h4>
                          </div>
                          <div className="card-body">
                           
                            <Piechart />
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-12 col-lg-6 mt-3">
                        <div className="card">
                          <div className="card-title">
                            <h4>
                              Role Wise Employee
                              <FontAwesomeIcon icon="fa-solid fa-circle-question" />
                            </h4>
                          </div>
                          <div className="card-body">
                            
                            <Piechart />
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-12 col-lg-12 mt-3">
                        <div className="card">
                          <div className="card-title">
                            <h4>
                              Headcount
                              <FontAwesomeIcon icon="fa-solid fa-circle-question" />
                            </h4>
                          </div>
                          <div className="card-body">
                           
                            <Barchart />
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-12 col-lg-12 mt-3">
                        <div className="card">
                          <div className="card-title">
                            <h4>
                              Joining Vs Attrition
                              <FontAwesomeIcon icon="fa-solid fa-circle-question" />
                            </h4>
                          </div>
                          <div className="card-body">
                           
                            <LineChart />
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-12 col-lg-6 mt-3">
                        <div className="card">
                          <div className="card-title">
                            <h4>
                              Leaves Taken
                              <FontAwesomeIcon icon="fa-solid fa-circle-question" />
                            </h4>
                          </div>
                          <div className="card-body">
                            <div className="card-body-data">
                              <FontAwesomeIcon icon="fa-solid fa-chart-simple" />
                              <div className="no-data">
                                <p>- Not enough data -</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-12 col-lg-6 mt-3">
                        {" "}
                        <div className="card">
                          <div className="card-title">
                            <h4>Birthday</h4>
                          </div>
                          <div className="card-body">
                            <div className="card-body-data">
                              <FontAwesomeIcon icon="fa-solid fa-cake-candles" />
                              <div className="no-data">
                                <p>- Not record found. -</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-12 col-lg-6 mt-3">
                        <div className="card">
                          <div className="card-title">
                            <h4>Late Attendance</h4>
                          </div>
                          <div className="card-body">
                            <table
                              className="table border-0 pb-3 admin-dash-table table-hover"
                              id="example"
                            >
                              <thead className="heading">
                                <tr className="to-do">
                                  <th className="">#</th>
                                  <th>Milestone Title</th>
                                  <th>Milestone Cost</th>
                                  <th>Project</th>
                                </tr>
                              </thead>
                              <tbody>
                               
                                <div className="card-body-data">
                                  <FontAwesomeIcon icon="fa-solid fa-list" />
                                  <div className="no-data">
                                    <p>- Not enough data -</p>
                                  </div>
                                </div>
                               
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
                {/* HR tab end */}

                {/* ticket tb start */}
                {/* <div
                  className="tab-pane fade "
                  id="Ticket-tab-pane"
                  role="tabpanel"
                  aria-labelledby="Ticket-tab"
                  tabindex="0"
                >
                  <div className="AdvanceDashboardTabsContent">
                    <div className="row ">
                      <div className="col-lg-6 col-md-6 mb-3">
                        <div className="box bg-white">
                          <div className="text-holder">
                            <h5 className="text">Tickets</h5>
                            <div className="number-holder">
                              <p className="number">
                                <div>
                                  <span id="">0</span>
                                </div>
                                <div>
                                  <p>
                                    Unresolved Tickets
                                    <FontAwesomeIcon icon="fa-solid fa-circle-question" />{" "}
                                  </p>
                                </div>
                              </p>
                              <p className="number">
                                <div>
                                  <span id="">0</span>
                                </div>
                                <div>
                                  <p>
                                    Resolved Tickets
                                    <FontAwesomeIcon icon="fa-solid fa-circle-question" />{" "}
                                  </p>
                                </div>
                              </p>
                            </div>
                          </div>
                          <div className="users">
                            <FontAwesomeIcon icon="fa-solid fa-ticket" />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 mb-3">
                        <div className="box bg-white">
                          <div className="text-holder">
                            <h5 className="text">
                              Total Unsigned Ticket
                              <FontAwesomeIcon icon="fa-solid fa-circle-question" />
                            </h5>
                            <div className="number-holder">
                              <p className="number">
                                <span id="">0</span>
                              </p>
                            </div>
                          </div>
                          <div className="users">
                            <FontAwesomeIcon icon="fa-solid fa-ticket" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12 col-lg-6 mt-3">
                        <div className="card">
                          <div className="card-title">
                            <h4>Type Wise ticket</h4>
                          </div>
                          <div className="card-body">
                            <div className="card-body-data">
                              <FontAwesomeIcon icon="fa-solid fa-chart-pie" />
                              <div className="no-data">
                                <p>- Not enough data -</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-12 col-lg-6 mt-3">
                        <div className="card">
                          <div className="card-title">
                            <h4>Status Wise Ticket</h4>
                          </div>
                          <div className="card-body">
                            <div className="card-body-data">
                              <FontAwesomeIcon icon="fa-solid fa-chart-pie" />
                              <div className="no-data">
                                <p>- Not enough data -</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-12 col-lg-6 mt-3">
                        <div className="card">
                          <div className="card-title">
                            <h4>Channer wise Ticket</h4>
                          </div>
                          <div className="card-body">
                            <div className="card-body-data">
                              <FontAwesomeIcon icon="fa-solid fa-chart-pie" />
                              <div className="no-data">
                                <p>- Not enough data -</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-12 col-lg-6 mt-3">
                        <div className="card">
                          <div className="card-title">
                            <h4>Open Tickets</h4>
                          </div>
                          <div className="card-body">
                            <div className="card-body-data">
                              <FontAwesomeIcon icon="fa-solid fa-ticket" />
                              <div className="no-data">
                                <p>- Not record found -</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
                {/* ticket tb end */}

                {/* finance tab start */}
                {/* <div
                  className="tab-pane fade "
                  id="Finance-tab-pane"
                  role="tabpanel"
                  aria-labelledby="Finance-tab"
                  tabindex="0"
                >
                  <div className="AdvanceDashboardTabsContent">
                    <div className="row ">
                      <div className="col-xl-4 col-lg-6 col-md-6">
                        <div className="box bg-white">
                          <div className="text-holder">
                            <h5 className="text">Invoices</h5>
                            <div className="number-holder">
                              <p className="number">
                                <div>
                                  <span id="">0</span>
                                </div>
                                <div>
                                  <p>Paid invoices</p>
                                </div>
                              </p>
                              <p className="number">
                                <div>
                                  <span id="">0</span>
                                </div>
                                <div>
                                  <p>Deu invoices</p>
                                </div>
                              </p>
                            </div>
                          </div>
                          <div className="users">
                            <FontAwesomeIcon icon="fa-solid fa-file-contract" />
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-4 col-lg-6 col-md-6">
                        <div className="box bg-white">
                          <div className="text-holder">
                            <h5 className="text">Finance</h5>
                            <div className="number-holder">
                              <p className="number">
                                <div>
                                  <span id="">$0.00</span>
                                </div>
                                <div>
                                  <p>Total Expenses</p>
                                </div>
                              </p>
                              <p className="number">
                                <div>
                                  <span id="">$0.00</span>
                                </div>
                                <div>
                                  <p>Total Earnings</p>
                                </div>
                              </p>
                            </div>
                          </div>
                          <div className="users">
                            <FontAwesomeIcon icon="fa-solid fa-coins" />
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-4 col-lg-6 col-md-6">
                        <div className="box bg-white">
                          <div className="text-holder">
                            <h5 className="text">Total Pending Amount</h5>
                            <div className="number-holder">
                              <p className="number">
                                <span id="">$0.00</span>
                              </p>
                            </div>
                          </div>
                          <div className="users">
                            <FontAwesomeIcon icon="fa-solid fa-coins" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12 col-lg-6 mt-3">
                        <div className="card">
                          <div className="card-title">
                            <h4>
                              <FontAwesomeIcon icon="fa-solid fa-circle-question" />
                              Invoice Overview{" "}
                            </h4>
                          </div>
                          <div className="card-body">
                            <div className="card-body-data">
                              <FontAwesomeIcon icon="fa-solid fa-chart-pie" />
                              <div className="no-data">
                                <p>- Not enough data -</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-12 col-lg-6 mt-3">
                        <div className="card">
                          <div className="card-title">
                            <h4>
                              <FontAwesomeIcon icon="fa-solid fa-circle-question" />
                              Estimate Overview
                            </h4>
                          </div>
                          <div className="card-body">
                            <div className="card-body-data">
                              <FontAwesomeIcon icon="fa-solid fa-chart-pie" />
                              <div className="no-data">
                                <p>- Not enough data -</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-12 col-lg-6 mt-3">
                        <div className="card">
                          <div className="card-title">
                            <h4>
                              <FontAwesomeIcon icon="fa-solid fa-circle-question" />
                              proposal Overview{" "}
                            </h4>
                          </div>
                          <div className="card-body">
                            <div className="card-body-data">
                              <FontAwesomeIcon icon="fa-solid fa-chart-pie" />
                              <div className="no-data">
                                <p>- Not enough data -</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-12 col-lg-6 mt-3">
                        {" "}
                        <div className="card">
                          <div className="card-title">
                            <h4>
                              Client Wise Earning{" "}
                              <FontAwesomeIcon icon="fa-solid fa-circle-question" />
                            </h4>
                          </div>
                          <div className="card-body">
                            <div className="card-body-data">
                              <FontAwesomeIcon icon="fa-solid fa-chart-simple" />
                              <div className="no-data">
                                <p>- Not enough data -</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-12 col-lg-6 mt-3">
                        <div className="card">
                          <div className="card-title">
                            <h4>
                              Earning By Project
                              <FontAwesomeIcon icon="fa-solid fa-circle-question" />
                            </h4>
                          </div>
                          <div className="card-body">
                            <div className="card-body-data">
                              <FontAwesomeIcon icon="fa-solid fa-chart-simple" />
                              <div className="no-data">
                                <p>- Not enough data -</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
                {/* finance tab end */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default AdvanceDashboard;
