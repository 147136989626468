import React, { useContext } from "react";
import { useState, useEffect } from "react";

import "../../../../Tabels/Tabels.css";
import { Context } from "../../../../../utils/context";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";
import ModalSave from "../../../../common/ModelSave";
import { CancelButton, SaveButton } from "../../../../common/Button";
import { Row, Col, Form, Modal } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import classNames from "classnames";
import { useParams } from "react-router-dom";


library.add(fas);

const AddOffCanvance = (props) => {
  const { postData, getData, Select2Data, getDimension } = useContext(Context);
  const [course, setCourse] = useState([]);
  const [coursetype, setCourseType] = useState([]);
  const [courseId, setCourseId] = useState([]);
  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });

  const { Id } = useParams();
  // console.log("ID:", id);
  console.log(courseId);
  const GetAllCourse = async (id) => {
    const response = await getData(`/allcourse/${id}`);
    // const response = await getData(`/allstate/${id}`);
    if (response.success) {
      setCourse(await Select2Data(response?.data, "course_id"));
    }
  };

  const GetAllCourseType = async () => {
    const response = await getData("/allcoursetype");

    if (response.success) {
      setCourseType(await Select2Data(response?.data, "course_type_id"));
    }
  };

  useEffect(() => {
    GetAllCourseType();
  }, []);

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    watch,
    getValues,
    setValue
  } = useForm();

  const imageFile = watch("image");
  console.log(errors);
  const onSubmit = async (data) => {
    try {
      const fromData = new FormData();
      fromData.append("name", data?.name);
      fromData.append("course_id", data?.course_id?.value);
      fromData.append("course_type_id", data?.course_type_id?.value);

      fromData.append("image", data?.image[0]);
      console.log(data, "data");
      const response = await postData(
        `/masters/ed-tech/course/common/banner`,
        fromData
      );
      console.log(response, "response");
      if (response?.success) {
        await setShowModal({ code: response.code, message: response.message });
      } else {
        await setShowModal({ code: response.code, message: response.message });
      }
      setTimeout(() => {
        setShowModal(0);
        props.handleClose();
      }, 1000);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Modal
        onHide={props.handleClose}
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Add Banner
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            onSubmit={() => handleSubmit(onSubmit)}
            role="form"
            className="countryclass"
          >
            <Row>
              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="row justify-content-center mb-2">
                    <Form.Label>Course Type</Form.Label>

                    <Controller
                      name="course_type_id" // name of the field
                      {...register("course_type_id", {
                        required: "Course Type is required",
                      })}
                      control={control}
                      render={({ field }) => (
                        <Select
                          styles={{
                            control: (baseStyles) => ({
                              ...baseStyles,
                              borderColor: errors.course_type_id
                                ? "red"
                                : baseStyles,
                            }),
                          }}
                          {...field}
                          options={coursetype}
                          onChange={(selectedOption) => {
                            field.onChange(selectedOption.value); // Update Controller's value
                            GetAllCourse(selectedOption.value);
                            setValue("course_type_id", selectedOption);
                          }}

                        />
                      )}
                    />

                    {errors.course_type_id && (
                      <span className="text-danger">
                        {errors.course_type_id.message}
                      </span>
                    )}
                  </Row>
                </div>
              </Col>

              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="row justify-content-center mb-2">
                    <Form.Label>Course</Form.Label>

                    <Controller
                      name="course_id" // name of the field
                      {...register("course_id", {
                        required: "Course  is required",
                      })}
                      control={control}
                      render={({ field }) => (
                        <Select
                          styles={{
                            control: (baseStyles) => ({
                              ...baseStyles,
                              borderColor: errors.course_id
                                ? "red"
                                : baseStyles,
                            }),
                          }}
                          {...field}
                          options={course}
                        />
                      )}
                    />

                    {errors.course_id && (
                      <span className="text-danger">
                        {errors.course_id.message}
                      </span>
                    )}
                  </Row>
                </div>
              </Col>
              {/* Title */}
              <Col lg={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label > Title</Form.Label>

                    <Form.Group>
                      <Form.Control
                        className={classNames("", {
                          "is-invalid": errors?.name,
                        })}
                        type="text"
                        {...register("name", {
                          required: "Title is required",
                        })}
                        placeholder="Title"
                      />
                    </Form.Group>
                    {errors.name && (
                      <span className="text-danger">
                        {errors.name.message}
                      </span>
                    )}

                  </Row>
                </div>
              </Col>


              {/* Image Upload */}
              <Col lg={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label > Image </Form.Label>

                    <Form.Group>
                      <Form.Control
                        className={classNames("", {
                          "is-invalid": errors?.image,
                        })}
                        type="file"
                        {...register("image", {
                          required: "Image is required",
                          validate: async (value) => {
                            if (typeof value !== "string") {
                              const fileTypes = ["jpg", "png", "jpeg"];
                              const fileType = value[0].name?.split(".")[1];

                              if (!fileTypes.includes(fileType)) {
                                return `please upload a valid file format. (${fileTypes})`;
                              }

                              const sizes = await getDimension(value[0]);
                              if (
                                sizes.width !== 1296 &&
                                sizes.height !== 396
                              ) {
                                return "Image width and height must be 1296px and 396px";
                              }

                              const fileSize = Math.round(
                                value[0].size / 1024
                              );
                              if (fileSize > 500) {
                                return "file size must be lower than 500kb";
                              }
                            }
                          },
                        })}
                        accept=".jpg, .jpeg, .png"
                      />
                    </Form.Group>
                    {errors.image && (
                      <span className="text-danger">
                        {errors.image.message}
                      </span>
                    )}

                  </Row>
                </div>
              </Col>

              {/* Image Preview */}
              <Col lg={6}>
                <div className="main-form-section mt-3" style={{ marginLeft: '50px' }}>
                  <Row className="justify-content-center">
                    <Form.Label>Image Preview</Form.Label>

                    {imageFile && imageFile?.length > 0 && (
                      <div className="image-preview-container">
                        <img
                          src={URL.createObjectURL(imageFile[0])}
                          alt="Preview"
                          className="image-preview"
                          style={{ width: "648px", height: "198px" }}
                        />
                      </div>
                    )}

                  </Row>
                </div>
              </Col>
            </Row>

            {/* Buttons */}
            <Row className="justify-content-center mt-5 pb-3">
              <Col sm={6} className="d-flex justify-content-center">
                <CancelButton name={"Cancel"} handleClose={props.handleClose} />
                <SaveButton
                  name={"Save"}
                  handleSubmit={handleSubmit(onSubmit)}
                />
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>

      <ModalSave
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      />
    </>
  );
};

export default AddOffCanvance;
