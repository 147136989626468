import React, { useContext } from "react";
import { useState, useEffect, useRef } from "react";
import "../../../../Tabels/Tabels.css";
import { Link } from "react-router-dom";
import { Context } from "../../../../../utils/context";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import Offcanvas from "react-bootstrap/Offcanvas";
// *******************toster****************************
import Card from "react-bootstrap/Card";
import ModalSave from "../../../../common/ModelSave";
import Validation from "../../../../common/FormValidation";
import { CancelButton, SaveButton } from "../../../../common/Button";
import Modal from "react-bootstrap/Modal";
import { Container, Row, Col, Button, Form, InputGroup } from "react-bootstrap";
import JoditEditor from "jodit-react";
import { useForm } from "react-hook-form";
import classNames from "classnames";
library.add(fas);

const EditOffCanvance = (props) => {
  const { postData, getData, IMG_URL, getDimension } = useContext(Context);

  const id = props.show;
  // const [data, setData] = useState();

  // const editor = useRef(null);
  // const [formData, setFormData] = useState({
  //   name: "",
  //   image: "",
  // });
  // const [imagePreview, setImagePreview] = useState(null);
  // const [content, setContent] = useState("");
  // const [errors, setErrors] = useState();
  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });
  

  const GetEditData = async () => {
    const response = await getData(
      `/masters/ed-tech/university/dy-patil/alumni-testimonal/${id}`
    );
    console.log(response?.data);
    reset(response?.data);
  };

  useEffect(() => {
    GetEditData();
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    reset,
    watch,
  } = useForm();
  console.log(getValues());
  const videoFile = watch("video");

  const onSubmit = async (data) => {
    try {
      const fromData = new FormData();
      fromData.append("name", data?.name);

      if (typeof data?.video !== "string") {
        fromData.append("video", data?.video[0]);
      }
      const response = await postData(
        `/masters/ed-tech/university/dy-patil/alumni-testimonal/${id}`,
        fromData
      );
      console.log(response);
      if (response?.success) {
        await setShowModal({ code: response.code, message: response.message });
      } else {
        await setShowModal({ code: response.code, message: response.message });
      }
      setTimeout(() => {
        setShowModal(0);
        props.handleClose();
      }, 1000);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Modal
        onHide={props.handleClose}
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Edit Alumni Testimonials
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            onSubmit={() => handleSubmit(onSubmit)}
            role="form"
            className="countryclass"
          >
            <Row>
              {/* Title */}
              <Col lg={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label column sm={3} className="text-center">
                      Title
                    </Form.Label>
                    <Col sm={9}>
                      <Form.Group>
                        <Form.Control
                          className={classNames("", {
                            "is-invalid": errors?.name,
                          })}
                          type="text"
                          {...register("name", {
                            required: "Title is required",
                          })}
                          placeholder="Title"
                        />
                      </Form.Group>
                      {errors.title && (
                        <span className="text-danger">
                          {errors.title.message}
                        </span>
                      )}
                    </Col>
                  </Row>
                </div>
              </Col>

              {/* Image Upload */}
              <Col lg={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label column sm={3} className="text-center">
                      Video
                    </Form.Label>
                    <Col sm={9}>
                      <Form.Group>
                        <Form.Control
                          className={classNames("", {
                            "is-invalid": errors?.video,
                          })}
                          type="file"
                          {...register("video", {
                            // required: "image1 is required",
                            // validate: async (value) => {
                            //   const fileTypes = ["jpg", "png", "jpeg"];
                            //   const fileType = value[0].name.split(".")[1];
                            //   if (!fileTypes.includes(fileType)) {
                            //     return `please upload a valid file format. (${fileTypes})`;
                            //   }
                            //   const sizes = await getDimension(value[0]);
                            //   if (sizes.width !== 285 && sizes.height !== 298) {
                            //     return "Image width and height must be 285px and 298px";
                            //   }
                            //   const fileSize = Math.round(value[0].size / 1024);
                            //   if (fileSize > 500) {
                            //     return "file size must be lower than 500kb";
                            //   }
                            // },
                          })}
                          accept="video/mp4, video/x-m4v, video/*"
                        />
                      </Form.Group>
                      {errors.video && (
                        <span className="text-danger">
                          {errors.video.message}
                        </span>
                      )}
                    </Col>
                  </Row>
                </div>
              </Col>

              {/* Image Preview */}
              <Col lg={6}>
                <div className="main-form-section mt-3">
                  <Form.Label className="text-center">Video Preview</Form.Label>
                  <Row className="justify-content-center">
                    <Col sm={9}>
                      {typeof getValues("video") == "string" ? (
                        <div className="image-preview-container">
                          <video
                            src={IMG_URL + getValues("video")}
                            alt="Preview"
                            className="image-preview"
                            style={{ width: "150px", height: "130px" }}
                            muted
                            controls
                          />
                        </div>
                      ) : (
                        videoFile &&
                        videoFile?.length > 0 && (
                          <div className="image-preview-container">
                            <video
                              // src={URL.createObjectURL(getValues("image")[0])}
                              src={URL?.createObjectURL(videoFile[0])}
                              alt="Preview"
                              className="image-preview"
                              style={{ width: "150px", height: "130px" }}
                              controls
                              muted
                            />
                          </div>
                        )
                      )}
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>

            {/* Buttons */}
            <Row className="justify-content-center mt-5 pb-3">
              <Col sm={6} className="d-flex justify-content-center">
                <CancelButton name={"Cancel"} handleClose={props.handleClose} />
                <SaveButton
                  name={"Save"}
                  handleSubmit={handleSubmit(onSubmit)}
                />
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>

      <ModalSave
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      />
    </>
  );
};

export default EditOffCanvance;
