import React, { useContext } from "react";
import { useState, useEffect, useRef } from "react";
import "../../../../Tabels/Tabels.css";
import { Link } from "react-router-dom";
import { Context } from "../../../../../utils/context";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import Offcanvas from "react-bootstrap/Offcanvas";
// *******************toster****************************
import Card from "react-bootstrap/Card";
import ModalSave from "../../../../common/ModelSave";
import Validation from "../../../../common/FormValidation";
import { CancelButton, SaveButton } from "../../../../common/Button";
import Modal from "react-bootstrap/Modal";
import { Container, Row, Col, Button, Form, InputGroup } from "react-bootstrap";
import JoditEditor from "jodit-react";
import { useForm, Controller } from "react-hook-form";
import classNames from "classnames";
import Select from "react-select";
library.add(fas);

const EditOffCanvance = (props) => {
  const { postData, getData, IMG_URL, getDimension, Select2Data } =
    useContext(Context);

  const id = props.show;
  const [courseType, setCourseType] = useState([]);
  const [course, setCourse] = useState([]);
  // const [data, setData] = useState();

  // const editor = useRef(null);
  // const [formData, setFormData] = useState({
  //   name: "",
  //   image: "",
  // });
  // const [imagePreview, setImagePreview] = useState(null);
  // const [content, setContent] = useState("");
  // const [errors, setErrors] = useState();
  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });

  const GetEditData = async () => {
    const response = await getData(`/masters/ed-tech/course/common/what/${id}`);
    console.log(response.data);
    reset(response?.data);
  };

  const GetAllCourseType = async () => {
    const response = await getData("/allcoursetype");

    if (response.success) {
      setCourseType(await Select2Data(response?.data, "course_type_id"));
    }
  };

  const GetAllCourse = async (id) => {
    const response = await getData(`/allcourse/${id}`);
    // const response = await getData(`/allstate/${id}`);
    if (response.success) {
      setCourse(await Select2Data(response?.data, "course_id"));
    }
  };

  useEffect(() => {
    GetEditData();
    GetAllCourseType();
    GetAllCourse();
  }, []);

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    reset,
    watch,
    setValue,
    trigger
  } = useForm();
  console.log(getValues());
  const videoFile = watch("video");

  const onSubmit = async (data) => {
    try {
      const fromData = new FormData();
      fromData.append("name", data?.name);
      fromData.append("description", data?.description);
      fromData.append("course_id", data?.course_id?.value);
      fromData.append("course_type_id", data?.course_type_id?.value);
      fromData.append("video", data?.video[0]);
      console.log(data);
      const response = await postData(
        `/masters/ed-tech/course/common/what/${id}`,
        fromData
      );
      console.log(response);
      if (response?.success) {
        await setShowModal({ code: response.code, message: response.message });
      } else {
        await setShowModal({ code: response.code, message: response.message });
      }
      setTimeout(() => {
        setShowModal(0);
        props.handleClose();
      }, 1000);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Modal
        onHide={props.handleClose}
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Edit What is ?
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            onSubmit={() => handleSubmit(onSubmit)}
            role="form"
            className="countryclass"
          >
            <Row>
              <Col md={6}>
                <div className="main-form-section mt-3">

                  <Form.Label>Course Type</Form.Label>

                  <Controller
                    name="course_type_id"
                    {...register("course_type_id", {
                      required: "Course Type is required",
                    })}
                    control={control}
                    render={({ field }) => (
                      <Select
                        styles={{
                          control: (baseStyles) => ({
                            ...baseStyles,
                            borderColor: errors.course_type_id
                              ? "red"
                              : baseStyles,
                          }),
                        }}
                        {...field}
                        options={courseType}
                        onChange={(selectedOption) => {
                          field.onChange(selectedOption.value); // Update Controller's value
                          GetAllCourse(selectedOption.value);
                          setValue("course_type_id", selectedOption);
                          setValue("course_id", "");
                        }}
                      />
                    )}
                  />

                  {errors.course_type_id && (
                    <span className="text-danger">
                      {errors.course_type_id.message}
                    </span>
                  )}

                </div>

                <div className="main-form-section mt-3">

                  <Form.Label>Course</Form.Label>

                  <Controller
                    name="course_id"
                    {...register("course_id", {
                      required: "Course is required",
                    })}
                    control={control}
                    render={({ field }) => (
                      <Select
                        styles={{
                          control: (baseStyles) => ({
                            ...baseStyles,
                            borderColor: errors.course_id
                              ? "red"
                              : baseStyles,
                          }),
                        }}
                        {...field}
                        options={course}
                      />
                    )}
                  />

                  {errors.course_id && (
                    <span className="text-danger">
                      {errors.course_id.message}
                    </span>
                  )}

                </div>

                <div className="main-form-section mt-3">

                  <Form.Label>Title</Form.Label>

                  <Form.Control
                    className={classNames("", {
                      "is-invalid": errors?.name,
                    })}
                    type="text"
                    {...register("name", {
                      required: "Title is required",
                      // maxLength: {
                      //   value: 30,
                      //   message: "Title must be at most 30 characters long",
                      // },
                    })}
                    placeholder="Title"
                  />

                  {errors.name && (
                    <span className="text-danger">
                      {errors.name.message}
                    </span>
                  )}


                </div>

                {/* <div className="main-form-section mt-3">
                  <Row className="justify-content-center"> */}
                {/* <Form.Label column sm={2} className="text-center">
                      Description
                    </Form.Label>
                    <Col sm={10}>
                      <Form.Group>
                        <Controller
                          name="description" // Provide the field name
                          control={control} // Pass the control object from useForm()
                          rules={{ required: "Description is required" }} // Validation rules
                          render={({ field }) => (
                            <JoditEditor
                              value={field.value}
                              onChange={(newContent) =>
                                field.onChange(newContent)
                              }
                              onBlur={field.onBlur}
                              className={classNames("", {
                                "is-invalid": !!errors.description,
                              })}
                              placeholder="Description"
                            />
                          )}
                        />
                      </Form.Group>
                      {errors.description && (
                        <span className="text-danger">
                          {errors.description.message}
                        </span>
                      )}
                    </Col> */}


                <div className="main-form-section mt-3" >

                  <Form.Label>
                    Video
                  </Form.Label>

                  <Form.Control
                    className={classNames("", {
                      "is-invalid": errors?.video,
                    })}
                    type="file"
                    {...register("video", {

                    })}
                    accept="video/mp4, video/x-m4v, video/*"
                  />

                  {errors.video && (
                    <span className="text-danger">
                      {errors.video.message}
                    </span>
                  )}

                </div>

              </Col>

              <Col lg={6} className="text-center">
                {/* Image Preview */}
                <Col lg={6}>
                  <div className="main-form-section mt-3" style={{ marginLeft: '30px' }}>
                    <Form.Label > Video Preview </Form.Label>
                    {typeof getValues("video") == "string" ? (
                      <div className="image-preview-container">
                        <video
                          src={IMG_URL + getValues("video")}
                          alt="Preview"
                          className="image-preview"
                          style={{
                            width: "250px",
                            height: "250px",
                          }}
                          muted
                          controls
                        />
                      </div>
                    ) : (
                      videoFile &&
                      videoFile?.length > 0 && (
                        <div className="image-preview-container">
                          <video
                            // src={URL.createObjectURL(getValues("image")[0])}
                            src={URL?.createObjectURL(videoFile[0])}
                            alt="Preview"
                            className="image-preview"
                            style={{
                              width: "250px",
                              height: "250px",
                            }}
                            controls
                            muted
                          />
                        </div>
                      )
                    )}



                  </div>
                </Col>
                {/* </Row>
                </div> */}
              </Col>
            </Row>

            <Col lg={12}>
              <div className="main-form-section mt-3">
                <Row className="justify-content-start"> {/* Align to left */}
                  {/* Description */}
                  <Col sm={12}>
                    <Form.Label className="text-center">
                      Description
                    </Form.Label>
                    <Form.Group>
                      <Controller
                        name="description"
                        control={control}
                        rules={{ required: 'Description is required.', 
                        // maxLength: 
                        // { value: 600, message: 'Description must be at most 600 characters long.' } 
                      }} // Correct the maxLength rule
                        render={({ field: { onChange, onBlur, value } }) => (
                          <JoditEditor
                            value={value}
                            onBlur={() => {
                              onBlur();
                              trigger('description');
                            }}
                            onChange={onChange}
                          />
                        )}
                      />
                    </Form.Group>
                    {errors.description && (
                      <span className="text-danger">
                        {errors.description.message}
                      </span>
                    )}
                  </Col>
                </Row>
              </div>
            </Col>

            {/* Buttons */}
            <Row className="justify-content-center mt-5 pb-3">
              <Col sm={6} className="d-flex justify-content-center">
                <CancelButton name={"Cancel"} handleClose={props.handleClose} />
                <SaveButton
                  name={"Save"}
                  handleSubmit={handleSubmit(onSubmit)}
                />
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal >
      <ModalSave
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      />
    </>
  );
};

export default EditOffCanvance;
