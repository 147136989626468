import React, { useContext } from "react";
import { useState, useEffect } from "react";
import "../../Tabels/Tabels.css";
import { Link } from "react-router-dom";
import { Context } from "../../../utils/context";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import Offcanvas from "react-bootstrap/Offcanvas";
// *******************toster****************************
import Card from "react-bootstrap/Card";
import Select from "react-select";
import ModalSave from "../../common/ModelSave";
import Validation from "../../common/FormValidation";
import { CancelButton, SaveButton } from "../../common/Button";

import {
  Container,
  Row,
  Col,
  Button,
  Form,
  InputGroup,
  Modal,
} from "react-bootstrap";

library.add(fas);

const EditOffCanvance = (props) => {
  const { postData, getData, Select2Data } = useContext(Context);
  //   const { id } = useParams();

  const id = props.show;
  const [experiencetype, setExperienceType] = useState([]);
  const [roles, setRoles] = useState([]);
  const [experienceinyears, setExperienceinyears] = useState([]);
  const [skillsname, setSkillsname] = useState([]);
  const [skillsleague, setSkillsleague] = useState([]);

  const [selectedskillsname, setSelectedSkillsname] = useState([]);
  const [selectedskillsleague, setSelectedSkillslame] = useState([]);

  const [formData, setFormData] = useState({
    id: "",
    experience_type_id: "",
    roles_id: "",
    experience_in_year_id: "",
    hic_no: "",
    skills_name: "",
    skills_league: "",
  });

  useEffect(() => {
    setFormData({
      ...formData,
      skills_name: selectedskillsname,
      skills_league: selectedskillsleague,
    });
  }, [selectedskillsname, selectedskillsleague]);

  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });

  const handleChange = (e) => {
    if (
      e?.name === "experience_type_id" ||
      e?.name === "roles_id" ||
      e?.name === "experience_in_year_id"
    ) {
      setFormData({ ...formData, [e.name]: e });
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = await Validation.FormValidation({
      classname: ".rolesubtypeclass",
    });

    const validationErrorsSelect = Validation.SelectValidation({
      classname: ".rolesubtypeclass",
    });
    if (validationErrors && validationErrorsSelect) {
      try {
        const data = {
          experience_type_id: formData.experience_type_id.value,
          roles_id: formData.roles_id.value,
          experience_in_year_id: formData.experience_in_year_id.value,
          hic_no: formData.hic_no,
          skills_name: formData.skills_name.map((item) => item.value),
          skills_league: formData.skills_league.map((item) => item.value),
        };
        const response = await postData(`/masters/skills_master/${id}`, data);
        if (response.success) {
          setShowModal({ code: response.code, message: response.message });
          setTimeout(() => {
            setShowModal(0);
            props.handleClose();
          }, 1000);
        } else {
          setShowModal({ code: response.code, message: response.message });
          setTimeout(() => {
            setShowModal(0);
            props.handleClose();
          }, 1000);
        }
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    }
  };

  const handleKeyPressContact = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);

    if (!/^\d*\.?\d*$/.test(keyValue)) {
      event.preventDefault();
    }
  };

  const GetEditData = async () => {
    const response = await getData(`/masters/skills_master/${id}`);

    const data = {
      id: response?.data?.id,
      experience_type_id: {
        value: response?.data.experience_type.id,
        label: response?.data.experience_type.name,
        name: "experience_type_id",
      },
      roles_id: {
        value: response?.data.role.id,
        label: response?.data.role.name,
        name: "roles_id",
      },
      experience_in_year_id: {
        value: response?.data.experience_in_year.id,
        label: response?.data.experience_in_year.name,
        name: "experience_in_year_id",
      },
      hic_no: response?.data.hic_no,
      skills_name:
        response?.data.skills_skills_names?.map((item) => ({
          value: item.skill.id,
          label: item.skill.name,
          name: "skills_name",
        })) || [],

      skills_league:
        response?.data.skills_skills_leagues?.map((item) => ({
          value: item.skill_league.id,
          label: item.skill_league.name,
          name: "skills_league",
        })) || [],
    };
    setFormData(data);
    setSelectedSkillsname(
      response?.data.skills_skills_names?.map((item) => ({
        value: item.skill.id,
        label: item.skill.name,
        name: "skills_name",
      })) || []
    );
    setSelectedSkillslame(
      response?.data.skills_skills_leagues?.map((item) => ({
        value: item.skill_league.id,
        label: item.skill_league.name,
        name: "skills_league",
      })) || []
    );
  };

  useEffect(() => {
    GetEditData();
  }, []);

  const GetAllMasters = async () => {
    const allexperiencetype = await getData("/allexperiencetype");
    const allroles = await getData("/allroles");
    const allexperienceinyears = await getData("/allexperienceinyears");
    const allskills = await getData("/allskills");
    const allskillsleague = await getData("/allskillsleague");

    if (
      allexperiencetype.success &&
      allroles.success &&
      allexperienceinyears.success &&
      allskills.success
    ) {
      setExperienceType(
        await Select2Data(allexperiencetype?.data, "experience_type_id")
      );

      setRoles(await Select2Data(allroles?.data, "roles_id"));

      setExperienceinyears(
        await Select2Data(allexperienceinyears?.data, "experience_in_year_id")
      );

      setSkillsname(await Select2Data(allskills?.data, "skills_name"));
      setSkillsleague(
        await Select2Data(allskillsleague?.data, "skills_league")
      );
    }
  };

  useEffect(() => {
    GetAllMasters();
  }, []);

  return (
    <>
      {/* <Offcanvas
        show={props.show}
        style={{ width: "80%" }}
        placement={"end"}
        onHide={props.handleClose}
      >
        <Offcanvas.Header closeButton>

        </Offcanvas.Header>
        <Offcanvas.Body>
          <Card className="shadow-lg p-3 mb-5 bg-white rounded">
            <Card.Body>
              <Card.Title>Edit Role SubType</Card.Title>
              <hr />
              <Container>
                <Row>
                  <Col md={12}>
                    <Container>
                      <Row className="">
                        <Col xxl={12} xl={12} md={10}>
                          <Form
                            onSubmit={handleSubmit}
                            role="form"
                            className="rolesubtypeclass"
                          >
                            <Row>

                              <Col md={6}>
                                <div className="main-form-section mt-5">
                                  <Row className="row justify-content-center mb-2">
                                    <Form.Label column sm={3}>
                                      Experience Type{" "}
                                    </Form.Label>
                                    <Col sm={6}>
                                      <Select
                                        name="experience_type_id"
                                        className="input-mandatory"
                                        onChange={handleChange}
                                        placeholder="Select Experience Type"
                                        value={formData.experience_type_id}
                                        options={experiencetype}
                                      />
                                      <span className="error-message"></span>
                                    </Col>
                                  </Row>
                                </div>
                              </Col>

                              <Col md={6}>
                                <div className="main-form-section mt-5">
                                  <Row className="row justify-content-center mb-2">
                                    <Form.Label column sm={3}>
                                      Roles Type{" "}
                                    </Form.Label>
                                    <Col sm={6}>
                                      <Select
                                        name="roles_id"
                                        className="input-mandatory"
                                        onChange={handleChange}
                                        placeholder="Select Roles Type"
                                        value={formData.roles_id}
                                        options={roles}
                                      />
                                      <span className="error-message"></span>
                                    </Col>
                                  </Row>
                                </div>
                              </Col>

                              <Col md={6}>
                                <div className="main-form-section mt-5">
                                  <Row className="row justify-content-center mb-2">
                                    <Form.Label column sm={3}>
                                      Experience In Year{" "}
                                    </Form.Label>
                                    <Col sm={6}>
                                      <Select
                                        name="experience_in_year_id"
                                        className="input-mandatory"
                                        onChange={handleChange}
                                        placeholder="Select Experience In Year"
                                        value={formData.experience_in_year_id}
                                        options={experienceinyears}
                                      />
                                      <span className="error-message"></span>
                                    </Col>
                                  </Row>
                                </div>
                              </Col>

                              <Col md={6}>
                                <div className="main-form-section mt-5">
                                  <Row className="justify-content-center">
                                    <Form.Label column sm={3}>
                                      Hic No
                                    </Form.Label>
                                    <Col sm={6}>
                                      <Form.Group>
                                        <InputGroup>
                                          <Form.Control
                                            type="tel"
                                            name="hic_no"
                                            value={formData.hic_no}
                                            onChange={handleChange}
                                            onKeyPress={handleKeyPressContact}
                                            placeholder="Hic No"
                                            id="inputEmail3"
                                            className="input-mandatory"
                                          />
                                        </InputGroup>
                                        <span className="error-message"></span>
                                      </Form.Group>
                                    </Col>
                                  </Row>
                                </div>
                              </Col>

                              <Col md={6}>
                                <div className="main-form-section mt-5">
                                  <Row className="row justify-content-center mb-2">
                                    <Form.Label column sm={3}>
                                      Skills Name{" "}
                                    </Form.Label>
                                    <Col sm={6}>
                                      <Select
                                        name="skills_name"
                                        className="input-mandatory"
                                        isMulti
                                        onChange={(e) => {
                                          setSelectedSkillsname(e);
                                        }}
                                        value={formData?.skills_name}
                                        placeholder="Select Skills Name"
                                        options={skillsname}
                                      />
                                      <span className="error-message"></span>
                                    </Col>
                                  </Row>
                                </div>
                              </Col>

                              <Col md={6}>
                                <div className="main-form-section mt-5">
                                  <Row className="row justify-content-center mb-2">
                                    <Form.Label column sm={3}>
                                      Skills League{" "}
                                    </Form.Label>
                                    <Col sm={6}>
                                      <Select
                                        name="skills_league"
                                        className="input-mandatory"
                                        isMulti
                                        value={formData?.skills_league}
                                        onChange={(e) => {
                                          setSelectedSkillslame(e);
                                        }}
                                        placeholder="Select Skills League"
                                        options={skillsleague}
                                      />
                                      <span className="error-message"></span>
                                    </Col>
                                  </Row>
                                </div>
                              </Col>


                              <Row className="mt-5 pb-3">
                                <div className="d-flex justify-content-center">
                                  <Link>
                                    <CancelButton
                                      name={"cancel"}
                                      handleClose={props.handleClose}
                                    />
                                  </Link>
                                  <SaveButton
                                    name={"save"}
                                    handleSubmit={handleSubmit}
                                  />
                                </div>
                              </Row>

                            </Row>
                          </Form>
                        </Col>
                      </Row>
                    </Container>
                  </Col>
                </Row>
              </Container>
            </Card.Body>
          </Card>
        </Offcanvas.Body>
      </Offcanvas> */}

      <Modal
        {...props}
        size="lg"
        onHide={props.handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Edit Skills Master
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            onSubmit={handleSubmit}
            role="form"
            className="rolesubtypeclass"
          >
            <Row>
              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="row justify-content-center mb-2">
                    <Form.Label>Experience Type </Form.Label>

                    <Select
                      name="experience_type_id"
                      className="input-mandatory"
                      onChange={handleChange}
                      placeholder="Select Experience Type"
                      value={formData.experience_type_id}
                      options={experiencetype}
                    />
                    <span className="error-message"></span>
                  </Row>
                </div>
              </Col>

              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="row justify-content-center mb-2">
                    <Form.Label>Roles</Form.Label>

                    <Select
                      name="roles_id"
                      className="input-mandatory"
                      onChange={handleChange}
                      placeholder="Select Roles"
                      value={formData.roles_id}
                      options={roles}
                    />
                    <span className="error-message"></span>
                  </Row>
                </div>
              </Col>

              <Col md={6}>
                <div className="main-form-section mt-5">
                  <Row className="row justify-content-center mb-2">
                    <Form.Label>Experience In Year </Form.Label>

                    <Select
                      name="experience_in_year_id"
                      className="input-mandatory"
                      onChange={handleChange}
                      placeholder="Select Experience In Year"
                      value={formData.experience_in_year_id}
                      options={experienceinyears}
                    />
                    <span className="error-message"></span>
                  </Row>
                </div>
              </Col>

              <Col md={6}>
                <div className="main-form-section mt-5">
                  <Row className="justify-content-center">
                    <Form.Label>Hic No</Form.Label>

                    <Form.Group>
                      <InputGroup>
                        <Form.Control
                          type="number"
                          name="hic_no"
                          value={formData.hic_no}
                          onChange={handleChange}
                          placeholder="Hic No"
                          id="inputEmail3"
                          className="input-mandatory"
                        />
                      </InputGroup>
                      <span className="error-message"></span>
                    </Form.Group>
                  </Row>
                </div>
              </Col>

              <Col md={6}>
                <div className="main-form-section mt-5">
                  <Row className="row justify-content-center mb-2">
                    <Form.Label>Skills Name </Form.Label>

                    <Select
                      name="skills_name"
                      className="input-mandatory"
                      isMulti
                      onChange={(e) => {
                        setSelectedSkillsname(e);
                      }}
                      value={formData?.skills_name}
                      placeholder="Select Skills Name"
                      options={skillsname}
                    />
                    <span className="error-message"></span>
                  </Row>
                </div>
              </Col>

              <Col md={6}>
                <div className="main-form-section mt-5">
                  <Row className="row justify-content-center mb-2">
                    <Form.Label>Skills League </Form.Label>

                    <Select
                      name="skills_league"
                      className="input-mandatory"
                      isMulti
                      value={formData?.skills_league}
                      onChange={(e) => {
                        setSelectedSkillslame(e);
                      }}
                      placeholder="Select Skills League"
                      options={skillsleague}
                    />
                    <span className="error-message"></span>
                  </Row>
                </div>
              </Col>

              <Row className="mt-5 pb-3">
                <div className="d-flex justify-content-center">
                  <Link>
                    <CancelButton
                      name={"cancel"}
                      handleClose={props.handleClose}
                    />
                  </Link>
                  <SaveButton name={"save"} handleSubmit={handleSubmit} />
                </div>
              </Row>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>

      <ModalSave
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      />
    </>
  );
};

export default EditOffCanvance;
