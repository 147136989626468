import React, { useContext } from "react";
import { useState, useEffect, useRef } from "react";
import "../../../../Tabels/Tabels.css";
import { Link } from "react-router-dom";
import { Context } from "../../../../../utils/context";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import Offcanvas from "react-bootstrap/Offcanvas";
// *******************toster****************************
import Card from "react-bootstrap/Card";
import ModalSave from "../../../../common/ModelSave";
import Validation from "../../../../common/FormValidation";
import { CancelButton, SaveButton } from "../../../../common/Button";
import Modal from "react-bootstrap/Modal";
import { Container, Row, Col, Button, Form, InputGroup } from "react-bootstrap";
import JoditEditor from "jodit-react";
import { useForm } from "react-hook-form";
import classNames from "classnames";
import Table from "react-bootstrap/Table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
library.add(fas);

const EditOffCanvance = (props) => {
  const { postData, getData, IMG_URL, getDimension, deleteData } = useContext(Context);

  const id = props.show;
  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });

  const deleteImage = async (imageId) => {
    try {
      const response = await deleteData(`/product/product-image/${imageId}`);
      if (response.success) {
        console.log("Image deleted successfully");
      } else {
        console.error("Failed to delete image");
      }
      GetEditData();
    } catch (error) {
      console.error("Error deleting image:", error);
    }
  };

  const [data, setDate] = useState([]);

  const GetEditData = async () => {
    const response = await getData(
      `/masters/ed-tech/university/barati-vidyapeeth/we-deliver/${id}`
    );
    console.log(response?.data);
    setDate(response?.data);
    reset(response?.data);
  };

  useEffect(() => {
    GetEditData();
    console.log(data);
  }, []);
  // useEffect(() => {
  //   GetEditData();
  // }, [deleteImage]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    reset,
    watch,
  } = useForm();
  console.log(getValues());
  const imagesFile = watch("images");

  const onSubmit = async (data) => {
    try {
      const formData = new FormData();
      formData.append("name", data?.name);
      formData.append("description", data?.description);
      for (let i = 0; i < data?.images.length; i++) {
        formData.append("images", data?.images[i]);
      }
      const response = await postData(
        `/masters/ed-tech/university/barati-vidyapeeth/we-deliver/${id}`,
        formData
      );
      console.log(response);
      if (response?.success) {
        await setShowModal({ code: response?.code, message: response?.message });
      } else {
        await setShowModal({ code: response?.code, message: response?.message });
      }
      setTimeout(() => {
        setShowModal(0);
        props.handleClose();
      }, 1000);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Modal
        onHide={props.handleClose}
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Edit We Deliver
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            onSubmit={() => handleSubmit(onSubmit)}
            role="form"
            className="countryclass"
          >
            <Row>

              <Col lg={6}>
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>Preview</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {getValues("images") && Object.values(getValues("images")).map((image, index) => (
                      <tr key={index}>
                        <td>
                          <img
                            src={image.url ? IMG_URL + image.url : URL.createObjectURL(imagesFile[index])}
                            alt={`Image ${index + 1}`}
                            style={{ width: "100px", height: "100px" }}
                          />

                        </td>
                        <td style={{ width: "50px" }}>
                          <Button
                            variant="danger"
                            onClick={() => deleteImage(image.id)}
                            style={{ width: "100%", padding: "0.375rem 0.75rem" }}
                          >
                            <FontAwesomeIcon icon={["fas", "trash"]} />
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>

                </Table>
              </Col>

              {/* Title */}
              <Col lg={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label column sm={3} className="text-center">
                      Title
                    </Form.Label>
                    <Col sm={9}>
                      <Form.Group>
                        <Form.Control
                          className={classNames("", {
                            "is-invalid": errors?.name,
                          })}
                          type="text"
                          {...register("name", {
                            required: "Title is required",
                            maxLength: {
                              value: 70,
                              message: "Title must be at most 70 characters long",
                            },
                          })}
                          placeholder="Title"
                        />
                      </Form.Group>
                      {errors.name && (
                        <span className="text-danger">
                          {errors.name.message}
                        </span>
                      )}
                    </Col>
                  </Row>
                </div>

                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label column sm={3} className="text-center">
                      description
                    </Form.Label>
                    <Col sm={9}>
                      <Form.Group>
                        <Form.Control
                          className={classNames("", {
                            "is-invalid": errors?.description,
                          })}
                          type="text"
                          {...register("description", {
                            required: "description is required",
                            maxLength: {
                              value: 500,
                              message: "Title must be at most 500 characters long",
                            },
                          })}
                          placeholder="description"
                        />
                      </Form.Group>
                      {errors.description && (
                        <span className="text-danger">
                          {errors.description.message}
                        </span>
                      )}
                    </Col>
                  </Row>
                </div>

                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label column sm={3} className="text-center">
                      images
                    </Form.Label>
                    <Col sm={9}>
                      <Form.Group>
                        <Form.Control
                          className={classNames("", {
                            "is-invalid": errors?.images,
                          })}
                          type="file"
                          multiple
                          {...register("images", {

                            validate: async (value) => {
                              console.log(value, "value");
                              if (!value || value.length <= 0) {
                                const fileTypes = ["jpg", "png", "jpeg", "JPG"];
                                const fileType = value[0]?.name?.split(".")[1];

                                if (!fileTypes.includes(fileType)) {
                                  return `please upload a valid file format. (${fileTypes})`;
                                }

                                const sizes = await getDimension(value[0]);
                                if (
                                  sizes.width !== 306 &&
                                  sizes.height !== 306
                                ) {
                                  return "Image width and height must be 416px and 363px";
                                }

                                const fileSize = Math.round(
                                  value[0].size / 1024
                                );
                                if (fileSize > 500) {
                                  return "file size must be lower than 500kb";
                                }
                              }
                            },
                          })}

                          accept=".jpg, .jpeg, .png"
                        />
                      </Form.Group>


                      {errors.images && (
                        <span className="text-danger">
                          {errors.images.message}
                        </span>
                      )}
                    </Col>
                  </Row>
                </div>
              </Col>



            </Row>
            {/* Buttons */}
            <Row className="justify-content-center mt-5 pb-3">
              <Col sm={6} className="d-flex justify-content-center">
                <CancelButton name={"Cancel"} handleClose={props.handleClose} />
                <SaveButton
                  name={"Save"}
                  handleSubmit={handleSubmit(onSubmit)}
                />
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>

      <ModalSave
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      />
    </>
  );
};

export default EditOffCanvance;
