
import React, { useContext } from "react";
import { useState, useEffect } from "react";
import "../../Tabels/Tabels.css";
import { Link } from "react-router-dom";
import { Context } from "../../../utils/context";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import Offcanvas from "react-bootstrap/Offcanvas";
// *******************toster****************************
import Card from "react-bootstrap/Card";
import Select from "react-select";
import ModalSave from "../../common/ModelSave";
import Validation from "../../common/FormValidation";
import { CancelButton, SaveButton } from "../../common/Button";

import { Container, Row, Col, Button, Form, InputGroup, Modal } from "react-bootstrap";

library.add(fas);

const EditOffCanvance = (props) => {
  const { postData, getData } = useContext(Context);
  //   const { id } = useParams();

  const id = props.show;
  const [roletype, setRoletype] = useState([]);
  const [formData, setFormData] = useState({
    id: "",
    name: "",
    role_type_id: "",
  });

  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });

  const handleChange = (e) => {
    if (e?.name == "role_type_id") {
      setFormData({ ...formData, [e.name]: e });
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = await Validation.FormValidation({
      classname: ".rolesubtypeclass",
    });

    const validationErrorsSelect = Validation.SelectValidation({
      classname: ".rolesubtypeclass",
    });
    if (validationErrors && validationErrorsSelect) {
      try {
        const response = await postData(`/masters/role_subtype/${id}`, formData);
        if (response.success) {
          setShowModal({ code: response.code, message: response.message });
          setTimeout(() => {
            setShowModal(0);
            props.handleClose();
          }, 1000);
        } else {
          setShowModal({ code: response.code, message: response.message });
          setTimeout(() => {
            setShowModal(0);
            props.handleClose();
          }, 1000);
        }
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    }
  };

  const GetEditData = async () => {
    const response = await getData(`/masters/role_subtype/${id}`);

    setFormData(response?.data);
  };

  useEffect(() => {
    GetEditData();
  }, []);

  const GetAllroletype = async () => {
    const response = await getData("/allroletype");
    const option = [];
    if (response.success) {
      response?.data?.map((data) => {
        option.push({
          value: data.id,
          name: "role_type_id",
          label: data.name,
        });
      });
    }
    setRoletype(option);
  };

  useEffect(() => {
    GetAllroletype();
  }, []);

  return (
    <>
      {/* <Offcanvas
          show={props.show}
          style={{ width: "80%" }}
          placement={"end"}
          onHide={props.handleClose}
        >
          <Offcanvas.Header closeButton>
           
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Card className="shadow-lg p-3 mb-5 bg-white rounded">
              <Card.Body>
                <Card.Title>Edit Role SubType</Card.Title>
                <hr />
                <Container>
                  <Row>
                    <Col md={12}>
                      <Container>
                        <Row className="">
                          <Col xxl={12} xl={12} md={10}>
                            <Form
                              onSubmit={handleSubmit}
                              role="form"
                              className="rolesubtypeclass"
                            >
                              <Row>
                              
                                <Col md={6}>
                                  <div className="main-form-section mt-5">
                                    <Row className="row justify-content-center mb-2">
                                      <Form.Label column sm={3}>
                                        Role Type 
                                      </Form.Label>
                                      <Col sm={6}>
                                        <Select
                                          name="role_type_id"
                                          className="input-mandatory"
                                          value={formData?.role_type_id}
                                          options={roletype}
                                          onChange={handleChange}
                                        />{" "}
                                        <span className="error-message"></span>
                                      </Col>
                                    </Row>
                                  </div>
                                </Col>
                                <Col md={6}>
                                  <div className="main-form-section mt-5">
                                    <Row className="justify-content-center">
                                      <Form.Label column sm={3}>
                                        Role SubType
                                      </Form.Label>
                                      <Col sm={6}>
                                        <Form.Group>
                                          <InputGroup>
                                            <Form.Control
                                              type="text"
                                              name="name"
                                              value={formData?.name}
                                              onChange={handleChange}
                                              placeholder="Role SubType "
                                              id="inputEmail3"
                                              className="input-mandatory"
                                            />
                                          </InputGroup>
                                          <span className="error-message"></span>
                                        </Form.Group>
                                      </Col>
                                    </Row>
                                  </div>
                                </Col>
                               
  
                                <Row className="mt-5 pb-3">
                                  <div className="d-flex justify-content-center">
                                    <Link>
                                      <CancelButton
                                        name={"cancel"}
                                        handleClose={props.handleClose}
                                      />
                                    </Link>
                                    <SaveButton
                                      name={"save"}
                                      handleSubmit={handleSubmit}
                                    />
                                  </div>
                                </Row>
                              
                              </Row>
                            </Form>
                          </Col>
                        </Row>
                      </Container>
                    </Col>
                  </Row>
                </Container>
              </Card.Body>
            </Card>
          </Offcanvas.Body>
        </Offcanvas> */}

      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        onHide={props.handleClose}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Edit RoleSubtype
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            onSubmit={handleSubmit}
            role="form"
            className="rolesubtypeclass"
          >
            <Row>

              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="row justify-content-center mb-2">
                    <Form.Label >
                      Role Type
                    </Form.Label>
                    
                      <Select
                        name="role_type_id"
                        className="input-mandatory"
                        value={formData?.role_type_id}
                        options={roletype}
                        onChange={handleChange}
                      />{" "}
                      <span className="error-message"></span>
                    
                  </Row>
                </div>
              </Col>
              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label >
                      Role SubType
                    </Form.Label>
                   
                      <Form.Group>
                        <InputGroup>
                          <Form.Control
                            type="text"
                            name="name"
                            value={formData?.name}
                            onChange={handleChange}
                            placeholder="Role SubType "
                            id="inputEmail3"
                            className="input-mandatory"
                          />
                        </InputGroup>
                        <span className="error-message"></span>
                      </Form.Group>
                    
                  </Row>
                </div>
              </Col>


              <Row className="mt-5 pb-3">
                <div className="d-flex justify-content-center">
                  <Link>
                    <CancelButton
                      name={"cancel"}
                      handleClose={props.handleClose}
                    />
                  </Link>
                  <SaveButton
                    name={"save"}
                    handleSubmit={handleSubmit}
                  />
                </div>
              </Row>

            </Row>
          </Form>
        </Modal.Body>

      </Modal>

      <ModalSave
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      />
    </>
  );
};

export default EditOffCanvance;
