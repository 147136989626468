import React, { useContext, useEffect, useState } from "react";
import "./App.css";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";

import Login from "./Components/Login/Login";

// Dashboard

import Dashboard from "./Components/DashBoard/DashBoard";
import Sidebar from "./Components/Sidebar/Sidebar";
import Header from "./Components/Header/Header";
import AdvanceDashboard from "./Components/DashBoard/AdvanceDashboard/AdvanceDashboard2";

import { Context } from "./utils/context";
import SettingContent from "./Components/Masters/SettingContent/SettingContent";

import ContactUsList from "./Components/CustomerManagement/ContactUSList/Tables";
// import EdTech from "./Components/EdTech/EdTech/Edtech";

import BbaBanner from "./Components/Ed_tech/Courses/Ug_Courses/Bba/Banner/Tables";
import BbaAdvantages from "./Components/Ed_tech/Courses/Ug_Courses/Bba/Advantages/Tables";
import BbaDiscover from "./Components/Ed_tech/Courses/Ug_Courses/Bba/Discover/Tables";
import BbaReasons from "./Components/Ed_tech/Courses/Ug_Courses/Bba/Reasons/Tables";
import BbaWhatIs from "./Components/Ed_tech/Courses/Ug_Courses/Bba/What_is/Tables";
import BbaWhoQualified from "./Components/Ed_tech/Courses/Ug_Courses/Bba/Who_Qualified/Tables";

import BcaBanner from "./Components/Ed_tech/Courses/Ug_Courses/Bca/Banner/Tables";
import BcaAdvantages from "./Components/Ed_tech/Courses/Ug_Courses/Bca/Advantages/Tables";
import BcaDiscover from "./Components/Ed_tech/Courses/Ug_Courses/Bca/Discover/Tables";
import BcaReasons from "./Components/Ed_tech/Courses/Ug_Courses/Bca/Reasons/Tables";
import BcaWhatIs from "./Components/Ed_tech/Courses/Ug_Courses/Bca/What_is/Tables";
import BcaWhoQualified from "./Components/Ed_tech/Courses/Ug_Courses/Bca/Who_Qualified/Tables";

import BcomBanner from "./Components/Ed_tech/Courses/Ug_Courses/Bcom/Banner/Tables";
import BcomAdvantages from "./Components/Ed_tech/Courses/Ug_Courses/Bcom/Advantages/Tables";
import BcomDiscover from "./Components/Ed_tech/Courses/Ug_Courses/Bcom/Discover/Tables";
import BcomReasons from "./Components/Ed_tech/Courses/Ug_Courses/Bcom/Reasons/Tables";
import BcomWhatIs from "./Components/Ed_tech/Courses/Ug_Courses/Bcom/What_is/Tables";
import BcomWhoQualified from "./Components/Ed_tech/Courses/Ug_Courses/Bcom/Who_Qualified/Tables";

import BscBanner from "./Components/Ed_tech/Courses/Ug_Courses/Bsc/Banner/Tables";
import BscAdvantages from "./Components/Ed_tech/Courses/Ug_Courses/Bsc/Advantages/Tables";
import BscDiscover from "./Components/Ed_tech/Courses/Ug_Courses/Bsc/Discover/Tables";
import BscReasons from "./Components/Ed_tech/Courses/Ug_Courses/Bsc/Reasons/Tables";
import BscWhatIs from "./Components/Ed_tech/Courses/Ug_Courses/Bsc/What_is/Tables";
import BscWhoQualified from "./Components/Ed_tech/Courses/Ug_Courses/Bsc/Who_Qualified/Tables";

import BlibBanner from "./Components/Ed_tech/Courses/Ug_Courses/Blib/Banner/Tables";
import BlibReasons from "./Components/Ed_tech/Courses/Ug_Courses/Blib/Reasons/Tables";
import BlibWhatIs from "./Components/Ed_tech/Courses/Ug_Courses/Blib/What_is/Tables";
import BlibWhoQualified from "./Components/Ed_tech/Courses/Ug_Courses/Blib/Who_Qualified/Tables";

import BaBanner from "./Components/Ed_tech/Courses/Ug_Courses/Ba/Banner/Tables";
import BaReasons from "./Components/Ed_tech/Courses/Ug_Courses/Ba/Reasons/Tables";
import BaWhatIs from "./Components/Ed_tech/Courses/Ug_Courses/Ba/What_is/Tables";
import BaWhoQualified from "./Components/Ed_tech/Courses/Ug_Courses/Ba/Who_Qualified/Tables";

// import Home from "./Components/Dashboard/Home/Home.js";
import MBABanner from "./Components/Ed_tech/Courses/PGCourses/MBA/Banner/Tables";
import MBAWhat from "./Components/Ed_tech/Courses/PGCourses/MBA/What/Tables";
import MBAReasons from "./Components/Ed_tech/Courses/PGCourses/MBA/Reasons/Tables";
import MBADiscover from "./Components/Ed_tech/Courses/PGCourses/MBA/Discover/Tables";
import MBAAdvantages from "./Components/Ed_tech/Courses/PGCourses/MBA/Advantages/Tables";
import MBAWhoQualified from "./Components/Ed_tech/Courses/PGCourses/MBA/WhoQualified/Tables";

import MCABanner from "./Components/Ed_tech/Courses/PGCourses/MCA/Banner/Tables";
import MCAWhat from "./Components/Ed_tech/Courses/PGCourses/MCA/What/Tables";
import MCAReasons from "./Components/Ed_tech/Courses/PGCourses/MCA/Reasons/Tables";
import MCADiscover from "./Components/Ed_tech/Courses/PGCourses/MCA/Discover/Tables";
import MCAAdvantages from "./Components/Ed_tech/Courses/PGCourses/MCA/Advantages/Tables";
import MCAWhoQualified from "./Components/Ed_tech/Courses/PGCourses/MCA/WhoQualified/Tables";

import MComBanner from "./Components/Ed_tech/Courses/PGCourses/MCOM/Banner/Tables";
import MComWhat from "./Components/Ed_tech/Courses/PGCourses/MCOM/What/Tables";
import MComReasons from "./Components/Ed_tech/Courses/PGCourses/MCOM/Reasons/Tables";
import MComDiscover from "./Components/Ed_tech/Courses/PGCourses/MCOM/Discover/Tables";
import MComAdvantages from "./Components/Ed_tech/Courses/PGCourses/MCOM/Advantages/Tables";
import MComWhoQualified from "./Components/Ed_tech/Courses/PGCourses/MCOM/WhoQualified/Tables";

import MABanner from "./Components/Ed_tech/Courses/PGCourses/MA/Banner/Tables";
import MAWhat from "./Components/Ed_tech/Courses/PGCourses/MA/What/Tables";
import MAReasons from "./Components/Ed_tech/Courses/PGCourses/MA/Reasons/Tables";
import MADiscover from "./Components/Ed_tech/Courses/PGCourses/MA/Discover/Tables";
import MAAdvantages from "./Components/Ed_tech/Courses/PGCourses/MA/Advantages/Tables";
import MAWhoQualified from "./Components/Ed_tech/Courses/PGCourses/MA/WhoQualified/Tables";
import MAVideo from "./Components/Ed_tech/Courses/PGCourses/MA/Video/Tables";

import MLIBBanner from "./Components/Ed_tech/Courses/PGCourses/MLIB/Banner/Tables";
import MLIBWhat from "./Components/Ed_tech/Courses/PGCourses/MLIB/What/Tables";
import MLIBReasons from "./Components/Ed_tech/Courses/PGCourses/MLIB/Reasons/Tables";
import MLIBWhoQualified from "./Components/Ed_tech/Courses/PGCourses/MLIB/WhoQualified/Tables";

import MSCBanner from "./Components/Ed_tech/Courses/PGCourses/MSC/Banner/Tables";
import MSCWhat from "./Components/Ed_tech/Courses/PGCourses/MSC/What/Tables";
import MSCReasons from "./Components/Ed_tech/Courses/PGCourses/MSC/Reasons/Tables";
import MSCWhoQualified from "./Components/Ed_tech/Courses/PGCourses/MSC/WhoQualified/Tables";

// CERTIFICATES

import CertificateBanner from "./Components/Ed_tech/Courses/certificates/diploma/Banner/Table";
import CertificateOnlineDiploma from "./Components/Ed_tech/Courses/certificates/diploma/OnlineDiploma/Table";
import CertificateTopReason from "./Components/Ed_tech/Courses/certificates/diploma/TopReason/Table";
import CertificateDiscoverThePower from "./Components/Ed_tech/Courses/certificates/diploma/DiscoverThePower/Table";
import CertificateWhoQualified from "./Components/Ed_tech/Courses/certificates/diploma/WhoQualified/Table";
import CertificateOnlineDiplomaSectionTwo from "./Components/Ed_tech/Courses/certificates/diploma/OnlineDiplomaSectionTwo/Table";
import CertificateOnlineDiplomaVideo from "./Components/Ed_tech/Courses/certificates/diploma/OnlineDiplomaVideo/Table";

// DIPLOMA COMMON

import DiplomaCommonBanner from "./Components/Ed_tech/Courses/Common/CommonBanner/Table";
import SmallBanner from "./Components/Ed_tech/Courses/Common/SmallBanner/Table";
import CommonWhat from "./Components/Ed_tech/Courses/Common/CommonWhat/Table";
import CommonReason from "./Components/Ed_tech/Courses/Common/CommonReason/Table";
import CommonWhoQualified from "./Components/Ed_tech/Courses/Common/WhoQualified/Table";
import CoursesDiscover from "./Components/Ed_tech/Courses/Common/Discover/Tables";
import CoursesAdvantages from "./Components/Ed_tech/Courses/Common/Advantages/Tables";

// ADMISSION
import AdmissionBanner from "./Components/Ed_tech/Admission/Banner/Table";
import DocumentForAdmission from "./Components/Ed_tech/Admission/DocumentForAdmission/Table";
import Policy from "./Components/Ed_tech/Admission/Policy/Table";
import RegistrationProcess from "./Components/Ed_tech/Admission/RegistrationProcess/Table";
import Steps from "./Components/Ed_tech/Admission/Steps/Table";

// HOME
import HomeBanner from "./Components/Ed_tech/Home/Banner/Table";
import HomeMedia from "./Components/Ed_tech/Home/Media/Table";
import OnlineLearning from "./Components/Ed_tech/Home/OnlineLearning/Table";
import PremierUniversities from "./Components/Ed_tech/Home/PremierUniversities/Table";
import AlumniNetwork from "./Components/Ed_tech/Home/AlumniNetwork/Table";
import PopularCourse from "./Components/Ed_tech/Home/PopularCourse/Table";
import ProfcymaAssistance from "./Components/Ed_tech/Home/ProfcymaAssistance/Table";
import FAQ from "./Components/Ed_tech/Home/FAQ/Table";
import FAQSubtype from "./Components/Ed_tech/Home/FAQ-Subtype/Table";
import WhoWeAre from "./Components/Ed_tech/Home/WhoWeAre/Tables";
import StandOut from "./Components/Ed_tech/Home/ProfcymaStandOut/Table";
import Video from "./Components/Ed_tech/Home/Video/Tables";
import PopularNews from "./Components/Ed_tech/Home/PopularNews/Table";
import FeeStructure from "./Components/Ed_tech/Home/FeeStructure/Table";
import RightGuidance from "./Components/Ed_tech/Home/RightGuidance/Table";
import EdTechAddress from "./Components/Ed_tech/Home/EdTechAddress/Table";
import OurAlumni from "./Components/Ed_tech/Home/OurAlumni/Table";

// UNIVERSITY

// AMITY

import AmityBanner from "./Components/Ed_tech//Universities/AmityUniversity/Banner/Table";
import AmityFAQ from "./Components/Ed_tech//Universities/AmityUniversity/FAQ/Table";
import AmityAchievement from "./Components/Ed_tech//Universities/AmityUniversity/Achievement/Table";
import AmityAdvantageOfTakingAdmission from "./Components/Ed_tech//Universities/AmityUniversity/AdvantageOfTakingAdmission/Table";
import AmityHiringPartner from "./Components/Ed_tech//Universities/AmityUniversity/HiringPartner/Table";
import KeyProgramFeatures from "./Components/Ed_tech//Universities/AmityUniversity/KeyProgramFeatures/Table";
import ProgramOffered from "./Components/Ed_tech//Universities/AmityUniversity/ProgramOffered/Table";
import StageTwo from "./Components/Ed_tech//Universities/AmityUniversity/StageTwo/Table";
import AmityBannerTwo from "./Components/Ed_tech//Universities/AmityUniversity/BannerTwo/Table";
import AmityWeDeliver from "./Components/Ed_tech//Universities/AmityUniversity/WeDeliver/Table";
// common

import CommonBanner from "./Components/Ed_tech//Universities/common/Banner/Table";
import CommonFAQ from "./Components/Ed_tech//Universities/common/FAQ/Table";
import CommonAchievement from "./Components/Ed_tech/Universities/common/Achievement/Table";
import CommonAdvantageOfTakingAdmission from "./Components/Ed_tech//Universities/common/AdvantageOfTakingAdmission/Table";
import CommonHiringPartner from "./Components/Ed_tech//Universities/common/HiringPartner/Table";
import CommonKeyProgramFeatures from "./Components/Ed_tech//Universities/common/KeyProgramFeatures/Table";
import CommonProgramOffered from "./Components/Ed_tech/Universities/common/ProgramOffered/Table";
import CommonStageTwo from "./Components/Ed_tech//Universities/common/StageTwo/Table";
import CommonBannerTwo from "./Components/Ed_tech//Universities/common/BannerTwo/Table";
import CommonWeDeliver from "./Components/Ed_tech//Universities/common/WeDeliver/Table";
import UnivSmallBanner from "./Components/Ed_tech//Universities/common/SmallBanner/Table";
import CommonAlumani from "./Components/Ed_tech//Universities/common/AlumniTestimonal/Table";
import CommonAlumaniImage from "./Components/Ed_tech//Universities/common/AlumniTestimonalImage/Table";
import CommonBarrier from "./Components/Ed_tech//Universities/common/Barriers/Table";
import CommonFuture from "./Components/Ed_tech//Universities/common/Future/Table";
import CommonSectionTwo from "./Components/Ed_tech//Universities/common/SectionTwo/Table";
import CommonStudentBenefit from "./Components/Ed_tech//Universities/common/StudentBenefits/Table";
import AboutBanner from "./Components/Ed_tech/Universities/common/About/Table";
// D Y Patil

import DYPatilBanner from "./Components/Ed_tech/Universities/DYPatilUniversity/Banner/Table";
import DYPatilAdvantageOfTakingAdmission from "./Components/Ed_tech//Universities/DYPatilUniversity/AdvantageOfTakingAdmission/Table";
import DYPatilAchievement from "./Components/Ed_tech//Universities/DYPatilUniversity/Achievement/Table";
import DYPatilAlumniTestimonal from "./Components/Ed_tech//Universities/DYPatilUniversity/AlumniTestimonal/Table";
import DYPatilAlumniFAQ from "./Components/Ed_tech//Universities/DYPatilUniversity/FAQ/Table";
import DYPatilHiringPartner from "./Components/Ed_tech//Universities/DYPatilUniversity/HiringPartner/Table";
import DYPatilKeyProgramFeatures from "./Components/Ed_tech//Universities/DYPatilUniversity/KeyProgramFeatures/Table";
import DYPatilProgramOffered from "./Components/Ed_tech//Universities/DYPatilUniversity/ProgramOffered/Table";
import DYPatilStudentBenefits from "./Components/Ed_tech//Universities/DYPatilUniversity/StudentBenefits/Table";
import DYPatilSectionTwo from "./Components/Ed_tech//Universities/DYPatilUniversity/SectionTwo/Table";
import DYPatilBannerTwo from "./Components/Ed_tech/Universities/DYPatilUniversity/BannerTwo/Table";
import DYPatilBarriers from "./Components/Ed_tech/Universities/DYPatilUniversity/Barriers/Table";
import DYPatilFuture from "./Components/Ed_tech//Universities/DYPatilUniversity/Future/Table";
import DYPatilAlumniTestimonalImage from "./Components/Ed_tech/Universities/DYPatilUniversity/AlumniTestimonalImage/Table";

// BHARTI VIDYAPEETH
import BhartiVidyaPeethBanner from "./Components/Ed_tech//Universities/BhartiVidyapeeth/Banner/Table";
import BhartiVidyaPeethFAQ from "./Components/Ed_tech//Universities/BhartiVidyapeeth/FAQ/Table";
import BhartiVidyaPeethHiringPartner from "./Components/Ed_tech//Universities/BhartiVidyapeeth/HiringPartner/Table";
import BhartiVidyaPeethKeyProgramFeatures from "./Components/Ed_tech//Universities/BhartiVidyapeeth/KeyProgramFeatures/Table";
import BhartiVidyaPeethProgramOffered from "./Components/Ed_tech//Universities/BhartiVidyapeeth/ProgramOffered/Table";
import BhartiVidyaPeethSectionTwo from "./Components/Ed_tech//Universities/BhartiVidyapeeth/SectionTwo/Table";
import BhartiVidyaPeethWeDeliver from "./Components/Ed_tech//Universities/BhartiVidyapeeth/WeDeliver/Table";
import BhartiVidyaPeethAdvantageOfTakingAdmission from "./Components/Ed_tech//Universities/BhartiVidyapeeth/AdvantageOfTakingAdmission/Table";
import BhartiVidyaPeethBannerTwo from "./Components/Ed_tech//Universities/BhartiVidyapeeth/BannerTwo/Table";

//  BLOG
import BlogCategory from "./Components/Ed_tech/Blog/Blog_Category/Tables";
import Blog from "./Components/Ed_tech/Blog/Blogs/Tables";
import BlogBanner from "./Components/Ed_tech/Blog/Banner/Tables";
// import AboutBanner from "./Components/Ed_tech/AboutAs/Banner/Tables";
import AboutAdvantages from "./Components/Ed_tech/AboutAs/Advantages/Tables";
import AboutAboutas from "./Components/Ed_tech/AboutAs/About/Tables";

import BecomePartner from "./Components/Ed_tech/customer_management/become_partner/Tables";
import ContactUs from "./Components/Ed_tech/customer_management/contact_us/Tables";
import Ebrochure from "./Components/Ed_tech/customer_management/e_brochure/Tables";

//  Job Portal
//home
import JobPortalTestimonials from "./Components/Job_portal/Home/Testimonials/Tables";
import JobPortalHomeBanner from "./Components/Job_portal/Home/Banner/Tables";
import JobPortalHomeBannerTwo from "./Components/Job_portal/Home/BannerTwo/Tables";
import ScreeningQuestion from "./Components/Job_portal/Home/ScreeningQuestion/Table";
import AdditionalQuestion from "./Components/Job_portal/Home/AdditionalQuestion/Table";
import AdditionalQuestionAnswer from "./Components/Job_portal/Home/AdditionalQuestionAnswer/Table";
//blog
import BlogCareerOpportunities from "./Components/Job_portal/BlogData/Career_opportunitie/Tables";

//register
import JobPortalRegister from "./Components/Job_portal/customer_management/Users/Tables";
import JobPortalContactUs from "./Components/Job_portal/customer_management/contact_us/Tables";
import JobPortalRequestCallBack from "./Components/Job_portal/customer_management/request_call_back/Tables";
// import JobPortalEmployer from "./Components/Job_portal/customer_management/company_details/Tables";
import JobPortalJobs from "./Components/Job_portal/jobs/Tables";
import JobPortalCompany from "./Components/Job_portal/company/Tables";
import JobPortalJobSeeker from "./Components/Job_portal/customer_management/job_seeker/Tables";
import JobPortalPrivacyPolicy from "./Components/Job_portal/privacy_policy/Table";
import JobPortalPlan from "./Components/Job_portal/plan/Table";

import JobPortalAdvertisment from "./Components/Job_portal/advertisments/Tables";

// Finance

import FBanner from "./Components/Finance/Home/Banner/Table";
import FStandOut from "./Components/Finance/Home/F_StandOut/Table";
import FAdvantages from "./Components/Finance/Home/F_Advantages/Table";
import FClientSatisfaction from "./Components/Finance/Home/F_Client_Satisfaction/Table";
import FPartnerType from "./Components/Finance/Home/F_Partner_Types/Table";
import FOurPartner from "./Components/Finance/Home/F_Our_Partner/Table";

import FWhoWeAre from "./Components/Finance/About_Us/F_WhoWeAre/Table";
import FTeams from "./Components/Finance/About_Us/F_Team/Table";
import FCardFirst from "./Components/Finance/About_Us/F_CardFirst/Table";
import FCardSecond from "./Components/Finance/About_Us/F_CardSecond/Table";

import FBlogBanner from "./Components/Finance/F_Blogs/BlogBanner/Table";
import FBlogs from "./Components/Finance/F_Blogs/F_Blogs/Table";
import FPrivacyPolicy from "./Components/Finance/Privacy_Policy/PrivacyPolicy/Table";

import FServiceType from "./Components/Finance/Services/F_Service_Types/Table";
import FService from "./Components/Finance/Services/F_Service/Table";

import FLBanner from "./Components/Finance/Loan/L_Banner/Table";
import FLClientReviews from "./Components/Finance/Loan/L_clients_review/Table";
import FLProfcymaFinance from "./Components/Finance/Loan/L_Profcyma_finance/Table";
import FLWhoCanApply from "./Components/Finance/Loan/L_who_can_apply/Table";
import FLSteps from "./Components/Finance/Loan/L_steps/Table";

import FIBanner from "./Components/Finance/Insurance/I_Banner/Table";
import FIAdvantages from "./Components/Finance/Insurance/I_Advantages/Table";
import FIChooseProfcyma from "./Components/Finance/Insurance/I_choose_profcyma/Table";
import FIEligibility from "./Components/Finance/Insurance/I_Eligibility/Table";
import FIDocuments from "./Components/Finance/Insurance/I_Documants/Table";
import FITestimonial from "./Components/Finance/Insurance/I_Testimonial/Table";

import FBank from "./Components/Finance/Finance_Masters/Banks/Table";
import FEmploymentTyes from "./Components/Finance/Finance_Masters/Employment_Type/Table";
import FResidentTyes from "./Components/Finance/Finance_Masters/Resident_Type/Table";
import FYearlyIncome from "./Components/Finance/Finance_Masters/Yearly_Income/Table";
import FDesiredAmount from "./Components/Finance/Finance_Masters/Desired_Amount/Table";
import FCompanies from "./Components/Finance/Finance_Masters/F_Companies/Table";
import FCard from "./Components/Finance/Finance_Masters/Cards/Table";

import FInqeryForm from "./Components/Finance/customer_management/inquery_form/Tables";
import FFeedbackForm from "./Components/Finance/customer_management/feedback_forms/Tables";
import FInsuranceForm from "./Components/Finance/customer_management/insurance_form/Tables";
import FLoanForm from "./Components/Finance/customer_management/loan_form/Tables";
import FCreditScoreForm from "./Components/Finance/customer_management/creditScore_forms/Tables";
import FCreditCardEligibility from "./Components/Finance/customer_management/creditCard_elegibility/Tables";
import LoanApplication from "./Components/Finance/customer_management/loan_application/Tables";


import EDTechBecomePartneBanner from "./Components/Ed_tech/customer_management/Become_Partner_Banner/Tables";
import EDTechAssociates from "./Components/Ed_tech/customer_management/Our_associates/Tables";
import EDTechInqueries from "./Components/Ed_tech/customer_management/inqueries/Tables";
import AddmissionForm from "./Components/Ed_tech/customer_management/add_reg_form/Tables";

import SpecializationBanner from "./Components/Ed_tech/Specialization/Banner/Table";
import SpecializationWhyChoose from "./Components/Ed_tech/Specialization/WhyChoose/Table";
import SpecializationEligibility from "./Components/Ed_tech/Specialization/Eligibility/Table";
import SpecializationHighlights from "./Components/Ed_tech/Specialization/Highlights/Table";
import SpecializationSyllabus from "./Components/Ed_tech/Specialization/Syllabus/Table";
import SpecializationWithOnline from "./Components/Ed_tech/Specialization/WithOnline/Table";
import SpecializationFooterImage from "./Components/Ed_tech/Specialization/FooterImage/Table";

const App = () => {
  const location = useLocation();
  const { isSidebarOpen } = useContext(Context);
  const [headerText, setHeaderText] = useState(location.pathname);

  const isLoginPage = location.pathname === "/";

  useEffect(() => {
    setHeaderText(location.pathname);
  });
  return (
    <div
      className={`main ${isSidebarOpen ? "sidebar-open" : "sidebar-closed"}`}
    >
      {isLoginPage ? (
        <Login />
      ) : (
        <div className="row sidebar-x-scroll">
          <div
            className={`${isSidebarOpen
              ? "col-xl-2 col-lg-2 col-md-3"
              : "scale-in-hor-left col-xl-1 col-lg-1 col-md-1"
              }  pe-0`}
          >
            <Sidebar />
          </div>
          <div
            className={`${isSidebarOpen
              ? "col-xl-10 col-md-9  col-lg-10"
              : "scale-in-hor-right  col-xl-11 col-md-11"
              }  ps-0`}
          >
            <div className="allRoutesMain">
              <Routes>
                <Route path="/" element={<Login />} />
                <Route path="/Header" element={<Header />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route
                  path="/advanceDashboard"
                  element={<AdvanceDashboard />}
                />
                <Route
                  path="/recruiters/contact_us_list"
                  element={<ContactUsList />}
                />
                <Route path="/settingContent" element={<SettingContent />} />
                <Route
                  path="masters/ed-tech/course/pg-course/mba/banner"
                  element={<MBABanner />}
                />
                <Route
                  path="masters/ed-tech/course/pg-course/mba/what"
                  element={<MBAWhat />}
                />
                <Route
                  path="/masters/ed-tech/course/pg-course/mba/reasons"
                  element={<MBAReasons />}
                />
                <Route
                  path="masters/ed-tech/course/pg-course/mba/discover"
                  element={<MBADiscover />}
                />
                <Route
                  path="/masters/ed-tech/course/pg-course/mba/advantages"
                  element={<MBAAdvantages />}
                />
                <Route
                  path="/masters/ed-tech/course/pg-course/mba/who_qualified"
                  element={<MBAWhoQualified />}
                />
                {/* <Route path="/settingContent" element={<SettingContent />} /> */}
                <Route
                  path="masters/ed-tech/course/pg-course/mca/banner"
                  element={<MCABanner />}
                />
                <Route
                  path="masters/ed-tech/course/pg-course/mca/what"
                  element={<MCAWhat />}
                />
                <Route
                  path="/masters/ed-tech/course/pg-course/mca/reasons"
                  element={<MCAReasons />}
                />
                <Route
                  path="masters/ed-tech/course/pg-course/mca/discover"
                  element={<MCADiscover />}
                />
                <Route
                  path="/masters/ed-tech/course/pg-course/mca/advantages"
                  element={<MCAAdvantages />}
                />
                <Route
                  path="/masters/ed-tech/course/pg-course/mca/who_qualified"
                  element={<MCAWhoQualified />}
                />
                <Route
                  path="masters/ed-tech/course/pg-course/mcom/banner"
                  element={<MComBanner />}
                />
                <Route
                  path="masters/ed-tech/course/pg-course/mcom/what"
                  element={<MComWhat />}
                />
                <Route
                  path="/masters/ed-tech/course/pg-course/mcom/reasons"
                  element={<MComReasons />}
                />
                <Route
                  path="masters/ed-tech/course/pg-course/mcom/discover"
                  element={<MComDiscover />}
                />
                <Route
                  path="/masters/ed-tech/course/pg-course/mcom/advantages"
                  element={<MComAdvantages />}
                />
                <Route
                  path="/masters/ed-tech/course/pg-course/mcom/who_qualified"
                  element={<MComWhoQualified />}
                />
                {/* <Route path="/edTech" element={<EdTech />} /> */}
                <Route
                  path="masters/ed-tech/course/pg-course/ma/banner"
                  element={<MABanner />}
                />
                <Route
                  path="masters/ed-tech/course/pg-course/ma/what"
                  element={<MAWhat />}
                />
                <Route
                  path="/masters/ed-tech/course/pg-course/ma/reasons"
                  element={<MAReasons />}
                />
                <Route
                  path="masters/ed-tech/course/pg-course/ma/discover"
                  element={<MADiscover />}
                />
                <Route
                  path="/masters/ed-tech/course/pg-course/ma/advantages"
                  element={<MAAdvantages />}
                />
                <Route
                  path="/masters/ed-tech/course/pg-course/ma/who_qualified"
                  element={<MAWhoQualified />}
                />
                <Route
                  path="/masters/ed-tech/course/pg-course/ma/video"
                  element={<MAVideo />}
                />
                <Route
                  path="masters/ed-tech/course/pg-course/mlib/banner"
                  element={<MLIBBanner />}
                />
                <Route
                  path="masters/ed-tech/course/pg-course/mlib/what"
                  element={<MLIBWhat />}
                />
                <Route
                  path="/masters/ed-tech/course/pg-course/mlib/reasons"
                  element={<MLIBReasons />}
                />{" "}
                SC
                <Route
                  path="/masters/ed-tech/course/pg-course/mlib/who_qualified"
                  element={<MLIBWhoQualified />}
                />
                <Route
                  path="masters/ed-tech/course/pg-course/msc/banner"
                  element={<MSCBanner />}
                />
                <Route
                  path="masters/ed-tech/course/pg-course/msc/what"
                  element={<MSCWhat />}
                />
                <Route
                  path="/masters/ed-tech/course/pg-course/msc/reasons"
                  element={<MSCReasons />}
                />
                <Route
                  path="/masters/ed-tech/course/pg-course/msc/who_qualified"
                  element={<MSCWhoQualified />}
                />
                <Route
                  path="/masters/ed-tech/course/ug-courses/bba/banner"
                  element={<BbaBanner />}
                />
                <Route
                  path="/masters/ed-tech/course/ug-courses/bba/advantages"
                  element={<BbaAdvantages />}
                />
                <Route
                  path="/masters/ed-tech/course/ug-courses/bba/discover"
                  element={<BbaDiscover />}
                />
                <Route
                  path="/masters/ed-tech/course/ug-courses/bba/reasons"
                  element={<BbaReasons />}
                />
                <Route
                  path="/masters/ed-tech/course/ug-courses/bba/what_is"
                  element={<BbaWhatIs />}
                />
                <Route
                  path="/masters/ed-tech/course/ug-courses/bba/who_qualified"
                  element={<BbaWhoQualified />}
                />
                <Route
                  path="/masters/ed-tech/course/ug-courses/bca/banner"
                  element={<BcaBanner />}
                />
                <Route
                  path="/masters/ed-tech/course/ug-courses/bca/advantages"
                  element={<BcaAdvantages />}
                />
                <Route
                  path="/masters/ed-tech/course/ug-courses/bca/discover"
                  element={<BcaDiscover />}
                />
                <Route
                  path="/masters/ed-tech/course/ug-courses/bca/reasons"
                  element={<BcaReasons />}
                />
                <Route
                  path="/masters/ed-tech/course/ug-courses/bca/what_is"
                  element={<BcaWhatIs />}
                />
                <Route
                  path="/masters/ed-tech/course/ug-courses/bca/who_qualified"
                  element={<BcaWhoQualified />}
                />
                <Route
                  path="/masters/ed-tech/course/ug-courses/bcom/banner"
                  element={<BcomBanner />}
                />
                <Route
                  path="/masters/ed-tech/course/ug-courses/bcom/advantages"
                  element={<BcomAdvantages />}
                />
                <Route
                  path="/masters/ed-tech/course/ug-courses/bcom/discover"
                  element={<BcomDiscover />}
                />
                <Route
                  path="/masters/ed-tech/course/ug-courses/bcom/reasons"
                  element={<BcomReasons />}
                />
                <Route
                  path="/masters/ed-tech/course/ug-courses/bcom/what_is"
                  element={<BcomWhatIs />}
                />
                <Route
                  path="/masters/ed-tech/course/ug-courses/bcom/who_qualified"
                  element={<BcomWhoQualified />}
                />
                <Route
                  path="/masters/ed-tech/course/ug-courses/bsc/banner"
                  element={<BscBanner />}
                />
                <Route
                  path="/masters/ed-tech/course/ug-courses/bsc/advantages"
                  element={<BscAdvantages />}
                />
                <Route
                  path="/masters/ed-tech/course/ug-courses/bsc/discover"
                  element={<BscDiscover />}
                />
                <Route
                  path="/masters/ed-tech/course/ug-courses/bsc/reasons"
                  element={<BscReasons />}
                />
                <Route
                  path="/masters/ed-tech/course/ug-courses/bsc/what_is"
                  element={<BscWhatIs />}
                />
                <Route
                  path="/masters/ed-tech/course/ug-courses/bsc/who_qualified"
                  element={<BscWhoQualified />}
                />
                <Route
                  path="/masters/ed-tech/course/ug-courses/blib/banner"
                  element={<BlibBanner />}
                />
                <Route
                  path="/masters/ed-tech/course/ug-courses/blib/reasons"
                  element={<BlibReasons />}
                />
                <Route
                  path="/masters/ed-tech/course/ug-courses/blib/what_is"
                  element={<BlibWhatIs />}
                />
                <Route
                  path="/masters/ed-tech/course/ug-courses/blib/who_qualified"
                  element={<BlibWhoQualified />}
                />
                <Route
                  path="/masters/ed-tech/course/ug-courses/ba/banner"
                  element={<BaBanner />}
                />
                <Route
                  path="/masters/ed-tech/course/ug-courses/ba/reasons"
                  element={<BaReasons />}
                />
                <Route
                  path="/masters/ed-tech/course/ug-courses/ba/what_is"
                  element={<BaWhatIs />}
                />
                <Route
                  path="/masters/ed-tech/course/ug-courses/ba/who_qualified"
                  element={<BaWhoQualified />}
                />
                {/* CERTIFICATES */}
                <Route
                  path="/masters/ed-tech/course/certification/diploma/banner"
                  element={<CertificateBanner />}
                />
                <Route
                  path="/masters/ed-tech/course/certification/diploma/online-diploma"
                  element={<CertificateOnlineDiploma />}
                />
                <Route
                  path="/masters/ed-tech/course/certification/diploma/top-reason"
                  element={<CertificateTopReason />}
                />
                <Route
                  path="/masters/ed-tech/course/certification/diploma/discover-the-power"
                  element={<CertificateDiscoverThePower />}
                />
                <Route
                  path="/masters/ed-tech/course/certification/diploma/who-qualified"
                  element={<CertificateWhoQualified />}
                />
                <Route
                  path="/masters/ed-tech/course/certification/diploma/section-two"
                  element={<CertificateOnlineDiplomaSectionTwo />}
                />
                <Route
                  path="/masters/ed-tech/course/certification/diploma/video"
                  element={<CertificateOnlineDiplomaVideo />}
                />
                {/* COMMON Course */}
                <Route
                  path="/masters/ed-tech/course/common/banner"
                  element={<DiplomaCommonBanner />}
                />
                <Route
                  path="/masters/ed-tech/course/common/small-banner"
                  element={<SmallBanner />}
                />
                <Route
                  path="/masters/ed-tech/course/common/what"
                  element={<CommonWhat />}
                />
                <Route
                  path="/masters/ed-tech/course/common/reason"
                  element={<CommonReason />}
                />
                <Route
                  path="/masters/ed-tech/course/common/who-qualified"
                  element={<CommonWhoQualified />}
                />
                <Route
                  path="/masters/ed-tech/course/common/discover"
                  element={<CoursesDiscover />}
                />
                <Route
                  path="/masters/ed-tech/course/common/advantages"
                  element={<CoursesAdvantages />}
                />
                {/* Admission */}
                <Route
                  path="/masters/ed-tech/admission/banner"
                  element={<AdmissionBanner />}
                />
                <Route
                  path="/masters/ed-tech/admission/document-for-admission"
                  element={<DocumentForAdmission />}
                />
                <Route
                  path="/masters/ed-tech/admission/policy"
                  element={<Policy />}
                />
                <Route
                  path="/masters/ed-tech/admission/registration-process"
                  element={<RegistrationProcess />}
                />
                <Route
                  path="/masters/ed-tech/admission/steps"
                  element={<Steps />}
                />
                {/* HOME */}
                <Route
                  path="/masters/ed-tech/home/banner"
                  element={<HomeBanner />}
                />
                <Route
                  path="/masters/ed-tech/home/media"
                  element={<HomeMedia />}
                />
                <Route
                  path="/masters/ed-tech/home/online-learning"
                  element={<OnlineLearning />}
                />
                <Route
                  path="/masters/ed-tech/home/premier-universities"
                  element={<PremierUniversities />}
                />
                <Route
                  path="/masters/ed-tech/home/alumni-network"
                  element={<AlumniNetwork />}
                />
                <Route
                  path="/masters/ed-tech/home/popular-course"
                  element={<PopularCourse />}
                />
                <Route
                  path="/masters/ed-tech/home/profcyma-assistance"
                  element={<ProfcymaAssistance />}
                />
                <Route path="/masters/ed-tech/home/faq" element={<FAQ />} />
                <Route
                  path="/masters/ed-tech/home/faq-subtype"
                  element={<FAQSubtype />}
                />
                <Route
                  path="/masters/ed-tech/home/who-we-are"
                  element={<WhoWeAre />}
                />
                <Route
                  path="/masters/ed-tech/home/stand-out"
                  element={<StandOut />}
                />
                <Route path="/masters/ed-tech/home/video" element={<Video />} />
                <Route
                  path="/masters/ed-tech/home/popular-news"
                  element={<PopularNews />}
                />
                <Route
                  path="/masters/ed-tech/home/fee-structure"
                  element={<FeeStructure />}
                />
                <Route
                  path="/masters/ed-tech/home/right-guidence"
                  element={<RightGuidance />}
                />
                <Route
                  path="/masters/ed-tech/home/address"
                  element={<EdTechAddress />}
                />
                <Route
                  path="/masters/ed-tech/home/our-alumni"
                  element={<OurAlumni />}
                />
                {/* UNIVERSITY */}
                {/* AMITY */}
                <Route
                  path="/masters/ed-tech/university/amity/banner"
                  element={<AmityBanner />}
                />
                <Route
                  path="/masters/ed-tech/university/amity/faq"
                  element={<AmityFAQ />}
                />
                <Route
                  path="/masters/ed-tech/university/amity/achievement"
                  element={<AmityAchievement />}
                />
                <Route
                  path="/masters/ed-tech/university/amity/advantages-of-taking-admission"
                  element={<AmityAdvantageOfTakingAdmission />}
                />
                <Route
                  path="/masters/ed-tech/university/amity/hiring-partner"
                  element={<AmityHiringPartner />}
                />
                <Route
                  path="/masters/ed-tech/university/amity/key-program-features"
                  element={<KeyProgramFeatures />}
                />
                <Route
                  path="/masters/ed-tech/university/amity/program-offered"
                  element={<ProgramOffered />}
                />
                <Route
                  path="/masters/ed-tech/university/amity/stagetwo"
                  element={<StageTwo />}
                />
                <Route
                  path="/masters/ed-tech/university/amity/banner"
                  element={<AmityBanner />}
                />
                <Route
                  path="/masters/ed-tech/university/amity/two"
                  element={<AmityBannerTwo />}
                />
                <Route
                  path="/masters/ed-tech/university/amity/we-deliver"
                  element={<AmityWeDeliver />}
                />
                {/* Common */}
                <Route
                  path="/masters/ed-tech/university/common/banner"
                  element={<CommonBanner />}
                />
                <Route
                  path="/masters/ed-tech/university/common/small-banner"
                  element={<UnivSmallBanner />}
                />
                <Route
                  path="/masters/ed-tech/university/common/faq"
                  element={<CommonFAQ />}
                />
                <Route
                  path="/masters/ed-tech/university/common/achievement"
                  element={<CommonAchievement />}
                />
                <Route
                  path="/masters/ed-tech/university/common/advantages-of-taking-admission"
                  element={<CommonAdvantageOfTakingAdmission />}
                />
                <Route
                  path="/masters/ed-tech/university/common/hiring-partner"
                  element={<CommonHiringPartner />}
                />
                <Route
                  path="/masters/ed-tech/university/common/key-program-features"
                  element={<CommonKeyProgramFeatures />}
                />
                <Route
                  path="/masters/ed-tech/university/common/program-offered"
                  element={<CommonProgramOffered />}
                />
                <Route
                  path="/masters/ed-tech/university/common/stagetwo"
                  element={<CommonStageTwo />}
                />
                <Route
                  path="/masters/ed-tech/university/common/banner"
                  element={<CommonBanner />}
                />
                <Route
                  path="/masters/ed-tech/university/common/two"
                  element={<CommonBannerTwo />}
                />
                <Route
                  path="/masters/ed-tech/university/common/we-deliver"
                  element={<CommonWeDeliver />}
                />
                <Route
                  path="/masters/ed-tech/university/common/program-offered"
                  element={<CommonProgramOffered />}
                />
                <Route
                  path="/masters/ed-tech/university/common/stagetwo"
                  element={<CommonStageTwo />}
                />
                <Route
                  path="/masters/ed-tech/university/common/banner"
                  element={<CommonBanner />}
                />
                <Route
                  path="/masters/ed-tech/university/common/two"
                  element={<CommonBannerTwo />}
                />
                <Route
                  path="/masters/ed-tech/university/common/we-deliver"
                  element={<CommonWeDeliver />}
                />
                <Route
                  path="/masters/ed-tech/university/common/alumani"
                  element={<CommonAlumani />}
                />
                <Route
                  path="/masters/ed-tech/university/common/alumani-image"
                  element={<CommonAlumaniImage />}
                />
                <Route
                  path="/masters/ed-tech/university/common/barrier"
                  element={<CommonBarrier />}
                />
                <Route
                  path="/masters/ed-tech/university/common/future"
                  element={<CommonFuture />}
                />
                <Route
                  path="/masters/ed-tech/university/common/section-two"
                  element={<CommonSectionTwo />}
                />
                <Route
                  path="/masters/ed-tech/university/common/student-benefit"
                  element={<CommonStudentBenefit />}
                />
                <Route
                  path="/masters/ed-tech/university/common/about-banner"
                  element={<AboutBanner />}
                />
                {/* D Y Patil */}
                <Route
                  path="/masters/ed-tech/university/dy-patil/banner"
                  element={<DYPatilBanner />}
                />
                <Route
                  path="/masters/ed-tech/university/dy-patil/advantage-of-taking-admission"
                  element={<DYPatilAdvantageOfTakingAdmission />}
                />
                <Route
                  path="/masters/ed-tech/university/dy-patil/achievement"
                  element={<DYPatilAchievement />}
                />
                <Route
                  path="/masters/ed-tech/university/dy-patil/alumni-testimonal"
                  element={<DYPatilAlumniTestimonal />}
                />
                <Route
                  path="/masters/ed-tech/university/dy-patil/faq"
                  element={<DYPatilAlumniFAQ />}
                />
                <Route
                  path="/masters/ed-tech/university/dy-patil/hiring-partner"
                  element={<DYPatilHiringPartner />}
                />
                <Route
                  path="/masters/ed-tech/university/dy-patil/key-program-features"
                  element={<DYPatilKeyProgramFeatures />}
                />
                <Route
                  path="/masters/ed-tech/university/dy-patil/program-offered"
                  element={<DYPatilProgramOffered />}
                />
                <Route
                  path="/masters/ed-tech/university/dy-patil/student-benefits"
                  element={<DYPatilStudentBenefits />}
                />
                <Route
                  path="/masters/ed-tech/university/dy-patil/sectiontwo"
                  element={<DYPatilSectionTwo />}
                />
                <Route
                  path="/masters/ed-tech/university/dy-patil/two"
                  element={<DYPatilBannerTwo />}
                />
                <Route
                  path="/masters/ed-tech/university/dy-patil/future"
                  element={<DYPatilFuture />}
                />
                <Route
                  path="/masters/ed-tech/university/dy-patil/barriers"
                  element={<DYPatilBarriers />}
                />
                <Route
                  path="/masters/ed-tech/university/dy-patil/alumni-testimonals"
                  element={<DYPatilAlumniTestimonalImage />}
                />
                {/* BHARTI VIDYAPEETH */}
                <Route
                  path="/masters/ed-tech/university/barati-vidyapeeth/banner"
                  element={<BhartiVidyaPeethBanner />}
                />
                <Route
                  path="/masters/ed-tech/university/barati-vidyapeeth/faq"
                  element={<BhartiVidyaPeethFAQ />}
                />
                <Route
                  path="/masters/ed-tech/university/barati-vidyapeeth/hiring-partner"
                  element={<BhartiVidyaPeethHiringPartner />}
                />
                <Route
                  path="/masters/ed-tech/university/barati-vidyapeeth/key-program-features"
                  element={<BhartiVidyaPeethKeyProgramFeatures />}
                />
                <Route
                  path="/masters/ed-tech/university/barati-vidyapeeth/program-offered"
                  element={<BhartiVidyaPeethProgramOffered />}
                />
                <Route
                  path="/masters/ed-tech/university/barati-vidyapeeth/section-two"
                  element={<BhartiVidyaPeethSectionTwo />}
                />
                <Route
                  path="/masters/ed-tech/university/barati-vidyapeeth/we-deliver"
                  element={<BhartiVidyaPeethWeDeliver />}
                />
                <Route
                  path="/masters/ed-tech/university/barati-vidyapeeth/advantage-of-taking-admission"
                  element={<BhartiVidyaPeethAdvantageOfTakingAdmission />}
                />
                <Route
                  path="/masters/ed-tech/university/barati-vidyapeeth/two"
                  element={<BhartiVidyaPeethBannerTwo />}
                />
                {/* BLOG */}
                <Route
                  path="/masters/ed-tech/blog/banner"
                  element={<BlogBanner />}
                />
                <Route
                  path="/masters/ed-tech/blog/blog-category"
                  element={<BlogCategory />}
                />

                <Route path="/masters/ed-tech/blog/blogs" element={<Blog />} />
                <Route
                  path="/masters/ed-tech/customer-management/become-partner"
                  element={<BecomePartner />}
                />
                <Route
                  path="/masters/ed-tech/customer-management/contact-us"
                  element={<ContactUs />}
                />
                <Route
                  path="/masters/ed-tech/customer-management/inqueries"
                  element={<EDTechInqueries />}
                />
                <Route
                  path="/masters/ed-tech/customer-management/e-brochure"
                  element={<Ebrochure />}
                />
                <Route
                  path="/masters/ed-tech/customer-management/add-reg-form"
                  element={<AddmissionForm />}
                />
                {/* <Route path="masters/ed-tech/course/pg-course/ma/banner" element={<MABanner />} />
                <Route path="masters/ed-tech/course/pg-course/ma/what" element={<MAWhat />} />
                <Route path="/masters/ed-tech/course/pg-course/ma/reasons" element={<MAReasons />} />
                <Route path="masters/ed-tech/course/pg-course/ma/discover" element={<MADiscover />} />
                <Route path="/masters/ed-tech/course/pg-course/ma/advantages" element={<MAAdvantages />} />
                <Route path="/masters/ed-tech/course/pg-course/ma/who_qualified" element={<MAWhoQualified />} />

                <Route path="masters/ed-tech/course/pg-course/mlib/banner" element={<MLIBBanner />} />
                <Route path="masters/ed-tech/course/pg-course/mlib/what" element={<MLIBWhat />} />
                <Route path="/masters/ed-tech/course/pg-course/mlib/reasons" element={<MLIBReasons />} />            SC
                <Route path="/masters/ed-tech/course/pg-course/mlib/who_qualified" element={<MLIBWhoQualified />} />

                <Route path="masters/ed-tech/course/pg-course/msc/banner" element={<MSCBanner />} />
                <Route path="masters/ed-tech/course/pg-course/msc/what" element={<MSCWhat />} />
                <Route path="/masters/ed-tech/course/pg-course/msc/reasons" element={<MSCReasons />} />
                <Route path="/masters/ed-tech/course/pg-course/msc/who_qualified" element={<MSCWhoQualified />} />

                <Route path="/masters/ed-tech/course/ug-courses/bba/banner" element={<BbaBanner />} />
                <Route path="/masters/ed-tech/course/ug-courses/bba/advantages" element={<BbaAdvantages />} />
                <Route path="/masters/ed-tech/course/ug-courses/bba/discover" element={<BbaDiscover />} />
                <Route path="/masters/ed-tech/course/ug-courses/bba/reasons" element={<BbaReasons />} />
                <Route path="/masters/ed-tech/course/ug-courses/bba/what_is" element={<BbaWhatIs />} />

                <Route path="/masters/ed-tech/about-as/about" element={<AboutAboutas />} />
                <Route path="/masters/ed-tech/about-as/advantages" element={<AboutAdvantages />} />
                <Route path="/masters/ed-tech/about-as/banner" element={<AboutBanner />} /> */}
                {/* MA */}
                <Route
                  path="masters/ed-tech/course/pg-course/ma/banner"
                  element={<MABanner />}
                />
                <Route
                  path="masters/ed-tech/course/pg-course/ma/what"
                  element={<MAWhat />}
                />
                <Route
                  path="/masters/ed-tech/course/pg-course/ma/reasons"
                  element={<MAReasons />}
                />
                <Route
                  path="masters/ed-tech/course/pg-course/ma/discover"
                  element={<MADiscover />}
                />
                <Route
                  path="/masters/ed-tech/course/pg-course/ma/advantages"
                  element={<MAAdvantages />}
                />
                <Route
                  path="/masters/ed-tech/course/pg-course/ma/who_qualified"
                  element={<MAWhoQualified />}
                />
                <Route
                  path="masters/ed-tech/course/pg-course/mlib/banner"
                  element={<MLIBBanner />}
                />
                <Route
                  path="masters/ed-tech/course/pg-course/mlib/what"
                  element={<MLIBWhat />}
                />
                <Route
                  path="/masters/ed-tech/course/pg-course/mlib/reasons"
                  element={<MLIBReasons />}
                />{" "}
                <Route
                  path="/masters/ed-tech/course/pg-course/mlib/who_qualified"
                  element={<MLIBWhoQualified />}
                />
                <Route
                  path="masters/ed-tech/course/pg-course/msc/banner"
                  element={<MSCBanner />}
                />
                <Route
                  path="masters/ed-tech/course/pg-course/msc/what"
                  element={<MSCWhat />}
                />
                <Route
                  path="/masters/ed-tech/course/pg-course/msc/reasons"
                  element={<MSCReasons />}
                />
                <Route
                  path="/masters/ed-tech/course/pg-course/msc/who_qualified"
                  element={<MSCWhoQualified />}
                />
                <Route
                  path="/masters/ed-tech/course/ug-courses/bba/banner"
                  element={<BbaBanner />}
                />
                <Route
                  path="/masters/ed-tech/course/ug-courses/bba/advantages"
                  element={<BbaAdvantages />}
                />
                <Route
                  path="/masters/ed-tech/course/ug-courses/bba/discover"
                  element={<BbaDiscover />}
                />
                <Route
                  path="/masters/ed-tech/course/ug-courses/bba/reasons"
                  element={<BbaReasons />}
                />
                <Route
                  path="/masters/ed-tech/course/ug-courses/bba/what_is"
                  element={<BbaWhatIs />}
                />
                {/* ABOUT US */}
                <Route
                  path="/masters/ed-tech/about-as/about"
                  element={<AboutAboutas />}
                />
                <Route
                  path="/masters/ed-tech/about-as/advantages"
                  element={<AboutAdvantages />}
                />
                <Route
                  path="/masters/ed-tech/about-as/banner"
                  element={<AboutBanner />}
                />
                {/* {Job Portals} */}
                {/* {Job Portals Home} */}
                <Route
                  path="/masters/job-portal/home/testimonials"
                  element={<JobPortalTestimonials />}
                />
                <Route
                  path="/masters/job-portal/home/banner-two"
                  element={<JobPortalHomeBannerTwo />}
                />
                <Route
                  path="/masters/job-portal/home/banner"
                  element={<JobPortalHomeBanner />}
                />
                <Route
                  path="/masters/job-portal/home/screening-question"
                  element={<ScreeningQuestion />}
                />
                <Route
                  path="/masters/job-portal/home/additional-question"
                  element={<AdditionalQuestion />}
                />
                <Route
                  path="/masters/job-portal/home/answer-additional-question"
                  element={<AdditionalQuestionAnswer />}
                />
                {/* {Job Portals blog} */}
                <Route
                  path="/masters/job-portal/blog/career-opportunities"
                  element={<BlogCareerOpportunities />}
                />
                {/* {Job Portals customer management} */}
                <Route
                  path="/masters/job-portal/customer-management/users"
                  element={<JobPortalRegister />}
                />
                <Route
                  path="/masters/job-portal/customer-management/contact-us"
                  element={<JobPortalContactUs />}
                />
                <Route
                  path="/masters/job-portal/customer-management/request-call-back"
                  element={<JobPortalRequestCallBack />}
                />
                <Route
                  path="/masters/job-portal/customer-management/company-details"
                  element={<JobPortalCompany />}
                />

                <Route
                  path="/masters/job-portal/customer-management/job-seekers/details"
                  element={<JobPortalJobSeeker />}
                />
                <Route
                  path="/masters/job-portal/customer-management/employer/jobs"
                  element={<JobPortalJobs />}
                />
                <Route
                  path="/masters/job-portal/privacy-policy"
                  element={<JobPortalPrivacyPolicy />}
                />
                <Route
                  path="/masters/job-portal/plan"
                  element={<JobPortalPlan />}
                />
                <Route
                  path="/masters/job-portal/advertisment"
                  element={<JobPortalAdvertisment />}
                />
                <Route path="/finance/home/banner" element={<FBanner />} />
                <Route path="/finance/home/stand-out" element={<FStandOut />} />
                <Route
                  path="/finance/home/advantages"
                  element={<FAdvantages />}
                />
                <Route
                  path="/finance/home/client-satisfaction"
                  element={<FClientSatisfaction />}
                />
                <Route
                  path="/finance/home/partner-type"
                  element={<FPartnerType />}
                />
                <Route
                  path="/finance/home/our-partner"
                  element={<FOurPartner />}
                />
                <Route
                  path="/finance/about-us/whoweare"
                  element={<FWhoWeAre />}
                />
                <Route path="/finance/about-us/f-teams" element={<FTeams />} />
                <Route
                  path="/finance/about-us/card_first"
                  element={<FCardFirst />}
                />
                <Route
                  path="/finance/about-us/card_second"
                  element={<FCardSecond />}
                />
                <Route
                  path="/finance/blog/blog-banner"
                  element={<FBlogBanner />}
                />
                <Route path="/finance/blog/blogs" element={<FBlogs />} />
                <Route
                  path="/finance/privacy/policy"
                  element={<FPrivacyPolicy />}
                />
                <Route
                  path="/finance/service/service-type"
                  element={<FServiceType />}
                />
                <Route
                  path="/finance/service/services"
                  element={<FService />}
                />
                <Route path="/finance/loan/banner" element={<FLBanner />} />
                <Route path="/finance/loan/steps" element={<FLSteps />} />
                <Route
                  path="/finance/loan/client-reviews"
                  element={<FLClientReviews />}
                />
                <Route
                  path="/finance/loan/profcyma-finance"
                  element={<FLProfcymaFinance />}
                />
                <Route
                  path="/finance/loan/whocanapply"
                  element={<FLWhoCanApply />}
                />
                <Route
                  path="/finance/insurance/banner"
                  element={<FIBanner />}
                />
                <Route
                  path="/finance/insurance/advantages"
                  element={<FIAdvantages />}
                />
                <Route
                  path="/finance/insurance/choose-profcyma"
                  element={<FIChooseProfcyma />}
                />
                <Route
                  path="/finance/insurance/eligibility"
                  element={<FIEligibility />}
                />
                <Route
                  path="/finance/insurance/documents"
                  element={<FIDocuments />}
                />
                <Route
                  path="/finance/insurance/testimonial"
                  element={<FITestimonial />}
                />
                <Route path="/finance/masters/banks" element={<FBank />} />
                <Route path="/finance/masters/cards" element={<FCard />} />
                <Route
                  path="/finance/masters/employment-types"
                  element={<FEmploymentTyes />}
                />
                <Route
                  path="/finance/masters/residence-types"
                  element={<FResidentTyes />}
                />
                <Route
                  path="/finance/masters/yearly-income"
                  element={<FYearlyIncome />}
                />
                <Route
                  path="/finance/masters/desired-amount"
                  element={<FDesiredAmount />}
                />
                <Route
                  path="/finance/masters/f-companies"
                  element={<FCompanies />}
                />
                <Route
                  path="/finance/customer-management/inqury-form"
                  element={<FInqeryForm />}
                />
                <Route
                  path="/finance/customer-management/feedback-form"
                  element={<FFeedbackForm />}
                />
                <Route
                  path="/finance/customer-management/insurance-form"
                  element={<FInsuranceForm />}
                />
                <Route
                  path="/finance/customer-management/loan-form"
                  element={<FLoanForm />}
                />
                <Route
                  path="/finance/customer-management/credit-score-form"
                  element={<FCreditScoreForm />}
                />
                <Route
                  path="/finance/customer-management/credit-card-eligibility"
                  element={<FCreditCardEligibility />}
                />
                <Route
                  path="/finance/customer-management/loan-application"
                  element={<LoanApplication />}
                />
                <Route
                  path="/edtech-becomepartner-banner"
                  element={<EDTechBecomePartneBanner />}
                />
                <Route
                  path="/edtech-cusomer-managment-associates"
                  element={<EDTechAssociates />}
                />
                <Route
                  path="/edtech/specialization/banner"
                  element={<SpecializationBanner />}
                />
                <Route
                  path="/edtech/specialization/why-choose"
                  element={<SpecializationWhyChoose />}
                />
                <Route
                  path="/edtech/specialization/eligibility"
                  element={<SpecializationEligibility />}
                />
                <Route
                  path="/edtech/specialization/highlights"
                  element={<SpecializationHighlights />}
                />
                <Route
                  path="/edtech/specialization/syllabus"
                  element={<SpecializationSyllabus />}
                />
                <Route
                  path="/edtech/specialization/with-online"
                  element={<SpecializationWithOnline />}
                />
                <Route
                  path="/edtech/specialization/footer-image"
                  element={<SpecializationFooterImage />}
                />
              </Routes>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default App;
