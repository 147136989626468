import React, { useContext } from "react";
import { useState, useEffect, useRef } from "react";
import "../../../../Tabels/Tabels.css";
import { Link } from "react-router-dom";
import { Context } from "../../../../../utils/context";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import Offcanvas from "react-bootstrap/Offcanvas";
// *******************toster****************************
import Card from "react-bootstrap/Card";
import ModalSave from "../../../../common/ModelSave";
import Validation from "../../../../common/FormValidation";
import { CancelButton, SaveButton } from "../../../../common/Button";
import Modal from "react-bootstrap/Modal";
import { Container, Row, Col, Button, Form, InputGroup } from "react-bootstrap";
import JoditEditor from "jodit-react";
import { useForm, Controller } from "react-hook-form";
import classNames from "classnames";
library.add(fas);

const EditOffCanvance = (props) => {
  const { postData, getData, IMG_URL, getDimension } = useContext(Context);

  const id = props.show;
  // const [data, setData] = useState();

  // const editor = useRef(null);
  // const [formData, setFormData] = useState({
  //   name: "",
  //   image: "",
  // });
  // const [imagePreview, setImagePreview] = useState(null);
  // const [content, setContent] = useState("");
  // const [errors, setErrors] = useState();
  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });
  // const handleChange = async (e) => {
  //   if (e.target.name === "image") {
  //     const data = new FormData();
  //     data.append("image", e.target.files[0]);
  //     data.append("width", 313);
  //     data.append("height", 252);
  //     const checkimage = await postData("/image", data);

  //     if (!checkimage?.success) {
  //       await setError({ image: checkimage?.message });
  //     } else {
  //       const newFormData = {
  //         ...formData,
  //         image: e.target.files[0],
  //         imageUrl: URL.createObjectURL(e.target.files[0]),
  //       };
  //       await setError({ image: null });
  //       await setFormData(newFormData);
  //     }
  //   } else {
  //     setFormData({ ...formData, [e.target.name]: e.target.value });
  //   }
  // };
  // const [error, setError] = useState({
  //   image: null,
  // });

  // const validateForm = () => {
  //   let errors = {};
  //   const regexNumeric = /^[0-9]+$/;
  //   const regexAlfaNumric = /^[A-Za-z0-9]+(\s[A-Za-z0-9]+)*$/;
  //   const regexAlfa = /^[A-Za-z]+(\s[A-Za-z]+)*$/;

  //   if (!formData.name) {
  //     errors.name = "name is required";
  //   }
  //   if (!formData.image) {
  //     errors.image = "Image is required";
  //   }

  //   // Add more validations as needed

  //   return errors;
  // };

  // const errorStyle = {
  //   color: "red",
  //   marginLeft: "5px",
  //   // fontSize: "11px",
  // };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();

  //   const validationErrors = validateForm();

  //   if (Object.keys(validationErrors).length > 0) {
  //     setErrors(validationErrors);
  //   } else {
  //     try {
  //       console.log("FormData", formData);
  //       const formDataToSend = new FormData();
  //       formDataToSend.append("name", formData?.name);
  //       formDataToSend.append("image", formData?.image);

  //       const response = await postData(
  //         `/masters/our_popular_courses/${id}`,
  //         formDataToSend
  //       );
  //       console.log("Data to be sent :- ", response);
  //       if (response.success) {
  //         setShowModal({ code: response.code, message: response.message });
  //         setTimeout(() => {
  //           setShowModal(0);
  //           props.handleClose();
  //         }, 1000);
  //       } else {
  //         setShowModal({ code: response.code, message: response.message });
  //         setTimeout(() => {
  //           setShowModal(0);
  //           props.handleClose();
  //         }, 1000);
  //       }
  //     } catch (error) {
  //       console.error("Error submitting form:", error);
  //     }
  //   }
  // };

  const GetEditData = async () => {
    const response = await getData(
      `/masters/ed-tech/university/dy-patil/future/${id}`
    );
    console.log(response?.data);
    reset(response?.data);
  };

  useEffect(() => {
    GetEditData();
  }, []);

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    reset,
    watch,
  } = useForm();
  console.log(getValues());
  const imageFile = watch("image");

  const onSubmit = async (data) => {
    try {
      const fromData = new FormData();
      fromData.append("name", data?.name);
      fromData.append("description", data?.description);

      const response = await postData(
        `/masters/ed-tech/university/dy-patil/future/${id}`,
        fromData
      );
      console.log(response);
      if (response?.success) {
        await setShowModal({ code: response.code, message: response.message });
      } else {
        await setShowModal({ code: response.code, message: response.message });
      }
      setTimeout(() => {
        setShowModal(0);
        props.handleClose();
      }, 1000);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Modal
        onHide={props.handleClose}
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Edit Future
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            onSubmit={() => handleSubmit(onSubmit)}
            role="form"
            className="countryclass"
          >
            <Row>
              {/* Title */}
              <Col lg={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label column sm={3} className="text-center">
                      Title
                    </Form.Label>
                    <Col sm={9}>
                      <Form.Group>
                        <Form.Control
                          className={classNames("", {
                            "is-invalid": errors?.name,
                          })}
                          type="text"
                          {...register("name", {
                            required: "Title is required",
                          })}
                          placeholder="Title"
                        />
                      </Form.Group>
                      {errors.title && (
                        <span className="text-danger">
                          {errors.title.message}
                        </span>
                      )}
                    </Col>
                  </Row>
                </div>
              </Col>

              <Col lg={12}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label column sm={2} className="text-center">
                      Description
                    </Form.Label>
                    <Col sm={10}>
                      <Form.Group>
                        <Controller
                          name="description" // Provide the field name
                          control={control} // Pass the control object from useForm()
                          rules={{ required: "Description is required" }} // Validation rules
                          render={({ field }) => (
                            <JoditEditor
                              value={field?.value}
                              onChange={(newContent) =>
                                field.onChange(newContent)
                              }
                              onBlur={field.onBlur}
                              className={classNames("", {
                                "is-invalid": !!errors.description,
                              })}
                              placeholder="Description"
                            />
                          )}
                        />
                      </Form.Group>
                      {errors.description && (
                        <span className="text-danger">
                          {errors.description.message}
                        </span>
                      )}
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>

            {/* Buttons */}
            <Row className="justify-content-center mt-5 pb-3">
              <Col sm={6} className="d-flex justify-content-center">
                <CancelButton name={"Cancel"} handleClose={props.handleClose} />
                <SaveButton
                  name={"Save"}
                  handleSubmit={handleSubmit(onSubmit)}
                />
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>

      <ModalSave
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      />
    </>
  );
};

export default EditOffCanvance;
