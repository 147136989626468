import React, { useContext } from "react";
import { useState } from "react";
import "../../Tabels/Tabels.css";
import Select from "react-select";
import { Context } from "../../../utils/context";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";

import ModalSave from "../../common/ModelSave";
import { CancelButton, SaveButton } from "../../common/Button";
import { Row, Col, Form, Modal } from "react-bootstrap";
// import { useForm } from "react-hook-form";
import classNames from "classnames";
import JoditEditor from 'jodit-react';
import { useForm, Controller } from 'react-hook-form';

library.add(fas);

const AddOffCanvance = (props) => {
  const { postData, getDimension } = useContext(Context);

  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });

  const screens = [
    { value: "home", name: "type", label: "Home" },
    // { value: "job", name: "type", label: "Job" },
    // { value: "company", name: "type", label: "Company" },
    { value: "blog", name: "type", label: "Blog" },

  ];

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm();

  const videoFile = watch("video");
  const imageFile = watch("image");
  console.log(errors);
  const onSubmit = async (data) => {
    try {
      const fromData = new FormData();
      fromData.append("name", data?.name);
      fromData.append("type", data?.type?.value);
      fromData.append("description", data?.description);
      fromData.append("image", data?.image[0]);

      const response = await postData(`/masters/job-portal/home/advertisment`, fromData);
      console.log(response);
      if (response?.success) {
        await setShowModal({ code: response.code, message: response.message });
      } else {
        await setShowModal({ code: response.code, message: response.message });
      }
      setTimeout(() => {
        setShowModal(0);
        props.handleClose();
      }, 1000);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Modal
        onHide={props.handleClose}
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Add Advertisment
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            onSubmit={() => handleSubmit(onSubmit)}
            role="form"
            className="countryclass"
          >

            <Row>
              <Col lg={6}>
                <div className="main-form-section mt-3">

                  <Form.Label >For  </Form.Label>  (select the screen )

                  <Controller
                    name="type" // name of the field
                    {...register("type", {
                      required: "Screen Name is required",
                    })}
                    control={control}
                    render={({ field }) => (
                      <Select
                        styles={{
                          control: (baseStyles) => ({
                            ...baseStyles,
                            borderColor: errors.type
                              ? "red"
                              : baseStyles,
                          }),
                        }}
                        {...field}
                        options={screens}
                      />
                    )}
                  />
                  {errors.type && (
                    <span className="text-danger">
                      {errors.type.message}
                    </span>
                  )}

                </div>

                <div className="main-form-section mt-3">

                  <Form.Label > Title</Form.Label>

                  <Form.Group>
                    <Form.Control
                      className={classNames("", {
                        "is-invalid": errors?.name,
                      })}
                      type="text"
                      {...register("name", {
                        required: "name is required",
                      })}
                      placeholder="name"
                    />
                  </Form.Group>
                  {errors.name && (
                    <span className="text-danger">
                      {errors.name.message}
                    </span>
                  )}

                </div>

                <div className="main-form-section mt-3">

                  <Form.Label > Image </Form.Label>

                  <Form.Group>
                    <Form.Control
                      className={classNames("", {
                        "is-invalid": errors?.image,
                      })}
                      type="file"
                      {...register("image", {
                        required: "Image is required",

                      })}
                      accept=".jpg, .jpeg, .png"
                    />
                  </Form.Group>
                  {errors.image && (
                    <span className="text-danger">
                      {errors.image.message}
                    </span>
                  )}

                </div>
              </Col>

              {/* Image Preview */}
              <Col lg={6}>
                <div className="main-form-section mt-3">

                  <Form.Label>
                    Image Preview
                  </Form.Label>

                  {imageFile && imageFile?.length > 0 && (
                    <div className="image-preview-container">
                      <img
                        src={URL.createObjectURL(imageFile[0])}
                        alt="Preview"
                        className="image-preview"
                        style={{ width: 200, height: 250, marginLeft: 50 }}
                      />
                    </div>
                  )}

                </div>
              </Col>

              {/* <Col lg={12}>
                <div className="main-form-section mt-3">

                  <Form.Label >
                    Description (if any)
                  </Form.Label>

                  <Form.Group>
                    <Controller
                      name="description" // Provide the field name
                      control={control} // Pass the control object from useForm()
                      rules={{ required: "Description is required" }} // Validation rules
                      render={({ field }) => (
                        <JoditEditor
                          value={field.value}
                          onChange={(newContent) => field.onChange(newContent)}
                          onBlur={field.onBlur}
                          className={classNames("", {
                            "is-invalid": !!errors.description,
                          })}
                          placeholder="Description"
                        />
                      )}
                    />
                  </Form.Group>
                  {errors.description && (
                    <span className="text-danger">
                      {errors.description.message}
                    </span>
                  )}

                </div>
              </Col> */}

            </Row>



            {/* Buttons */}
            <Row className="justify-content-center mt-5 pb-3">
              <Col sm={6} className="d-flex justify-content-center">
                <CancelButton name={"Cancel"} handleClose={props.handleClose} />
                <SaveButton
                  name={"Save"}
                  handleSubmit={handleSubmit(onSubmit)}
                />
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>

      <ModalSave
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      />
    </>
  );
};

export default AddOffCanvance;
