import React, { useContext } from "react";
import { useState, useEffect } from "react";
import "../../Tabels/Tabels.css";
import { Link } from "react-router-dom";
import { Context } from "../../../utils/context";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import Offcanvas from "react-bootstrap/Offcanvas";
import Card from "react-bootstrap/Card";
import Select from "react-select";
import ModalSave from "../../common/ModelSave";
import Validation from "../../common/FormValidation";
import { CancelButton, SaveButton } from "../../common/Button";

import {
  Container,
  Row,
  Col,
  Button,
  Form,
  InputGroup,
  Modal,
} from "react-bootstrap";

library.add(fas);

const AddOffCanvance = (props) => {
  const { postData, getData } = useContext(Context);

  const [country, setCountry] = useState([]);
  const [state, setState] = useState([]);
  const [formData, setFormData] = useState({
    country_id: "",
    state_id: "",
    name: "",
  });

  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });

  const handleChange = (e) => {
    if (e?.name == "country_id" || e?.name == "state_id") {
      // await setFormData({ ...formData, [e.name]: e });
      if (e?.name == "country_id") {
        setFormData({
          ...formData,
          [e.name]: e,
          state_id: "",
        });
        getSelectedState(e.value);
      } else {
        setFormData({ ...formData, [e.name]: e });
      }
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = await Validation.FormValidation({
      classname: ".cityclass",
    });

    const validationErrorsSelect = Validation.SelectValidation({
      classname: ".cityclass",
    });

    if (validationErrors && validationErrorsSelect) {
      try {
        const response = await postData("/masters/city", formData);
        if (response.success) {
          setShowModal({ code: response.code, message: response.message });
          setTimeout(() => {
            setShowModal(0);
            props.handleClose();
          }, 1000);
        } else {
          setShowModal({ code: response.code, message: response.message });
          setTimeout(() => {
            setShowModal(0);
            props.handleClose();
          }, 1000);
        }
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    }
  };

  async function getSelectedState(id) {
    const response = await getData(`/allstate/${id}`);

    if (response.success) {
      setState(response.data);
    }
  }

  const GetAllCountry = async () => {
    const response = await getData("/allcountry");

    const option = [];
    if (response.success) {
      response?.data?.map((data) => {
        option.push({
          value: data.id,
          name: "country_id",
          label: data.name,
        });
      });
    }
    setCountry(option);
  };

  useEffect(() => {
    GetAllCountry();
  }, []);

  return (
    <>


      <Modal
        show={props.show}
        onHide={props.handleClose}
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Add City</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit} role="form" className="cityclass">
            <Row>
              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="row justify-content-center mb-2">
                    <Form.Label>Select Country</Form.Label>

                    <Select
                      name="country_id"
                      className="input-mandatory"
                      onChange={(selectedOption) =>
                        handleChange(selectedOption)
                      }
                      value={formData.country_id}
                      options={country}
                    />

                    <span className="error-message"></span>
                  </Row>
                </div>
              </Col>

              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="row justify-content-center mb-2">
                    <Form.Label> Select State</Form.Label>

                    <Select
                      name="state_id"
                      className="input-mandatory"
                      onChange={handleChange}
                      value={formData.state_id}
                      options={state.map((stateOption) => ({
                        value: stateOption.id,
                        name: "state_id",
                        label: stateOption.name,
                      }))}
                    />

                    <span className="error-message"></span>
                  </Row>
                </div>
              </Col>

              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label> City</Form.Label>

                    <Form.Group>
                      <InputGroup>
                        <Form.Control
                          type="text"
                          name="name"
                          value={formData.name}
                          onChange={handleChange}
                          placeholder="City "
                          id="inputEmail3"
                          className="input-mandatory"
                        />
                      </InputGroup>
                      <span className="error-message"></span>
                    </Form.Group>
                  </Row>
                </div>
              </Col>

              <Row className="mt-5 pb-3">
                <div className="d-flex justify-content-center">
                  <Link>
                    <CancelButton
                      name={"cancel"}
                      handleClose={props.handleClose}
                    />
                  </Link>
                  <SaveButton name={"save"} handleSubmit={handleSubmit} />
                </div>
              </Row>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>

      <ModalSave
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      />
    </>
  );
};

export default AddOffCanvance;
