import React, { useContext, useRef } from "react";
import { useState, useEffect } from "react";
import "../../Tabels/Tabels.css";
import { Link } from "react-router-dom";
import { Context } from "../../../utils/context";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import Offcanvas from "react-bootstrap/Offcanvas";
import Card from "react-bootstrap/Card";
import Select from "react-select";
import ModalSave from "../../common/ModelSave";
import Validation from "../../common/FormValidation";
import { CancelButton, SaveButton } from "../../common/Button";
import JoditEditor from "jodit-react";

import {
  Container,
  Row,
  Col,
  Button,
  Form,
  InputGroup,
  Modal,
} from "react-bootstrap";

library.add(fas);

const AddOffCanvance = (props) => {
  const editor = useRef(null);
  const { postData, getData } = useContext(Context);
  const [imagePreview, setImagePreview] = useState("");
  const [blogtype, setBlogtype] = useState([]);
  const [formData, setFormData] = useState({
    image: "",
    blogtype_id: "",
    title: "",
    description: "",
    date: "",
  });

  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });

  const handleChange = async (e) => {
    if (e?.name == "blogtype_id") {
      setFormData({ ...formData, [e.name]: e });
    } else if (e.target.name === "image") {
      const data = new FormData();
      data.append("image", e.target.files[0]);
      data.append("width", 271);
      data.append("height", 181);
      const checkimage = await postData("/image", data);

      if (!checkimage?.success) {
        await setError({ image: checkimage?.message });
      } else {
        const newFormData = {
          ...formData,
          image: e.target.files[0],
          imageUrl: URL.createObjectURL(e.target.files[0]),
        };
        await setError({ image: null });
        await setFormData(newFormData);
      }
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  const handleDescriptionChange = (value) => {
    setFormData({ ...formData, description: value });
  };

  const [error, setError] = useState({
    image: null,
  });

  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = await Validation.FormValidation({
      classname: ".blogclass",
    });

    const validationErrorsSelect = Validation.SelectValidation({
      classname: ".blogclass",
    });

    if (validationErrors && validationErrorsSelect && error.image == null) {
      try {
        const data = new FormData();

        data.append("image", formData.image);
        data.append("blogtype_id", formData.blogtype_id.value);
        data.append("title", formData.title);
        data.append("description", formData.description);
        data.append("date", formData.date);

        const response = await postData("/masters/blog", data);

        if (response.success) {
          setShowModal({ code: response.code, message: response.message });
          setTimeout(() => {
            setShowModal(0);
            props.handleClose();
          }, 1000);
        } else {
          setShowModal({ code: response.code, message: response.message });
          setTimeout(() => {
            setShowModal(0);
            props.handleClose();
          }, 1000);
        }
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    }
  };

  const GetAllBlogtype = async () => {
    const response = await getData("/allblogtype");
    const option = [];
    if (response?.success) {
      response?.data?.map((data) => {
        option.push({
          value: data.id,
          name: "blogtype_id",
          label: data.name,
        });
      });
    }
    setBlogtype(option);
  };

  useEffect(() => {
    GetAllBlogtype();
  }, []);

  const errorStyle = {
    color: "red",
    marginLeft: "5px",
  };

  return (
    <>
      <Modal
        {...props}
        onHide={props.handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Add Blog</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit} role="form" className="blogclass">
            <Row>
              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="row justify-content-center mb-2">
                    <Form.Label>select Blog Type</Form.Label>

                    <Select
                      name="blogtype_id"
                      className="input-mandatory"
                      onChange={(selectedOption) =>
                        handleChange(selectedOption)
                      }
                      value={formData.blogtype_id}
                      options={blogtype}
                    />
                    <span className="error-message"></span>
                  </Row>
                </div>
              </Col>

              <Col sm={6}>
                <div className="main-form-section mt-3">
                  <Row className="row justify-content-center mb-2">
                    <Form.Label>Title</Form.Label>

                    <Form.Group>
                      <InputGroup>
                        <Form.Control
                          type="text"
                          name="title"
                          value={formData.title}
                          onChange={handleChange}
                          placeholder="Enter Title"
                          id="inputEmail3"
                          className="input-mandatory"
                        />
                      </InputGroup>
                      <span className="error-message"></span>
                    </Form.Group>
                  </Row>
                </div>
              </Col>

              <Col sm={6}>
                <div className="main-form-section mt-5">
                  <Row className="row justify-content-center mb-2">
                    <Form.Label>Date</Form.Label>

                    <Form.Group>
                      <InputGroup>
                        <Form.Control
                          type="date"
                          name="date"
                          value={formData.date}
                          onChange={handleChange}
                          placeholder="Date"
                          id="inputEmail3"
                          className="input-mandatory"
                        />
                      </InputGroup>
                      <span className="error-message"></span>
                    </Form.Group>
                  </Row>
                </div>
              </Col>

              <Row>
                <Col md={12}>
                  <div className="main-form-section mt-5">
                    <Row className="justify-content-center">
                      <Form.Label>Description</Form.Label>

                      <Form.Group>
                        <InputGroup>
                          <JoditEditor
                            ref={editor}
                            value={formData?.description}
                            tabIndex={2}
                            className="custom-jodit-editor"
                            //onBlur={newContent=>setContent(newContent)}
                            onChange={handleDescriptionChange}
                          />
                        </InputGroup>
                        <span className="error-message"></span>
                      </Form.Group>
                    </Row>
                  </div>
                </Col>
              </Row>

              <Col sm={6}>
                <div className="main-form-section mt-5">
                  <Row className="row justify-content-center mb-2">
                    <Form.Label>Image</Form.Label>

                    <Form.Group>
                      <InputGroup>
                        <Form.Control
                          type="file"
                          name="image"
                          accept=".jpg, .jpeg, .png"
                          // onChange={async (e) => {
                          //   const selectedImage = e.target.files[0];
                          //   setImagePreview(URL.createObjectURL(selectedImage));
                          //   const newFormData = {
                          //     ...formData,
                          //     image: selectedImage,
                          //   };
                          //   await setFormData(newFormData);
                          // }}
                          onChange={handleChange}
                          placeholder="Add Image"
                          id="inputEmail3"
                          className="input-mandatory"
                        />
                      </InputGroup>
                      <span className="error-message"></span>
                      {error?.image && (
                        <p className="m-0" style={{ color: "red" }}>
                          {error?.image}
                        </p>
                      )}
                    </Form.Group>
                  </Row>
                </div>
              </Col>

              <Col sm={6}>
                <div className="main-form-section mt-5">
                  <Row className="row justify-content-center mb-2">
                    <Form.Label>Image Preview</Form.Label>

                    {imagePreview && (
                      <div className="image-preview-container">
                        <img
                          src={imagePreview}
                          alt="Preview"
                          className="image-preview-container"
                          style={{
                            width: "120px",
                            height: "120px",
                          }}
                        />
                      </div>
                    )}
                  </Row>
                </div>
              </Col>

              <Row className="mt-5 pb-3">
                <div className="d-flex justify-content-center">
                  <Link>
                    <CancelButton
                      name={"cancel"}
                      handleClose={props.handleClose}
                    />
                  </Link>
                  <SaveButton name={"save"} handleSubmit={handleSubmit} />
                </div>
              </Row>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>

      <ModalSave
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      />
    </>
  );
};

export default AddOffCanvance;
