import React, { useContext } from "react";
import { useState, useEffect } from "react";
import "../../Tabels/Tabels.css";
import { Link } from "react-router-dom";
import { Context } from "../../../utils/context";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import Offcanvas from "react-bootstrap/Offcanvas";
// *******************toster****************************
import Card from "react-bootstrap/Card";
import Select from "react-select";
import ModalSave from "../../common/ModelSave";
import Validation from "../../common/FormValidation";
import { CancelButton, SaveButton } from "../../common/Button";

import {
  Container,
  Row,
  Col,
  Button,
  Form,
  InputGroup,
  Modal,
} from "react-bootstrap";

library.add(fas);

const EditOffCanvance = (props) => {
  const { postData, getData } = useContext(Context);
  //   const { id } = useParams();

  const id = props.show;
  const [roletype, setroletype] = useState([]);
  const [state, setState] = useState([]);
  const [data, setData] = useState();
  const [formData, setFormData] = useState({
    role_type_id: "",
    role_subtype_id: "",
    name: "",
  });

  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });

  const handleChange = (e) => {
    if (e?.name == "role_type_id" || e?.name == "role_subtype_id") {
      // await setFormData({ ...formData, [e.name]: e });
      if (e?.name == "role_type_id") {
        setFormData({
          ...formData,
          [e.name]: e,
          state_id: "",
        });
        getSelectedState(e.value);
      } else {
        setFormData({ ...formData, [e.name]: e });
      }
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  async function getSelectedState(id) {
    const response = await getData(`/allrolesubtype/${id}`);
    if (response.success) {
      setState(response.data);
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = await Validation.FormValidation({
      classname: ".rolesclass",
    });

    const validationErrorsSelect = Validation.SelectValidation({
      classname: ".rolesclass",
    });
    if (validationErrors && validationErrorsSelect) {
      try {
        const response = await postData(`/masters/roles/${id}`, formData);
        if (response.success) {
          setShowModal({ code: response.code, message: response.message });
          setTimeout(() => {
            setShowModal(0);
            props.handleClose();
          }, 1000);
        } else {
          setShowModal({ code: response.code, message: response.message });
          setTimeout(() => {
            setShowModal(0);
            props.handleClose();
          }, 1000);
        }
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    }
  };

  const GetEditData = async () => {
    const response = await getData(`/masters/roles/${id}`);
    setData(response);

    setFormData(response?.data);
    const res = await getData("/allroletype");

    const option = [];
    if (res.success) {
      res?.data?.map((data) => {
        option.push({
          value: data.id,
          name: "role_type_id",
          label: data.name,
        });
      });
    }
    setroletype(option);
  };

  useEffect(() => {
    GetEditData();
  }, []);

  const GetAllroletype = async () => {
    const response = await getData("/allroletype");
    setroletype(response);
  };

  useEffect(() => {
    GetAllroletype();
  }, []);

  const errorStyle = {
    color: "red",
    marginLeft: "5px",
  };

  const successStyle = {
    color: "green",
    marginLeft: "5px",
  };

  return (
    <>
      {/* <Offcanvas
        show={props.show}
        style={{ width: "80%" }}
        placement={"end"}
        onHide={props.handleClose}
      >
        <Offcanvas.Header closeButton>

        </Offcanvas.Header>
        <Offcanvas.Body>
          <Card className="shadow-lg p-3 mb-5 bg-white rounded">
            <Card.Body>
              <Card.Title>Edit Roles</Card.Title>
              <hr />
              <Container>
                <Row>
                  <Col md={12}>
                    <Container>
                      <Row className="">
                        <Col xxl={12} xl={12} md={10}>
                          <Form
                            onSubmit={handleSubmit}
                            role="form "
                            className="rolesclass"
                          >
                            <Row>

                              <Col md={6}>
                                <div className="main-form-section mt-5">
                                  <Row className="row justify-content-center mb-2">
                                    <Form.Label column sm={3}>
                                      Select RoleType{" "}
                                    </Form.Label>
                                    <Col sm={6}>
                                      <Select
                                        name="country_id"
                                        className="input-mandatory"
                                        onChange={handleChange}
                                        value={formData?.role_type_id}
                                        options={roletype}
                                      />

                                      <span className="error-message"></span>
                                    </Col>
                                  </Row>
                                </div>
                              </Col>

                              <Col md={6}>
                                <div className="main-form-section mt-5">
                                  <Row className="row justify-content-center mb-2">
                                    <Form.Label
                                      column
                                      sm={3}

                                    >
                                      Select Role Subtype{" "}
                                    </Form.Label>
                                    <Col sm={6}>
                                      <Select
                                        name="state_id"
                                        className="input-mandatory"
                                        onChange={handleChange}
                                        value={formData.role_subtype_id}
                                        options={state?.map((data) => ({
                                          value: data.id,
                                          name: "role_subtype_id",
                                          label: data.name,
                                        }))}
                                      />

                                      <span className="error-message"></span>
                                    </Col>
                                  </Row>
                                </div>
                              </Col>

                              <Col md={6}>
                                <div className="main-form-section mt-5">
                                  <Row className="justify-content-center">
                                    <Form.Label column sm={3}>
                                      Roles
                                    </Form.Label>
                                    <Col sm={6}>
                                      <Form.Group>
                                        <InputGroup>
                                          <Form.Control
                                            type="text"
                                            name="name"
                                            value={formData?.name}
                                            onChange={handleChange}
                                            placeholder="Roles "
                                            id="inputEmail3"
                                            className="input-mandatory"
                                          />
                                        </InputGroup>
                                        <span className="error-message"></span>
                                      </Form.Group>
                                    </Col>
                                  </Row>
                                </div>
                              </Col>
                             

                              <Row className="mt-5 pb-3">
                                <div className="d-flex justify-content-center">
                                  <Link>
                                    <CancelButton
                                      name={"cancel"}
                                      handleClose={props.handleClose}
                                    />
                                  </Link>
                                  <SaveButton
                                    name={"save"}
                                    handleSubmit={handleSubmit}
                                  />
                                </div>
                              </Row>

                            </Row>
                          </Form>
                        </Col>
                      </Row>
                    </Container>
                  </Col>
                </Row>
              </Container>
            </Card.Body>
          </Card>
        </Offcanvas.Body>
      </Offcanvas> */}

      <Modal
        {...props}
        size="lg"
        onHide={props.handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Edit Roles
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit} role="form " className="rolesclass">
            <Row>
              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="row justify-content-center mb-2">
                    <Form.Label>Select RoleType </Form.Label>

                    <Select
                      name="country_id"
                      className="input-mandatory"
                      onChange={handleChange}
                      value={formData?.role_type_id}
                      options={roletype}
                    />

                    <span className="error-message"></span>
                  </Row>
                </div>
              </Col>

              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="row justify-content-center mb-2">
                    <Form.Label>Select Role Subtype</Form.Label>

                    <Select
                      name="state_id"
                      className="input-mandatory"
                      onChange={handleChange}
                      value={formData.role_subtype_id}
                      options={state?.map((data) => ({
                        value: data.id,
                        name: "role_subtype_id",
                        label: data.name,
                      }))}
                    />

                    <span className="error-message"></span>
                  </Row>
                </div>
              </Col>

              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label>Roles</Form.Label>

                    <Form.Group>
                      <InputGroup>
                        <Form.Control
                          type="text"
                          name="name"
                          value={formData?.name}
                          onChange={handleChange}
                          placeholder="Roles "
                          id="inputEmail3"
                          className="input-mandatory"
                        />
                      </InputGroup>
                      <span className="error-message"></span>
                    </Form.Group>
                  </Row>
                </div>
              </Col>

              <Row className="mt-5 pb-3">
                <div className="d-flex justify-content-center">
                  <Link>
                    <CancelButton
                      name={"cancel"}
                      handleClose={props.handleClose}
                    />
                  </Link>
                  <SaveButton name={"save"} handleSubmit={handleSubmit} />
                </div>
              </Row>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>

      <ModalSave
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      />
    </>
  );
};

export default EditOffCanvance;
