import React, { useContext } from "react";
import { useState, useEffect } from "react";
import "../../Tabels/Tabels.css";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";

import ModalSave from "../../common/ModelSave";
import { CancelButton, SaveButton } from "../../common/Button";
import { Row, Col, Form, Modal } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import classNames from "classnames";
import { Context } from "../../../utils/context";
import JoditEditor from "jodit-react";
import Select from "react-select";
library.add(fas);

const AddOffCanvance = (props) => {
  const { postData, getDimension, getData, Select2Data } = useContext(Context);

  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm();

  const imageFile = watch("image");
  console.log(errors);

  const onSubmit = async (data) => {
    try {
      const fromData = new FormData();
      fromData.append("name", data?.name);
      fromData.append("price", data?.price);
      fromData.append("job_post", data?.job_post);
      fromData.append("application", data?.application);
      fromData.append("featured_jobs", data?.featured_jobs);
      fromData.append("days_validity", data?.days_validity);

      const response = await postData(`/masters/job-portal/plan`, fromData);

      if (response?.success) {
        await setShowModal({
          code: response?.code,
          message: response?.message,
        });
      } else {
        await setShowModal({
          code: response?.code,
          message: response?.message,
        });
      }
      setTimeout(() => {
        setShowModal(0);
        props.handleClose();
      }, 1000);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Modal
        onHide={props.handleClose}
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Add Plan</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            onSubmit={() => handleSubmit(onSubmit)}
            role="form"
            className="countryclass"
          >
            <Row>
              {/* Title */}
              <Col lg={6}>
                <div className="main-form-section mt-3">
                  <Form.Label>  Name </Form.Label>
                  <Form.Group>
                    <Form.Control
                      className={classNames("", {
                        "is-invalid": errors?.name,
                      })}
                      type="text"
                      {...register("name", {
                        required: "Name is required",
                      })}
                      placeholder="Name"
                    />
                  </Form.Group>
                  {errors.name && (
                    <span className="text-danger">
                      {errors.name.message}
                    </span>
                  )}

                </div>
              </Col>

              <Col lg={6}>
                <div className="main-form-section mt-3">
                  <Form.Label>price </Form.Label>
                  <Form.Group>
                    <Form.Control
                      className={classNames("", {
                        "is-invalid": errors?.price,
                      })}
                      type="text"
                      {...register("price", {
                        required: "price is required",
                      })}
                      placeholder="price"
                    />
                  </Form.Group>
                  {errors.price && (
                    <span className="text-danger">
                      {errors.price.message}
                    </span>
                  )}

                </div>
              </Col>

              <Col lg={6}>
                <div className="main-form-section mt-3">
                  <Form.Label>Job Post</Form.Label>
                  <Form.Group>
                    <Form.Control
                      className={classNames("", {
                        "is-invalid": errors?.job_post,
                      })}
                      type="text"
                      {...register("job_post", {
                        required: "Job Post is required",
                      })}
                      placeholder="job_post"
                    />
                  </Form.Group>
                  {errors.job_post && (
                    <span className="text-danger">
                      {errors.job_post.message}
                    </span>
                  )}

                </div>
              </Col>

              <Col lg={6}>
                <div className="main-form-section mt-3">
                  <Form.Label>Application</Form.Label>
                  <Form.Group>
                    <Form.Control
                      className={classNames("", {
                        "is-invalid": errors?.application,
                      })}
                      type="text"
                      {...register("application", {
                        required: "Application is required",
                      })}
                      placeholder="application"
                    />
                  </Form.Group>
                  {errors.application && (
                    <span className="text-danger">
                      {errors.application.message}
                    </span>
                  )}

                </div>
              </Col>

              <Col lg={6}>
                <div className="main-form-section mt-3">
                  <Form.Label>Features Job</Form.Label>
                  <Form.Group>
                    <Form.Control
                      className={classNames("", {
                        "is-invalid": errors?.featured_jobs,
                      })}
                      type="text"
                      {...register("featured_jobs", {
                        required: "Features Job is required",
                      })}
                      placeholder="featured_jobs"
                    />
                  </Form.Group>
                  {errors.featured_jobs && (
                    <span className="text-danger">
                      {errors.featured_jobs.message}
                    </span>
                  )}

                </div>
              </Col>

              <Col lg={6}>
                <div className="main-form-section mt-3">
                  <Form.Label>Days validity</Form.Label>
                  <Form.Group>
                    <Form.Control
                      className={classNames("", {
                        "is-invalid": errors?.days_validity,
                      })}
                      type="number"
                      {...register("days_validity", {
                        required: "Days validity is required",
                      })}
                      placeholder="days_validity"
                    />
                  </Form.Group>
                  {errors.days_validity && (
                    <span className="text-danger">
                      {errors.days_validity.message}
                    </span>
                  )}

                </div>
              </Col>

            </Row>

            {/* Buttons */}
            <Row className="justify-content-center mt-5 pb-3">
              <Col sm={6} className="d-flex justify-content-center">
                <CancelButton name={"Cancel"} handleClose={props.handleClose} />
                <SaveButton
                  name={"Save"}
                  handleSubmit={handleSubmit(onSubmit)}
                />
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>

      <ModalSave
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      />
    </>
  );
};

export default AddOffCanvance;
