import React, { useState, handlelick, active, useContext } from "react";
import "./Sidebar.css";
import SideBarIMG from "../assets/HICLOUSIA-logo-for-developer.png";
import { Link, useLocation } from "react-router-dom";
import { FaBars } from "react-icons/fa";
import Accordion from "react-bootstrap/Accordion";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import { Context } from "../../utils/context";

library.add(fas);

const Sidebar = () => {
  const { isSidebarOpen, setSidebarOpen } = useContext(Context);
  const location = useLocation();

  const [isOpen, setIsOpen] = useState(true);
  const [showItemNames, setShowItemNames] = useState(true);
  const [headerText, setHeaderText] = useState(location.pathname);

  useEffect(() => {
    setHeaderText(location.pathname);
  });

  const [active, setActive] = useState("0");
  const handleClick = (eventKey) => {
    if (active === eventKey) {
      setActive(null);
    } else {
      setActive(eventKey);
      setSidebarOpen(true);
    }
  };

  const isActive = (path) => {
    return location.pathname.includes(path) ? "active block" : "none";
  };

  return (
    <>
      <section className="sidebar">
        <div className="top_section">
          {/* <img
            style={{ display: isSidebarOpen ? "block" : "none" }}
            className="logo ms-2"
            src={SideBarIMG}
          /> */}
          <h1>LLP</h1>
        </div>
        <ul className="list-unstyled ps-0">
          {/* ***************************************************Dashboard*************************************************************** */}

          <Accordion
            defaultActiveKey="0"
            className="mb-1"
            data-bs-toggle="collapse"
            data-bs-target="#home-collapse"
            aria-expanded="false"
          >
            <Accordion.Item
              eventKey="0"
              className="mb-1"
              onClick={() => handleClick("0")}
            >
              <Accordion.Header>
                <div className=" me-3 scale-in-hor-right">
                  <FontAwesomeIcon
                    icon="fa-solid fa-house"
                    onClick={() => setIsOpen(!isOpen)}
                  />
                  <b></b>
                  <b></b>
                </div>
                <p
                  className="sidebar_txt"
                  style={{ display: isSidebarOpen ? "block" : "none" }}
                >
                  Dashboard
                </p>

                {/* </button> */}
              </Accordion.Header>
              <Accordion.Body>
                <ul
                  className={`accordion_list list-unstyled fw-normal pb-1 small ${isSidebarOpen ? "arrowshow" : "arrowHide"
                    }`}
                  style={{
                    display: isSidebarOpen ? "block" : "none",
                  }}
                >
                  <Link to="/advanceDashboard" className="text-decoration-none">
                    <li className={isActive("/advanceDashboard")}>
                      <p key={2} className="insideDropdown_list" id={"2"}>
                        Advance Dashboard
                      </p>
                    </li>
                  </Link>
                </ul>
              </Accordion.Body>
            </Accordion.Item>
            {/* *********************************************************Dashboard Ends*************************************************************** */}

            {/* **************************************************************************Setting************************************************************** */}

            <Accordion.Item
              eventKey="1"
              className="mb-1"
              onClick={() => handleClick("1")}
            >
              <Link to={"/settingContent"} className=" text-decoration-none">
                <Accordion.Header>
                  <div className=" me-3">
                    <FontAwesomeIcon icon="fa-solid fa-gear" />
                    <b></b>
                    <b></b>
                  </div>

                  <p
                    style={{
                      display: isSidebarOpen ? "block" : "none",
                    }}
                    className="sidebar_txt"
                  >
                    Settings
                  </p>
                </Accordion.Header>
              </Link>
            </Accordion.Item>

            {/* **************************************************************************Setting Ends************************************************************** */}

            <Accordion.Item
              eventKey="3"
              className="mb-1"
              onClick={() => handleClick("3")}
            >
              <Accordion.Header>
                <div className="me-3">
                  <FontAwesomeIcon
                    icon="fa-solid fa-users"
                    onClick={() => setIsOpen(!isOpen)}
                  />
                  <b></b>
                  <b></b>
                </div>
                <p
                  style={{ display: isSidebarOpen ? "block" : "none" }}
                  className="sidebar_txt"
                >
                  Customer Management
                </p>
              </Accordion.Header>
              <Accordion.Body
                style={{
                  display: isOpen ? "block" : "none",
                }}
              >
                <ul
                  className="accordion_list list-unstyled fw-normal pb-1 small"
                  style={{
                    display: isSidebarOpen ? "block" : "none",
                  }}
                >
                  <li
                    className={
                      isOpen
                        ? headerText.includes("/recruiters/contact_us_list")
                          ? "active block"
                          : "none"
                        : "none"
                    }
                  // className={isActive("/masters/contact_us_list")}
                  >
                    <Link
                      to="/recruiters/contact_us_list"
                      className="text-decoration-none rounded "
                    >
                      <p className="insideDropdown_list">Contact Us List</p>
                    </Link>
                  </li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>

            {/* Ed-Tech */}

            <Accordion.Item
              eventKey="4"
              className="mb-1"
              onClick={() => handleClick("4")}
            >
              <Accordion.Header>
                <div className="me-3">
                  <FontAwesomeIcon
                    icon="fa-solid fa-graduation-cap"
                    onClick={() => setIsOpen(!isOpen)}
                  />
                  <b></b>
                  <b></b>
                </div>
                <p
                  style={{ display: isSidebarOpen ? "block" : "none" }}
                  className="sidebar_txt"
                >
                  Ed Tech
                </p>
              </Accordion.Header>
              <Accordion.Body
                style={{
                  display: isOpen ? "block" : "none",
                }}
              >
                <ul
                  className="accordion_list list-unstyled fw-normal pb-1 small"
                  style={{
                    display: isSidebarOpen ? "block" : "none",
                  }}
                >
                  <Link
                    // to="/recruiters/contact_us_list"
                    className="text-decoration-none rounded "
                  >
                    <Accordion className="accordion-item-sub">
                      <Accordion.Item eventKey="00" className="mb-1">
                        <Accordion.Header className="scale-in-hor-right">
                          {/* <div className="me-3">
                            <FontAwesomeIcon
                              icon="fa-solid fa-house"

                              onClick={() => setIsOpen(!isOpen)}
                            />
                            <b></b>
                            <b></b>
                          </div> */}
                          Home
                        </Accordion.Header>
                        <Accordion.Body>
                          <ul
                            className="accordion_list list-unstyled fw-normal pb-1 small"
                            style={{
                              display: isSidebarOpen ? "block" : "none",
                            }}
                          >
                            <li
                              className={
                                isOpen
                                  ? headerText.includes(
                                    "/masters/ed-tech/home/banner"
                                  )
                                    ? "active block"
                                    : "none"
                                  : "none"
                              }
                            // className={isActive("/masters/contact_us_list")}
                            >
                              <Link
                                to="/masters/ed-tech/home/banner"
                                className="text-decoration-none rounded "
                              >
                                <p className="insideDropdown_list">Banner</p>
                              </Link>
                            </li>
                            <li
                              className={
                                isOpen
                                  ? headerText.includes(
                                    "/masters/ed-tech/home/media"
                                  )
                                    ? "active block"
                                    : "none"
                                  : "none"
                              }
                            // className={isActive("/masters/contact_us_list")}
                            >
                              <Link
                                to="/masters/ed-tech/home/media"
                                className="text-decoration-none rounded "
                              >
                                <p className="insideDropdown_list">Media</p>
                              </Link>
                            </li>
                            <li
                              className={
                                isOpen
                                  ? headerText.includes(
                                    "/masters/ed-tech/home/online-learning"
                                  )
                                    ? "active block"
                                    : "none"
                                  : "none"
                              }
                            // className={isActive("/masters/contact_us_list")}
                            >
                              <Link
                                to="/masters/ed-tech/home/online-learning"
                                className="text-decoration-none rounded "
                              >
                                <p className="insideDropdown_list">
                                  Online Learning
                                </p>
                              </Link>
                            </li>
                            <li
                              className={
                                isOpen
                                  ? headerText.includes(
                                    "/masters/ed-tech/home/premier-universities"
                                  )
                                    ? "active block"
                                    : "none"
                                  : "none"
                              }
                            // className={isActive("/masters/contact_us_list")}
                            >
                              <Link
                                to="/masters/ed-tech/home/premier-universities"
                                className="text-decoration-none rounded "
                              >
                                <p className="insideDropdown_list">
                                  Premier Universities
                                </p>
                              </Link>
                            </li>
                            <li
                              className={
                                isOpen
                                  ? headerText.includes(
                                    "/masters/ed-tech/home/alumni-network"
                                  )
                                    ? "active block"
                                    : "none"
                                  : "none"
                              }
                            // className={isActive("/masters/contact_us_list")}
                            >
                              <Link
                                to="/masters/ed-tech/home/alumni-network"
                                className="text-decoration-none rounded "
                              >
                                <p className="insideDropdown_list">
                                  Alumni Network
                                </p>
                              </Link>
                            </li>
                            <li
                              className={
                                isOpen
                                  ? headerText.includes(
                                    "/masters/ed-tech/home/popular-course"
                                  )
                                    ? "active block"
                                    : "none"
                                  : "none"
                              }
                            // className={isActive("/masters/contact_us_list")}
                            >
                              <Link
                                to="/masters/ed-tech/home/popular-course"
                                className="text-decoration-none rounded "
                              >
                                <p className="insideDropdown_list">
                                  Popular Course
                                </p>
                              </Link>
                            </li>
                            <li
                              className={
                                isOpen
                                  ? headerText.includes(
                                    "/masters/ed-tech/home/profcyma-assistance"
                                  )
                                    ? "active block"
                                    : "none"
                                  : "none"
                              }
                            // className={isActive("/masters/contact_us_list")}
                            >
                              <Link
                                to="/masters/ed-tech/home/profcyma-assistance"
                                className="text-decoration-none rounded "
                              >
                                <p className="insideDropdown_list">
                                  Profcyma Assistance
                                </p>
                              </Link>
                            </li>
                            <li
                              className={
                                isOpen
                                  ? headerText.includes(
                                    "/masters/ed-tech/home/faq"
                                  )
                                    ? "active block"
                                    : "none"
                                  : "none"
                              }
                            // className={isActive("/masters/contact_us_list")}
                            >
                              <Link
                                to="/masters/ed-tech/home/faq"
                                className="text-decoration-none rounded "
                              >
                                <p className="insideDropdown_list">FAQ</p>
                              </Link>
                            </li>
                            <li
                              className={
                                isOpen
                                  ? headerText.includes(
                                    "/masters/ed-tech/home/faq-subtype"
                                  )
                                    ? "active block"
                                    : "none"
                                  : "none"
                              }
                            // className={isActive("/masters/contact_us_list")}
                            >
                              <Link
                                to="/masters/ed-tech/home/faq-subtype"
                                className="text-decoration-none rounded "
                              >
                                <p className="insideDropdown_list">
                                  FAQ Sub Type
                                </p>
                              </Link>
                            </li>
                            <li
                              className={
                                isOpen
                                  ? headerText.includes(
                                    "/masters/ed-tech/home/who-we-are"
                                  )
                                    ? "active block"
                                    : "none"
                                  : "none"
                              }
                            // className={isActive("/masters/contact_us_list")}
                            >
                              <Link
                                to="/masters/ed-tech/home/who-we-are"
                                className="text-decoration-none rounded "
                              >
                                <p className="insideDropdown_list">
                                  Who We Are
                                </p>
                              </Link>
                            </li>
                            <li
                              className={
                                isOpen
                                  ? headerText.includes(
                                    "/masters/ed-tech/home/stand-out"
                                  )
                                    ? "active block"
                                    : "none"
                                  : "none"
                              }
                            // className={isActive("/masters/contact_us_list")}
                            >
                              <Link
                                to="/masters/ed-tech/home/stand-out"
                                className="text-decoration-none rounded "
                              >
                                <p className="insideDropdown_list">Stand Out</p>
                              </Link>
                            </li>
                            <li
                              className={
                                isOpen
                                  ? headerText.includes(
                                    "/masters/ed-tech/home/popular-news"
                                  )
                                    ? "active block"
                                    : "none"
                                  : "none"
                              }
                            // className={isActive("/masters/contact_us_list")}
                            >
                              <Link
                                to="/masters/ed-tech/home/popular-news"
                                className="text-decoration-none rounded "
                              >
                                <p className="insideDropdown_list">
                                  Popular News
                                </p>
                              </Link>
                            </li>
                            <li
                              className={
                                isOpen
                                  ? headerText.includes(
                                    "/masters/ed-tech/home/fee-structure"
                                  )
                                    ? "active block"
                                    : "none"
                                  : "none"
                              }
                            // className={isActive("/masters/contact_us_list")}
                            >
                              <Link
                                to="/masters/ed-tech/home/fee-structure"
                                className="text-decoration-none rounded "
                              >
                                <p className="insideDropdown_list">
                                  Fee Structure
                                </p>
                              </Link>
                            </li>{" "}
                            <li
                              className={
                                isOpen
                                  ? headerText.includes(
                                    "/masters/ed-tech/home/right-guidence"
                                  )
                                    ? "active block"
                                    : "none"
                                  : "none"
                              }
                            // className={isActive("/masters/contact_us_list")}
                            >
                              <Link
                                to="/masters/ed-tech/home/right-guidence"
                                className="text-decoration-none rounded "
                              >
                                <p className="insideDropdown_list">
                                  Right Guidance
                                </p>
                              </Link>
                            </li>
                            <li
                              className={
                                isOpen
                                  ? headerText.includes(
                                    "/masters/ed-tech/home/address"
                                  )
                                    ? "active block"
                                    : "none"
                                  : "none"
                              }
                            // className={isActive("/masters/contact_us_list")}
                            >
                              <Link
                                to="/masters/ed-tech/home/address"
                                className="text-decoration-none rounded "
                              >
                                <p className="insideDropdown_list">
                                  Ed Tech Address
                                </p>
                              </Link>
                            </li>
                            <li
                              className={
                                isOpen
                                  ? headerText.includes(
                                    "/masters/ed-tech/home/our-alumni"
                                  )
                                    ? "active block"
                                    : "none"
                                  : "none"
                              }
                            // className={isActive("/masters/contact_us_list")}
                            >
                              <Link
                                to="/masters/ed-tech/home/our-alumni"
                                className="text-decoration-none rounded "
                              >
                                <p className="insideDropdown_list">
                                  Our Alumni
                                </p>
                              </Link>
                            </li>
                          </ul>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="01" className="mb-1">
                        <Accordion.Header className="scale-in-hor-right">
                          About Us
                        </Accordion.Header>
                        <Accordion.Body>
                          <ul
                            className="accordion_list list-unstyled fw-normal pb-1 small"
                            style={{
                              display: isSidebarOpen ? "block" : "none",
                            }}
                          >
                            <li
                              className={
                                isOpen
                                  ? headerText.includes(
                                    "/masters/ed-tech/about-as/banner"
                                  )
                                    ? "active block"
                                    : "none"
                                  : "none"
                              }
                            // className={isActive("/masters/contact_us_list")}
                            >
                              <Link
                                to="/masters/ed-tech/about-as/banner"
                                className="text-decoration-none rounded "
                              >
                                <p className="insideDropdown_list">Banner</p>
                              </Link>
                            </li>
                            <li
                              className={
                                isOpen
                                  ? headerText.includes(
                                    "/masters/ed-tech/about-as/about"
                                  )
                                    ? "active block"
                                    : "none"
                                  : "none"
                              }
                            // className={isActive("/masters/contact_us_list")}
                            >
                              <Link
                                to="/masters/ed-tech/about-as/about"
                                className="text-decoration-none rounded "
                              >
                                <p className="insideDropdown_list">About </p>
                              </Link>
                            </li>
                            <li
                              className={
                                isOpen
                                  ? headerText.includes(
                                    "/masters/ed-tech/about-as/advantages"
                                  )
                                    ? "active block"
                                    : "none"
                                  : "none"
                              }
                            // className={isActive("/masters/contact_us_list")}
                            >
                              <Link
                                to="/masters/ed-tech/about-as/advantages"
                                className="text-decoration-none rounded "
                              >
                                <p className="insideDropdown_list">
                                  Advantages
                                </p>
                              </Link>
                            </li>
                            {/* <li
                              className={
                                isOpen
                                  ? headerText.includes(
                                    "/recruiters/contact_us_list3"
                                  )
                                    ? "active block"
                                    : "none"
                                  : "none"
                              }
                            // className={isActive("/masters/contact_us_list")}
                            >
                              <Link
                                to="/recruiters/contact_us_list"
                                className="text-decoration-none rounded "
                              >
                                <p className="insideDropdown_list">4</p>
                              </Link>
                            </li>
                            <li
                              className={
                                isOpen
                                  ? headerText.includes(
                                    "/recruiters/contact_us_list3"
                                  )
                                    ? "active block"
                                    : "none"
                                  : "none"
                              }
                            // className={isActive("/masters/contact_us_list")}
                            >
                              <Link
                                to="/recruiters/contact_us_list"
                                className="text-decoration-none rounded "
                              >
                                <p className="insideDropdown_list">5</p>
                              </Link>
                            </li>
                            <li
                              className={
                                isOpen
                                  ? headerText.includes(
                                    "/recruiters/contact_us_list3"
                                  )
                                    ? "active block"
                                    : "none"
                                  : "none"
                              }
                            // className={isActive("/masters/contact_us_list")}
                            >
                              <Link
                                to="/recruiters/contact_us_list"
                                className="text-decoration-none rounded "
                              >
                                <p className="insideDropdown_list">6</p>
                              </Link>
                            </li> */}
                          </ul>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="03" className="mb-1">
                        <Accordion.Header className="scale-in-hor-right">
                          Cources Settings
                        </Accordion.Header>
                        <Accordion.Body>
                          <Accordion className="child-sub">
                            {/* <Accordion.Item eventKey="0">
                              <Accordion.Header>
                                Under Gradudate
                              </Accordion.Header>
                              <Accordion.Body>
                                <Accordion>
                                  <Accordion.Item eventKey="0">
                                   

                                    <Accordion.Header>BBA</Accordion.Header>
                                    <Accordion.Body>
                                      <ul>
                                        <li
                                          className={
                                            isOpen
                                              ? headerText.includes(
                                                "/masters/ed-tech/course/ug-courses/bba/banner"
                                              )
                                                ? "active block"
                                                : "none"
                                              : "none"
                                          }
                                        // className={isActive("/masters/contact_us_list")}
                                        >
                                          <Link
                                            to="/masters/ed-tech/course/ug-courses/bba/banner"
                                            className="text-decoration-none rounded "
                                          >
                                            <p className="insideDropdown_list">
                                              banner
                                            </p>
                                          </Link>
                                        </li>
                                        <li
                                          className={
                                            isOpen
                                              ? headerText.includes(
                                                "/masters/ed-tech/course/ug-courses/bba/advantages"
                                              )
                                                ? "active block"
                                                : "none"
                                              : "none"
                                          }
                                        // className={isActive("/masters/contact_us_list")}
                                        >
                                          <Link
                                            to="/masters/ed-tech/course/ug-courses/bba/advantages"
                                            className="text-decoration-none rounded "
                                          >
                                            <p className="insideDropdown_list">
                                              Advantages
                                            </p>
                                          </Link>
                                        </li>
                                        <li
                                          className={
                                            isOpen
                                              ? headerText.includes(
                                                "/masters/ed-tech/course/ug-courses/bba/discover"
                                              )
                                                ? "active block"
                                                : "none"
                                              : "none"
                                          }
                                        // className={isActive("/masters/contact_us_list")}
                                        >
                                          <Link
                                            to="/masters/ed-tech/course/ug-courses/bba/discover"
                                            className="text-decoration-none rounded "
                                          >
                                            <p className="insideDropdown_list">
                                              Discover
                                            </p>
                                          </Link>
                                        </li>
                                        <li
                                          className={
                                            isOpen
                                              ? headerText.includes(
                                                "/masters/ed-tech/course/ug-courses/bba/reasons"
                                              )
                                                ? "active block"
                                                : "none"
                                              : "none"
                                          }
                                        // className={isActive("/masters/contact_us_list")}
                                        >
                                          <Link
                                            to="/masters/ed-tech/course/ug-courses/bba/reasons"
                                            className="text-decoration-none rounded "
                                          >
                                            <p className="insideDropdown_list">
                                              Reasons
                                            </p>
                                          </Link>
                                        </li>
                                        <li
                                          className={
                                            isOpen
                                              ? headerText.includes(
                                                "/masters/ed-tech/course/ug-courses/bba/what_is"
                                              )
                                                ? "active block"
                                                : "none"
                                              : "none"
                                          }
                                        // className={isActive("/masters/contact_us_list")}
                                        >
                                          <Link
                                            to="/masters/ed-tech/course/ug-courses/bba/what_is"
                                            className="text-decoration-none rounded "
                                          >
                                            <p className="insideDropdown_list">
                                              What Is ?
                                            </p>
                                          </Link>
                                        </li>
                                        <li
                                          className={
                                            isOpen
                                              ? headerText.includes(
                                                "/masters/ed-tech/course/ug-courses/bba/who_qualified"
                                              )
                                                ? "active block"
                                                : "none"
                                              : "none"
                                          }
                                        // className={isActive("/masters/contact_us_list")}
                                        >
                                          <Link
                                            to="/masters/ed-tech/course/ug-courses/bba/who_qualified"
                                            className="text-decoration-none rounded "
                                          >
                                            <p className="insideDropdown_list">
                                              Who Qualified ?
                                            </p>
                                          </Link>
                                        </li>
                                      </ul>
                                    </Accordion.Body>
                                  </Accordion.Item>
                                  <Accordion.Item eventKey="1">
                                    <Accordion.Header>BA</Accordion.Header>
                                    <Accordion.Body>
                                      <ul>
                                        <li
                                          className={
                                            isOpen
                                              ? headerText.includes(
                                                "/masters/ed-tech/course/ug-courses/ba/banner"
                                              )
                                                ? "active block"
                                                : "none"
                                              : "none"
                                          }
                                        // className={isActive("/masters/contact_us_list")}
                                        >
                                          <Link
                                            to="/masters/ed-tech/course/ug-courses/ba/banner"
                                            className="text-decoration-none rounded "
                                          >
                                            <p className="insideDropdown_list">
                                              banner
                                            </p>
                                          </Link>
                                        </li>

                                        <li
                                          className={
                                            isOpen
                                              ? headerText.includes(
                                                "/masters/ed-tech/course/ug-courses/ba/reasons"
                                              )
                                                ? "active block"
                                                : "none"
                                              : "none"
                                          }
                                        // className={isActive("/masters/contact_us_list")}
                                        >
                                          <Link
                                            to="/masters/ed-tech/course/ug-courses/ba/reasons"
                                            className="text-decoration-none rounded "
                                          >
                                            <p className="insideDropdown_list">
                                              Reasons
                                            </p>
                                          </Link>
                                        </li>
                                        <li
                                          className={
                                            isOpen
                                              ? headerText.includes(
                                                "/masters/ed-tech/course/ug-courses/ba/what_is"
                                              )
                                                ? "active block"
                                                : "none"
                                              : "none"
                                          }
                                        // className={isActive("/masters/contact_us_list")}
                                        >
                                          <Link
                                            to="/masters/ed-tech/course/ug-courses/ba/what_is"
                                            className="text-decoration-none rounded "
                                          >
                                            <p className="insideDropdown_list">
                                              What Is ?
                                            </p>
                                          </Link>
                                        </li>
                                        <li
                                          className={
                                            isOpen
                                              ? headerText.includes(
                                                "/masters/ed-tech/course/ug-courses/ba/who_qualified"
                                              )
                                                ? "active block"
                                                : "none"
                                              : "none"
                                          }
                                        // className={isActive("/masters/contact_us_list")}
                                        >
                                          <Link
                                            to="/masters/ed-tech/course/ug-courses/ba/who_qualified"
                                            className="text-decoration-none rounded "
                                          >
                                            <p className="insideDropdown_list">
                                              Who Qualified ?
                                            </p>
                                          </Link>
                                        </li>
                                      </ul>
                                    </Accordion.Body>
                                  </Accordion.Item>
                                  <Accordion.Item eventKey="2">
                                    <Accordion.Header>BCA</Accordion.Header>
                                    <Accordion.Body>
                                      <ul>
                                        <li
                                          className={
                                            isOpen
                                              ? headerText.includes(
                                                "/masters/ed-tech/course/ug-courses/bca/banner"
                                              )
                                                ? "active block"
                                                : "none"
                                              : "none"
                                          }
                                        // className={isActive("/masters/contact_us_list")}
                                        >
                                          <Link
                                            to="/masters/ed-tech/course/ug-courses/bca/banner"
                                            className="text-decoration-none rounded "
                                          >
                                            <p className="insideDropdown_list">
                                              banner
                                            </p>
                                          </Link>
                                        </li>
                                        <li
                                          className={
                                            isOpen
                                              ? headerText.includes(
                                                "/masters/ed-tech/course/ug-courses/bca/advantages"
                                              )
                                                ? "active block"
                                                : "none"
                                              : "none"
                                          }
                                        // className={isActive("/masters/contact_us_list")}
                                        >
                                          <Link
                                            to="/masters/ed-tech/course/ug-courses/bca/advantages"
                                            className="text-decoration-none rounded "
                                          >
                                            <p className="insideDropdown_list">
                                              Advantages
                                            </p>
                                          </Link>
                                        </li>
                                        <li
                                          className={
                                            isOpen
                                              ? headerText.includes(
                                                "/masters/ed-tech/course/ug-courses/bca/discover"
                                              )
                                                ? "active block"
                                                : "none"
                                              : "none"
                                          }
                                        // className={isActive("/masters/contact_us_list")}
                                        >
                                          <Link
                                            to="/masters/ed-tech/course/ug-courses/bca/discover"
                                            className="text-decoration-none rounded "
                                          >
                                            <p className="insideDropdown_list">
                                              Discover
                                            </p>
                                          </Link>
                                        </li>
                                        <li
                                          className={
                                            isOpen
                                              ? headerText.includes(
                                                "/masters/ed-tech/course/ug-courses/bca/reasons"
                                              )
                                                ? "active block"
                                                : "none"
                                              : "none"
                                          }
                                        // className={isActive("/masters/contact_us_list")}
                                        >
                                          <Link
                                            to="/masters/ed-tech/course/ug-courses/bca/reasons"
                                            className="text-decoration-none rounded "
                                          >
                                            <p className="insideDropdown_list">
                                              Reasons
                                            </p>
                                          </Link>
                                        </li>
                                        <li
                                          className={
                                            isOpen
                                              ? headerText.includes(
                                                "/masters/ed-tech/course/ug-courses/bca/what_is"
                                              )
                                                ? "active block"
                                                : "none"
                                              : "none"
                                          }
                                        // className={isActive("/masters/contact_us_list")}
                                        >
                                          <Link
                                            to="/masters/ed-tech/course/ug-courses/bca/what_is"
                                            className="text-decoration-none rounded "
                                          >
                                            <p className="insideDropdown_list">
                                              What Is ?
                                            </p>
                                          </Link>
                                        </li>
                                        <li
                                          className={
                                            isOpen
                                              ? headerText.includes(
                                                "/masters/ed-tech/course/ug-courses/bca/who_qualified"
                                              )
                                                ? "active block"
                                                : "none"
                                              : "none"
                                          }
                                        // className={isActive("/masters/contact_us_list")}
                                        >
                                          <Link
                                            to="/masters/ed-tech/course/ug-courses/bca/who_qualified"
                                            className="text-decoration-none rounded "
                                          >
                                            <p className="insideDropdown_list">
                                              Who Qualified ?
                                            </p>
                                          </Link>
                                        </li>
                                      </ul>
                                    </Accordion.Body>
                                  </Accordion.Item>
                                  <Accordion.Item eventKey="3">
                                    <Accordion.Header>B.Com</Accordion.Header>
                                    <Accordion.Body>
                                      <ul>
                                        <li
                                          className={
                                            isOpen
                                              ? headerText.includes(
                                                "/masters/ed-tech/course/ug-courses/bcom/banner"
                                              )
                                                ? "active block"
                                                : "none"
                                              : "none"
                                          }
                                        // className={isActive("/masters/contact_us_list")}
                                        >
                                          <Link
                                            to="/masters/ed-tech/course/ug-courses/bcom/banner"
                                            className="text-decoration-none rounded "
                                          >
                                            <p className="insideDropdown_list">
                                              banner
                                            </p>
                                          </Link>
                                        </li>
                                        <li
                                          className={
                                            isOpen
                                              ? headerText.includes(
                                                "/masters/ed-tech/course/ug-courses/bcom/advantages"
                                              )
                                                ? "active block"
                                                : "none"
                                              : "none"
                                          }
                                        // className={isActive("/masters/contact_us_list")}
                                        >
                                          <Link
                                            to="/masters/ed-tech/course/ug-courses/bcom/advantages"
                                            className="text-decoration-none rounded "
                                          >
                                            <p className="insideDropdown_list">
                                              Advantages
                                            </p>
                                          </Link>
                                        </li>
                                        <li
                                          className={
                                            isOpen
                                              ? headerText.includes(
                                                "/masters/ed-tech/course/ug-courses/bcom/discover"
                                              )
                                                ? "active block"
                                                : "none"
                                              : "none"
                                          }
                                        // className={isActive("/masters/contact_us_list")}
                                        >
                                          <Link
                                            to="/masters/ed-tech/course/ug-courses/bcom/discover"
                                            className="text-decoration-none rounded "
                                          >
                                            <p className="insideDropdown_list">
                                              Discover
                                            </p>
                                          </Link>
                                        </li>
                                        <li
                                          className={
                                            isOpen
                                              ? headerText.includes(
                                                "/masters/ed-tech/course/ug-courses/bcom/reasons"
                                              )
                                                ? "active block"
                                                : "none"
                                              : "none"
                                          }
                                        // className={isActive("/masters/contact_us_list")}
                                        >
                                          <Link
                                            to="/masters/ed-tech/course/ug-courses/bcom/reasons"
                                            className="text-decoration-none rounded "
                                          >
                                            <p className="insideDropdown_list">
                                              Reasons
                                            </p>
                                          </Link>
                                        </li>
                                        <li
                                          className={
                                            isOpen
                                              ? headerText.includes(
                                                "/masters/ed-tech/course/ug-courses/bcom/what_is"
                                              )
                                                ? "active block"
                                                : "none"
                                              : "none"
                                          }
                                        // className={isActive("/masters/contact_us_list")}
                                        >
                                          <Link
                                            to="/masters/ed-tech/course/ug-courses/bcom/what_is"
                                            className="text-decoration-none rounded "
                                          >
                                            <p className="insideDropdown_list">
                                              What Is ?
                                            </p>
                                          </Link>
                                        </li>
                                        <li
                                          className={
                                            isOpen
                                              ? headerText.includes(
                                                "/masters/ed-tech/course/ug-courses/bcom/who_qualified"
                                              )
                                                ? "active block"
                                                : "none"
                                              : "none"
                                          }
                                        // className={isActive("/masters/contact_us_list")}
                                        >
                                          <Link
                                            to="/masters/ed-tech/course/ug-courses/bcom/who_qualified"
                                            className="text-decoration-none rounded "
                                          >
                                            <p className="insideDropdown_list">
                                              Who Qualified ?
                                            </p>
                                          </Link>
                                        </li>
                                      </ul>
                                    </Accordion.Body>
                                  </Accordion.Item>
                                  <Accordion.Item eventKey="4">
                                    <Accordion.Header>B.sc</Accordion.Header>
                                    <Accordion.Body>
                                      <ul>
                                        <li
                                          className={
                                            isOpen
                                              ? headerText.includes(
                                                "/masters/ed-tech/course/ug-courses/bsc/banner"
                                              )
                                                ? "active block"
                                                : "none"
                                              : "none"
                                          }
                                        // className={isActive("/masters/contact_us_list")}
                                        >
                                          <Link
                                            to="/masters/ed-tech/course/ug-courses/bsc/banner"
                                            className="text-decoration-none rounded "
                                          >
                                            <p className="insideDropdown_list">
                                              banner
                                            </p>
                                          </Link>
                                        </li>
                                        <li
                                          className={
                                            isOpen
                                              ? headerText.includes(
                                                "/masters/ed-tech/course/ug-courses/bsc/advantages"
                                              )
                                                ? "active block"
                                                : "none"
                                              : "none"
                                          }
                                        // className={isActive("/masters/contact_us_list")}
                                        >
                                          <Link
                                            to="/masters/ed-tech/course/ug-courses/bsc/advantages"
                                            className="text-decoration-none rounded "
                                          >
                                            <p className="insideDropdown_list">
                                              Advantages
                                            </p>
                                          </Link>
                                        </li>
                                        <li
                                          className={
                                            isOpen
                                              ? headerText.includes(
                                                "/masters/ed-tech/course/ug-courses/bsc/discover"
                                              )
                                                ? "active block"
                                                : "none"
                                              : "none"
                                          }
                                        // className={isActive("/masters/contact_us_list")}
                                        >
                                          <Link
                                            to="/masters/ed-tech/course/ug-courses/bsc/discover"
                                            className="text-decoration-none rounded "
                                          >
                                            <p className="insideDropdown_list">
                                              Discover
                                            </p>
                                          </Link>
                                        </li>
                                        <li
                                          className={
                                            isOpen
                                              ? headerText.includes(
                                                "/masters/ed-tech/course/ug-courses/bsc/reasons"
                                              )
                                                ? "active block"
                                                : "none"
                                              : "none"
                                          }
                                        // className={isActive("/masters/contact_us_list")}
                                        >
                                          <Link
                                            to="/masters/ed-tech/course/ug-courses/bsc/reasons"
                                            className="text-decoration-none rounded "
                                          >
                                            <p className="insideDropdown_list">
                                              Reasons
                                            </p>
                                          </Link>
                                        </li>
                                        <li
                                          className={
                                            isOpen
                                              ? headerText.includes(
                                                "/masters/ed-tech/course/ug-courses/bsc/what_is"
                                              )
                                                ? "active block"
                                                : "none"
                                              : "none"
                                          }
                                        // className={isActive("/masters/contact_us_list")}
                                        >
                                          <Link
                                            to="/masters/ed-tech/course/ug-courses/bsc/what_is"
                                            className="text-decoration-none rounded "
                                          >
                                            <p className="insideDropdown_list">
                                              What Is ?
                                            </p>
                                          </Link>
                                        </li>
                                        <li
                                          className={
                                            isOpen
                                              ? headerText.includes(
                                                "/masters/ed-tech/course/ug-courses/bsc/who_qualified"
                                              )
                                                ? "active block"
                                                : "none"
                                              : "none"
                                          }
                                        // className={isActive("/masters/contact_us_list")}
                                        >
                                          <Link
                                            to="/masters/ed-tech/course/ug-courses/bsc/who_qualified"
                                            className="text-decoration-none rounded "
                                          >
                                            <p className="insideDropdown_list">
                                              Who Qualified ?
                                            </p>
                                          </Link>
                                        </li>
                                      </ul>
                                    </Accordion.Body>
                                  </Accordion.Item>
                                  <Accordion.Item eventKey="5">
                                    <Accordion.Header>B.lib</Accordion.Header>
                                    <Accordion.Body>
                                      <ul>
                                        <li
                                          className={
                                            isOpen
                                              ? headerText.includes(
                                                "/masters/ed-tech/course/ug-courses/blib/banner"
                                              )
                                                ? "active block"
                                                : "none"
                                              : "none"
                                          }
                                        // className={isActive("/masters/contact_us_list")}
                                        >
                                          <Link
                                            to="/masters/ed-tech/course/ug-courses/blib/banner"
                                            className="text-decoration-none rounded "
                                          >
                                            <p className="insideDropdown_list">
                                              banner
                                            </p>
                                          </Link>
                                        </li>

                                        <li
                                          className={
                                            isOpen
                                              ? headerText.includes(
                                                "/masters/ed-tech/course/ug-courses/blib/reasons"
                                              )
                                                ? "active block"
                                                : "none"
                                              : "none"
                                          }
                                        // className={isActive("/masters/contact_us_list")}
                                        >
                                          <Link
                                            to="/masters/ed-tech/course/ug-courses/blib/reasons"
                                            className="text-decoration-none rounded "
                                          >
                                            <p className="insideDropdown_list">
                                              Reasons
                                            </p>
                                          </Link>
                                        </li>
                                        <li
                                          className={
                                            isOpen
                                              ? headerText.includes(
                                                "/masters/ed-tech/course/ug-courses/blib/what_is"
                                              )
                                                ? "active block"
                                                : "none"
                                              : "none"
                                          }
                                        // className={isActive("/masters/contact_us_list")}
                                        >
                                          <Link
                                            to="/masters/ed-tech/course/ug-courses/blib/what_is"
                                            className="text-decoration-none rounded "
                                          >
                                            <p className="insideDropdown_list">
                                              What Is ?
                                            </p>
                                          </Link>
                                        </li>
                                        <li
                                          className={
                                            isOpen
                                              ? headerText.includes(
                                                "/masters/ed-tech/course/ug-courses/blib/who_qualified"
                                              )
                                                ? "active block"
                                                : "none"
                                              : "none"
                                          }
                                        // className={isActive("/masters/contact_us_list")}
                                        >
                                          <Link
                                            to="/masters/ed-tech/course/ug-courses/blib/who_qualified"
                                            className="text-decoration-none rounded "
                                          >
                                            <p className="insideDropdown_list">
                                              Who Qualified ?
                                            </p>
                                          </Link>
                                        </li>
                                      </ul>
                                    </Accordion.Body>
                                  </Accordion.Item>
                                </Accordion>
                              </Accordion.Body>
                            </Accordion.Item> */}

                            {/* <Accordion.Item eventKey="1">
                              <Accordion.Header>
                                Post Gradudate
                              </Accordion.Header>
                              <Accordion.Body>
                                <Accordion>
                                  <Accordion.Item eventKey="0">
                                    <Accordion.Header>MBA</Accordion.Header>
                                    <Accordion.Body>
                                      <ul>
                                        <li
                                          className={
                                            isOpen
                                              ? headerText.includes(
                                                "masters/ed-tech/course/pg-course/mba/banner"
                                              )
                                                ? "active block"
                                                : "none"
                                              : "none"
                                          }
                                        // className={isActive("/masters/contact_us_list")}
                                        >
                                          <Link
                                            to="masters/ed-tech/course/pg-course/mba/banner"
                                            className="text-decoration-none rounded "
                                          >
                                            <p className="insideDropdown_list">
                                              Banner
                                            </p>
                                          </Link>
                                        </li>
                                        <li
                                          className={
                                            isOpen
                                              ? headerText.includes(
                                                "masters/ed-tech/course/pg-course/mba/what"
                                              )
                                                ? "active block"
                                                : "none"
                                              : "none"
                                          }
                                        // className={isActive("/masters/contact_us_list")}
                                        >
                                          <Link
                                            to="masters/ed-tech/course/pg-course/mba/what"
                                            className="text-decoration-none rounded "
                                          >
                                            <p className="insideDropdown_list">
                                              What
                                            </p>
                                          </Link>
                                        </li>

                                        <li
                                          className={
                                            isOpen
                                              ? headerText.includes(
                                                "/masters/ed-tech/course/pg-course/mba/reasons"
                                              )
                                                ? "active block"
                                                : "none"
                                              : "none"
                                          }
                                        // className={isActive("/masters/contact_us_list")}
                                        >
                                          <Link
                                            to="/masters/ed-tech/course/pg-course/mba/reasons"
                                            className="text-decoration-none rounded "
                                          >
                                            <p className="insideDropdown_list">
                                              Reason
                                            </p>
                                          </Link>
                                        </li>
                                        <li
                                          className={
                                            isOpen
                                              ? headerText.includes(
                                                "/masters/ed-tech/course/pg-course/mba/discover"
                                              )
                                                ? "active block"
                                                : "none"
                                              : "none"
                                          }
                                        // className={isActive("/masters/contact_us_list")}
                                        >
                                          <Link
                                            to="/masters/ed-tech/course/pg-course/mba/discover"
                                            className="text-decoration-none rounded "
                                          >
                                            <p className="insideDropdown_list">
                                              Discover
                                            </p>
                                          </Link>
                                        </li>
                                        <li
                                          className={
                                            isOpen
                                              ? headerText.includes(
                                                "/masters/ed-tech/course/pg-course/mba/advantages"
                                              )
                                                ? "active block"
                                                : "none"
                                              : "none"
                                          }
                                        // className={isActive("/masters/contact_us_list")}
                                        >
                                          <Link
                                            to="/masters/ed-tech/course/pg-course/mba/advantages"
                                            className="text-decoration-none rounded "
                                          >
                                            <p className="insideDropdown_list">
                                              Advantages
                                            </p>
                                          </Link>
                                        </li>
                                        <li
                                          className={
                                            isOpen
                                              ? headerText.includes(
                                                "/masters/ed-tech/course/pg-course/mba/who_qualified"
                                              )
                                                ? "active block"
                                                : "none"
                                              : "none"
                                          }
                                        // className={isActive("/masters/contact_us_list")}
                                        >
                                          <Link
                                            to="/masters/ed-tech/course/pg-course/mba/who_qualified"
                                            className="text-decoration-none rounded "
                                          >
                                            <p className="insideDropdown_list">
                                              Who Qualified
                                            </p>
                                          </Link>
                                        </li>
                                      </ul>
                                    </Accordion.Body>
                                  </Accordion.Item>
                                  <Accordion.Item eventKey="1">
                                    <Accordion.Header>MCA</Accordion.Header>
                                    <Accordion.Body>
                                      <ul>
                                        <li
                                          className={
                                            isOpen
                                              ? headerText.includes(
                                                "masters/ed-tech/course/pg-course/mca/banner"
                                              )
                                                ? "active block"
                                                : "none"
                                              : "none"
                                          }
                                        // className={isActive("/masters/contact_us_list")}
                                        >
                                          <Link
                                            to="masters/ed-tech/course/pg-course/mca/banner"
                                            className="text-decoration-none rounded "
                                          >
                                            <p className="insideDropdown_list">
                                              Banner
                                            </p>
                                          </Link>
                                        </li>
                                        <li
                                          className={
                                            isOpen
                                              ? headerText.includes(
                                                "masters/ed-tech/course/pg-course/mca/what"
                                              )
                                                ? "active block"
                                                : "none"
                                              : "none"
                                          }
                                        // className={isActive("/masters/contact_us_list")}
                                        >
                                          <Link
                                            to="masters/ed-tech/course/pg-course/mca/what"
                                            className="text-decoration-none rounded "
                                          >
                                            <p className="insideDropdown_list">
                                              What
                                            </p>
                                          </Link>
                                        </li>

                                        <li
                                          className={
                                            isOpen
                                              ? headerText.includes(
                                                "/masters/ed-tech/course/pg-course/mca/reasons"
                                              )
                                                ? "active block"
                                                : "none"
                                              : "none"
                                          }
                                        // className={isActive("/masters/contact_us_list")}
                                        >
                                          <Link
                                            to="/masters/ed-tech/course/pg-course/mca/reasons"
                                            className="text-decoration-none rounded "
                                          >
                                            <p className="insideDropdown_list">
                                              Reason
                                            </p>
                                          </Link>
                                        </li>
                                        <li
                                          className={
                                            isOpen
                                              ? headerText.includes(
                                                "/masters/ed-tech/course/pg-course/mca/discover"
                                              )
                                                ? "active block"
                                                : "none"
                                              : "none"
                                          }
                                        // className={isActive("/masters/contact_us_list")}
                                        >
                                          <Link
                                            to="/masters/ed-tech/course/pg-course/mca/discover"
                                            className="text-decoration-none rounded "
                                          >
                                            <p className="insideDropdown_list">
                                              Discover
                                            </p>
                                          </Link>
                                        </li>
                                        <li
                                          className={
                                            isOpen
                                              ? headerText.includes(
                                                "/masters/ed-tech/course/pg-course/mca/advantages"
                                              )
                                                ? "active block"
                                                : "none"
                                              : "none"
                                          }
                                        // className={isActive("/masters/contact_us_list")}
                                        >
                                          <Link
                                            to="/masters/ed-tech/course/pg-course/mca/advantages"
                                            className="text-decoration-none rounded "
                                          >
                                            <p className="insideDropdown_list">
                                              Advantages
                                            </p>
                                          </Link>
                                        </li>
                                        <li
                                          className={
                                            isOpen
                                              ? headerText.includes(
                                                "/masters/ed-tech/course/pg-course/mca/who_qualified"
                                              )
                                                ? "active block"
                                                : "none"
                                              : "none"
                                          }
                                        // className={isActive("/masters/contact_us_list")}
                                        >
                                          <Link
                                            to="/masters/ed-tech/course/pg-course/mca/who_qualified"
                                            className="text-decoration-none rounded "
                                          >
                                            <p className="insideDropdown_list">
                                              Who Qualified
                                            </p>
                                          </Link>
                                        </li>
                                      </ul>
                                    </Accordion.Body>
                                  </Accordion.Item>
                                  <Accordion.Item eventKey="2">
                                    <Accordion.Header>M.Com</Accordion.Header>
                                    <Accordion.Body>
                                      <ul>
                                        <li
                                          className={
                                            isOpen
                                              ? headerText.includes(
                                                "masters/ed-tech/course/pg-course/mcom/banner"
                                              )
                                                ? "active block"
                                                : "none"
                                              : "none"
                                          }
                                        // className={isActive("/masters/contact_us_list")}
                                        >
                                          <Link
                                            to="masters/ed-tech/course/pg-course/mcom/banner"
                                            className="text-decoration-none rounded "
                                          >
                                            <p className="insideDropdown_list">
                                              Banner
                                            </p>
                                          </Link>
                                        </li>
                                        <li
                                          className={
                                            isOpen
                                              ? headerText.includes(
                                                "masters/ed-tech/course/pg-course/mcom/what"
                                              )
                                                ? "active block"
                                                : "none"
                                              : "none"
                                          }
                                        // className={isActive("/masters/contact_us_list")}
                                        >
                                          <Link
                                            to="masters/ed-tech/course/pg-course/mcom/what"
                                            className="text-decoration-none rounded "
                                          >
                                            <p className="insideDropdown_list">
                                              What
                                            </p>
                                          </Link>
                                        </li>

                                        <li
                                          className={
                                            isOpen
                                              ? headerText.includes(
                                                "/masters/ed-tech/course/pg-course/mcom/reasons"
                                              )
                                                ? "active block"
                                                : "none"
                                              : "none"
                                          }
                                        // className={isActive("/masters/contact_us_list")}
                                        >
                                          <Link
                                            to="/masters/ed-tech/course/pg-course/mcom/reasons"
                                            className="text-decoration-none rounded "
                                          >
                                            <p className="insideDropdown_list">
                                              Reason
                                            </p>
                                          </Link>
                                        </li>
                                        <li
                                          className={
                                            isOpen
                                              ? headerText.includes(
                                                "/masters/ed-tech/course/pg-course/mcom/discover"
                                              )
                                                ? "active block"
                                                : "none"
                                              : "none"
                                          }
                                        // className={isActive("/masters/contact_us_list")}
                                        >
                                          <Link
                                            to="/masters/ed-tech/course/pg-course/mcom/discover"
                                            className="text-decoration-none rounded "
                                          >
                                            <p className="insideDropdown_list">
                                              Discover
                                            </p>
                                          </Link>
                                        </li>
                                        <li
                                          className={
                                            isOpen
                                              ? headerText.includes(
                                                "/masters/ed-tech/course/pg-course/mcom/advantages"
                                              )
                                                ? "active block"
                                                : "none"
                                              : "none"
                                          }
                                        // className={isActive("/masters/contact_us_list")}
                                        >
                                          <Link
                                            to="/masters/ed-tech/course/pg-course/mcom/advantages"
                                            className="text-decoration-none rounded "
                                          >
                                            <p className="insideDropdown_list">
                                              Advantages
                                            </p>
                                          </Link>
                                        </li>
                                        <li
                                          className={
                                            isOpen
                                              ? headerText.includes(
                                                "/masters/ed-tech/course/pg-course/mcom/who_qualified"
                                              )
                                                ? "active block"
                                                : "none"
                                              : "none"
                                          }
                                        // className={isActive("/masters/contact_us_list")}
                                        >
                                          <Link
                                            to="/masters/ed-tech/course/pg-course/mcom/who_qualified"
                                            className="text-decoration-none rounded "
                                          >
                                            <p className="insideDropdown_list">
                                              Who Qualified
                                            </p>
                                          </Link>
                                        </li>
                                      </ul>
                                    </Accordion.Body>
                                  </Accordion.Item>
                                  <Accordion.Item eventKey="3">
                                    <Accordion.Header>MA</Accordion.Header>
                                    <Accordion.Body>
                                      <ul>
                                        <li
                                          className={
                                            isOpen
                                              ? headerText.includes(
                                                "masters/ed-tech/course/pg-course/ma/banner"
                                              )
                                                ? "active block"
                                                : "none"
                                              : "none"
                                          }
                                        // className={isActive("/masters/contact_us_list")}
                                        >
                                          <Link
                                            to="masters/ed-tech/course/pg-course/ma/banner"
                                            className="text-decoration-none rounded "
                                          >
                                            <p className="insideDropdown_list">
                                              Banner
                                            </p>
                                          </Link>
                                        </li>
                                        <li
                                          className={
                                            isOpen
                                              ? headerText.includes(
                                                "masters/ed-tech/course/pg-course/ma/what"
                                              )
                                                ? "active block"
                                                : "none"
                                              : "none"
                                          }
                                        // className={isActive("/masters/contact_us_list")}
                                        >
                                          <Link
                                            to="masters/ed-tech/course/pg-course/ma/what"
                                            className="text-decoration-none rounded "
                                          >
                                            <p className="insideDropdown_list">
                                              What
                                            </p>
                                          </Link>
                                        </li>

                                        <li
                                          className={
                                            isOpen
                                              ? headerText.includes(
                                                "/masters/ed-tech/course/pg-course/ma/reasons"
                                              )
                                                ? "active block"
                                                : "none"
                                              : "none"
                                          }
                                        // className={isActive("/masters/contact_us_list")}
                                        >
                                          <Link
                                            to="/masters/ed-tech/course/pg-course/ma/reasons"
                                            className="text-decoration-none rounded "
                                          >
                                            <p className="insideDropdown_list">
                                              Reason
                                            </p>
                                          </Link>
                                        </li>
                                        <li
                                          className={
                                            isOpen
                                              ? headerText.includes(
                                                "/masters/ed-tech/course/pg-course/ma/discover"
                                              )
                                                ? "active block"
                                                : "none"
                                              : "none"
                                          }
                                        // className={isActive("/masters/contact_us_list")}
                                        >
                                          <Link
                                            to="/masters/ed-tech/course/pg-course/ma/discover"
                                            className="text-decoration-none rounded "
                                          >
                                            <p className="insideDropdown_list">
                                              Discover
                                            </p>
                                          </Link>
                                        </li>
                                        <li
                                          className={
                                            isOpen
                                              ? headerText.includes(
                                                "/masters/ed-tech/course/pg-course/ma/advantages"
                                              )
                                                ? "active block"
                                                : "none"
                                              : "none"
                                          }
                                        // className={isActive("/masters/contact_us_list")}
                                        >
                                          <Link
                                            to="/masters/ed-tech/course/pg-course/ma/advantages"
                                            className="text-decoration-none rounded "
                                          >
                                            <p className="insideDropdown_list">
                                              Advantages
                                            </p>
                                          </Link>
                                        </li>
                                        <li
                                          className={
                                            isOpen
                                              ? headerText.includes(
                                                "/masters/ed-tech/course/pg-course/ma/who_qualified"
                                              )
                                                ? "active block"
                                                : "none"
                                              : "none"
                                          }
                                        // className={isActive("/masters/contact_us_list")}
                                        >
                                          <Link
                                            to="/masters/ed-tech/course/pg-course/ma/who_qualified"
                                            className="text-decoration-none rounded "
                                          >
                                            <p className="insideDropdown_list">
                                              Who Qualified
                                            </p>
                                          </Link>
                                        </li>

                                        <li
                                          className={
                                            isOpen
                                              ? headerText.includes(
                                                "/masters/ed-tech/course/pg-course/ma/video"
                                              )
                                                ? "active block"
                                                : "none"
                                              : "none"
                                          }
                                        // className={isActive("/masters/contact_us_list")}
                                        >
                                          <Link
                                            to="/masters/ed-tech/course/pg-course/ma/video"
                                            className="text-decoration-none rounded "
                                          >
                                            <p className="insideDropdown_list">
                                              Video
                                            </p>
                                          </Link>
                                        </li>
                                      </ul>
                                    </Accordion.Body>
                                  </Accordion.Item>
                                  <Accordion.Item eventKey="4">
                                    <Accordion.Header>MSC</Accordion.Header>
                                    <Accordion.Body>
                                      <ul>
                                        <li
                                          className={
                                            isOpen
                                              ? headerText.includes(
                                                "masters/ed-tech/course/pg-course/msc/banner"
                                              )
                                                ? "active block"
                                                : "none"
                                              : "none"
                                          }
                                        // className={isActive("/masters/contact_us_list")}
                                        >
                                          <Link
                                            to="masters/ed-tech/course/pg-course/msc/banner"
                                            className="text-decoration-none rounded "
                                          >
                                            <p className="insideDropdown_list">
                                              Banner
                                            </p>
                                          </Link>
                                        </li>
                                        <li
                                          className={
                                            isOpen
                                              ? headerText.includes(
                                                "masters/ed-tech/course/pg-course/msc/what"
                                              )
                                                ? "active block"
                                                : "none"
                                              : "none"
                                          }
                                        // className={isActive("/masters/contact_us_list")}
                                        >
                                          <Link
                                            to="masters/ed-tech/course/pg-course/msc/what"
                                            className="text-decoration-none rounded "
                                          >
                                            <p className="insideDropdown_list">
                                              What
                                            </p>
                                          </Link>
                                        </li>

                                        <li
                                          className={
                                            isOpen
                                              ? headerText.includes(
                                                "/masters/ed-tech/course/pg-course/msc/reasons"
                                              )
                                                ? "active block"
                                                : "none"
                                              : "none"
                                          }
                                        // className={isActive("/masters/contact_us_list")}
                                        >
                                          <Link
                                            to="/masters/ed-tech/course/pg-course/msc/reasons"
                                            className="text-decoration-none rounded "
                                          >
                                            <p className="insideDropdown_list">
                                              Reason
                                            </p>
                                          </Link>
                                        </li>

                                        <li
                                          className={
                                            isOpen
                                              ? headerText.includes(
                                                "/masters/ed-tech/course/pg-course/msc/who_qualified"
                                              )
                                                ? "active block"
                                                : "none"
                                              : "none"
                                          }
                                        // className={isActive("/masters/contact_us_list")}
                                        >
                                          <Link
                                            to="/masters/ed-tech/course/pg-course/msc/who_qualified"
                                            className="text-decoration-none rounded "
                                          >
                                            <p className="insideDropdown_list">
                                              Who Qualified
                                            </p>
                                          </Link>
                                        </li>
                                      </ul>
                                    </Accordion.Body>
                                  </Accordion.Item>
                                  <Accordion.Item eventKey="4">
                                    <Accordion.Header>MLIB</Accordion.Header>
                                    <Accordion.Body>
                                      <ul>
                                        <li
                                          className={
                                            isOpen
                                              ? headerText.includes(
                                                "masters/ed-tech/course/pg-course/mlib/banner"
                                              )
                                                ? "active block"
                                                : "none"
                                              : "none"
                                          }
                                        // className={isActive("/masters/contact_us_list")}
                                        >
                                          <Link
                                            to="masters/ed-tech/course/pg-course/mlib/banner"
                                            className="text-decoration-none rounded "
                                          >
                                            <p className="insideDropdown_list">
                                              Banner
                                            </p>
                                          </Link>
                                        </li>
                                        <li
                                          className={
                                            isOpen
                                              ? headerText.includes(
                                                "masters/ed-tech/course/pg-course/mlib/what"
                                              )
                                                ? "active block"
                                                : "none"
                                              : "none"
                                          }
                                        // className={isActive("/masters/contact_us_list")}
                                        >
                                          <Link
                                            to="masters/ed-tech/course/pg-course/mlib/what"
                                            className="text-decoration-none rounded "
                                          >
                                            <p className="insideDropdown_list">
                                              What
                                            </p>
                                          </Link>
                                        </li>

                                        <li
                                          className={
                                            isOpen
                                              ? headerText.includes(
                                                "/masters/ed-tech/course/pg-course/mlib/reasons"
                                              )
                                                ? "active block"
                                                : "none"
                                              : "none"
                                          }
                                        // className={isActive("/masters/contact_us_list")}
                                        >
                                          <Link
                                            to="/masters/ed-tech/course/pg-course/mlib/reasons"
                                            className="text-decoration-none rounded "
                                          >
                                            <p className="insideDropdown_list">
                                              Reason
                                            </p>
                                          </Link>
                                        </li>

                                        <li
                                          className={
                                            isOpen
                                              ? headerText.includes(
                                                "/masters/ed-tech/course/pg-course/mlib/who_qualified"
                                              )
                                                ? "active block"
                                                : "none"
                                              : "none"
                                          }
                                        // className={isActive("/masters/contact_us_list")}
                                        >
                                          <Link
                                            to="/masters/ed-tech/course/pg-course/mlib/who_qualified"
                                            className="text-decoration-none rounded "
                                          >
                                            <p className="insideDropdown_list">
                                              Who Qualified
                                            </p>
                                          </Link>
                                        </li>
                                      </ul>
                                    </Accordion.Body>
                                  </Accordion.Item>
                                </Accordion>
                              </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="2">
                              <Accordion.Header>Certification</Accordion.Header>
                              <Accordion.Body>
                                <Accordion>
                                  <Accordion.Item eventKey="0">
                                    <Accordion.Header>Diploma</Accordion.Header>
                                    <Accordion.Body>
                                      <ul>
                                        <li
                                          className={
                                            isOpen
                                              ? headerText.includes(
                                                "/masters/ed-tech/course/certification/diploma/banner"
                                              )
                                                ? "active block"
                                                : "none"
                                              : "none"
                                          }
                                        // className={isActive("/masters/contact_us_list")}
                                        >
                                          <Link
                                            to="/masters/ed-tech/course/certification/diploma/banner"
                                            className="text-decoration-none rounded "
                                          >
                                            <p className="insideDropdown_list">
                                              Banner
                                            </p>
                                          </Link>
                                        </li>
                                        <li
                                          className={
                                            isOpen
                                              ? headerText.includes(
                                                "/masters/ed-tech/course/certification/diploma/online-diploma"
                                              )
                                                ? "active block"
                                                : "none"
                                              : "none"
                                          }
                                        // className={isActive("/masters/contact_us_list")}
                                        >
                                          <Link
                                            to="/masters/ed-tech/course/certification/diploma/online-diploma"
                                            className="text-decoration-none rounded "
                                          >
                                            <p className="insideDropdown_list">
                                              Online Diploma
                                            </p>
                                          </Link>
                                        </li>
                                        <li
                                          className={
                                            isOpen
                                              ? headerText.includes(
                                                "/masters/ed-tech/course/certification/diploma/top-reason"
                                              )
                                                ? "active block"
                                                : "none"
                                              : "none"
                                          }
                                        // className={isActive("/masters/contact_us_list")}
                                        >
                                          <Link
                                            to="/masters/ed-tech/course/certification/diploma/top-reason"
                                            className="text-decoration-none rounded "
                                          >
                                            <p className="insideDropdown_list">
                                              Top Reason
                                            </p>
                                          </Link>
                                        </li>
                                        <li
                                          className={
                                            isOpen
                                              ? headerText.includes(
                                                "/masters/ed-tech/course/certification/diploma/discover-the-power"
                                              )
                                                ? "active block"
                                                : "none"
                                              : "none"
                                          }
                                        // className={isActive("/masters/contact_us_list")}
                                        >
                                          <Link
                                            to="/masters/ed-tech/course/certification/diploma/discover-the-power"
                                            className="text-decoration-none rounded "
                                          >
                                            <p className="insideDropdown_list">
                                              Discover The Power
                                            </p>
                                          </Link>
                                        </li>
                                        <li
                                          className={
                                            isOpen
                                              ? headerText.includes(
                                                "/masters/ed-tech/course/certification/diploma/who-qualified"
                                              )
                                                ? "active block"
                                                : "none"
                                              : "none"
                                          }
                                        // className={isActive("/masters/contact_us_list")}
                                        >
                                          <Link
                                            to="/masters/ed-tech/course/certification/diploma/who-qualified"
                                            className="text-decoration-none rounded "
                                          >
                                            <p className="insideDropdown_list">
                                              Who Qualified
                                            </p>
                                          </Link>
                                        </li>
                                        <li
                                          className={
                                            isOpen
                                              ? headerText.includes(
                                                "/masters/ed-tech/course/certification/diploma/section-two"
                                              )
                                                ? "active block"
                                                : "none"
                                              : "none"
                                          }
                                        // className={isActive("/masters/contact_us_list")}
                                        >
                                          <Link
                                            to="/masters/ed-tech/course/certification/diploma/section-two"
                                            className="text-decoration-none rounded "
                                          >
                                            <p className="insideDropdown_list">
                                              Online Diploma Section Two
                                            </p>
                                          </Link>
                                        </li>

                                        <li
                                          className={
                                            isOpen
                                              ? headerText.includes(
                                                "/masters/ed-tech/course/certification/diploma/video"
                                              )
                                                ? "active block"
                                                : "none"
                                              : "none"
                                          }
                                        // className={isActive("/masters/contact_us_list")}
                                        >
                                          <Link
                                            to="/masters/ed-tech/course/certification/diploma/video"
                                            className="text-decoration-none rounded "
                                          >
                                            <p className="insideDropdown_list">
                                              Online Diploma Video
                                            </p>
                                          </Link>
                                        </li>
                                      </ul>
                                    </Accordion.Body>
                                  </Accordion.Item>
                                </Accordion>
                              </Accordion.Body>
                            </Accordion.Item> */}

                            <Accordion.Item eventKey="3">
                              <Accordion.Header>Courses</Accordion.Header>
                              {/* <Accordion.Body>
                                <Accordion>
                                  <Accordion.Header>
                                    Course
                                  </Accordion.Header> */}
                              <Accordion.Body>
                                <ul>
                                  <li
                                    className={
                                      isOpen
                                        ? headerText.includes(
                                          "/masters/ed-tech/course/common/banner"
                                        )
                                          ? "active block"
                                          : "none"
                                        : "none"
                                    }
                                  // className={isActive("/masters/contact_us_list")}
                                  >
                                    <Link
                                      to="/masters/ed-tech/course/common/banner"
                                      className="text-decoration-none rounded "
                                    >
                                      <p className="insideDropdown_list">
                                        Banner
                                      </p>
                                    </Link>
                                  </li>

                                  <li
                                    className={
                                      isOpen
                                        ? headerText.includes(
                                          "/masters/ed-tech/course/common/small-banner"
                                        )
                                          ? "active block"
                                          : "none"
                                        : "none"
                                    }
                                  // className={isActive("/masters/contact_us_list")}
                                  >
                                    <Link
                                      to="/masters/ed-tech/course/common/small-banner"
                                      className="text-decoration-none rounded "
                                    >
                                      <p className="insideDropdown_list">
                                        Small Banner
                                      </p>
                                    </Link>
                                  </li>

                                  <li
                                    className={
                                      isOpen
                                        ? headerText.includes(
                                          "/masters/ed-tech/course/common/what"
                                        )
                                          ? "active block"
                                          : "none"
                                        : "none"
                                    }
                                  // className={isActive("/masters/contact_us_list")}
                                  >
                                    <Link
                                      to="/masters/ed-tech/course/common/what"
                                      className="text-decoration-none rounded "
                                    >
                                      <p className="insideDropdown_list">
                                        What
                                      </p>
                                    </Link>
                                  </li>

                                  <li
                                    className={
                                      isOpen
                                        ? headerText.includes(
                                          "/masters/ed-tech/course/common/reason"
                                        )
                                          ? "active block"
                                          : "none"
                                        : "none"
                                    }
                                  // className={isActive("/masters/contact_us_list")}
                                  >
                                    <Link
                                      to="/masters/ed-tech/course/common/reason"
                                      className="text-decoration-none rounded "
                                    >
                                      <p className="insideDropdown_list">
                                        Reason
                                      </p>
                                    </Link>
                                  </li>

                                  <li
                                    className={
                                      isOpen
                                        ? headerText.includes(
                                          "/masters/ed-tech/course/common/who-qualified"
                                        )
                                          ? "active block"
                                          : "none"
                                        : "none"
                                    }
                                  // className={isActive("/masters/contact_us_list")}
                                  >
                                    <Link
                                      to="/masters/ed-tech/course/common/who-qualified"
                                      className="text-decoration-none rounded "
                                    >
                                      <p className="insideDropdown_list">
                                        Who Qualified
                                      </p>
                                    </Link>
                                  </li>

                                  <li
                                    className={
                                      isOpen
                                        ? headerText.includes(
                                          "/masters/ed-tech/course/common/discover"
                                        )
                                          ? "active block"
                                          : "none"
                                        : "none"
                                    }
                                  // className={isActive("/masters/contact_us_list")}
                                  >
                                    <Link
                                      to="/masters/ed-tech/course/common/discover"
                                      className="text-decoration-none rounded "
                                    >
                                      <p className="insideDropdown_list">
                                        Discover
                                      </p>
                                    </Link>
                                  </li>

                                  <li
                                    className={
                                      isOpen
                                        ? headerText.includes(
                                          "/masters/ed-tech/course/common/advantages"
                                        )
                                          ? "active block"
                                          : "none"
                                        : "none"
                                    }
                                  // className={isActive("/masters/contact_us_list")}
                                  >
                                    <Link
                                      to="/masters/ed-tech/course/common/advantages"
                                      className="text-decoration-none rounded "
                                    >
                                      <p className="insideDropdown_list">
                                        Advantages
                                      </p>
                                    </Link>
                                  </li>
                                </ul>
                              </Accordion.Body>
                              {/* </Accordion>
                              </Accordion.Body> */}
                            </Accordion.Item>
                          </Accordion>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="04" className="mb-1">
                        <Accordion.Header>Universities</Accordion.Header>
                        <Accordion.Body>
                          <Accordion className="child-sub">

                            <Accordion.Item eventKey="3">
                              <Accordion.Header>
                                Univerties Settings
                              </Accordion.Header>
                              <Accordion.Body>
                                <Accordion.Body>
                                  <ul>
                                    <li
                                      className={
                                        isOpen
                                          ? headerText.includes(
                                            "/masters/ed-tech/university/common/banner"
                                          )
                                            ? "active block"
                                            : "none"
                                          : "none"
                                      }
                                    // className={isActive("/masters/contact_us_list")}
                                    >
                                      <Link
                                        to="/masters/ed-tech/university/common/banner"
                                        className="text-decoration-none rounded "
                                      >
                                        <p className="insideDropdown_list">
                                          Banner
                                        </p>
                                      </Link>
                                    </li>



                                    <li
                                      className={
                                        isOpen
                                          ? headerText.includes(
                                            "/masters/ed-tech/university/common/section-two"
                                          )
                                            ? "active block"
                                            : "none"
                                          : "none"
                                      }
                                    // className={isActive("/masters/contact_us_list")}
                                    >
                                      <Link
                                        to="/masters/ed-tech/university/common/section-two"
                                        className="text-decoration-none rounded "
                                      >
                                        <p className="insideDropdown_list">
                                          University Description
                                        </p>
                                      </Link>
                                    </li>

                                    <li
                                      className={
                                        isOpen
                                          ? headerText.includes(
                                            "/masters/ed-tech/university/common/future"
                                          )
                                            ? "active block"
                                            : "none"
                                          : "none"
                                      }
                                    // className={isActive("/masters/contact_us_list")}
                                    >
                                      <Link
                                        to="/masters/ed-tech/university/common/future"
                                        className="text-decoration-none rounded "
                                      >
                                        <p className="insideDropdown_list">
                                          We Deliver
                                        </p>
                                      </Link>
                                    </li>


                                    <li
                                      className={
                                        isOpen
                                          ? headerText.includes(
                                            "/masters/ed-tech/university/common/program-offered"
                                          )
                                            ? "active block"
                                            : "none"
                                          : "none"
                                      }
                                    // className={isActive("/masters/contact_us_list")}
                                    >
                                      <Link
                                        to="/masters/ed-tech/university/common/program-offered"
                                        className="text-decoration-none rounded "
                                      >
                                        <p className="insideDropdown_list">
                                          Program Offered
                                        </p>
                                      </Link>
                                    </li>
                                    <li
                                      className={
                                        isOpen
                                          ? headerText.includes(
                                            "/masters/ed-tech/university/common/two"
                                          )
                                            ? "active block"
                                            : "none"
                                          : "none"
                                      }
                                    // className={isActive("/masters/contact_us_list")}
                                    >
                                      <Link
                                        to="/masters/ed-tech/university/common/two"
                                        className="text-decoration-none rounded "
                                      >
                                        <p className="insideDropdown_list">
                                          Banner Two
                                        </p>
                                      </Link>
                                    </li>
                                    <li
                                      className={
                                        isOpen
                                          ? headerText.includes(
                                            "/masters/ed-tech/university/common/key-program-features"
                                          )
                                            ? "active block"
                                            : "none"
                                          : "none"
                                      }
                                    // className={isActive("/masters/contact_us_list")}
                                    >
                                      <Link
                                        to="/masters/ed-tech/university/common/key-program-features"
                                        className="text-decoration-none rounded "
                                      >
                                        <p className="insideDropdown_list">
                                          Key Program Features
                                        </p>
                                      </Link>
                                    </li>
                                    <li
                                      className={
                                        isOpen
                                          ? headerText.includes(
                                            "/masters/ed-tech/university/common/advantages-of-taking-admission"
                                          )
                                            ? "active block"
                                            : "none"
                                          : "none"
                                      }
                                    // className={isActive("/masters/contact_us_list")}
                                    >
                                      <Link
                                        to="/masters/ed-tech/university/common/advantages-of-taking-admission"
                                        className="text-decoration-none rounded "
                                      >
                                        <p className="insideDropdown_list">
                                          Advantage Of Taking Admission
                                        </p>
                                      </Link>
                                    </li>

                                    <li
                                      className={
                                        isOpen
                                          ? headerText.includes(
                                            "/masters/ed-tech/university/common/hiring-partner"
                                          )
                                            ? "active block"
                                            : "none"
                                          : "none"
                                      }
                                    // className={isActive("/masters/contact_us_list")}
                                    >
                                      <Link
                                        to="/masters/ed-tech/university/common/hiring-partner"
                                        className="text-decoration-none rounded "
                                      >
                                        <p className="insideDropdown_list">
                                          Hiring Partner
                                        </p>
                                      </Link>
                                    </li>
                                    <li
                                      className={
                                        isOpen
                                          ? headerText.includes(
                                            "/masters/ed-tech/university/common/student-benefit"
                                          )
                                            ? "active block"
                                            : "none"
                                          : "none"
                                      }
                                    // className={isActive("/masters/contact_us_list")}
                                    >
                                      <Link
                                        to="/masters/ed-tech/university/common/student-benefit"
                                        className="text-decoration-none rounded "
                                      >
                                        <p className="insideDropdown_list">
                                          Student Benefits
                                        </p>
                                      </Link>
                                    </li>
                                    <li
                                      className={
                                        isOpen
                                          ? headerText.includes(
                                            "/masters/ed-tech/university/common/small-banner"
                                          )
                                            ? "active block"
                                            : "none"
                                          : "none"
                                      }
                                    // className={isActive("/masters/contact_us_list")}
                                    >
                                      <Link
                                        to="/masters/ed-tech/university/common/small-banner"
                                        className="text-decoration-none rounded "
                                      >
                                        <p className="insideDropdown_list">
                                          Ads Banner
                                        </p>
                                      </Link>
                                    </li>

                                    <li
                                      className={
                                        isOpen
                                          ? headerText.includes(
                                            "/masters/ed-tech/university/common/alumani"
                                          )
                                            ? "active block"
                                            : "none"
                                          : "none"
                                      }
                                    // className={isActive("/masters/contact_us_list")}
                                    >
                                      <Link
                                        to="/masters/ed-tech/university/common/alumani"
                                        className="text-decoration-none rounded "
                                      >
                                        <p className="insideDropdown_list">
                                          Alumani Video
                                        </p>
                                      </Link>
                                    </li>
                                    <li
                                      className={
                                        isOpen
                                          ? headerText.includes(
                                            "/masters/ed-tech/university/common/alumani-image"
                                          )
                                            ? "active block"
                                            : "none"
                                          : "none"
                                      }
                                    // className={isActive("/masters/contact_us_list")}
                                    >
                                      <Link
                                        to="/masters/ed-tech/university/common/alumani-image"
                                        className="text-decoration-none rounded "
                                      >
                                        <p className="insideDropdown_list">
                                          Alumani Image
                                        </p>
                                      </Link>
                                    </li>
                                    <li
                                      className={
                                        isOpen
                                          ? headerText.includes(
                                            "/masters/ed-tech/university/common/achievement"
                                          )
                                            ? "active block"
                                            : "none"
                                          : "none"
                                      }
                                    // className={isActive("/masters/contact_us_list")}
                                    >
                                      <Link
                                        to="/masters/ed-tech/university/common/achievement"
                                        className="text-decoration-none rounded "
                                      >
                                        <p className="insideDropdown_list">
                                          Achievement
                                        </p>
                                      </Link>
                                    </li>

                                    <li
                                      className={
                                        isOpen
                                          ? headerText.includes(
                                            "/masters/ed-tech/university/common/faq"
                                          )
                                            ? "active block"
                                            : "none"
                                          : "none"
                                      }
                                    // className={isActive("/masters/contact_us_list")}
                                    >
                                      <Link
                                        to="/masters/ed-tech/university/common/faq"
                                        className="text-decoration-none rounded "
                                      >
                                        <p className="insideDropdown_list">
                                          FAQ
                                        </p>
                                      </Link>
                                    </li>
                                    <li
                                      className={
                                        isOpen
                                          ? headerText.includes(
                                            "/masters/ed-tech/university/common/barrier"
                                          )
                                            ? "active block"
                                            : "none"
                                          : "none"
                                      }
                                    // className={isActive("/masters/contact_us_list")}
                                    >
                                      <Link
                                        to="/masters/ed-tech/university/common/barrier"
                                        className="text-decoration-none rounded "
                                      >
                                        <p className="insideDropdown_list">
                                          Barriers
                                        </p>
                                      </Link>
                                    </li>
                                  </ul>
                                </Accordion.Body>
                              </Accordion.Body>
                            </Accordion.Item>
                          </Accordion>

                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="05" className="mb-1">
                        <Accordion.Header>Admission</Accordion.Header>
                        <Accordion.Body>
                          <ul
                            className="accordion_list list-unstyled fw-normal pb-1 small"
                            style={{
                              display: isSidebarOpen ? "block" : "none",
                            }}
                          >
                            <li
                              className={
                                isOpen
                                  ? headerText.includes(
                                    "/masters/ed-tech/admission/banner"
                                  )
                                    ? "active block"
                                    : "none"
                                  : "none"
                              }
                            // className={isActive("/masters/contact_us_list")}
                            >
                              <Link
                                to="/masters/ed-tech/admission/banner"
                                className="text-decoration-none rounded "
                              >
                                <p className="insideDropdown_list">Banner</p>
                              </Link>
                            </li>

                            <li
                              className={
                                isOpen
                                  ? headerText.includes(
                                    "/masters/ed-tech/admission/document-for-admission"
                                  )
                                    ? "active block"
                                    : "none"
                                  : "none"
                              }
                            // className={isActive("/masters/contact_us_list")}
                            >
                              <Link
                                to="/masters/ed-tech/admission/document-for-admission"
                                className="text-decoration-none rounded "
                              >
                                <p className="insideDropdown_list">
                                  Document For Admission
                                </p>
                              </Link>
                            </li>

                            <li
                              className={
                                isOpen
                                  ? headerText.includes(
                                    "/masters/ed-tech/admission/policy"
                                  )
                                    ? "active block"
                                    : "none"
                                  : "none"
                              }
                            // className={isActive("/masters/contact_us_list")}
                            >
                              <Link
                                to="/masters/ed-tech/admission/policy"
                                className="text-decoration-none rounded "
                              >
                                <p className="insideDropdown_list">Policy</p>
                              </Link>
                            </li>

                            <li
                              className={
                                isOpen
                                  ? headerText.includes(
                                    "/masters/ed-tech/admission/registration-process"
                                  )
                                    ? "active block"
                                    : "none"
                                  : "none"
                              }
                            // className={isActive("/masters/contact_us_list")}
                            >
                              <Link
                                to="/masters/ed-tech/admission/registration-process"
                                className="text-decoration-none rounded "
                              >
                                <p className="insideDropdown_list">
                                  Registration Process
                                </p>
                              </Link>
                            </li>

                            <li
                              className={
                                isOpen
                                  ? headerText.includes(
                                    "/masters/ed-tech/admission/steps"
                                  )
                                    ? "active block"
                                    : "none"
                                  : "none"
                              }
                            // className={isActive("/masters/contact_us_list")}
                            >
                              <Link
                                to="/masters/ed-tech/admission/steps"
                                className="text-decoration-none rounded "
                              >
                                <p className="insideDropdown_list">Steps</p>
                              </Link>
                            </li>
                          </ul>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="06" className="mb-1">
                        <Accordion.Header>Blogs</Accordion.Header>
                        <Accordion.Body>
                          <ul
                            className="accordion_list list-unstyled fw-normal pb-1 small"
                            style={{
                              display: isSidebarOpen ? "block" : "none",
                            }}
                          >
                            <li
                              className={
                                isOpen
                                  ? headerText.includes(
                                    "/masters/ed-tech/blog/banner"
                                  )
                                    ? "active block"
                                    : "none"
                                  : "none"
                              }
                            // className={isActive("/masters/contact_us_list")}
                            >
                              <Link
                                to="/masters/ed-tech/blog/banner"
                                className="text-decoration-none rounded "
                              >
                                <p className="insideDropdown_list">
                                  Blog Banner
                                </p>
                              </Link>
                            </li>
                            <li
                              className={
                                isOpen
                                  ? headerText.includes(
                                    "/masters/ed-tech/blog/blog-category"
                                  )
                                    ? "active block"
                                    : "none"
                                  : "none"
                              }
                            // className={isActive("/masters/contact_us_list")}
                            >
                              <Link
                                to="/masters/ed-tech/blog/blog-category"
                                className="text-decoration-none rounded "
                              >
                                <p className="insideDropdown_list">
                                  Blog Category
                                </p>
                              </Link>
                            </li>
                            <li
                              className={
                                isOpen
                                  ? headerText.includes(
                                    "/masters/ed-tech/blog/blogs"
                                  )
                                    ? "active block"
                                    : "none"
                                  : "none"
                              }
                            // className={isActive("/masters/contact_us_list")}
                            >
                              <Link
                                to="/masters/ed-tech/blog/blogs"
                                className="text-decoration-none rounded "
                              >
                                <p className="insideDropdown_list">All Blogs</p>
                              </Link>
                            </li>
                          </ul>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="07" className="mb-1">
                        <Accordion.Header>Customer Management</Accordion.Header>
                        <Accordion.Body>
                          <ul
                            className="accordion_list list-unstyled fw-normal pb-1 small"
                            style={{
                              display: isSidebarOpen ? "block" : "none",
                            }}
                          >
                            <li
                              className={
                                isOpen
                                  ? headerText.includes(
                                    "/masters/ed-tech/customer-management/become-partner"
                                  )
                                    ? "active block"
                                    : "none"
                                  : "none"
                              }
                            // className={isActive("/masters/ed-tech/customer-management/become-partner")}
                            >
                              <Link
                                to="/masters/ed-tech/customer-management/become-partner"
                                className="text-decoration-none rounded "
                              >
                                <p className="insideDropdown_list">
                                  Become Partner Requests
                                </p>
                              </Link>
                            </li>
                            <li
                              className={
                                isOpen
                                  ? headerText.includes(
                                    "/edtech-becomepartner-banner"
                                  )
                                    ? "active block"
                                    : "none"
                                  : "none"
                              }
                            // className={isActive("/edtech-becomepartner-banner")}
                            >
                              <Link
                                to="/edtech-becomepartner-banner"
                                className="text-decoration-none rounded "
                              >
                                <p className="insideDropdown_list">
                                  Become Partner Banner
                                </p>
                              </Link>
                            </li>
                            <li
                              className={
                                isOpen
                                  ? headerText.includes(
                                    "/edtech-cusomer-managment-associates"
                                  )
                                    ? "active block"
                                    : "none"
                                  : "none"
                              }
                            // className={isActive("/edtech-cusomer-managment-associates")}
                            >
                              <Link
                                to="/edtech-cusomer-managment-associates"
                                className="text-decoration-none rounded "
                              >
                                <p className="insideDropdown_list">
                                  Our Associates
                                </p>
                              </Link>
                            </li>
                            <li
                              className={
                                isOpen
                                  ? headerText.includes(
                                    "/masters/ed-tech/customer-management/contact-us"
                                  )
                                    ? "active block"
                                    : "none"
                                  : "none"
                              }
                            // className={isActive("/masters/contact_us_list")}
                            >
                              <Link
                                to="/masters/ed-tech/customer-management/contact-us"
                                className="text-decoration-none rounded "
                              >
                                <p className="insideDropdown_list">
                                  Contact Us
                                </p>
                              </Link>
                            </li>
                            <li
                              className={
                                isOpen
                                  ? headerText.includes(
                                    "/masters/ed-tech/customer-management/inqueries"
                                  )
                                    ? "active block"
                                    : "none"
                                  : "none"
                              }
                            // className={isActive("/masters/contact_us_list")}
                            >
                              <Link
                                to="/masters/ed-tech/customer-management/inqueries"
                                className="text-decoration-none rounded "
                              >
                                <p className="insideDropdown_list">
                                  Inqueries
                                </p>
                              </Link>
                            </li>
                            <li
                              className={
                                isOpen
                                  ? headerText.includes(
                                    "/masters/ed-tech/customer-management/e-brochure"
                                  )
                                    ? "active block"
                                    : "none"
                                  : "none"
                              }
                            // className={isActive("/masters/contact_us_list")}
                            >
                              <Link
                                to="/masters/ed-tech/customer-management/e-brochure"
                                className="text-decoration-none rounded "
                              >
                                <p className="insideDropdown_list">
                                  E-Brochure
                                </p>
                              </Link>
                            </li>
                            <li
                              className={
                                isOpen
                                  ? headerText.includes(
                                    "/masters/ed-tech/customer-management/add-reg-form"
                                  )
                                    ? "active block"
                                    : "none"
                                  : "none"
                              }
                            // className={isActive("/masters/contact_us_list")}
                            >
                              <Link
                                to="/masters/ed-tech/customer-management/add-reg-form"
                                className="text-decoration-none rounded "
                              >
                                <p className="insideDropdown_list">
                                  Consult Now Form
                                </p>
                              </Link>
                            </li>
                          </ul>
                        </Accordion.Body>
                      </Accordion.Item>

                      <Accordion.Item eventKey="08" className="mb-1">
                        <Accordion.Header>Specialization</Accordion.Header>
                        <Accordion.Body>
                          <ul
                            className="accordion_list list-unstyled fw-normal pb-1 small"
                            style={{
                              display: isSidebarOpen ? "block" : "none",
                            }}
                          >
                            <li
                              className={
                                isOpen
                                  ? headerText.includes(
                                    "/edtech/specialization/banner"
                                  )
                                    ? "active block"
                                    : "none"
                                  : "none"
                              }
                            >
                              <Link
                                to="/edtech/specialization/banner"
                                className="text-decoration-none rounded "
                              >
                                <p className="insideDropdown_list">S. Banner</p>
                              </Link>
                            </li>

                            <li
                              className={
                                isOpen
                                  ? headerText.includes(
                                    "/edtech/specialization/why-choose"
                                  )
                                    ? "active block"
                                    : "none"
                                  : "none"
                              }
                            >
                              <Link
                                to="/edtech/specialization/why-choose"
                                className="text-decoration-none rounded "
                              >
                                <p className="insideDropdown_list">
                                  S. Why Choose
                                </p>
                              </Link>
                            </li>

                            <li
                              className={
                                isOpen
                                  ? headerText.includes(
                                    "/edtech/specialization/eligibility"
                                  )
                                    ? "active block"
                                    : "none"
                                  : "none"
                              }
                            >
                              <Link
                                to="/edtech/specialization/eligibility"
                                className="text-decoration-none rounded "
                              >
                                <p className="insideDropdown_list">
                                  S. Eligibility
                                </p>
                              </Link>
                            </li>

                            <li
                              className={
                                isOpen
                                  ? headerText.includes(
                                    "/edtech/specialization/highlights"
                                  )
                                    ? "active block"
                                    : "none"
                                  : "none"
                              }
                            >
                              <Link
                                to="/edtech/specialization/highlights"
                                className="text-decoration-none rounded "
                              >
                                <p className="insideDropdown_list">
                                  S. Highlights
                                </p>
                              </Link>
                            </li>

                            <li
                              className={
                                isOpen
                                  ? headerText.includes(
                                    "/edtech/specialization/syllabus"
                                  )
                                    ? "active block"
                                    : "none"
                                  : "none"
                              }
                            >
                              <Link
                                to="/edtech/specialization/syllabus"
                                className="text-decoration-none rounded "
                              >
                                <p className="insideDropdown_list">
                                  S. Syllabus
                                </p>
                              </Link>
                            </li>

                            <li
                              className={
                                isOpen
                                  ? headerText.includes(
                                    "/edtech/specialization/with-online"
                                  )
                                    ? "active block"
                                    : "none"
                                  : "none"
                              }
                            >
                              <Link
                                to="/edtech/specialization/with-online"
                                className="text-decoration-none rounded "
                              >
                                <p className="insideDropdown_list">
                                  S. With Online
                                </p>
                              </Link>
                            </li>

                            <li
                              className={
                                isOpen
                                  ? headerText.includes(
                                    "/edtech/specialization/footer-image"
                                  )
                                    ? "active block"
                                    : "none"
                                  : "none"
                              }
                            >
                              <Link
                                to="/edtech/specialization/footer-image"
                                className="text-decoration-none rounded "
                              >
                                <p className="insideDropdown_list">
                                  S. Footer Image
                                </p>
                              </Link>
                            </li>
                          </ul>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </Link>
                </ul>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item
              eventKey="5"
              className="mb-1"
              onClick={() => handleClick("3")}
            >
              <Accordion.Header>
                <div className="me-3">
                  <FontAwesomeIcon
                    icon="fa-solid fa-briefcase"
                    onClick={() => setIsOpen(!isOpen)}
                  />
                  <b></b>
                  <b></b>
                </div>
                <p
                  style={{ display: isSidebarOpen ? "block" : "none" }}
                  className="sidebar_txt"
                >
                  Job Portal
                </p>
              </Accordion.Header>
              <Accordion.Body
                style={{
                  display: isOpen ? "block" : "none",
                }}
              >
                <ul
                  className="accordion_list list-unstyled fw-normal pb-1 small"
                  style={{
                    display: isSidebarOpen ? "block" : "none",
                  }}
                >
                  <Link
                    // to="/recruiters/contact_us_list"
                    className="text-decoration-none rounded "
                  >
                    <Accordion className="accordion-item-sub">
                      <Accordion.Item eventKey="00" className="mb-1">
                        <Accordion.Header className="scale-in-hor-right">
                          Home
                        </Accordion.Header>
                        <Accordion.Body>
                          <ul
                            className="accordion_list list-unstyled fw-normal pb-1 small"
                            style={{
                              display: isSidebarOpen ? "block" : "none",
                            }}
                          >
                            <li
                              className={
                                isOpen
                                  ? headerText.includes(
                                    "/masters/job-portal/home/testimonials"
                                  )
                                    ? "active block"
                                    : "none"
                                  : "none"
                              }
                            // className={isActive("/masters/contact_us_list")}
                            >
                              <Link
                                to="/masters/job-portal/home/testimonials"
                                className="text-decoration-none rounded "
                              >
                                <p className="insideDropdown_list">
                                  Testimonials
                                </p>
                              </Link>
                            </li>
                            <li
                              className={
                                isOpen
                                  ? headerText.includes(
                                    "/masters/job-portal/home/banner"
                                  )
                                    ? "active block"
                                    : "none"
                                  : "none"
                              }
                            // className={isActive("/masters/contact_us_list")}
                            >
                              <Link
                                to="/masters/job-portal/home/banner"
                                className="text-decoration-none rounded "
                              >
                                <p className="insideDropdown_list">Banner</p>
                              </Link>
                            </li>
                            <li
                              className={
                                isOpen
                                  ? headerText.includes(
                                    "/masters/job-portal/home/banner-two"
                                  )
                                    ? "active block"
                                    : "none"
                                  : "none"
                              }
                            // className={isActive("/masters/contact_us_list")}
                            >
                              <Link
                                to="/masters/job-portal/home/banner-two"
                                className="text-decoration-none rounded "
                              >
                                <p className="insideDropdown_list">
                                  Banner Two
                                </p>
                              </Link>
                            </li>
                          </ul>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="01" className="mb-1">
                        <Accordion.Header className="scale-in-hor-right">
                          Masters
                        </Accordion.Header>
                        <Accordion.Body>
                          <ul
                            className="accordion_list list-unstyled fw-normal pb-1 small"
                            style={{
                              display: isSidebarOpen ? "block" : "none",
                            }}
                          >
                            <li
                              className={
                                isOpen
                                  ? headerText.includes(
                                    "/masters/job-portal/home/screening-question"
                                  )
                                    ? "active block"
                                    : "none"
                                  : "none"
                              }
                            // className={isActive("/masters/contact_us_list")}
                            >
                              <Link
                                to="/masters/job-portal/home/screening-question"
                                className="text-decoration-none rounded "
                              >
                                <p className="insideDropdown_list">
                                  Screening Question
                                </p>
                              </Link>
                            </li>

                            <li
                              className={
                                isOpen
                                  ? headerText.includes(
                                    "/masters/job-portal/home/additional-question"
                                  )
                                    ? "active block"
                                    : "none"
                                  : "none"
                              }
                            // className={isActive("/masters/contact_us_list")}
                            >
                              <Link
                                to="/masters/job-portal/home/additional-question"
                                className="text-decoration-none rounded "
                              >
                                <p className="insideDropdown_list">
                                  Additional Question
                                </p>
                              </Link>
                            </li>

                            <li
                              className={
                                isOpen
                                  ? headerText.includes(
                                    "/masters/job-portal/home/answer-additional-question"
                                  )
                                    ? "active block"
                                    : "none"
                                  : "none"
                              }
                            // className={isActive("/masters/contact_us_list")}
                            >
                              <Link
                                to="/masters/job-portal/home/answer-additional-question"
                                className="text-decoration-none rounded "
                              >
                                <p className="insideDropdown_list">
                                  Additional Question Answer
                                </p>
                              </Link>
                            </li>
                          </ul>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="02" className="mb-1">
                        <Accordion.Header className="scale-in-hor-right">
                          Blog
                        </Accordion.Header>
                        <Accordion.Body>
                          <ul
                            className="accordion_list list-unstyled fw-normal pb-1 small"
                            style={{
                              display: isSidebarOpen ? "block" : "none",
                            }}
                          >
                            <li
                              className={
                                isOpen
                                  ? headerText.includes(
                                    "/masters/job-portal/blog/career-opportunities"
                                  )
                                    ? "active block"
                                    : "none"
                                  : "none"
                              }
                            // className={isActive("/masters/contact_us_list")}
                            >
                              <Link
                                to="/masters/job-portal/blog/career-opportunities"
                                className="text-decoration-none rounded "
                              >
                                <p className="insideDropdown_list">
                                  Career Opportunities
                                </p>
                              </Link>
                            </li>
                          </ul>
                        </Accordion.Body>
                      </Accordion.Item>

                      <Accordion.Item eventKey="03" className="mb-1">
                        <Accordion.Header className="scale-in-hor-right">
                          Customer Management
                        </Accordion.Header>
                        <Accordion.Body>
                          <ul
                            className="accordion_list list-unstyled fw-normal pb-1 small"
                            style={{
                              display: isSidebarOpen ? "block" : "none",
                            }}
                          >
                            <li
                              className={
                                isOpen
                                  ? headerText.includes(
                                    "/masters/job-portal/customer-management/users"
                                  )
                                    ? "active block"
                                    : "none"
                                  : "none"
                              }
                            // className={isActive("/masters/contact_us_list")}
                            >
                              <Link
                                to="/masters/job-portal/customer-management/users"
                                className="text-decoration-none rounded "
                              >
                                <p className="insideDropdown_list">
                                  Users Register
                                </p>
                              </Link>
                            </li>
                            <li
                              className={
                                isOpen
                                  ? headerText.includes(
                                    "/masters/job-portal/customer-management/contact-us"
                                  )
                                    ? "active block"
                                    : "none"
                                  : "none"
                              }
                            // className={isActive("/masters/contact_us_list")}
                            >
                              <Link
                                to="/masters/job-portal/customer-management/contact-us"
                                className="text-decoration-none rounded "
                              >
                                <p className="insideDropdown_list">ContactUs</p>
                              </Link>
                            </li>
                            <li
                              className={
                                isOpen
                                  ? headerText.includes(
                                    "/masters/job-portal/customer-management/request-call-back"
                                  )
                                    ? "active block"
                                    : "none"
                                  : "none"
                              }
                            // className={isActive("/masters/contact_us_list")}
                            >
                              <Link
                                to="/masters/job-portal/customer-management/request-call-back"
                                className="text-decoration-none rounded "
                              >
                                <p className="insideDropdown_list">
                                  Rquest Call Back
                                </p>
                              </Link>
                            </li>

                            <li
                              className={
                                isOpen
                                  ? headerText.includes(
                                    "/masters/job-portal/customer-management/company-details"
                                  )
                                    ? "active block"
                                    : "none"
                                  : "none"
                              }
                            // className={isActive("/masters/contact_us_list")}
                            >
                              <Link
                                to="/masters/job-portal/customer-management/company-details"
                                className="text-decoration-none rounded "
                              >
                                <p className="insideDropdown_list">Employer</p>
                              </Link>
                            </li>

                            <li
                              className={
                                isOpen
                                  ? headerText.includes(
                                    "/masters/job-portal/customer-management/job-seekers/details"
                                  )
                                    ? "active block"
                                    : "none"
                                  : "none"
                              }
                            // className={isActive("/masters/contact_us_list")}
                            >
                              <Link
                                to="/masters/job-portal/customer-management/job-seekers/details"
                                className="text-decoration-none rounded "
                              >
                                <p className="insideDropdown_list">Job Seekers</p>
                              </Link>
                            </li>

                          </ul>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </Link>

                  <li
                    className={
                      isOpen
                        ? headerText.includes(
                          "/masters/job-portal/customer-management/employer/jobs"
                        )
                          ? "active block"
                          : "none"
                        : "none"
                    }
                  // className={isActive("/masters/contact_us_list")}
                  >
                    <Link
                      to="/masters/job-portal/customer-management/employer/jobs"
                      className="text-decoration-none rounded "
                    >
                      <p className="insideDropdown_list">Jobs</p>
                    </Link>
                  </li>

                  <li
                    className={
                      isOpen
                        ? headerText.includes(
                          "/masters/job-portal/customer-management/employer/company-details"
                        )
                          ? "active block"
                          : "none"
                        : "none"
                    }
                  // className={isActive("/masters/contact_us_list")}
                  >
                    <Link
                      to="/masters/job-portal/customer-management/employer/company-details"
                      className="text-decoration-none rounded "
                    >
                      <p className="insideDropdown_list">Company</p>
                    </Link>
                  </li>

                  <li
                    className={
                      isOpen
                        ? headerText.includes(
                          "/masters/job-portal/privacy-policy"
                        )
                          ? "active block"
                          : "none"
                        : "none"
                    }
                  // className={isActive("/masters/contact_us_list")}
                  >
                    <Link
                      to="/masters/job-portal/privacy-policy"
                      className="text-decoration-none rounded "
                    >
                      <p className="insideDropdown_list">Privacy Policy</p>
                    </Link>
                  </li>

                  <li
                    className={
                      isOpen
                        ? headerText.includes(
                          "/masters/job-portal/advertisment"
                        )
                          ? "active block"
                          : "none"
                        : "none"
                    }
                  // className={isActive("/masters/contact_us_list")}
                  >
                    <Link
                      to="/masters/job-portal/advertisment"
                      className="text-decoration-none rounded "
                    >
                      <p className="insideDropdown_list">Advertisment</p>
                    </Link>
                  </li>

                  <li
                    className={
                      isOpen
                        ? headerText.includes("/masters/job-portal/plan")
                          ? "active block"
                          : "none"
                        : "none"
                    }
                  // className={isActive("/masters/contact_us_list")}
                  >
                    <Link
                      to="/masters/job-portal/plan"
                      className="text-decoration-none rounded "
                    >
                      <p className="insideDropdown_list">Plan</p>
                    </Link>
                  </li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>

            {/* *********************** Finance ****************************** */}

            <Accordion.Item
              eventKey="6"
              className="mb-1"
              onClick={() => handleClick("3")}
            >
              <Accordion.Header>
                <div className="me-3">
                  <FontAwesomeIcon
                    icon="fa-solid fa-indian-rupee-sign"
                    onClick={() => setIsOpen(!isOpen)}
                  />
                  <b></b>
                  <b></b>
                </div>
                <p
                  style={{ display: isSidebarOpen ? "block" : "none" }}
                  className="sidebar_txt"
                >
                  Finance
                </p>
              </Accordion.Header>
              <Accordion.Body
                style={{
                  display: isOpen ? "block" : "none",
                }}
              >
                <ul
                  className="accordion_list list-unstyled fw-normal pb-1 small"
                  style={{
                    display: isSidebarOpen ? "block" : "none",
                  }}
                >
                  <Link
                    // to="/recruiters/contact_us_list"
                    className="text-decoration-none rounded "
                  >
                    <Accordion className="accordion-item-sub">
                      <Accordion.Item eventKey="00" className="mb-1">
                        <Accordion.Header className="scale-in-hor-right">
                          Home
                        </Accordion.Header>
                        <Accordion.Body>
                          <ul
                            className="accordion_list list-unstyled fw-normal pb-1 small"
                            style={{
                              display: isSidebarOpen ? "block" : "none",
                            }}
                          >
                            <li
                              className={
                                isOpen
                                  ? headerText.includes("/finance/home/banner")
                                    ? "active block"
                                    : "none"
                                  : "none"
                              }
                            // className={isActive("/masters/contact_us_list")}
                            >
                              <Link
                                to="/finance/home/banner"
                                className="text-decoration-none rounded "
                              >
                                <p className="insideDropdown_list">F. Banner</p>
                              </Link>
                            </li>
                            <li
                              className={
                                isOpen
                                  ? headerText.includes(
                                    "/finance/home/stand-out"
                                  )
                                    ? "active block"
                                    : "none"
                                  : "none"
                              }
                            // className={isActive("/masters/contact_us_list")}
                            >
                              <Link
                                to="/finance/home/stand-out"
                                className="text-decoration-none rounded "
                              >
                                <p className="insideDropdown_list">
                                  F. Stand Out
                                </p>
                              </Link>
                            </li>

                            <li
                              className={
                                isOpen
                                  ? headerText.includes(
                                    "/finance/home/advantages"
                                  )
                                    ? "active block"
                                    : "none"
                                  : "none"
                              }
                            // className={isActive("/masters/contact_us_list")}
                            >
                              <Link
                                to="/finance/home/advantages"
                                className="text-decoration-none rounded "
                              >
                                <p className="insideDropdown_list">
                                  F. Advantages
                                </p>
                              </Link>
                            </li>

                            <li
                              className={
                                isOpen
                                  ? headerText.includes(
                                    "/finance/home/client-satisfaction"
                                  )
                                    ? "active block"
                                    : "none"
                                  : "none"
                              }
                            // className={isActive("/masters/contact_us_list")}
                            >
                              <Link
                                to="/finance/home/client-satisfaction"
                                className="text-decoration-none rounded "
                              >
                                <p className="insideDropdown_list">
                                  F. Client Satisfaction
                                </p>
                              </Link>
                            </li>

                            <li
                              className={
                                isOpen
                                  ? headerText.includes(
                                    "/finance/home/partner-type"
                                  )
                                    ? "active block"
                                    : "none"
                                  : "none"
                              }
                            // className={isActive("/masters/contact_us_list")}
                            >
                              <Link
                                to="/finance/home/partner-type"
                                className="text-decoration-none rounded "
                              >
                                <p className="insideDropdown_list">
                                  F. Partner Type
                                </p>
                              </Link>
                            </li>

                            <li
                              className={
                                isOpen
                                  ? headerText.includes(
                                    "/finance/home/our-partner"
                                  )
                                    ? "active block"
                                    : "none"
                                  : "none"
                              }
                            // className={isActive("/masters/contact_us_list")}
                            >
                              <Link
                                to="/finance/home/our-partner"
                                className="text-decoration-none rounded "
                              >
                                <p className="insideDropdown_list">
                                  F. Our Partners
                                </p>
                              </Link>
                            </li>
                          </ul>
                        </Accordion.Body>
                      </Accordion.Item>

                      <Accordion.Item eventKey="01" className="mb-1">
                        <Accordion.Header className="scale-in-hor-right">
                          About Us
                        </Accordion.Header>
                        <Accordion.Body>
                          <ul
                            className="accordion_list list-unstyled fw-normal pb-1 small"
                            style={{
                              display: isSidebarOpen ? "block" : "none",
                            }}
                          >
                            <li
                              className={
                                isOpen
                                  ? headerText.includes(
                                    "/finance/about-us/card_first"
                                  )
                                    ? "active block"
                                    : "none"
                                  : "none"
                              }
                            // className={isActive("/masters/contact_us_list")}
                            >
                              <Link
                                to="/finance/about-us/card_first"
                                className="text-decoration-none rounded "
                              >
                                <p className="insideDropdown_list">
                                  F. Card First
                                </p>
                              </Link>
                            </li>
                            <li
                              className={
                                isOpen
                                  ? headerText.includes(
                                    "/finance/about-us/card_second"
                                  )
                                    ? "active block"
                                    : "none"
                                  : "none"
                              }
                            // className={isActive("/masters/contact_us_list")}
                            >
                              <Link
                                to="/finance/about-us/card_second"
                                className="text-decoration-none rounded "
                              >
                                <p className="insideDropdown_list">
                                  F. Card Second
                                </p>
                              </Link>
                            </li>
                            <li
                              className={
                                isOpen
                                  ? headerText.includes(
                                    "/finance/about-us/f-teams"
                                  )
                                    ? "active block"
                                    : "none"
                                  : "none"
                              }
                            // className={isActive("/masters/contact_us_list")}
                            >
                              <Link
                                to="/finance/about-us/f-teams"
                                className="text-decoration-none rounded "
                              >
                                <p className="insideDropdown_list">F. Leader</p>
                              </Link>
                            </li>
                            <li
                              className={
                                isOpen
                                  ? headerText.includes(
                                    "/finance/about-us/whoweare"
                                  )
                                    ? "active block"
                                    : "none"
                                  : "none"
                              }
                            // className={isActive("/masters/contact_us_list")}
                            >
                              <Link
                                to="/finance/about-us/whoweare"
                                className="text-decoration-none rounded "
                              >
                                <p className="insideDropdown_list">
                                  F. Who We Are
                                </p>
                              </Link>
                            </li>
                          </ul>
                        </Accordion.Body>
                      </Accordion.Item>

                      <Accordion.Item eventKey="02" className="mb-1">
                        <Accordion.Header className="scale-in-hor-right">
                          Blogs
                        </Accordion.Header>
                        <Accordion.Body>
                          <ul
                            className="accordion_list list-unstyled fw-normal pb-1 small"
                            style={{
                              display: isSidebarOpen ? "block" : "none",
                            }}
                          >
                            <li
                              className={
                                isOpen
                                  ? headerText.includes(
                                    "/finance/blog/blog-banner"
                                  )
                                    ? "active block"
                                    : "none"
                                  : "none"
                              }
                            // className={isActive("/masters/contact_us_list")}
                            >
                              <Link
                                to="/finance/blog/blog-banner"
                                className="text-decoration-none rounded "
                              >
                                <p className="insideDropdown_list">
                                  F. Blog Banner
                                </p>
                              </Link>
                            </li>

                            <li
                              className={
                                isOpen
                                  ? headerText.includes("/finance/blog/blogs")
                                    ? "active block"
                                    : "none"
                                  : "none"
                              }
                            // className={isActive("/masters/contact_us_list")}
                            >
                              <Link
                                to="/finance/blog/blogs"
                                className="text-decoration-none rounded "
                              >
                                <p className="insideDropdown_list">F. Blog</p>
                              </Link>
                            </li>
                          </ul>
                        </Accordion.Body>
                      </Accordion.Item>

                      {/* <Accordion.Item eventKey="03" className="mb-1">
                        <Accordion.Header className="scale-in-hor-right">
                          Privacy Policy
                        </Accordion.Header>
                        <Accordion.Body>
                          <ul
                            className="accordion_list list-unstyled fw-normal pb-1 small"
                            style={{
                              display: isSidebarOpen ? "block" : "none",
                            }}
                          >
                            <li
                              className={
                                isOpen
                                  ? headerText.includes(
                                    "/finance/privacy/policy"
                                  )
                                    ? "active block"
                                    : "none"
                                  : "none"
                              }
                            // className={isActive("/masters/contact_us_list")}
                            >
                              <Link
                                to="/finance/privacy/policy"
                                className="text-decoration-none rounded "
                              >
                                <p className="insideDropdown_list">
                                  F. Privacy Policy
                                </p>
                              </Link>
                            </li>
                          </ul>
                        </Accordion.Body>
                      </Accordion.Item> */}

                      <Accordion.Item eventKey="04" className="mb-1">
                        <Accordion.Header className="scale-in-hor-right">
                          Services
                        </Accordion.Header>
                        <Accordion.Body>
                          <ul
                            className="accordion_list list-unstyled fw-normal pb-1 small"
                            style={{
                              display: isSidebarOpen ? "block" : "none",
                            }}
                          >
                            {/* <li
                              className={
                                isOpen
                                  ? headerText.includes(
                                    "/finance/service/service-type"
                                  )
                                    ? "active block"
                                    : "none"
                                  : "none"
                              }
                            // className={isActive("/masters/contact_us_list")}
                            >
                              <Link
                                to="/finance/service/service-type"
                                className="text-decoration-none rounded "
                              >
                                <p className="insideDropdown_list">
                                  F. Service Types
                                </p>
                              </Link>
                            </li> */}

                            <li
                              className={
                                isOpen
                                  ? headerText.includes(
                                    "/finance/service/services"
                                  )
                                    ? "active block"
                                    : "none"
                                  : "none"
                              }
                            // className={isActive("/masters/contact_us_list")}
                            >
                              <Link
                                to="/finance/service/services"
                                className="text-decoration-none rounded "
                              >
                                <p className="insideDropdown_list">
                                  F. service
                                </p>
                              </Link>
                            </li>
                          </ul>
                        </Accordion.Body>
                      </Accordion.Item>

                      <Accordion.Item eventKey="05" className="mb-1">
                        <Accordion.Header className="scale-in-hor-right">
                          Loan
                        </Accordion.Header>
                        <Accordion.Body>
                          <ul
                            className="accordion_list list-unstyled fw-normal pb-1 small"
                            style={{
                              display: isSidebarOpen ? "block" : "none",
                            }}
                          >
                            <li
                              className={
                                isOpen
                                  ? headerText.includes("/finance/loan/banner")
                                    ? "active block"
                                    : "none"
                                  : "none"
                              }
                            // className={isActive("/masters/contact_us_list")}
                            >
                              <Link
                                to="/finance/loan/banner"
                                className="text-decoration-none rounded "
                              >
                                <p className="insideDropdown_list">
                                  F. Loan Banner
                                </p>
                              </Link>
                            </li>

                            <li
                              className={
                                isOpen
                                  ? headerText.includes("/finance/loan/steps")
                                    ? "active block"
                                    : "none"
                                  : "none"
                              }
                            // className={isActive("/masters/contact_us_list")}
                            >
                              <Link
                                to="/finance/loan/steps"
                                className="text-decoration-none rounded "
                              >
                                <p className="insideDropdown_list">
                                  F. Loan Steps
                                </p>
                              </Link>
                            </li>

                            <li
                              className={
                                isOpen
                                  ? headerText.includes(
                                    "/finance/loan/client-reviews"
                                  )
                                    ? "active block"
                                    : "none"
                                  : "none"
                              }
                            // className={isActive("/masters/contact_us_list")}
                            >
                              <Link
                                to="/finance/loan/client-reviews"
                                className="text-decoration-none rounded "
                              >
                                <p className="insideDropdown_list">
                                  F.L. Client Reviews
                                </p>
                              </Link>
                            </li>

                            <li
                              className={
                                isOpen
                                  ? headerText.includes(
                                    "/finance/loan/profcyma-finance"
                                  )
                                    ? "active block"
                                    : "none"
                                  : "none"
                              }
                            // className={isActive("/masters/contact_us_list")}
                            >
                              <Link
                                to="/finance/loan/profcyma-finance"
                                className="text-decoration-none rounded "
                              >
                                <p className="insideDropdown_list">
                                  F.L. Profcyma Finance
                                </p>
                              </Link>
                            </li>

                            <li
                              className={
                                isOpen
                                  ? headerText.includes(
                                    "/finance/loan/whocanapply"
                                  )
                                    ? "active block"
                                    : "none"
                                  : "none"
                              }
                            // className={isActive("/masters/contact_us_list")}
                            >
                              <Link
                                to="/finance/loan/whocanapply"
                                className="text-decoration-none rounded "
                              >
                                <p className="insideDropdown_list">
                                  F.L. Who Can Apply
                                </p>
                              </Link>
                            </li>
                          </ul>
                        </Accordion.Body>
                      </Accordion.Item>

                      <Accordion.Item eventKey="06" className="mb-1">
                        <Accordion.Header className="scale-in-hor-right">
                          Insurance
                        </Accordion.Header>
                        <Accordion.Body>
                          <ul
                            className="accordion_list list-unstyled fw-normal pb-1 small"
                            style={{
                              display: isSidebarOpen ? "block" : "none",
                            }}
                          >
                            <li
                              className={
                                isOpen
                                  ? headerText.includes(
                                    "/finance/insurance/banner"
                                  )
                                    ? "active block"
                                    : "none"
                                  : "none"
                              }
                            // className={isActive("/masters/contact_us_list")}
                            >
                              <Link
                                to="/finance/insurance/banner"
                                className="text-decoration-none rounded "
                              >
                                <p className="insideDropdown_list">
                                  F. I. Banner
                                </p>
                              </Link>
                            </li>

                            <li
                              className={
                                isOpen
                                  ? headerText.includes(
                                    "/finance/insurance/advantages"
                                  )
                                    ? "active block"
                                    : "none"
                                  : "none"
                              }
                            // className={isActive("/masters/contact_us_list")}
                            >
                              <Link
                                to="/finance/insurance/advantages"
                                className="text-decoration-none rounded "
                              >
                                <p className="insideDropdown_list">
                                  F. I. Advantages
                                </p>
                              </Link>
                            </li>

                            <li
                              className={
                                isOpen
                                  ? headerText.includes(
                                    "/finance/insurance/choose-profcyma"
                                  )
                                    ? "active block"
                                    : "none"
                                  : "none"
                              }
                            // className={isActive("/masters/contact_us_list")}
                            >
                              <Link
                                to="/finance/insurance/choose-profcyma"
                                className="text-decoration-none rounded "
                              >
                                <p className="insideDropdown_list">
                                  F. I. Choose Profcyma
                                </p>
                              </Link>
                            </li>

                            <li
                              className={
                                isOpen
                                  ? headerText.includes(
                                    "/finance/insurance/eligibility"
                                  )
                                    ? "active block"
                                    : "none"
                                  : "none"
                              }
                            // className={isActive("/masters/contact_us_list")}
                            >
                              <Link
                                to="/finance/insurance/eligibility"
                                className="text-decoration-none rounded "
                              >
                                <p className="insideDropdown_list">
                                  F. I. Eligibility
                                </p>
                              </Link>
                            </li>

                            <li
                              className={
                                isOpen
                                  ? headerText.includes(
                                    "/finance/insurance/documents"
                                  )
                                    ? "active block"
                                    : "none"
                                  : "none"
                              }
                            // className={isActive("/masters/contact_us_list")}
                            >
                              <Link
                                to="/finance/insurance/documents"
                                className="text-decoration-none rounded "
                              >
                                <p className="insideDropdown_list">
                                  F. I. Documents
                                </p>
                              </Link>
                            </li>

                            <li
                              className={
                                isOpen
                                  ? headerText.includes(
                                    "/finance/insurance/testimonial"
                                  )
                                    ? "active block"
                                    : "none"
                                  : "none"
                              }
                            // className={isActive("/masters/contact_us_list")}
                            >
                              <Link
                                to="/finance/insurance/testimonial"
                                className="text-decoration-none rounded "
                              >
                                <p className="insideDropdown_list">
                                  F. I. Testimonial
                                </p>
                              </Link>
                            </li>
                          </ul>
                        </Accordion.Body>
                      </Accordion.Item>

                      <Accordion.Item eventKey="07" className="mb-1">
                        <Accordion.Header className="scale-in-hor-right">
                          Masters
                        </Accordion.Header>
                        <Accordion.Body>
                          <ul
                            className="accordion_list list-unstyled fw-normal pb-1 small"
                            style={{
                              display: isSidebarOpen ? "block" : "none",
                            }}
                          >
                            <li
                              className={
                                isOpen
                                  ? headerText.includes(
                                    "/finance/masters/banks"
                                  )
                                    ? "active block"
                                    : "none"
                                  : "none"
                              }
                            // className={isActive("/masters/contact_us_list")}
                            >
                              <Link
                                to="/finance/masters/banks"
                                className="text-decoration-none rounded "
                              >
                                <p className="insideDropdown_list">F. Banks</p>
                              </Link>
                            </li>

                            <li
                              className={
                                isOpen
                                  ? headerText.includes(
                                    "/finance/masters/cards"
                                  )
                                    ? "active block"
                                    : "none"
                                  : "none"
                              }
                            // className={isActive("/masters/contact_us_list")}
                            >
                              <Link
                                to="/finance/masters/cards"
                                className="text-decoration-none rounded "
                              >
                                <p className="insideDropdown_list">F. Cards</p>
                              </Link>
                            </li>

                            <li
                              className={
                                isOpen
                                  ? headerText.includes(
                                    "/finance/masters/employment-types"
                                  )
                                    ? "active block"
                                    : "none"
                                  : "none"
                              }
                            // className={isActive("/masters/contact_us_list")}
                            >
                              <Link
                                to="/finance/masters/employment-types"
                                className="text-decoration-none rounded "
                              >
                                <p className="insideDropdown_list">
                                  F. Employment Types
                                </p>
                              </Link>
                            </li>

                            <li
                              className={
                                isOpen
                                  ? headerText.includes(
                                    "/finance/masters/residence-types"
                                  )
                                    ? "active block"
                                    : "none"
                                  : "none"
                              }
                            // className={isActive("/masters/contact_us_list")}
                            >
                              <Link
                                to="/finance/masters/residence-types"
                                className="text-decoration-none rounded "
                              >
                                <p className="insideDropdown_list">
                                  F. Residence Types
                                </p>
                              </Link>
                            </li>

                            <li
                              className={
                                isOpen
                                  ? headerText.includes(
                                    "/finance/masters/yearly-income"
                                  )
                                    ? "active block"
                                    : "none"
                                  : "none"
                              }
                            // className={isActive("/masters/contact_us_list")}
                            >
                              <Link
                                to="/finance/masters/yearly-income"
                                className="text-decoration-none rounded "
                              >
                                <p className="insideDropdown_list">
                                  F. Yearly Income
                                </p>
                              </Link>
                            </li>

                            <li
                              className={
                                isOpen
                                  ? headerText.includes(
                                    "/finance/masters/desired-amount"
                                  )
                                    ? "active block"
                                    : "none"
                                  : "none"
                              }
                            // className={isActive("/masters/contact_us_list")}
                            >
                              <Link
                                to="/finance/masters/desired-amount"
                                className="text-decoration-none rounded "
                              >
                                <p className="insideDropdown_list">
                                  F. Desired Amount
                                </p>
                              </Link>
                            </li>

                            <li
                              className={
                                isOpen
                                  ? headerText.includes(
                                    "/finance/masters/f-companies"
                                  )
                                    ? "active block"
                                    : "none"
                                  : "none"
                              }
                            // className={isActive("/masters/contact_us_list")}
                            >
                              <Link
                                to="/finance/masters/f-companies"
                                className="text-decoration-none rounded "
                              >
                                <p className="insideDropdown_list">
                                  F. Companies
                                </p>
                              </Link>
                            </li>
                          </ul>
                        </Accordion.Body>
                      </Accordion.Item>

                      <Accordion.Item eventKey="08" className="mb-1">
                        <Accordion.Header className="scale-in-hor-right">
                          Customer Management
                        </Accordion.Header>
                        <Accordion.Body>
                          <ul
                            className="accordion_list list-unstyled fw-normal pb-1 small"
                            style={{
                              display: isSidebarOpen ? "block" : "none",
                            }}
                          >

                            {/* <li
                              className={
                                isOpen
                                  ? headerText.includes(
                                    "/finance/customer-management/loan-form"
                                  )
                                    ? "active block"
                                    : "none"
                                  : "none"
                              }
                            // className={isActive("/masters/contact_us_list")}
                            >
                              <Link
                                to="/finance/customer-management/loan-form"
                                className="text-decoration-none rounded "
                              >
                                <p className="insideDropdown_list">
                                  Loan Forms
                                </p>
                              </Link>
                            </li> */}

                            <li
                              className={
                                isOpen
                                  ? headerText.includes(
                                    "/finance/customer-management/inqury-form"
                                  )
                                    ? "active block"
                                    : "none"
                                  : "none"
                              }
                            // className={isActive("/masters/contact_us_list")}
                            >
                              <Link
                                to="/finance/customer-management/inqury-form"
                                className="text-decoration-none rounded "
                              >
                                <p className="insideDropdown_list">
                                  Inquery Forms
                                </p>
                              </Link>
                            </li>

                            <li
                              className={
                                isOpen
                                  ? headerText.includes(
                                    "/finance/customer-management/feedback-form"
                                  )
                                    ? "active block"
                                    : "none"
                                  : "none"
                              }
                            // className={isActive("/masters/contact_us_list")}
                            >
                              <Link
                                to="/finance/customer-management/feedback-form"
                                className="text-decoration-none rounded "
                              >
                                <p className="insideDropdown_list">
                                  Feedback Forms
                                </p>
                              </Link>
                            </li>

                            <li
                              className={
                                isOpen
                                  ? headerText.includes(
                                    "/finance/customer-management/insurance-form"
                                  )
                                    ? "active block"
                                    : "none"
                                  : "none"
                              }
                            // className={isActive("/masters/contact_us_list")}
                            >
                              <Link
                                to="/finance/customer-management/insurance-form"
                                className="text-decoration-none rounded "
                              >
                                <p className="insideDropdown_list">
                                  Service Forms
                                </p>
                              </Link>
                            </li>

                            <li
                              className={
                                isOpen
                                  ? headerText.includes(
                                    "/finance/customer-management/credit-score-form"
                                  )
                                    ? "active block"
                                    : "none"
                                  : "none"
                              }
                            // className={isActive("/masters/contact_us_list")}
                            >
                              <Link
                                to="/finance/customer-management/credit-score-form"
                                className="text-decoration-none rounded "
                              >
                                <p className="insideDropdown_list">
                                  Credit Score Forms
                                </p>
                              </Link>
                            </li>

                            <li
                              className={
                                isOpen
                                  ? headerText.includes(
                                    "/finance/customer-management/credit-card-eligibility"
                                  )
                                    ? "active block"
                                    : "none"
                                  : "none"
                              }
                            // className={isActive("/masters/contact_us_list")}
                            >
                              <Link
                                to="/finance/customer-management/credit-card-eligibility"
                                className="text-decoration-none rounded "
                              >
                                <p className="insideDropdown_list">
                                  Credit Card Eligibility
                                </p>
                              </Link>
                            </li>

                            <li
                              className={
                                isOpen
                                  ? headerText.includes(
                                    "/finance/customer-management/loan-application"
                                  )
                                    ? "active block"
                                    : "none"
                                  : "none"
                              }
                            // className={isActive("/masters/contact_us_list")}
                            >
                              <Link
                                to="/finance/customer-management/loan-application"
                                className="text-decoration-none rounded "
                              >
                                <p className="insideDropdown_list">
                                  Loan Applications
                                </p>
                              </Link>
                            </li>
                          </ul>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </Link>
                </ul>
              </Accordion.Body>
            </Accordion.Item>

            {/*  */}
          </Accordion>
        </ul>
      </section>
    </>
  );
};

export default Sidebar;
