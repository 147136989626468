import React, { useContext } from "react";
import { useState, useEffect, useRef } from "react";
import "../../../Tabels/Tabels.css";
import { Link } from "react-router-dom";
import { Context } from "../../../../utils/context";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import Offcanvas from "react-bootstrap/Offcanvas";
// *******************toster****************************
import Card from "react-bootstrap/Card";
import ModalSave from "../../../common/ModelSave";
import Validation from "../../../common/FormValidation";
import { CancelButton, SaveButton } from "../../../common/Button";
import Modal from "react-bootstrap/Modal";
import { Container, Row, Col, Button, Form, InputGroup } from "react-bootstrap";
import JoditEditor from "jodit-react";
import { useForm, Controller } from "react-hook-form";
import classNames from "classnames";
import Select from "react-select";

library.add(fas);

const EditOffCanvance = (props) => {
  const { postData, getData, IMG_URL, getDimension, Select2Data } = useContext(Context);

  const id = props.show;

  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });
  const [services, setServices] = useState([]);

  const GetEditData = async () => {
    const response = await getData(`/masters/finance/loan/f-who-can-apply/${id}`);
    console.log(response?.data);
    reset(response?.data);
  };

  const GetAllServices = async () => {
    const response = await getData("/allFServices/1");

    if (response?.success) {
      setServices(await Select2Data(response?.data, "service_id"));
    }
  };

  useEffect(() => {
    GetEditData();
    GetAllServices();
  }, []);


  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    watch,
    getValues,
    setValue,
    reset,
  } = useForm();
  console.log(getValues());
  const imageFile = watch("image");

  const onSubmit = async (data) => {
    try {
      const fromData = new FormData();
      fromData.append("name", data?.name);
      fromData.append("description", data?.description);
      fromData.append("service_id", data?.service_id?.value);
      const response = await postData(
        `/masters/finance/loan/f-who-can-apply/${id}`,
        fromData
      );
      console.log(response);
      if (response?.success) {
        await setShowModal({ code: response.code, message: response.message });
      } else {
        await setShowModal({ code: response.code, message: response.message });
      }
      setTimeout(() => {
        setShowModal(0);
        props.handleClose();
      }, 1000);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Modal
        onHide={props.handleClose}
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Edit Who Can Apply
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            onSubmit={() => handleSubmit(onSubmit)}
            role="form"
            className="countryclass"
          >
            <Row>
              {/* Title */}
              <Col lg={6}>

                <div className="main-form-section mt-3">
                  <Row className="row justify-content-center mb-2">
                    <Form.Label>Service</Form.Label>
                    <Controller
                      name="service_id" // name of the field
                      {...register("service_id", {
                        required: "Service is required",
                      })}
                      control={control}
                      render={({ field }) => (
                        <Select
                          styles={{
                            control: (baseStyles) => ({
                              ...baseStyles,
                              borderColor: errors.service_id
                                ? "red"
                                : baseStyles,
                            }),
                          }}
                          {...field}
                          options={services}
                        />
                      )}
                    />
                    {errors.service_id && (
                      <span className="text-danger">
                        {errors.service_id.message}
                      </span>
                    )}
                  </Row>
                </div>
              </Col>
              {/* <Col lg={6}>
                <div className="main-form-section mt-3">

                  <Form.Label>Title</Form.Label>

                  <Form.Group>
                    <Form.Control
                      className={classNames("", {
                        "is-invalid": errors?.name,
                      })}
                      type="text"
                      {...register("name", {
                        required: "Title is required",
                        maxLength: {
                          value: 20,
                          message: "Title must be at most 20 characters long",
                        },
                      })}
                      placeholder="Title"
                    />
                  </Form.Group>
                  {errors.name && (
                    <span className="text-danger">
                      {errors.name.message}
                    </span>
                  )}


                </div>
              </Col> */}

              <Col lg={12}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-start"> {/* Align to left */}
                    {/* Description */}
                    <Col sm={12}>
                      <Form.Label className="text-center">
                        Description
                      </Form.Label>
                      <Form.Group>
                        <Controller
                          name="description" // Provide the field name
                          control={control} // Pass the control object from useForm()
                          rules={{ required: "Description is required" }} // Validation rules
                          render={({ field }) => (
                            <JoditEditor
                              value={field.value}
                              onChange={(newContent) =>
                                field.onChange(newContent)
                              }
                              onBlur={field.onBlur}
                              className={classNames("", {
                                "is-invalid": !!errors.description,
                              })}
                              placeholder="Description"
                            />
                          )}
                        />
                      </Form.Group>
                      {errors.description && (
                        <span className="text-danger">
                          {errors.description.message}
                        </span>
                      )}
                    </Col>
                  </Row>
                </div>
              </Col>

            </Row>

            {/* Buttons */}
            <Row className="justify-content-center mt-5 pb-3">
              <Col sm={6} className="d-flex justify-content-center">
                <CancelButton name={"Cancel"} handleClose={props.handleClose} />
                <SaveButton
                  name={"Save"}
                  handleSubmit={handleSubmit(onSubmit)}
                />
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>

      <ModalSave
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      />
    </>
  );
};

export default EditOffCanvance;
