import React, { useContext } from "react";
import { useState, useEffect, useRef } from "react";
import "../../../Tabels/Tabels.css";
import { Context } from "../../../../utils/context";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import ModalSave from "../../../common/ModelSave";
import { CancelButton } from "../../../common/Button";
import Modal from "react-bootstrap/Modal";
import { Row, Col } from "react-bootstrap";
library.add(fas);

const EditOffCanvance = (props) => {
  const { getData } = useContext(Context);

  const id = props.show;
  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });

  const [formData, setFormData] = useState({});

  const GetEditData = async () => {
    const response = await getData(`/masters/ed-tech/customer-management/reg-form/${id}`);
    setFormData(response?.data);
    console.log(formData);
  };

  useEffect(() => {
    GetEditData();
  }, []);

  return (
    <>
      <Modal
        onHide={props.handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={true} // Assuming you're controlling the modal visibility from outside
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            View Details
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {formData && formData?.right_guidence &&
            <Row>
              <Col lg={12}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Col sm={3} className="text-center">Guidence By:</Col>
                    <Col sm={9} className="text-center">{formData.right_guidence?.name}</Col>
                  </Row>
                </div>
              </Col>
            </Row>
          }
          <Row>
            <Col lg={12}>
              <div className="main-form-section mt-3">
                <Row className="justify-content-center">
                  <Col sm={3} className="text-center">Name:</Col>
                  <Col sm={9} className="text-center">{formData.name}</Col>
                </Row>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <div className="main-form-section mt-3">
                <Row className="justify-content-center">
                  <Col sm={3} className="text-center">Email:</Col>
                  <Col sm={9} className="text-center">{formData.email}</Col>
                </Row>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <div className="main-form-section mt-3">
                <Row className="justify-content-center">
                  <Col sm={3} className="text-center">Phone:</Col>
                  <Col sm={9} className="text-center">{formData.mobile}</Col>
                </Row>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <div className="main-form-section mt-3">
                <Row className="justify-content-center">
                  <Col sm={3} className="text-center">University:</Col>
                  <Col sm={9} className="text-center">{formData.university?.name || 'N/A'}</Col>
                </Row>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <div className="main-form-section mt-3">
                <Row className="justify-content-center">
                  <Col sm={3} className="text-center">Course Type:</Col>
                  <Col sm={9} className="text-center">{formData.course_type?.name || 'N/A'}</Col>
                </Row>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <div className="main-form-section mt-3">
                <Row className="justify-content-center">
                  <Col sm={3} className="text-center">Course:</Col>
                  <Col sm={9} className="text-center">{formData.ed_tech_course?.name || 'N/A'}</Col>
                </Row>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <div className="main-form-section mt-3">
                <Row className="justify-content-center">
                  <Col sm={3} className="text-center">Specialization:</Col>
                  <Col sm={9} className="text-center">{formData?.specialization?.name || 'N/A'}</Col>
                </Row>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <div className="main-form-section mt-3">
                <Row className="justify-content-center">
                  <Col sm={3} className="text-center">Highest Qualification:</Col>
                  <Col sm={9} className="text-center">{formData?.qualification || 'N/A'}</Col>
                </Row>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <div className="main-form-section mt-3">
                <Row className="justify-content-center">
                  <Col sm={3} className="text-center">Currently Working :</Col>
                  <Col sm={9} className="text-center">{formData?.working || 'N/A'}</Col>
                </Row>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <div className="main-form-section mt-3">
                <Row className="justify-content-center">
                  <Col sm={3} className="text-center">Hours on the Weekly Basis :</Col>
                  <Col sm={9} className="text-center">{formData?.hours || 'N/A'}</Col>
                </Row>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <div className="main-form-section mt-3">
                <Row className="justify-content-center">
                  <Col sm={3} className="text-center">Budget :</Col>
                  <Col sm={9} className="text-center">{formData?.budget || 'N/A'}</Col>
                </Row>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <div className="main-form-section mt-3">
                <Row className="justify-content-center">
                  <Col sm={3} className="text-center">Easy EMIs :</Col>
                  <Col sm={9} className="text-center">{formData?.emi || 'N/A'}</Col>
                </Row>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <div className="main-form-section mt-3">
                <Row className="justify-content-center">
                  <Col sm={3} className="text-center">What Matters :</Col>
                  <Col sm={9} className="text-center">{formData?.what_matters || 'N/A'}</Col>
                </Row>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <div className="main-form-section mt-3">
                <Row className="justify-content-center">
                  <Col sm={3} className="text-center">Salary Package :</Col>
                  <Col sm={9} className="text-center">{formData?.sallary_package || 'N/A'}</Col>
                </Row>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <div className="main-form-section mt-3">
                <Row className="justify-content-center">
                  <Col sm={3} className="text-center">Percentage :</Col>
                  <Col sm={9} className="text-center">{formData?.percentage || 'N/A'}</Col>
                </Row>
              </div>
            </Col>
          </Row>
          {/* Buttons */}
          <Row className="justify-content-center mt-5 pb-3">
            <Col sm={6} className="d-flex justify-content-center">
              <CancelButton name={"Close"} handleClose={props.handleClose} />
            </Col>
          </Row>
        </Modal.Body>
      </Modal>

      <ModalSave
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      />
    </>
  );
};

export default EditOffCanvance;
