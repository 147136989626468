import React, { useContext ,useEffect} from "react";
import { useState } from "react";
import "../../../Tabels/Tabels.css";
import { Context } from "../../../../utils/context";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";

import ModalSave from "../../../common/ModelSave";
import { CancelButton, SaveButton } from "../../../common/Button";
import { Row, Col, Form, Modal } from "react-bootstrap";
// import { useForm } from "react-hook-form";
import classNames from "classnames";
import JoditEditor from 'jodit-react';
import { useForm, Controller } from 'react-hook-form';

library.add(fas);

const AddOffCanvance = (props) => {
  const { postData, getDimension } = useContext(Context);

  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    watch,
    trigger,
    setValue
  } = useForm();


  const videoFile = watch("video");
  const descriptionValue = watch("description");
  console.log(errors);
  const onSubmit = async (data) => {
    try {
      const fromData = new FormData();
      fromData.append("name", data?.title);
      fromData.append("description", data?.description);


      const response = await postData(`/masters/ed-tech/about-as/about`, fromData);
      console.log(response);
      if (response?.success) {
        await setShowModal({ code: response.code, message: response.message });
      } else {
        await setShowModal({ code: response.code, message: response.message });
      }
      setTimeout(() => {
        setShowModal(0);
        props.handleClose();
      }, 1000);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    register('description', {
      required: 'Description is required.',
      maxLength: {
        value: 1900,
        message: 'Description must be at most 1900 characters long.'
      }
    });
  }, [register]);


  return (
    <>
      <Modal
        onHide={props.handleClose}
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Add About
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            onSubmit={() => handleSubmit(onSubmit)}
            role="form"
            className="countryclass"
          >
            <Row>
              {/* Title */}
              <Col lg={6}>
                <div className="main-form-section mt-3">

                  <Form.Label> Title </Form.Label>

                  <Form.Group>
                    <Form.Control
                      className={classNames("", {
                        "is-invalid": errors?.title,
                      })}
                      type="text"
                      {...register("title", {
                        required: "Title is required",
                      })}
                      placeholder="Title"
                    />
                  </Form.Group>
                  {errors.title && (
                    <span className="text-danger">
                      {errors.title.message}
                    </span>
                  )}

                </div>
              </Col>



              <Col lg={12}>
                <div className="main-form-section mt-3">

                  <Form.Label>
                    Description
                  </Form.Label>

                  <Form.Group>
                    {/* <Controller
                      name="description" // Provide the field name
                      control={control} // Pass the control object from useForm()
                      rules={{
                        required: "Description is required", maxLength: {
                          value: 1900,
                          message: "subtitle must be at most 1900 characters long",
                        },
                      }} // Validation rules
                      render={({ field }) => (
                        <JoditEditor
                          value={field.value}
                          onChange={(newContent) => field.onChange(newContent)}
                          onBlur={field.onBlur}
                          className={classNames("", {
                            "is-invalid": !!errors.description,
                          })}
                          placeholder="Description"
                        />
                      )}
                    /> */}
                     <JoditEditor
                        value={descriptionValue || ''}
                        onBlur={(newContent) => {
                          setValue('description', newContent); 
                          trigger('description'); 
                        }}
                      
                      />
                  </Form.Group>
                  {errors.description && (
                    <span className="text-danger">
                      {errors.description.message}
                    </span>
                  )}

                </div>
              </Col>


            </Row>



            {/* Buttons */}
            <Row className="justify-content-center mt-5 pb-3">
              <Col sm={6} className="d-flex justify-content-center">
                <CancelButton name={"Cancel"} handleClose={props.handleClose} />
                <SaveButton
                  name={"Save"}
                  handleSubmit={handleSubmit(onSubmit)}
                />
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>

      <ModalSave
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      />
    </>
  );
};

export default AddOffCanvance;
