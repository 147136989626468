import React, { useContext } from "react";
import { useState, useEffect } from "react";
import "../../../Tabels/Tabels.css";
import { Link } from "react-router-dom";
import { Context } from "../../../../utils/context";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
// *******************toster****************************
import Select from "react-select";
import ModalSave from "../../../common/ModelSave";
import Validation from "../../../common/FormValidation";
import { CancelButton, SaveButton } from "../../../common/Button";

import { Row, Col, Form, InputGroup, Modal } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import classNames from "classnames";
library.add(fas);

const EditOffCanvance = (props) => {
  const { postData, getData, Select2Data } = useContext(Context);

  const id = props.show;
  const [country, setCountry] = useState([]);

  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });

  const GetEditData = async () => {
    const response = await getData(
      `/masters/job-portal/home/additional-question-answer/${id}`
    );
    reset(response?.data);
  };

  const GetAllScreeningQuestion = async () => {
    const response = await getData("/all-additional-question");

    if (response.success) {
      setCountry(await Select2Data(response?.data, "additional_question_id"));
    }
  };

  useEffect(() => {
    GetAllScreeningQuestion();
    GetEditData();
  }, []);

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const onSubmit = async (data) => {
    try {
      console.log(data);
      const response = await postData(
        `/masters/job-portal/home/additional-question-answer/${id}`,
        data
      );
      console.log(response);
      if (response?.success) {
        await setShowModal({ code: response.code, message: response.message });
      } else {
        await setShowModal({ code: response.code, message: response.message });
      }
      setTimeout(() => {
        setShowModal(0);
        props.handleClose();
      }, 1000);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Modal
        {...props}
        onHide={props.handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Edit Additional Question Answer
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={() => handleSubmit(onSubmit)} role="form">
            <Row>
              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="row justify-content-center mb-2">
                    <Form.Label>Screening Question</Form.Label>

                    <Controller
                      name="additional_question_id"
                      {...register("additional_question_id", {
                        required: "Additional Question is required",
                      })}
                      control={control}
                      render={({ field }) => (
                        <Select
                          styles={{
                            control: (baseStyles) => ({
                              ...baseStyles,
                              borderColor: errors.additional_question_id
                                ? "red"
                                : baseStyles,
                            }),
                          }}
                          {...field}
                          options={country}
                        />
                      )}
                    />

                    {errors.additional_question_id && (
                      <span className="text-danger">
                        {errors.additional_question_id.message}
                      </span>
                    )}
                  </Row>
                </div>
              </Col>
              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label>Must Have</Form.Label>
                    <Form.Group>
                      <InputGroup>
                        {/* Replace Form.Control with Form.Check */}
                        <Form.Check
                          type="checkbox"
                          id="must-have-checkbox"
                          label="Must Have"
                          name="must_have"
                          className={classNames("", {
                            "is-invalid": errors?.must_have,
                          })}
                          {...register("must_have")}
                        />
                      </InputGroup>
                      {errors.must_have && (
                        <span className="text-danger">
                          {errors.must_have.message}
                        </span>
                      )}
                    </Form.Group>
                  </Row>
                </div>
              </Col>
              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label>Answer One</Form.Label>

                    <Form.Group>
                      <InputGroup>
                        <Form.Control
                          type="text"
                          name="answer_one"
                          // value={formData.name}
                          // onChange={handleChange}
                          placeholder="Answer One"
                          className={classNames("", {
                            "is-invalid": errors?.answer_one,
                          })}
                          {...register("answer_one", {
                            required: "Answer One is required",
                          })}
                          // id="inputEmail3"
                          // className="input-mandatory"
                        />
                      </InputGroup>
                      {errors.answer_one && (
                        <span className="text-danger">
                          {errors.answer_one.message}
                        </span>
                      )}
                    </Form.Group>
                  </Row>
                </div>
              </Col>{" "}

              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label>Answer One Value</Form.Label>

                    <Form.Group>
                      <InputGroup>
                        <Form.Control
                          type="text"
                          name="answer_one_value"
                          // value={formData.name}
                          // onChange={handleChange}
                          placeholder="Answer One"
                          className={classNames("", {
                            "is-invalid": errors?.answer_one_value,
                          })}
                          {...register("answer_one_value", {
                            required: "answer_one_value is required",
                          })}
                          // id="inputEmail3"
                          // className="input-mandatory"
                        />
                      </InputGroup>
                      {errors.answer_one_value && (
                        <span className="text-danger">
                          {errors.answer_one_value.message}
                        </span>
                      )}
                    </Form.Group>
                  </Row>
                </div>
              </Col>{" "}
              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label>Answer Two</Form.Label>

                    <Form.Group>
                      <InputGroup>
                        <Form.Control
                          type="text"
                          name="Answer One"
                          // value={formData.name}
                          // onChange={handleChange}
                          placeholder="answer_two"
                          className={classNames("", {
                            "is-invalid": errors?.answer_two,
                          })}
                          {...register("answer_two", {
                            required: "Answer Two is required",
                          })}
                          // id="inputEmail3"
                          // className="input-mandatory"
                        />
                      </InputGroup>
                      {errors.answer_two && (
                        <span className="text-danger">
                          {errors.answer_two.message}
                        </span>
                      )}
                    </Form.Group>
                  </Row>
                </div>
              </Col>
              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label>Answer Two Value</Form.Label>

                    <Form.Group>
                      <InputGroup>
                        <Form.Control
                          type="text"
                          name="answer_two_value"
                          // value={formData.name}
                          // onChange={handleChange}
                          placeholder="Answer Two"
                          className={classNames("", {
                            "is-invalid": errors?.answer_two_value,
                          })}
                          {...register("answer_two_value", {
                            required: "Answer Two is required",
                          })}
                          // id="inputEmail3"
                          // className="input-mandatory"
                        />
                      </InputGroup>
                      {errors.answer_two_value && (
                        <span className="text-danger">
                          {errors.answer_two_value.message}
                        </span>
                      )}
                    </Form.Group>
                  </Row>
                </div>
              </Col>
              <Row className="mt-5 pb-3">
                <div className="d-flex justify-content-center">
                  <Link>
                    <CancelButton
                      name={"cancel"}
                      handleClose={props.handleClose}
                    />
                  </Link>

                  <SaveButton
                    name={"save"}
                    handleSubmit={handleSubmit(onSubmit)}
                  />
                </div>
              </Row>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>

      <ModalSave
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      />
    </>
  );
};

export default EditOffCanvance;
