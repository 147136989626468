import React, { useContext } from "react";
import { useState, useEffect } from "react";
import "../../../Tabels/Tabels.css";
import { Context } from "../../../../utils/context";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";

import ModalSave from "../../../common/ModelSave";
import { CancelButton, SaveButton } from "../../../common/Button";
import { Row, Col, Form, Modal } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import classNames from "classnames";
import JoditEditor from "jodit-react";
import Select from "react-select";

library.add(fas);

const AddOffCanvance = (props) => {
  const { postData, getData, Select2Data, getDimension } = useContext(Context);

  const [blogCategory, setBlogCategory] = useState([]);

  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    control,
  } = useForm();

  const imageFile1 = watch("heading_image");
  const imageFile2 = watch("banner_image");
  const conclusionValue = watch("conclusion");
  const introductionValue = watch("introduction");
  const detailsValue = watch("details");
  console.log(errors);
  const onSubmit = async (data) => {
    try {
      console.log("Form data:", data);
      const fromData = new FormData();
      fromData.append("blog_category_id", data?.blog_category_id?.value);
      fromData.append("name", data?.name);
      fromData.append("introduction", data?.introduction);
      fromData.append("conclusion", data?.conclusion);
      fromData.append("details", data?.details);
      fromData.append("heading_image", data?.heading_image[0]);
      fromData.append("banner_image", data?.banner_image[0]);
      const response = await postData(`/masters/ed-tech/blog/blogs`, fromData);
      console.log("Form response :- ", response);

      if (response?.success) {
        await setShowModal({ code: response?.code, message: response?.message });
      } else {
        await setShowModal({ code: response?.code, message: response?.message });
      }
      setTimeout(() => {
        setShowModal(0);
        props.handleClose();
      }, 1000);
    } catch (error) {
      console.log(error);
    }

  };



  const GetAllBlogCategory = async () => {
    const response = await getData("/allblogcategory");

    if (response.success) {
      setBlogCategory(await Select2Data(response?.data, "blog_category_id"));
    }
  };

  useEffect(() => {
    GetAllBlogCategory();
  }, []);

  return (
    <>
      <Modal
        onHide={props.handleClose}
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Add Blog
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            onSubmit={handleSubmit(onSubmit)}
            role="form"
            className="countryclass"
          >
            <Row>
              {/* Left side: Title and Image Upload */}
              <Col lg={6}>
                <div className="main-form-section mt-3">
                  <Row className="row justify-content-center mb-2">
                    <Form.Label>Blog Category</Form.Label>

                    <Controller
                      name="blog_category_id"
                      {...register("blog_category_id", {
                        required: "Category is required",
                      })}
                      control={control}
                      render={({ field }) => (
                        <div style={{ width: "300px" }}>
                          <Select
                            styles={{
                              control: (baseStyles) => ({
                                ...baseStyles,

                                borderColor: errors.blog_category_id
                                  ? "red"
                                  : baseStyles,
                              }),
                            }}
                            {...field}
                            options={blogCategory}
                          />
                        </div>
                      )}
                    />


                    {errors.country_id && (
                      <span className="text-danger">
                        {errors.country_id.message}
                      </span>
                    )}
                  </Row>
                </div>
                <div className="main-form-section mt-3">
                  <Form.Label className="text-center">Heading</Form.Label>
                  <Row className="justify-content-center">
                    <Col sm={9}>
                      <Form.Group>
                        <Form.Control
                          className={classNames("", {
                            "is-invalid": errors?.name,
                          })}
                          type="text"
                          {...register("name", {
                            required: "Title is required",
                          })}
                          placeholder="Title"
                        />
                      </Form.Group>
                      {errors.name && (
                        <span className="text-danger">
                          {errors.name.message}
                        </span>
                      )}
                    </Col>
                  </Row>
                </div>
                <div className="main-form-section mt-3">
                  <Form.Label className="text-center">Heading Image</Form.Label>
                  <Row className="justify-content-center">
                    <Col sm={9}>
                      <Form.Group>
                        <Form.Control
                          className={classNames("", {
                            "is-invalid": errors?.heading_image,
                          })}
                          type="file"
                          {...register("heading_image", {
                            required: "Image is required",
                            validate: async (value) => {
                              if (typeof value !== "string") {
                                const fileTypes = ["jpg", "png", "jpeg"];
                                const fileType = value[0].name?.split(".")[1];

                                if (!fileTypes.includes(fileType)) {
                                  return `please upload a valid file format. (${fileTypes})`;
                                }

                                const sizes = await getDimension(value[0]);
                                if (sizes.width !== 230 && sizes.height !== 230) {
                                  return "Image width and height must be 230px and 230px";
                                }

                                // const fileSize = Math.round(value[0].size / 1024);
                                // if (fileSize > 500) {
                                //   return "file size must be lower than 500kb";
                                // }
                              }
                            }
                          })}
                          accept=".jpg, .jpeg, .png"
                        />
                      </Form.Group>
                      {errors.heading_image && (
                        <span className="text-danger">
                          {errors.heading_image.message}
                        </span>
                      )}
                    </Col>
                  </Row>
                </div>

                <div className="main-form-section mt-3">
                  <Form.Label className="text-center">Banner Image</Form.Label>
                  <Row className="justify-content-center">
                    <Col sm={9}>
                      <Form.Group>
                        <Form.Control
                          className={classNames("", {
                            "is-invalid": errors?.banner_image,
                          })}
                          type="file"
                          {...register("banner_image", {
                            required: "banner_image is required",
                            validate: async (value) => {
                              if (typeof value !== "string") {
                                const fileTypes = ["jpg", "png", "jpeg"];
                                const fileType = value[0].name?.split(".")[1];

                                if (!fileTypes.includes(fileType)) {
                                  return `please upload a valid file format. (${fileTypes})`;
                                }

                                const sizes = await getDimension(value[0]);
                                if (sizes.width !== 1296 && sizes.height !== 396) {
                                  return "Image width and height must be 1296px and 396px";
                                }

                                // const fileSize = Math.round(value[0].size / 1024);
                                // if (fileSize > 500) {
                                //   return "file size must be lower than 500kb";
                                // }
                              }
                            }
                          })}
                          accept=".jpg, .jpeg, .png"
                        />
                      </Form.Group>
                      {errors.heading_image && (
                        <span className="text-danger">
                          {errors.banner_image?.message}
                        </span>
                      )}
                    </Col>
                  </Row>
                </div>
              </Col>

              {/* Right side: Image Preview */}
              <Col lg={6}>
                <div className="main-form-section mt-3">
                  <Form.Label className="text-center">Heading Image</Form.Label>
                  <Row className="justify-content-center">
                    <Col sm={9}>
                      {imageFile1 && imageFile1?.length > 0 && (
                        <div className="image-preview-container">
                          <img
                            src={URL.createObjectURL(imageFile1[0])}
                            alt="Preview"
                            className="image-preview"
                            style={{ width: "100px", height: "100px" }}
                          />
                        </div>
                      )}
                    </Col>
                  </Row>
                </div>
                <div className="main-form-section mt-3">
                  <Form.Label className="text-center">Banner Image</Form.Label>
                  {/* <Row className="justify-content-center"> */}
                  <Col sm={9}>
                    {imageFile2 && imageFile2?.length > 0 && (
                      <div className="image-preview-container">
                        <img
                          src={URL.createObjectURL(imageFile2[0])}
                          alt="Preview"
                          className="image-preview"
                          style={{ width: "324px", height: "99px" }}
                        />
                      </div>
                    )}
                  </Col>
                  {/* </Row> */}
                </div>
              </Col>

              {/* Bottom: Description */}
              <Col lg={12}>
                <div className="main-form-section mt-3">
                  <Form.Label className="text-center">introduction</Form.Label>
                  <Row className="justify-content-center">
                    <Col sm={12}>
                      <JoditEditor
                        value={introductionValue}
                        onChange={(value) => setValue("introduction", value)}
                      />
                    </Col>
                  </Row>
                </div>
              </Col>

              <Col lg={12}>
                <div className="main-form-section mt-3">
                  <Form.Label className="text-center">Conclusion</Form.Label>
                  <Row className="justify-content-center">
                    <Col sm={12}>
                      <JoditEditor
                        value={conclusionValue}
                        onChange={(value) => setValue("conclusion", value)}
                      />
                    </Col>
                  </Row>
                </div>
              </Col>

              <Col lg={12}>
                <div className="main-form-section mt-3">
                  <Form.Label className="text-center">Details</Form.Label>
                  <Row className="justify-content-center">
                    <Col sm={12}>
                      <JoditEditor
                        value={detailsValue}
                        onChange={(value) => setValue("details", value)}
                      />
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>

            {/* Buttons */}
            <Row className="justify-content-center mt-5 pb-3">
              <Col sm={6} className="d-flex justify-content-center">
                <CancelButton name={"Cancel"} handleClose={props.handleClose} />
                <SaveButton
                  name={"Save"}
                  handleSubmit={handleSubmit(onSubmit)}
                />
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal >

      <ModalSave
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      />
    </>
  );
};

export default AddOffCanvance;
