import React, { useContext } from "react";
import { useState, useEffect, useRef } from "react";
import "../../../Tabels/Tabels.css";
import { Context } from "../../../../utils/context";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import ModalSave from "../../../common/ModelSave";
import { CancelButton } from "../../../common/Button";
import Modal from "react-bootstrap/Modal";
import { Row, Col } from "react-bootstrap";
library.add(fas);

const EditOffCanvance = (props) => {
  const { getData } = useContext(Context);

  const id = props.show;
  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    address: "",
  });

  const GetEditData = async () => {
    const response = await getData(`/masters/finance/customer-management/insurance-forms/${id}`);
    setFormData(response?.data);
    console.log(formData);
  };

  useEffect(() => {
    GetEditData();
  }, []);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'short' });
    const year = date.getFullYear();
    return `${day} ${month} ${year}`;
  };

  return (
    <>
      <Modal
        onHide={props.handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={true} // Assuming you're controlling the modal visibility from outside
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            View Details
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col lg={12}>
              <div className="main-form-section mt-3">
                <Row className="justify-content-center">
                  <Col sm={3} className="text-center">Name:</Col>
                  <Col sm={9} className="text-center">{formData?.name}</Col>
                </Row>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <div className="main-form-section mt-3">
                <Row className="justify-content-center">
                  <Col sm={3} className="text-center">Phone:</Col>
                  <Col sm={9} className="text-center">{formData?.contact_no}</Col>
                </Row>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <div className="main-form-section mt-3">
                <Row className="justify-content-center">
                  <Col sm={3} className="text-center">Date of Birth:</Col>
                  <Col sm={9} className="text-center">{formatDate(formData?.dob) || 'N/A'}</Col>
                </Row>
              </div>
            </Col>
          </Row>

          <Row>
            {/* <Col lg={12}>
              <div className="main-form-section mt-3">
                <Row className="justify-content-center">
                  <Col sm={3} className="text-center">Applied For</Col>
                  <Col sm={9} className="text-center"></Col>
                </Row>
              </div>
            </Col> */}
          </Row>
          <Row>
            <div className="main-form-section mt-5">
              <h5>
                Applied For
              </h5>
            </div>
          </Row>
          <Row>
            <Col lg={12}>
              <div className="main-form-section mt-3">
                <Row className="justify-content-center">
                  <Col sm={3} className="text-center">Service Type</Col>
                  <Col sm={9} className="text-center">{formData?.f_service?.f_service_type?.name}</Col>
                </Row>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <div className="main-form-section mt-3">
                <Row className="justify-content-center">
                  <Col sm={3} className="text-center">Service</Col>
                  <Col sm={9} className="text-center">{formData?.f_service?.name}</Col>
                </Row>
              </div>
            </Col>
          </Row>
          {/* Buttons */}
          <Row className="justify-content-center mt-5 pb-3">
            <Col sm={6} className="d-flex justify-content-center">
              <CancelButton name={"Close"} handleClose={props.handleClose} />
            </Col>
          </Row>
        </Modal.Body>
      </Modal>

      <ModalSave
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      />
    </>
  );
};

export default EditOffCanvance;
