import React, { useContext, useEffect } from "react";
import { useState } from "react";
import "../../../Tabels/Tabels.css";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";
import ModalSave from "../../../common/ModelSave";
import { CancelButton, SaveButton } from "../../../common/Button";
import { Row, Col, Form, Modal } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import classNames from "classnames";
import { Context } from "../../../../utils/context";

library.add(fas);

const AddOffCanvance = (props) => {
  const { postData, getData, getDimension, Select2Data } = useContext(Context);
  const [services, setServices] = useState([]);

  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm();

  const imageFile = watch("image");
  console.log(errors);

  const onSubmit = async (data) => {
    try {
      const fromData = new FormData();
      fromData.append("name", data?.name);
      fromData.append("service_id", data?.service_id?.value);
      fromData.append("image", data?.image[0]);
      const response = await postData(`/masters/finance/loan/f-profcyma-finance`, fromData);

      if (response?.success) {
        await setShowModal({
          code: response?.code,
          message: response?.message,
        });
      } else {
        await setShowModal({
          code: response?.code,
          message: response?.message,
        });
      }
      setTimeout(() => {
        setShowModal(0);
        props.handleClose();
      }, 700);
    } catch (error) {
      console.log(error);
    }
  };

  const GetAllServices = async () => {
    const response = await getData("/allFServices/1");

    if (response?.success) {
      setServices(await Select2Data(response?.data, "service_id"));
    }
  };

  useEffect(() => {
    GetAllServices();
  }, []);

  return (
    <>
      <Modal
        onHide={props.handleClose}
        {...props}
        size="lg"
        aria-labelledby="contained-modal-name-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-name-vcenter">
            Add Profcyma Finance
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            onSubmit={() => handleSubmit(onSubmit)}
            role="form"
            className="countryclass"
          >
            <Row>
              {/* name */}
              <Col lg={6}>
                <div className="main-form-section mt-3">
                  <Row className="row justify-content-center mb-2">
                    <Form.Label>Service</Form.Label>

                    <Controller
                      name="service_id" // name of the field
                      {...register("service_id", {
                        required: "Service is required",
                      })}
                      control={control}
                      render={({ field }) => (
                        <Select
                          styles={{
                            control: (baseStyles) => ({
                              ...baseStyles,
                              borderColor: errors.service_id
                                ? "red"
                                : baseStyles,
                            }),
                          }}
                          {...field}
                          options={services}


                        />
                      )}
                    />

                    {errors.service_id && (
                      <span className="text-danger">
                        {errors.service_id.message}
                      </span>
                    )}
                  </Row>
                </div>
                <div className="main-form-section mt-3">
                  <Form.Label > Title</Form.Label>
                  <Form.Group>
                    <Form.Control
                      className={classNames("", {
                        "is-invalid": errors?.name,
                      })}
                      type="text"
                      {...register("name", {
                        required: "Title is required",
                        // maxLength: {
                        //   value: 20,
                        //   message: "Title must be at most 20 characters long",
                        // },
                      })}
                      placeholder="Title"
                    />
                  </Form.Group>
                  {errors.name && (
                    <span className="text-danger">
                      {errors.name.message}
                    </span>
                  )}


                </div>


                <div className="main-form-section mt-3">
                  <Form.Label > Image</Form.Label>

                  <Form.Group>
                    <Form.Control
                      className={classNames("", {
                        "is-invalid": errors?.image,
                      })}
                      type="file"
                      {...register("image", {
                        // required: "Image is required",
                        // validate: async (value) => {
                        //   const fileTypes = ["jpg", "png", "jpeg"];
                        //   const fileType = value[0].name.split(".")[1];

                        //   if (!fileTypes.includes(fileType)) {
                        //     return `please upload a valid file format. (${fileTypes})`;
                        //   }

                        //   const sizes = await getDimension(value[0]);
                        //   if (
                        //     sizes.width !== 1296 &&
                        //     sizes.height !== 465
                        //   ) {
                        //     return "Image width and height must be 1296px and 465px";
                        //   }

                        //   const fileSize = Math.round(value[0].size / 1024);
                        //   if (fileSize > 500) {
                        //     return "file size must be lower than 500kb";
                        //   }
                        // },
                      })}
                      accept=".jpg, .jpeg, .png"
                    />
                  </Form.Group>
                  {errors.image && (
                    <span className="text-danger">
                      {errors.image.message}
                    </span>
                  )}

                </div>
              </Col>

              {/* Image Upload */}
              <Col lg={6}>


                <div className="main-form-section mt-3" style={{ marginLeft: '50px' }}>

                  <Form.Label > Image Preview</Form.Label>

                  {imageFile && imageFile?.length > 0 && (
                    <div className="image-preview-container">
                      <img
                        src={URL.createObjectURL(imageFile[0])}
                        alt="Preview"
                        className="image-preview"
                        style={{ width: "150px", height: "150px" }}
                      />
                    </div>
                  )}


                </div>
              </Col>
            </Row>

            {/* Buttons */}
            <Row className="justify-content-center mt-5 pb-3">
              <Col sm={6} className="d-flex justify-content-center">
                <CancelButton name={"Cancel"} handleClose={props.handleClose} />
                <SaveButton
                  name={"Save"}
                  handleSubmit={handleSubmit(onSubmit)}
                />
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>

      <ModalSave
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      />
    </>
  );
};

export default AddOffCanvance;
