import React, { useContext } from "react";
import { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import "../../../../Tabels/Tabels.css";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";

import ModalSave from "../../../../common/ModelSave";
import { CancelButton, SaveButton } from "../../../../common/Button";
import { Row, Col, Form, Modal } from "react-bootstrap";

import classNames from "classnames";
import { Context } from "../../../../../utils/context";
import JoditEditor from "jodit-react";
library.add(fas);

const AddOffCanvance = (props) => {
  const { postData, getData, Select2Data, getDimension } = useContext(Context);
  const [course, setCourse] = useState([]);

  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    watch, trigger,
    setValue
  } = useForm();

  const imageFile = watch("image");
  console.log(errors);
  const descriptionValue = watch("description");
  const GetAllUniversity = async () => {
    const response = await getData("/alluniversity");

    if (response.success) {
      setCourse(await Select2Data(response?.data, "university_id"));
    }
  };
  useEffect(() => {
    GetAllUniversity();
  }, []);

  const onSubmit = async (data) => {
    try {
      const fromData = new FormData();
      fromData.append("name", data?.name);
      fromData.append("description", data?.description);
      fromData.append("university_id", data?.university_id?.value);
      fromData.append("image", data?.image[0]);
      const response = await postData(
        `/masters/ed-tech/university/common/key-program-features`,
        fromData
      );

      if (response?.success) {
        await setShowModal({
          code: response?.code,
          message: response?.message,
        });
      } else {
        await setShowModal({
          code: response?.code,
          message: response?.message,
        });
      }
      setTimeout(() => {
        setShowModal(0);
        props.handleClose();
      }, 1000);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    register('description', {
      required: 'Description is required.',
      // maxLength: {
      //   value: 110,
      //   message: 'Description must be at most 110 characters long.'
      // }
    });
  }, [register]);

  return (
    <>
      <Modal
        onHide={props.handleClose}
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Add key Programing Feature
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            onSubmit={() => handleSubmit(onSubmit)}
            role="form"
            className="countryclass"
          >
            <Row>
              {/* Title */}
              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="row justify-content-center mb-2">
                    <Form.Label>University</Form.Label>
                    <Controller
                      name="university_id" // name of the field
                      {...register("university_id", {
                        required: "University  is required",
                      })}
                      control={control}
                      render={({ field }) => (
                        <Select
                          styles={{
                            control: (baseStyles) => ({
                              ...baseStyles,
                              borderColor: errors.university_id
                                ? "red"
                                : baseStyles,
                            }),
                          }}
                          {...field}
                          options={course}
                        />
                      )}
                    />

                    {errors.university_id && (
                      <span className="text-danger">
                        {errors.university_id.message}
                      </span>
                    )}
                  </Row>
                </div>

                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label>
                      Title
                    </Form.Label>
                    <Form.Group>
                      <Form.Control
                        className={classNames("", {
                          "is-invalid": errors?.name,
                        })}
                        type="text"
                        {...register("name", {
                          required: "Title is required",
                          maxLength: {
                            value: 15,
                            message: "Title must be at most 15 characters long",
                          },
                        })}
                        placeholder="Title"
                      />
                    </Form.Group>
                    {errors.name && (
                      <span className="text-danger">
                        {errors.name.message}
                      </span>
                    )}

                  </Row>
                </div>

                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label >
                      Image
                    </Form.Label>

                    <Form.Group>
                      <Form.Control
                        className={classNames("", {
                          "is-invalid": errors?.image,
                        })}
                        type="file"
                        {...register("image", {
                          required: "Image is required",
                          validate: async (value) => {
                            const fileTypes = ["jpg", "png", "jpeg"];
                            const fileType = value[0].name.split(".")[1];

                            if (!fileTypes.includes(fileType)) {
                              return `please upload a valid file format. (${fileTypes})`;
                            }

                            // const sizes = await getDimension(value[0]);
                            // if (sizes.width !== 85 && sizes.height !== 85) {
                            //   return "Image width and height must be 85 px and 85 px";
                            // }

                            // const fileSize = Math.round(value[0].size / 1024);
                            // if (fileSize > 500) {
                            //   return "file size must be lower than 500kb";
                            // }
                          },
                        })}
                        accept=".jpg, .jpeg, .png"
                      />
                    </Form.Group>
                    {errors.image && (
                      <span className="text-danger">
                        {errors.image.message}
                      </span>
                    )}

                  </Row>
                </div>
              </Col>

              {/* Image Preview */}
              <Col lg={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label >
                      Image Preview
                    </Form.Label>
                    <Col sm={9}>
                      {imageFile && imageFile?.length > 0 && (
                        <div className="image-preview-container" >
                          <img
                            src={URL.createObjectURL(imageFile[0])}
                            alt="Preview"
                            className="image-preview"
                            style={{ width: "100px", height: "100px" }}
                          />
                        </div>
                      )}
                    </Col>
                  </Row>
                </div>
              </Col>

              <Col lg={12}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label >
                      Description
                    </Form.Label>

                    <Form.Group>

                      <JoditEditor
                        value={descriptionValue || ''}
                        onBlur={(newContent) => {
                          setValue('description', newContent);
                          trigger('description');
                        }}

                      />
                    </Form.Group>
                    {errors.description && (
                      <span className="text-danger">
                        {errors.description.message}
                      </span>
                    )}

                  </Row>
                </div>
              </Col>
            </Row>

            {/* Buttons */}
            <Row className="justify-content-center mt-5 pb-3">
              <Col sm={6} className="d-flex justify-content-center">
                <CancelButton name={"Cancel"} handleClose={props.handleClose} />
                <SaveButton
                  name={"Save"}
                  handleSubmit={handleSubmit(onSubmit)}
                />
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>

      <ModalSave
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      />
    </>
  );
};

export default AddOffCanvance;
