import React, { useContext } from "react";
import { useState, useEffect } from "react";
import "../../Tabels/Tabels.css";
import { Link } from "react-router-dom";
import { Context } from "../../../utils/context";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import Offcanvas from "react-bootstrap/Offcanvas";
// *******************toster****************************
import Card from "react-bootstrap/Card";
import Select from "react-select";
import ModalSave from "../../common/ModelSave";
import Validation from "../../common/FormValidation";
import { CancelButton, SaveButton } from "../../common/Button";

import {
  Container,
  Row,
  Col,
  Button,
  Form,
  InputGroup,
  Modal,
} from "react-bootstrap";

library.add(fas);

const EditOffCanvance = (props) => {
  const { postData, getData, Select2Data } = useContext(Context);
  //   const { id } = useParams();

  const id = props.show;
  const [degreetype, setdegreetype] = useState([]);
  const [level, setLevel] = useState([]);
  const [talent, setTalent] = useState([]);
  const [formData, setFormData] = useState({
    id: "",
    name: "",
    talent_id: "",
    level_id: "",
    degree_type_id: "",
    hic_no: "",
  });

  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });

  const handleChange = (e) => {
    if (
      e?.name === "degree_type_id" ||
      e?.name === "talent_id" ||
      e?.name === "level_id"
    ) {
      setFormData({ ...formData, [e.name]: e });
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = await Validation.FormValidation({
      classname: ".degreetypeclass",
    });

    const validationErrorsSelect = Validation.SelectValidation({
      classname: ".degreetypeclass",
    });
    if (validationErrors && validationErrorsSelect) {
      try {
        const response = await postData(`/masters/degree/${id}`, formData);
        if (response.success) {
          setShowModal({ code: response.code, message: response.message });
          setTimeout(() => {
            setShowModal(0);
            props.handleClose();
          }, 1000);
        } else {
          setShowModal({ code: response.code, message: response.message });
          setTimeout(() => {
            setShowModal(0);
            props.handleClose();
          }, 1000);
        }
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    }
  };

  const handleKeyPressContact = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);

    if (!/^\d$/.test(keyValue)) {
      event.preventDefault();
    }
  };

  const GetEditData = async () => {
    const response = await getData(`/masters/degree/${id}`);

    setFormData(response?.data);
  };

  useEffect(() => {
    GetEditData();
  }, []);

  const GetAlldegreetype = async () => {
    const response = await getData("/alldegreetype");

    if (response.success) {
      setdegreetype(await Select2Data(response?.data, "degree_type_id"));
    }

    const reslevel = await getData("/alllevel");

    if (reslevel.success) {
      setLevel(await Select2Data(reslevel?.data, "level_id"));
    }

    const restalent = await getData("/alltalent");

    if (restalent.success) {
      setTalent(await Select2Data(restalent?.data, "talent_id"));
    }
  };

  useEffect(() => {
    GetAlldegreetype();
  }, []);

  return (
    <>
      {/* <Offcanvas
        show={props.show}
        style={{ width: "80%" }}
        placement={"end"}
        onHide={props.handleClose}
      >
        <Offcanvas.Header closeButton>
       
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Card className="shadow-lg p-3 mb-5 bg-white rounded">
            <Card.Body>
              <Card.Title>Edit Degree</Card.Title>
              <hr />
              <Container>
                <Row>
                  <Col md={12}>
                    <Container>
                      <Row className="">
                        <Col xxl={12} xl={12} md={10}>
                          <Form
                            onSubmit={handleSubmit}
                            role="form"
                            className="degreetypeclass"
                          >
                            <Row>
                             
                              <Col md={6}>
                                <div className="main-form-section mt-5">
                                  <Row className="row justify-content-center mb-2">
                                    <Form.Label column sm={3}>
                                      Degree Type
                                    </Form.Label>
                                    <Col sm={6}>
                                      <Select
                                        name="degree_type_id"
                                        className="input-mandatory"
                                        value={formData?.degree_type_id}
                                        options={degreetype}
                                        onChange={handleChange}
                                      />{" "}
                                      <span className="error-message"></span>
                                    </Col>
                                  </Row>
                                </div>
                              </Col>
                              <Col md={6}>
                                <div className="main-form-section mt-5">
                                  <Row className="justify-content-center">
                                    <Form.Label column sm={3}>
                                      Degree
                                    </Form.Label>
                                    <Col sm={6}>
                                      <Form.Group>
                                        <InputGroup>
                                          <Form.Control
                                            type="text"
                                            name="name"
                                            value={formData?.name}
                                            onChange={handleChange}
                                            placeholder="Degree "
                                            id="inputEmail3"
                                            className="input-mandatory"
                                          />
                                        </InputGroup>
                                        <span className="error-message"></span>
                                      </Form.Group>
                                    </Col>
                                  </Row>
                                </div>
                              </Col>

                              <Col md={6}>
                                <div className="main-form-section mt-5">
                                  <Row className="justify-content-center">
                                    <Form.Label column sm={3}>
                                      Hic No
                                    </Form.Label>
                                    <Col sm={6}>
                                      <Form.Group>
                                        <InputGroup>
                                          <Form.Control
                                            type="tel"
                                            name="hic_no"
                                            value={formData?.hic_no}
                                            onChange={handleChange}
                                            onKeyPress={handleKeyPressContact}
                                            placeholder="Hic No"
                                            id="inputEmail3"
                                            className="input-mandatory"
                                          />
                                        </InputGroup>
                                        <span className="error-message"></span>
                                      </Form.Group>
                                    </Col>
                                  </Row>
                                </div>
                              </Col>
                           

                              <Row className="mt-5 pb-3">
                                <div className="d-flex justify-content-center">
                                  <Link>
                                    <CancelButton
                                      name={"cancel"}
                                      handleClose={props.handleClose}
                                    />
                                  </Link>
                                  <SaveButton
                                    name={"save"}
                                    handleSubmit={handleSubmit}
                                  />
                                </div>
                              </Row>

                            </Row>
                          </Form>
                        </Col>
                      </Row>
                    </Container>
                  </Col>
                </Row>
              </Container>
            </Card.Body>
          </Card>
        </Offcanvas.Body>
      </Offcanvas> */}

      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        onHide={props.handleClose}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Edit Degree Type
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit} role="form" className="degreetypeclass">
            <Row>
              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="row justify-content-center mb-2">
                    <Form.Label>Degree Type</Form.Label>

                    <Select
                      name="degree_type_id"
                      className="input-mandatory"
                      onChange={handleChange}
                      placeholder="Degree Type"
                      value={formData.degree_type_id}
                      options={degreetype}
                    />
                    <span className="error-message"></span>
                  </Row>
                </div>
              </Col>

              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label>Degree</Form.Label>

                    <Form.Group>
                      <InputGroup>
                        <Form.Control
                          type="text"
                          name="name"
                          value={formData.name}
                          onChange={handleChange}
                          placeholder="Degree "
                          id="inputEmail3"
                          className="input-mandatory"
                        />
                      </InputGroup>
                      <span className="error-message"></span>
                    </Form.Group>
                  </Row>
                </div>
              </Col>

              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="row justify-content-center ">
                    <Form.Label>Level</Form.Label>

                    <Select
                      name="level_id"
                      className="input-mandatory"
                      onChange={handleChange}
                      placeholder="Level"
                      value={formData.level_id}
                      options={level}
                    />
                    <span className="error-message"></span>
                  </Row>
                </div>
              </Col>

              {/* <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="row justify-content-center">
                    <Form.Label>Talent</Form.Label>

                    <Select
                      name="talent_id"
                      className="input-mandatory"
                      onChange={handleChange}
                      placeholder="Talent"
                      value={formData.talent_id}
                      options={talent}
                    />
                    <span className="error-message"></span>
                  </Row>
                </div>
              </Col> */}

              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label>Hic No</Form.Label>

                    <Form.Group>
                      <InputGroup>
                        <Form.Control
                          type="tel"
                          name="hic_no"
                          value={formData.hic_no}
                          onChange={handleChange}
                          onKeyPress={handleKeyPressContact}
                          placeholder="Hic No"
                          id="inputEmail3"
                          className="input-mandatory"
                        />
                      </InputGroup>
                      <span className="error-message"></span>
                    </Form.Group>
                  </Row>
                </div>
              </Col>

              <Row className="mt-5 pb-3">
                <div className="d-flex justify-content-center">
                  <Link>
                    <CancelButton
                      name={"cancel"}
                      handleClose={props.handleClose}
                    />
                  </Link>
                  <SaveButton name={"save"} handleSubmit={handleSubmit} />
                </div>
              </Row>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>

      <ModalSave
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      />
    </>
  );
};

export default EditOffCanvance;
