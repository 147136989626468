import React, { useContext } from "react";
import { useState } from "react";
import "../../../../Tabels/Tabels.css";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";

import ModalSave from "../../../../common/ModelSave";
import { CancelButton, SaveButton } from "../../../../common/Button";
import { Row, Col, Form, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import classNames from "classnames";
import { Context } from "../../../../../utils/context";

library.add(fas);

const AddOffCanvance = (props) => {
  const { postData, getDimension } = useContext(Context);

  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm();

  const imagesFile = watch("images");
  console.log(errors);

  const onSubmit = async (data) => {
    try {
      const formData = new FormData();
      formData.append("name", data?.name);
      formData.append("description", data?.description);
      for (let i = 0; i < data?.images.length; i++) {
        formData.append("images", data?.images[i]);
      }
      const response = await postData(
        `/masters/ed-tech/university/barati-vidyapeeth/we-deliver`,
        formData
      );

      if (response?.success) {
        await setShowModal({
          code: response?.code,
          message: response?.message,
        });
      } else {
        await setShowModal({
          code: response?.code,
          message: response?.message,
        });
      }
      setTimeout(() => {
        setShowModal(0);
        props.handleClose();
      }, 1000);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Modal
        onHide={props.handleClose}
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Add We Deliver
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            onSubmit={() => handleSubmit(onSubmit)}
            role="form"
            className="countryclass"
          >
            <Row>
              {/* Title */}
              <Col lg={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label column sm={3} className="text-center">
                      Title
                    </Form.Label>
                    <Col sm={9}>
                      <Form.Group>
                        <Form.Control
                          className={classNames("", {
                            "is-invalid": errors?.name,
                          })}
                          type="text"
                          {...register("name", {
                            required: "Title is required",
                            maxLength: {
                              value: 70,
                              message: "Title must be at most 500 characters long",
                            },
                          })}
                          placeholder="Title"
                        />
                      </Form.Group>
                      {errors.name && (
                        <span className="text-danger">
                          {errors.name.message}
                        </span>
                      )}
                    </Col>
                  </Row>
                </div>
              </Col>

              <Col lg={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label column sm={3} className="text-center">
                      description
                    </Form.Label>
                    <Col sm={9}>
                      <Form.Group>
                        <Form.Control
                          className={classNames("", {
                            "is-invalid": errors?.description,
                          })}
                          type="text"
                          {...register("description", {
                            required: "description is required",
                            maxLength: {
                              value: 500,
                              message: "Title must be at most 70 characters long",
                            },
                          })}
                          placeholder="description"
                        />
                      </Form.Group>
                      {errors.description && (
                        <span className="text-danger">
                          {errors.description.message}
                        </span>
                      )}
                    </Col>
                  </Row>
                </div>
              </Col>

              {/* images Upload */}
              <Col lg={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label column sm={3} className="text-center">
                      images
                    </Form.Label>
                    <Col sm={9}>
                      <Form.Group>
                        <Form.Control
                          className={classNames("", {
                            "is-invalid": errors?.images,
                          })}
                          type="file"
                          multiple
                          {...register("images", {
                            required: "images is required",
                            validate: async (value) => {
                              const fileTypes = ["jpg", "png", "jpeg"];
                              const fileType = value[0].name.split(".")[1];

                              if (!fileTypes.includes(fileType)) {
                                return `please upload a valid file format. (${fileTypes})`;
                              }

                              const sizes = await getDimension(value[0]);
                              if (sizes.width !== 306 && sizes.height !== 306) {
                                return "images width and height must be 306px and 306px";
                              }

                              const fileSize = Math.round(value[0].size / 1024);
                              if (fileSize > 500) {
                                return "file size must be lower than 500kb";
                              }
                            },
                          })}
                        //   accept=".jpg, .jpeg, .png"
                        />
                      </Form.Group>
                      {errors.images && (
                        <span className="text-danger">
                          {errors.images.message}
                        </span>
                      )}
                    </Col>
                  </Row>
                </div>
              </Col>

              {/* images Preview */}
              <Col lg={12}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label column sm={3} className="text-center">
                      images Preview
                    </Form.Label>
                    <Col sm={9}>
                      {imagesFile && imagesFile?.length > 0 && (
                        <div className="images-preview-container">
                          <img
                            src={URL.createObjectURL(imagesFile[0])}
                            alt="Preview"
                            className="images-preview"
                            style={{ width: "100px", height: "100px" }}
                          />
                        </div>
                      )}
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>

            {/* Buttons */}
            <Row className="justify-content-center mt-5 pb-3">
              <Col sm={6} className="d-flex justify-content-center">
                <CancelButton name={"Cancel"} handleClose={props.handleClose} />
                <SaveButton
                  name={"Save"}
                  handleSubmit={handleSubmit(onSubmit)}
                />
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>

      <ModalSave
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      />
    </>
  );
};

export default AddOffCanvance;
