import React, { useContext } from "react";
import { useState, useEffect } from "react";
import "../../Tabels/Tabels.css";
import { Link } from "react-router-dom";
import { Context } from "../../../utils/context";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import Offcanvas from "react-bootstrap/Offcanvas";
import Card from "react-bootstrap/Card";
import ModalSave from "../../common/ModelSave";
import Validation from "../../common/FormValidation";
import { CancelButton, SaveButton } from "../../common/Button";
import Select from "react-select";
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  InputGroup,
  Modal,
} from "react-bootstrap";

library.add(fas);

const AddOffCanvance = (props) => {
  const { postData, getData } = useContext(Context);

  const [category, setCategory] = useState([]);
  const [formData, setFormData] = useState({
    name: "",
  });

  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = await Validation.FormValidation({
      classname: ".desciplineclass",
    });
    const validationErrorsSelect = Validation.SelectValidation({
      classname: ".desciplineclass",
    });

    if (validationErrors && validationErrorsSelect) {
      try {
        const response = await postData("/masters/department", formData);
        if (response.success) {
          setShowModal({ code: response.code, message: response.message });
          setTimeout(() => {
            setShowModal(0);
            props.handleClose();
          }, 1000);
        } else {
          setShowModal({ code: response.code, message: response.message });
          setTimeout(() => {
            setShowModal(0);
            props.handleClose();
          }, 1000);
        }
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    }
  };


  return (
    <>

      <Modal
        {...props}
        size="lg"
        onHide={props.handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Add Department
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit} role="form" className="desciplineclass">
            <Row>
              <Col lg={12} md={12} className=" mx-auto Add-content">
                <Row>

                  <Col md={6}>
                    <div className="main-form-section mt-3">
                      <Row className="justify-content-center">
                        <Form.Label>Department</Form.Label>

                        <Form.Group>
                          <InputGroup>
                            <Form.Control
                              type="text"
                              name="name"
                              value={formData.name}
                              onChange={handleChange}
                              placeholder="Descipline "
                              id="inputEmail3"
                              className="input-mandatory"
                            />
                          </InputGroup>
                          <span className="error-message"></span>
                        </Form.Group>
                      </Row>
                    </div>
                  </Col>
                </Row>

                <Row className="mt-5 pb-3">
                  <div className="d-flex justify-content-center">
                    <Link>
                      <CancelButton
                        name={"cancel"}
                        handleClose={props.handleClose}
                      />
                    </Link>
                    <SaveButton name={"save"} handleSubmit={handleSubmit} />
                  </div>
                </Row>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>

      <ModalSave
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      />
    </>
  );
};

export default AddOffCanvance;
