import React, { useContext } from "react";
import { useState } from "react";
import "../../../Tabels/Tabels.css";
import { Context } from "../../../../utils/context";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";

import ModalSave from "../../../common/ModelSave";
import { CancelButton, SaveButton } from "../../../common/Button";
import { Row, Col, Form, Modal } from "react-bootstrap";
// import { useForm } from "react-hook-form";
import classNames from "classnames";
import JoditEditor from "jodit-react";
import { useForm, Controller } from "react-hook-form";

library.add(fas);

const AddOffCanvance = (props) => {
  const { postData, getDimension } = useContext(Context);

  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm();

  const videoFile = watch("video");
  const imageFile = watch("image");
  console.log(errors);
  const onSubmit = async (data) => {
    try {
      const fromData = new FormData();
      fromData.append("name", data?.name);

      const response = await postData(
        `/masters/job-portal/home/screening-question`,
        fromData
      );
      console.log(response);
      if (response?.success) {
        await setShowModal({ code: response.code, message: response.message });
      } else {
        await setShowModal({ code: response.code, message: response.message });
      }
      setTimeout(() => {
        setShowModal(0);
        props.handleClose();
      }, 1000);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Modal
        onHide={props.handleClose}
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Add Screening Question
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            onSubmit={() => handleSubmit(onSubmit)}
            role="form"
            className="countryclass"
          >
            <Row>
              {/* Title */}
              <Row>
                <Col lg={6}>
                  <div className="main-form-section mt-3">
                    <Row className="justify-content-center">
                      <Form.Label column sm={3} className="text-center">
                        Name
                      </Form.Label>
                      <Col sm={9}>
                        <Form.Group>
                          <Form.Control
                            className={classNames("", {
                              "is-invalid": errors?.name,
                            })}
                            type="text"
                            {...register("name", {
                              required: "name is required",
                            })}
                            placeholder="name"
                          />
                        </Form.Group>
                        {errors.name && (
                          <span className="text-danger">
                            {errors.name.message}
                          </span>
                        )}
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </Row>

            <Row className="justify-content-center mt-5 pb-3">
              <Col sm={6} className="d-flex justify-content-center">
                <CancelButton name={"Cancel"} handleClose={props.handleClose} />
                <SaveButton
                  name={"Save"}
                  handleSubmit={handleSubmit(onSubmit)}
                />
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>

      <ModalSave
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      />
    </>
  );
};

export default AddOffCanvance;
