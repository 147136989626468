import React, { useContext } from "react";
import { useState, useEffect, useRef } from "react";
import "../../../Tabels/Tabels.css";
import { Link } from "react-router-dom";
import { Context } from "../../../../utils/context";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import Offcanvas from "react-bootstrap/Offcanvas";
// *******************toster****************************
import Card from "react-bootstrap/Card";
import ModalSave from "../../../common/ModelSave";
import Validation from "../../../common/FormValidation";
import { CancelButton, SaveButton } from "../../../common/Button";
import Modal from "react-bootstrap/Modal";
import { Container, Row, Col, Button, Form, InputGroup } from "react-bootstrap";
import JoditEditor from "jodit-react";
import { useForm, Controller } from "react-hook-form";
import classNames from "classnames";
library.add(fas);

const EditOffCanvance = (props) => {
  const { postData, getData, IMG_URL, getDimension } = useContext(Context);

  const id = props.show;
  // const [data, setData] = useState();

  // const editor = useRef(null);
  // const [formData, setFormData] = useState({
  //   name: "",
  //   image: "",
  // });
  // const [imagePreview, setImagePreview] = useState(null);
  // const [content, setContent] = useState("");
  // const [errors, setErrors] = useState();
  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });

  const GetEditData = async () => {
    const response = await getData(
      `/masters/ed-tech/home/premier-universities/${id}`
    );
    console.log(response?.data);
    reset(response?.data);
  };

  useEffect(() => {
    GetEditData();
  }, []);

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    reset,
    watch,
  } = useForm();
  console.log(getValues());
  const imageFile = watch("image");

  const onSubmit = async (data) => {
    try {
      const fromData = new FormData();
      fromData.append("title", data?.title);
      fromData.append("description", data?.description);

      if (typeof data?.image !== "string") {
        fromData.append("image", data?.image[0]);
      }
      const response = await postData(
        `/masters/ed-tech/home/premier-universities/${id}`,
        fromData
      );
      console.log(response);
      if (response?.success) {
        await setShowModal({ code: response.code, message: response.message });
      } else {
        await setShowModal({ code: response.code, message: response.message });
      }
      setTimeout(() => {
        setShowModal(0);
        props.handleClose();
      }, 1000);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Modal
        onHide={props.handleClose}
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Edit Premier Universities
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            onSubmit={() => handleSubmit(onSubmit)}
            role="form"
            className="countryclass"
          >
            <Row>
              {/* Title */}
              <Col lg={6}>
                <div className="main-form-section mt-3">
                  <Form.Label>Title</Form.Label>

                  <Form.Group>
                    <Form.Control
                      className={classNames("", {
                        "is-invalid": errors?.title,
                      })}
                      type="text"
                      {...register("title", {
                        required: "Title is required",
                        maxLength: {
                          value: 23,
                          message: "Title must be at most 23 characters long",
                        },
                      })}
                      placeholder="Title"
                    />
                  </Form.Group>
                  {errors.title && (
                    <span className="text-danger">{errors.title.message}</span>
                  )}
                </div>

                <div className="main-form-section mt-3">
                  <Form.Label>Image</Form.Label>

                  <Form.Group>
                    <Form.Control
                      className={classNames("", {
                        "is-invalid": errors?.image,
                      })}
                      type="file"
                      {...register("image", {
                        // validate: async (value) => {
                        //   console.log(value);
                        //   if (typeof value !== "string") {
                        //     const fileTypes = ["jpg", "png", "jpeg", "JPG"];
                        //     const fileType = value[0]?.name?.split(".")[1];
                        //     if (!fileTypes.includes(fileType)) {
                        //       return `please upload a valid file format. (${fileTypes})`;
                        //     }
                        //     const sizes = await getDimension(value[0]);
                        //     if (
                        //       sizes.width !== 449 &&
                        //       sizes.height !== 70
                        //     ) {
                        //       return "Image width and height must be 449px and 70px";
                        //     }
                        //     const fileSize = Math.round(
                        //       value[0].size / 1024
                        //     );
                        //     if (fileSize > 500) {
                        //       return "file size must be lower than 500kb";
                        //     }
                        //   }
                        // },
                      })}
                      accept=".jpg, .jpeg, .png"
                    />
                  </Form.Group>
                  {errors.image && (
                    <span className="text-danger">{errors.image.message}</span>
                  )}
                </div>
              </Col>

              <Col lg={6}>
                <div className="main-form-section mt-3">
                  <Form.Label>Image Preview</Form.Label>

                  {typeof getValues("image") == "string" ? (
                    <div className="image-preview-container">
                      <img
                        src={IMG_URL + getValues("image")}
                        alt="Preview"
                        className="image-preview"
                        style={{ width: "100px", height: "100px" }}
                      />
                    </div>
                  ) : (
                    imageFile &&
                    imageFile?.length > 0 && (
                      <div className="image-preview-container">
                        <img
                          // src={URL.createObjectURL(getValues("image")[0])}
                          src={URL?.createObjectURL(imageFile[0])}
                          alt="Preview"
                          className="image-preview"
                          style={{ width: "100px", height: "100px" }}
                        />
                      </div>
                    )
                  )}
                </div>
              </Col>

              <Col lg={12}>
                <div className="main-form-section mt-3">
                  <Form.Label>Description</Form.Label>

                  <Form.Group>
                    <Controller
                      name="description" // Provide the field name
                      control={control} // Pass the control object from useForm()
                      rules={{
                        required: "Description is required",
                        maxLength: {
                          value: 130,
                          message: "Title must be at most 130 characters long",
                        },
                      }} // Validation rules
                      render={({ field }) => (
                        <JoditEditor
                          value={field.value}
                          onChange={(newContent) => field.onChange(newContent)}
                          onBlur={field.onBlur}
                          className={classNames("", {
                            "is-invalid": !!errors.description,
                          })}
                          placeholder="Description"
                        />
                      )}
                    />
                  </Form.Group>
                  {errors.description && (
                    <span className="text-danger">
                      {errors.description.message}
                    </span>
                  )}
                </div>
              </Col>

              {/* Image Upload */}

              {/* Image Preview */}
            </Row>

            {/* Buttons */}
            <Row className="justify-content-center mt-5 pb-3">
              <Col sm={6} className="d-flex justify-content-center">
                <CancelButton name={"Cancel"} handleClose={props.handleClose} />
                <SaveButton
                  name={"Save"}
                  handleSubmit={handleSubmit(onSubmit)}
                />
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>

      <ModalSave
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      />
    </>
  );
};

export default EditOffCanvance;
