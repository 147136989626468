import React, { useContext } from "react";
import { useState, useEffect, useRef } from "react";
import "../../Tabels/Tabels.css";
import { Link } from "react-router-dom";
import { Context } from "../../../utils/context";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import Offcanvas from "react-bootstrap/Offcanvas";
// *******************toster****************************
import Card from "react-bootstrap/Card";
import ModalSave from "../../common/ModelSave";
import Validation from "../../common/FormValidation";
import { CancelButton, SaveButton } from "../../common/Button";
import Modal from "react-bootstrap/Modal";
import { Container, Row, Col, Button, Form, InputGroup } from "react-bootstrap";
import JoditEditor from "jodit-react";
import Select from "react-select";
import classNames from "classnames";
import { useForm, Controller } from "react-hook-form";
library.add(fas);

const EditOffCanvance = (props) => {
  const { postData, getData, IMG_URL, getDimension ,Select2Data} = useContext(Context);
  const [university, setUniversity] = useState([]);
  const id = props.show;
  
  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });
  

  const GetEditData = async () => {
    const response = await getData(
      `/masters/skills/${id}`
    );
    console.log(response.data);
    reset(response?.data);
  };

  useEffect(() => {
    GetEditData();
  }, []);

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    reset,
    watch,
  } = useForm();
  console.log(getValues());
  const imageFile = watch("image");

  const GetAllUniversity = async () => {
    const response = await getData("/allskilltype");

    if (response.success) {
      setUniversity(await Select2Data(response?.data, "skill_type_id"));
    }
  };
  useEffect(() => {
    GetAllUniversity();    
  }, []);

  const onSubmit = async (data) => {
    try {
      const fromData = new FormData();
      fromData.append("name", data?.name);
      fromData.append("skill_type_id", data?.skill_type_id?.value);
      
      const response = await postData(
        `/masters/skills/${id}`,
        fromData
      );
      console.log(response);
      if (response?.success) {
        await setShowModal({ code: response.code, message: response.message });
      } else {
        await setShowModal({ code: response.code, message: response.message });
      }
      setTimeout(() => {
        setShowModal(0);
        props.handleClose();
      }, 1000);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Modal
        onHide={props.handleClose}
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Edit Skill Name
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            onSubmit={() => handleSubmit(onSubmit)}
            role="form"
            className="countryclass"
          >
            <Row>
              {/* Title */}

              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center ">
                  <Form.Label column sm={3} className="text-center">
                      Skill type
                    </Form.Label>
                    <Col sm={9}>
                    <Controller
                      name="skill_type_id"
                      {...register("skill_type_id", {
                        required: "skill type is required",
                      })}
                      control={control}
                      render={({ field }) => (
                        <Select
                          styles={{
                            control: (baseStyles) => ({
                              ...baseStyles,
                              borderColor: errors.skill_type_id
                                ? "red"
                                : baseStyles,
                            }),
                          }}
                          {...field}
                          options={university}
                        />
                      )}
                    />
                    </Col>
                    {errors.skill_type_id && (
                      <span className="text-danger">
                        {errors.skill_type_id.message}
                      </span>
                    )}
                  </Row>
                </div>
              </Col>
              <Col lg={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label column sm={3} className="text-center">
                      Name
                    </Form.Label>
                    <Col sm={9}>
                      <Form.Group>
                        <Form.Control
                          className={classNames("", {
                            "is-invalid": errors?.name,
                          })}
                          type="text"
                          {...register("name", {
                            required: "Title is required",
                          })}
                          placeholder="Title"
                        />
                      </Form.Group>
                      {errors.name && (
                        <span className="text-danger">
                          {errors.name.message}
                        </span>
                      )}
                    </Col>
                  </Row>
                </div>
              </Col>

              
            </Row>

            {/* Buttons */}
            <Row className="justify-content-center mt-5 pb-3">
              <Col sm={6} className="d-flex justify-content-center">
                <CancelButton name={"Cancel"} handleClose={props.handleClose} />
                <SaveButton
                  name={"Save"}
                  handleSubmit={handleSubmit(onSubmit)}
                />
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>

      <ModalSave
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      />
    </>
  );
};

export default EditOffCanvance;


